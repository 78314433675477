import { AEngine } from "../core/AEngine.js";
import { createArray } from "../utils/tools.js";
import { densityOptions, mapStyleOptions } from "./AMapHelperService.js";
export var APrefs;
(function (APrefs) {
    // HIDE_MAP_LABELS = 'aci-map-hide-labels',
    APrefs["MENU_OPEN"] = "MENU-OPEN";
    APrefs["BG_COLOR"] = "BACKGROUND-COLOR";
    APrefs["HIDE_IMAGE_VIEW"] = "IMAGE-VIEW-CLOSED";
    APrefs["LAYOUT_ADVANCED"] = "LAYOUT-VIEW-ADVANCED";
    APrefs["VERIFICATION_CHANNELS"] = "VERIFICATION_CHANNELS";
    APrefs["SESSION_MODE"] = "SESSION_MODE";
    APrefs["MAP_STYLE"] = "MAP_STYLE";
    APrefs["MAP_LABEL_DENSITY"] = "MAP_LABEL_DENSITY";
    APrefs["MAP_LANDMARK_DENSITY"] = "MAP_LANDMARK_DENSITY";
    APrefs["USER_ACTIONS"] = "usr-actions";
})(APrefs || (APrefs = {}));
export class APreferenceService {
    constructor() {
        Object.defineProperty(window, 'Preferences', {
            get: () => this
        });
    }
    getFormInputsAll() {
        AEngine.log('APrefs', Object.keys(APrefs));
        const pairs = {
            USER_ACTIONS: { hidden: true },
            VERIFICATION_CHANNELS: { hidden: true },
            SESSION_MODE: { hidden: true },
            MAP_STYLE: {
                id: 'mapStyle',
                type: 'select',
                width: 'col-12',
                options: mapStyleOptions,
            },
            MAP_LABEL_DENSITY: {
                id: 'labelDensity',
                type: 'select',
                options: densityOptions
            },
            MAP_LANDMARK_DENSITY: {
                id: 'landmarkDensity',
                type: 'select',
                options: densityOptions,
            },
            MENU_OPEN: {
                type: 'checkbox',
                desc: 'Expand Side Menu',
            },
            HIDE_IMAGE_VIEW: {
                type: 'checkbox',
                desc: 'Hide Image View',
            },
            LAYOUT_ADVANCED: {
                type: 'checkbox',
                desc: 'Display Simple View For Maps',
            },
            BG_COLOR: {
                type: 'select',
                desc: 'Background Color',
                width: 'col-12',
                options: [
                    'gradient-default',
                    ...createArray(36).map((_, i) => `gradient-${i + 1}`)
                ]
            },
        };
        for (let key in pairs) {
            let inp = pairs[key];
            if (inp.hidden !== true) {
                inp.id = APrefs[key];
                inp.value = this.get(APrefs[key]);
                inp.label = inp.label ?? inp.desc;
            }
        }
        return pairs;
    }
    setAll(pairs) {
        Object.keys(pairs).map((key) => {
            const value = pairs[key];
            this.set(key, value);
        });
    }
    /**
     * Same functionality as save(key, object)
     */
    set(key, object) {
        this.save(key, object);
    }
    save(key, object) {
        const json = JSON.stringify(object);
        localStorage.setItem(key, json);
    }
    /**
     * Same functionality as load(key, object)
     */
    get(key) {
        return this.load(key);
    }
    load(key, defaultValue = null) {
        const item = localStorage.getItem(key);
        return (item !== null) ? JSON.parse(item) : defaultValue;
    }
}

import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, COLUMN_NUMBER, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AImageHelper } from "../../classes/AImageHelper.js";
import { AEngine } from "../../core/AEngine.js";
import { locationimage_tableformatter } from "../../utils/table_formatter.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { MAP_OPTIONS, UNLOAD_OPTIONS } from "../../services/AMapHelperService.js";
import { APrefs } from "../../services/APreferenceService.js";
import { createMap } from "../../utils/maps.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable, AUrlEncodedImageFromBase64, getMarkerBounds, mergeDeep } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.imageHelper = new AImageHelper();
        this.map = createMap('map', {
            streetViewControl: true,
        });
        this.dragService = AEngine.get(ADragService);
    }
    async init() {
        await mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: false,
        }).catch(AError.handle);
        this.setupUI().catch(AError.handle);
        this.dragService.createDraggable();
        Events.on(EVENTS.CONTENT_RESIZE, _ => {
            google.maps.event.trigger(this.map, 'resize');
            google.maps.event.trigger(this.map.streetView, 'resize');
        });
        this.map.fit();
        // TODO: Find out why this gives errors
        // mapHelperService.displaySessionsOnMap({
        //   interpolate: false,
        //   sessions: this.SessionMarkers
        // })
        await this.initLocationTypeFilter();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        this.refresh().catch(AError.handle);
    }
    async initLocationTypeFilter() {
        const res = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT l.LocationType as Value, l.LocationType FROM (
          SELECT LocationType FROM locations
          UNION SELECT LocationType FROM location_images
          GROUP BY LocationType
        ) l
      `)
        });
        const locationTypes = res.map(({ Value, LocationType }) => ({ Value, LocationType }));
        $('#LocationType').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let { Value, LocationType } of locationTypes) {
                $(this).append(`<option value="${Value}">${LocationType}</option>`);
            }
        });
    }
    async setupUI() {
        const $splitter = $('.splitter');
        const $toggle = $('#toggle-images');
        $toggle.on('click', _ => this.toggleImageView({ $splitter, $toggle }));
        if (preferenceService.load(APrefs.HIDE_IMAGE_VIEW, false)) {
            this.toggleImageView({ $splitter, $toggle });
        }
    }
    toggleImageView({ $toggle, $splitter }) {
        $toggle.toggleClass('toggled');
        $toggle.children().toggleClass('hidden');
        $splitter.toggleClass('v-expand');
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
        preferenceService.save(APrefs.HIDE_IMAGE_VIEW, $splitter.hasClass('v-expand'));
    }
    gpsPointToLatLng(GpsPoint) {
        return new google.maps.LatLng(GpsPoint.coordinates[1], GpsPoint.coordinates[0]);
    }
    async fetchLocation(params) {
        return await Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT LocationId, CreationDeviceId, LocationType, Name, Description, GpsPoint, BeginTimeFrame, EndTimeFrame, Days, ExpiryDate, CreationTime, CreationUser
          FROM locations
          WHERE LocationId=:LocationId AND CreationDeviceId=:CreationDeviceId
        `),
            Params: params
        }));
    }
    fetchImages(record) {
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT LocationId, CreationDeviceId, ImageDeviceId, ImageIndex, LocationType, Name, ImageTime, Image
          FROM location_images WHERE LocationId=:LocationId
        `),
            Params: { LocationId: record.LocationId }
        }));
    }
    async onMarkerClick(record) {
        const position = this.gpsPointToLatLng(record.GpsPoint);
        this.map.setCenter(position);
        this.imageHelper.clearImageSet();
        const location = record.LocationId !== null ? await this.fetchLocation(record) : undefined;
        const res = await this.fetchImages(record);
        const imageOptions = {
            allowFilter: true,
            allowFullscreen: true,
            defaultSize: {
                height: this.imageHelper.$photos.height()
            }
        };
        this.imageHelper.addImageSet(res.map(({ Image }) => AUrlEncodedImageFromBase64(Image)), imageOptions);
        const data = location?.First || record;
        purgatoryService.buildAndShowInfoWindow({
            marker: this.gpsPointToLatLng(record.GpsPoint),
            data,
            // TODO: Implement sorting
            sorting: [],
            parent: $('.part-one'),
            tableFormatter: locationimage_tableformatter()
        });
        this.grid.selectedRecord = this.grid.store.find((gridRecord) => {
            return record.LocationId == gridRecord.LocationId
                && record.LocationType == gridRecord.LocationType
                && record.Name == gridRecord.Name;
        });
    }
    getQuery(filters) {
        return (filters.ListType === undefined) ? (`
      SELECT
        COUNT(*) as Images,
        LocationId,
        LocationType,
        Name,
        ImageTime,
        ST_AsGeoJSON(GpsPoint) as GpsPoint,
        CreationDeviceId
      FROM location_images
      WHERE IF(:LocationType IS NULL, true, LocationType=:LocationType)
      GROUP BY LocationId, LocationType, Name
      ORDER BY ImageTime ${filters.OrderBy}
      LIMIT :Limit
    `) : (`
      SELECT
        SUM(IF(i.LocationId IS NULL, 0, 1)) as Images,
        LocationId,
        l.LocationType,
        l.Name,
        MAX(ImageTime) as ImageTime,
        ST_AsGeoJSON(l.GpsPoint) as GpsPoint,
        l.CreationDeviceId
      FROM locations l
      LEFT JOIN location_images i USING (LocationId)
      WHERE ExpiryDate < NOW() AND IF(:LocationType IS NULL, true, l.LocationType=:LocationType)
      GROUP BY LocationId, LocationType, Name
      ORDER BY MAX(ImageTime) ${filters.OrderBy}
      Limit :Limit
    `);
    }
    refresh() {
        const filters = FilterManager.saveExplicit();
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: this.getQuery(filters),
            Params: mergeDeep({ LocationType: null }, filters),
            Language: Language
        })).then((res) => {
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(res.Original, {
                    'LocationId': COLUMN_TEXT,
                    'Images': COLUMN_NUMBER,
                    'LocationType': COLUMN_TEXT,
                    'Name': COLUMN_TEXT,
                    'ImageTime': COLUMN_DATETIME,
                    'CreationDeviceId': { hidden: true },
                    'GpsPoint': { hidden: true },
                }),
                data: AConvertToGridData(res.Original, {
                    'ImageTime': DATA_DATETIME
                })
            });
            this.grid.on('cellclick', ({ record }) => {
                this.onMarkerClick(record.data);
            });
            if (this.locationMarkers) {
                mapHelperService.unload(this.locationMarkers, UNLOAD_OPTIONS.Default);
                mapHelperService.destroy(this.locationMarkers);
            }
            this.locationMarkers = res.map((row) => {
                const position = this.gpsPointToLatLng(row.GpsPoint);
                let marker = new google.maps.Marker({
                    position: position,
                    icon: (Number(row.Images) > 0) ? '/img/marker1-g.png' : '/img/marker1.png',
                    map: this.map,
                });
                marker.addListener('click', () => this.onMarkerClick(row));
                return marker;
            }).filter(v => v !== undefined);
            this.map.fitBounds(getMarkerBounds(this.locationMarkers));
        }).catch(AError.handle);
    }
}
export function css() {
    return ( /*html*/`
    <style>
      #AjaxContent .wrapper.md {
        height: calc(50% - 79px);
      }

      .splitter .aci-map {
        height: 100%;
      }

      .splitter #streetview {
        height: 100%;
      }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column col-12">
        <div class="form-group">
          <label class="form-label" for="ListType">Display</label>
          <select class="form-select" id="ListType">
            <option value="REQUESTED_LOCATIONS" selected="selected">Requested Locations</option>
            <option value="%">All Images</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="OrderBy">Sort</label>
          <select class="form-select" id="OrderBy">
            <option value="DESC" selected="selected">Descending (New - Old)</option>
            <option value="ASC">Ascending (Old - New)</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="LocationType">LocationType</label>
          <select class="form-select" id="LocationType">
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child child-relative no-y-overflow">
      <button id="toggle-images" class="img-tab-toggle">
        <div>
          <i class="fa-solid fa-angle-down"></i>
          Hide Image View
        </div>
        <div class="hidden">
          <i class="fa-solid fa-angle-up"></i>
          Show Image View
        </div>
      </button>

      <div class="splitter two-split" style="overflow: hidden; height: calc(100% - 200px);">
        <div class="drag-section part-one">
          <div id="table-bryntum" class="fh"></div>
        </div>
        
        <div id="separator1" class="drag-seperator separator"></div>

        <div class="drag-section part-two">
          <div id="map" class="aci-map"></div>
        </div>
      </div>
      <div class="photos photos-scroll-container photos-size-200">
        <div show-if-no-results class="v-padding-2">
          <div class="v-padding">
            <div class="fw text-center">
              <i class="fa-solid fa-folder-magnifying-glass fa-4x"></i>
            </div>
            <div class="no-images-warning">
              Could not find any photos!
            </div>
          </div>
        </div>
      </div>
    </div>
  `);
}

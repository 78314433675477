import { CHART_TYPE } from "../classes/ADetectionStatistics.js";
import { AKpiBlockChannels } from "./AKpiBlockChannels.js";
import { AKpiBlockChartAggragation } from "./AKpiBlockChartAggregation.js";
import { AKpiBlockDetectionStateTable } from "./AKpiBlockDetectionStateTable.js";
import { AKpiBlockKpiChart } from "./AKpiBlockKpiChart.js";
import { AKpiBlockMap } from "./AKpiBlockMap.js";
import { AKpiBlockOldestDetectionTable } from "./AKpiBlockOldestDetectionTable.js";
import { AKpiBlockSanctions } from "./AKpiBlockSanctions.js";
import { AKpiBlockPerformance } from "./AKpiBlockPerformance.js";
import { AKpiBlockServerChart } from "./AKpiBlockServerChart.js";
import { AKpiBlockTreeBreakdown } from "./AKpiBlockTreeBreakdown.js";
import { AKpiBlockUnifications } from "./AKpiBlockUnifications.js";
import { AKpiSystem } from "./AKpiSystem.js";
import { AKpiBlockSanctionsAdvanced } from "./AKpiBlockSanctionsAdvanced.js";
import { AKpiBlockScansPerTimeUnit } from "./AKpiBlockScansPerTimeUnit.js";
import { AKpiBlockTimeDistrubution } from "./AKpiBlockTimeDistrubution.js";
import { AKpiBlockDeprecated } from "./AKpiBlockDeprecated.js";
import { AKpiBlockUnhandledQueue } from "./AKpiBlockUnhandledQueue.js";
import { ADynamicChartUtils } from "../charts/ADynamicChartUtils.js";
export const globalKpiWidthOptions = [
    { value: 'col-2', text: '16 %' },
    { value: 'col-3', text: '25 %' },
    { value: 'col-4', text: '33 %' },
    { value: 'col-6', text: '50 %' },
    { value: 'col-8', text: '66 %' },
    { value: 'col-9', text: '75 %' },
    { value: 'col-12', text: '100 %' },
];
export const globalKpiConfig = {
    // 'AKpiBlockVehicleSpeed': {
    //   getUniqueName: (settings) => AKpiSystem.calcCombination(settings['horizontalAxis'], settings['verticalAxis']),
    //   getCls: () => AKpiBlockVehicleSpeed,
    //   shouldCheckSubOption: false,
    //   async getSettings() {
    //     const axisOptions = [
    //       'Area','Zone','WaySegment', 'HourOfDay','DayOfWeek','MonthOfYear',{key: 'DetectionDevice', text: 'DetectionDevice'}
    //   ].map(key => typeof key === 'string' ? ({ key, text: key }) : key)
    //     return {
    //       horizontalAxis: axisOptions, // TODO: Implement translations
    //       verticalAxis: axisOptions, // TODO: Implement translations
    //     }
    //   },
    // },
    'AKpiBlockDeprecated': {
        overrideKpiType: 'Deprecated KPI',
        getUniqueName: (settings) => AKpiSystem.calcCombination('Deprecated'),
        getCls: () => AKpiBlockDeprecated,
        shouldCheckSubOption: false,
        hasImage: false,
    },
    'AKpiBlockKpiChart': {
        overrideKpiType: 'Custom Chart Detections',
        getUniqueName: (settings) => AKpiSystem.calcCombination(settings['kpiName']),
        getCls: () => AKpiBlockKpiChart,
        shouldCheckSubOption: false,
        hasImage: true,
        async getSettings() {
            const customKpiTypes = await widgetService.fetchAllCustomCharts({ DataSource: 'detections' });
            return {
                kpiName: customKpiTypes.map((v) => {
                    return { key: v.Name, text: v.Name, translate: false };
                })
            };
        },
        validate(settings) {
            return (settings['kpiName'] || '').length > 0;
        }
    },
    'AKpiBlockChartAggragation': {
        overrideKpiType: 'Custom Chart Aggregation',
        getUniqueName: (settings) => AKpiSystem.calcCombination(settings['kpiName']),
        getCls: () => AKpiBlockChartAggragation,
        shouldCheckSubOption: false,
        hasImage: false,
        async getSettings() {
            const customKpiTypes = await widgetService.fetchAllCustomCharts({ DataSource: 'aggregation' });
            return {
                kpiName: customKpiTypes.map((v) => {
                    return { key: v.Name, text: v.Name, translate: false };
                })
            };
        },
        validate(settings) {
            return (settings['kpiName'] || '').length > 0;
        }
    },
    'AKpiBlockUnhandledQueue': {
        overrideKpiType: 'UnhandledVerificationQueue',
        getUniqueName: (settings) => AKpiSystem.calcCombination('UnhandledVerificationQueue', settings['GroupBy']),
        getCls: () => AKpiBlockUnhandledQueue,
        shouldCheckSubOption: true,
        hasImage: false,
        async getSettings() {
            return {
                ChartType: ADynamicChartUtils.dynamicChartSettings.chartType.map((opt) => Object.assign({ translate: true }, opt)),
                GroupBy: [
                    { key: 'HourOfDay', text: 'HourOfDay', translate: true },
                    { key: 'DayOfWeek', text: 'DayOfWeek', translate: true },
                    { key: 'MonthOfYear', text: 'MonthOfYear', translate: true },
                    { key: 'Year', text: 'Year', translate: true },
                ]
            };
        }
    },
    'AKpiBlockSanctions': {
        getUniqueName: (settings) => AKpiSystem.calcCombination('Sanctions', settings['displayLegend']),
        getCls: () => AKpiBlockSanctions,
        shouldCheckSubOption: true,
        hasImage: true,
        async getSettings() {
            const displayLegend = [
                'yes', 'no'
            ];
            return {
                displayLegend: displayLegend.map((key) => ({ key: key, text: key }))
            };
        },
    },
    'AKpiBlockSanctionsAdvanced': {
        overrideKpiType: 'Sanctions Advanced',
        getUniqueName: (settings) => AKpiSystem.calcCombination('Sanctions Advanced', settings['GroupBy']),
        getCls: () => AKpiBlockSanctionsAdvanced,
        shouldCheckSubOption: true,
        hasImage: false,
        async getSettings() {
            return {
                GroupBy: [
                    { key: 'VerificationUser', text: 'VerificationUser', translate: true },
                    { key: 'VerificationDeviceType', text: 'VerificationDeviceType', translate: true },
                    { key: 'HourOfDay', text: 'HourOfDay', translate: true },
                    // { key: 'VerificationDevice', text: 'VerificationDevice', translate: true },
                    // { key: 'DetectionDeviceId', text: 'DetectionDevice', translate: true },
                    // { key: 'ScanDeviceType', text: 'ScanDeviceType', translate: true },
                    // { key: 'ScansPerHour', text: 'ScansPerHour', transformAxisCategories: true },
                ]
            };
        },
    },
    'AKpiBlockPerformance': {
        overrideKpiType: 'User Performance',
        getUniqueName: (settings) => AKpiSystem.calcCombination('User Performance', settings['GroupBy']),
        getCls: () => AKpiBlockPerformance,
        shouldCheckSubOption: false,
        hasImage: false,
        async getSettings() {
            return {
                GroupBy: [
                    { key: 'DetectionUser', text: 'DetectionUser', translate: true },
                    { key: 'CentralVerification', text: 'CentralVerification', translate: true },
                    { key: 'Pda', text: 'Pda', translate: true },
                    // { key: 'ScansPerHour', text: 'ScansPerHour', transformAxisCategories: true },
                ]
            };
        },
    },
    'AKpiBlockTimeDistribution': {
        overrideKpiType: 'Time Distribution',
        getUniqueName: (settings) => AKpiSystem.calcCombination('TimeDistribution'),
        getCls: () => AKpiBlockTimeDistrubution,
        shouldCheckSubOption: false,
        hasImage: false,
    },
    'AKpiBlockScansPerTimeUnit': {
        overrideKpiType: 'ScansPerTimeUnit',
        getUniqueName: (settings) => AKpiSystem.calcCombination('ScansPerTimeUnit'),
        getCls: () => AKpiBlockScansPerTimeUnit,
        shouldCheckSubOption: false,
        hasImage: false,
    },
    // 'AKpiBlockThematicChart': {
    //   overrideKpiType: 'Custom Chart Aggregation (deprecated)',
    //   getUniqueName: (settings) => AKpiSystem.calcCombination(settings['mapTo'], settings['verticalAxis']),
    //   getCls: () => AKpiBlockThematicChart,
    //   shouldCheckSubOption: false,
    //   async getSettings() {
    //     return {
    //       chartType: [
    //         { key: 'column', text: 'Column Chart' },
    //         { key: 'line', text: 'Line Chart' },
    //         { key: 'spline', text: 'Spline Chart' },
    //         { key: 'area', text: 'Area Chart' },
    //         { key: 'areaspline', text: 'Area Spline Chart' },
    //         { key: 'scatter', text: 'Scatter Chart' },
    //         { key: 'pie', text: 'Pie Chart' },
    //       ],
    //       mapTo: [
    //         { key: 'Street', text: 'Street' },
    //         { key: 'Zone', text: 'Zone' },
    //         { key: 'Area', text: 'Area' },
    //       ],
    //       verticalAxis: [
    //         { key: 'Detections', text: 'Detections' },
    //         { key: 'Capacity', text: 'Capacity' },
    //         { key: 'Occupancy', text: 'Occupancy' },
    //         { key: 'VisitorRate', text: 'VisitorRate' },
    //         { key: 'PermitRate', text: 'PermitRate' },
    //         { key: 'Compliancy', text: 'Compliancy' },
    //         { key: 'CompliancyVisitor', text: 'CompliancyVisitor' },
    //         { key: 'EnforcementIntensity', text: 'EnforcementIntensity' },
    //       ],
    //     }
    //   },
    // },
    'AKpiBlockServerChart': {
        getUniqueName: (settings) => settings['viewType'],
        getCls: () => AKpiBlockServerChart,
        shouldCheckSubOption: true,
        hasImage: true,
        realtimeOnly: true,
        async getSettings() {
            const viewTypes = ['Control Center | All', 'Control Center | Process', 'Control Center | Operational'];
            return {
                viewType: viewTypes.map((key) => ({ key, text: key }))
            };
        },
    },
    'AKpiBlockUnifications': {
        getUniqueName: (settings) => settings['chartType'],
        getCls: () => AKpiBlockUnifications,
        shouldCheckSubOption: true,
        hasImage: true,
        async getSettings() {
            const chartTypes = [
                CHART_TYPE.DIGITAL, CHART_TYPE.ILLEGALY_PARKED, CHART_TYPE.OCCUPANCY, CHART_TYPE.PARKING_RIGHT,
                CHART_TYPE.TIME_LIMITED_PARKING, CHART_TYPE.VERIFICATION, CHART_TYPE.DETECTION_STATE
            ];
            return {
                chartType: chartTypes.map((key) => ({ key: key, text: key }))
            };
        },
    },
    'AKpiBlockTreeBreakdown': {
        getUniqueName: (settings) => `Tree Breakdown`,
        getCls: () => AKpiBlockTreeBreakdown,
        shouldCheckSubOption: true
    },
    'AKpiBlockMap': {
        getUniqueName: (settings) => `Map`,
        getCls: () => AKpiBlockMap,
        shouldCheckSubOption: true,
        hasImage: true,
        realtimeOnly: true
    },
    'AKpiBlockDetectionStateTable': {
        getUniqueName: (settings) => `DetectionStateDuration`,
        getCls: () => AKpiBlockDetectionStateTable,
        shouldCheckSubOption: true,
        hasImage: true,
    },
    'AKpiBlockOldestDetectionTable': {
        getUniqueName: (settings) => `DetectionState Oldest Detection`,
        getCls: () => AKpiBlockOldestDetectionTable,
        shouldCheckSubOption: true
    },
    'AKpiBlockChannels': {
        overrideKpiType: 'Channel Queues',
        getUniqueName: (settings) => `Channel Queues`,
        getCls: () => AKpiBlockChannels,
        shouldCheckSubOption: true,
        hasImage: true,
    },
};

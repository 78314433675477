export class ASearchHandler {
    constructor() {
        this.prevSearch = '';
    }
    get grid() {
        return PageScript.grid;
    }
    applyFilter() {
        const { found, find } = this.grid.features['search'];
        if (this.prevSearch === find || find === undefined) {
            return;
        }
        this.prevSearch = find;
        const ids = found.map(row => row.data.id);
        console.log('ids', ids);
        // const searchColumns = columns.visibleColumns.filter(c => c['searchable'] === true).map(c => c['field'])
        this.grid.store.filter({
            id: 'searchFilter',
            filterBy: (record) => ids.includes(record['id']),
        });
    }
    onSearchFieldClear() {
        this.grid.features.search.clear();
    }
    onSearchFieldChange(a) {
        this.grid.store.removeFilter('searchFilter');
        const nonHtmlEncodeFields = [...PageScript.grid.columns].map(c => c.data).filter(c => c.htmlEncode !== false).map(c => c.field);
        this.grid.features.search.search(a.value, false, false, nonHtmlEncodeFields);
    }
}

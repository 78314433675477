import { AError } from "../../classes/AError.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertMillisecondsToHM, AFormatDate, AInputDate, AInputTime, createSelectScanDeviceListHtmlAll, estimateRouteDistanceFromParkingStreetDistance, estimateRouteDurationFromDistance, estimateRouteDurationFromParkingStreetDistance, metersToKilometerText, secondsToDurationTextHHMM } from "../../utils/tools.js";
import { createMap } from "../../utils/maps.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { AColorHSV } from "../../classes/AColorHSV.js";
export class APage {
    constructor() {
        this.quickSelectBtns = {
            'Today': {
                onClick: ($filters) => {
                    $filters.find('#FromDate').val(AInputDate(new Date()));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(new Date()));
                    $filters.find('#ToTime').val('23:00');
                    FilterManager.selectShortcut('.quick-4');
                }
            },
            'Tomorrow': {
                onClick: ($filters) => {
                    const { tomorrowDate } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(tomorrowDate));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(tomorrowDate));
                    $filters.find('#ToTime').val('23:00');
                    FilterManager.selectShortcut('.quick-4');
                }
            },
            'Yesterday': {
                onClick: ($filters) => {
                    const { yesterdayDate } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(yesterdayDate));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(yesterdayDate));
                    $filters.find('#ToTime').val('23:00');
                    FilterManager.selectShortcut('.quick-4');
                }
            },
            'This Week': {
                onClick: ($filters) => {
                    const { startOfWeek, endOfWeek } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(startOfWeek));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(endOfWeek));
                    $filters.find('#ToTime').val('23:00');
                    FilterManager.selectShortcut('.quick-4');
                }
            },
            'Next Week': {
                onClick: ($filters) => {
                    const { startOfNextWeek, endOfNextWeek } = FilterManager;
                    $filters.find('#FromDate').val(AInputDate(startOfNextWeek));
                    $filters.find('#FromTime').val('06:00');
                    $filters.find('#ToDate').val(AInputDate(endOfNextWeek));
                    $filters.find('#ToTime').val('23:00');
                    FilterManager.selectShortcut('.quick-4');
                }
            }
        };
        FilterManager.load();
        $('#FromTime').val("06:00");
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        this.plannedRoutes = [];
        this.drivenRoutes = [];
        this.Routes = [];
        this.Requests = [];
        this.regimes = [];
        this.planning_map = $('#planning_map'); // Create JQuery collection with id='map'
        this.planning_map = createMap('planning_map', {
            zoom: 24
        });
        this.planning_map.fit();
        const legend = document.getElementById("planning_map_legend");
        this.planning_map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
        this.translations = await Loading.waitForPromises(Translate.get([
            'To',
            'From',
            'Occupancy',
            'VisitorRate',
            'Compliancy',
            'CompliancyVisitors',
            'EnforcementIntensity',
            'Device',
            'Route',
            'Date',
            'Save',
            'Send',
            'Back',
            'Next',
            'Edit Planning',
            'New Planning',
            'Add Break',
            'Edit Break',
            'Delete Break',
            'Resend Planning',
            'Delete Planning',
            'To Car',
            'Sort RouteAreas on',
            'Last Enforcement',
            'Routes Planned',
            'Date Start',
            'Date Finish',
            'Start',
            'Finish',
            'Name',
            'Estimate length',
            'Estimate duration',
            'Route Name',
            'Duration',
            'Now',
            'Warning: Overlapping Planning',
            'New Planning Overlaps With',
            'Continue',
            'Not connected',
            'Car is currenly not connected, route will be send when connected',
            'Received',
            'Route is received',
            'Create',
            'Visitor Rate',
            'Parking Pressure',
            'Enforcement Intensity',
            'Filter Regimes'
        ]));
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            aqua: new AColorHSV(180, 63, 96),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        this.subscribeToRouteServiceMsgs();
        $('#btn_add_planning_routes').on('click', _ => this.alert_newPlanningRoutes());
        $('#btn_add_planning_routearea').on('click', _ => this.alert_newPlanningRouteArea());
        $('#btn_add_break').on('click', _ => this.alert_newBreak());
        $('#btn_reset_view').on('click', _ => this.resetView());
        await Loading.waitForPromises([
            this.getGeoMap()
        ]).catch(AError.handle);
        this.refresh();
    }
    subscribeToRouteServiceMsgs() {
        Events.on(`PlanRoute_ResendRouteToCarByShareId_Response`, response => {
            if (response.State == "NotOnline") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Not connected'],
                    content: this.translations['Car is currenly not connected, route will be send when connected']
                });
            }
            else if (response.State == "Ok") {
                Alerts.show({
                    type: ALERTS.Form,
                    translatedTitle: this.translations['Received'],
                    content: this.translations['Route is received']
                });
            }
            else {
                Alerts.show({
                    title: ALERT_TITLES.Error,
                    content: response.StateText,
                    type: ALERTS.Form,
                });
            }
        });
    }
    async getRoutesAndRequests() {
        await Loading.waitForPromises([
            this.getRoutes(),
            this.getRequests()
        ]);
        Promise.resolve();
    }
    async refresh() {
        let $page = this;
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        await Loading.waitForPromises([
            this.getPlannedRoutes(),
            this.getDrivenRoutes(),
            this.getRoutesAndRequests()
        ]);
        this.mergeDrivenWithPlanned();
        function verticalTimeLines() {
            let result = [
                {
                    color: "#FF0000",
                    width: 2,
                    value: new Date().getTime(),
                    label: {
                        text: $page.translations['Now'],
                        y: -5,
                        x: -1,
                        align: 'center',
                        rotation: 0
                    },
                    zIndex: 10
                }
            ];
            let $date = new Date(filters.FromDate);
            $date.setMinutes(0);
            $date.setSeconds(0);
            $date.setMilliseconds(0);
            const $to = new Date(filters.ToDate);
            do {
                $date.setHours($date.getHours() + 1);
                result.push({
                    color: "#f0f0f0",
                    width: 1,
                    value: $date.getTime(),
                    zIndex: 0
                });
            } while ($date < $to);
            return result;
        }
        function seriesPointWidth() {
            switch (Object.keys($page.plannedRoutes).length) {
                case 1: return 100;
                case 2: return 85;
                case 3: return 75;
                case 4: return 60;
                case 5: return 50;
                case 6: return 45;
                case 7: return 40;
                case 8: return 35;
                case 9: return 30;
                case 10: return 25;
                default: return 20;
            }
        }
        const data = this.transformPlannedRoutes();
        try {
            this.chart = Highcharts.chart({
                chart: {
                    renderTo: 'timeline',
                    type: 'xrange',
                    zooming: { type: 'x', resetButton: { position: { align: 'left', verticalAlign: 'top' } } },
                    events: {
                        redraw() {
                            // @ts-ignore // TODO: FIX HIGHCHARTS
                            if (this.resetZoomButton)
                                this.resetZoomButton.hide();
                            $('#btn_reset_view').prop('disabled', false);
                            $page.redrawEvent();
                        },
                        click: function () {
                            $page.resetView();
                        }
                    }
                },
                title: {
                    text: this.translations['Routes Planned']
                },
                xAxis: {
                    type: 'datetime',
                    min: new Date(filters.FromDate).getTime(),
                    max: new Date(filters.ToDate).getTime(),
                    tickInterval: 3600 * 500,
                    plotLines: verticalTimeLines()
                },
                yAxis: {
                    title: { text: '' },
                    categories: Object.keys(this.plannedRoutes),
                    reversed: true
                },
                plotOptions: {
                    series: {
                        allowPointSelect: true,
                        events: {
                            click: function (e) {
                                if ($page.geoMap != undefined && $page.geoMap.WaySegments != undefined) {
                                    $page.onPlanningClicked(e.point);
                                }
                            }
                        }
                    }
                },
                tooltip: {
                    formatter: function () {
                        // @ts-ignore
                        const { point } = this;
                        // @ts-ignore // TODO: FIX HIGHCHARTS
                        const { x, x2, y, routeId, duration, device, routename } = point;
                        const from = AFormatDate(new Date(x));
                        const to = AFormatDate(new Date(x2));
                        return (`
              <b>${$page.translations['Route Name']}</b>: ${routename}<br>
              <b>${$page.translations['Device']}</b>: ${device}<br>
              <b>${$page.translations['From']}</b> ${from}<br>
              <b>${$page.translations['To']}</b> ${to}
              <b>${$page.translations['Duration']}</b>: ${duration}<br>
            `);
                    }
                },
                series: [
                    {
                        type: 'xrange',
                        showInLegend: false,
                        pointWidth: seriesPointWidth(),
                        data: data.map((item) => {
                            return Object.assign({}, item, {});
                        }),
                        dataLabels: {
                            enabled: false
                        },
                        states: {
                            hover: {
                                borderColor: '#8FB1D0',
                                lineWidth: 5,
                            },
                            select: {
                                borderColor: '#5F768B',
                                lineWidth: 5,
                            }
                        }
                    }
                ],
                legend: {},
                exporting: {
                    allowHTML: true
                }
            });
            // $('.highcharts-xaxis-labels text').on('click', function () {
            //   alert($(this).index());
            // });
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
        }
        let routeservicerunning = ($page.geoMap != undefined && $page.geoMap.WaySegments != undefined);
        if (routeservicerunning) {
            $('#btn_add_planning_routes').prop('disabled', true);
            for (const index in this.Routes) {
                if (this.Routes[index].Active) {
                    $('#btn_add_planning_routes').prop('disabled', false);
                    break;
                }
            }
        }
        else {
            $('#btn_add_planning_routes').prop('disabled', true);
            $('#btn_add_planning_routearea').prop('disabled', true);
            $('#btn_add_break').prop('disabled', true);
        }
        this.setSeriesColors();
        this.resetInfo();
        this.redrawEvent();
    }
    resetView() {
        // Highcharts Version 11 doesn't have chart.zoom() method
        // this.chart.zoom()
        this.chart.xAxis[0].setExtremes(undefined, undefined);
        //this.redrawEvent()
        $('#btn_reset_view').prop('disabled', true);
    }
    redrawEvent() {
        // Deselect all in timeline
        this.chart.series.forEach(s => {
            s.data.forEach((point) => {
                point.select(false);
            });
        });
        // min date / max date in timeline
        var min_timeline, max_timeline;
        ({ min: min_timeline, max: max_timeline } = this.chart.axes[0].getExtremes());
        const min_date_timeline = new Date(min_timeline);
        const max_date_timeline = new Date(max_timeline);
        let selected_plannings = [];
        let minFromDate, maxToDate;
        this.routeMapHelperService.removeWaySegments(this.planning_map);
        for (const i in this.plannedRoutes) {
            const $device_routes = this.plannedRoutes[i];
            for (const j in $device_routes.Routes) {
                const $route_planned = $device_routes.Routes[j];
                const FromDate = new Date($route_planned.RouteFromTime);
                const ToDate = new Date($route_planned.RouteToTime);
                if ((FromDate < min_date_timeline || FromDate > max_date_timeline) &&
                    (ToDate < min_date_timeline || ToDate > max_date_timeline))
                    continue;
                if (!minFromDate || FromDate < minFromDate)
                    minFromDate = FromDate;
                if (!maxToDate || ToDate > maxToDate)
                    maxToDate = ToDate;
                selected_plannings.push($route_planned);
            }
        }
        var zoom_changed_listener = this.planning_map.addListener("zoom_changed", () => {
            google.maps.event.removeListener(zoom_changed_listener);
            $('#btn_reset_view').prop('disabled', false);
        });
        var dragend_listener = this.planning_map.addListener("dragend", () => {
            google.maps.event.removeListener(dragend_listener);
            $('#btn_reset_view').prop('disabled', false);
        });
        if (this.geoMap != undefined && this.geoMap.WaySegments != undefined) {
            if (selected_plannings.length == 0)
                this.resetInfo();
            else if (selected_plannings.length === 1) {
                if (selected_plannings[0].RouteId === "1")
                    this.setInfoSingleBreak(selected_plannings[0]);
                else
                    this.setInfoSinglePlanning(selected_plannings[0]);
            }
            else if (selected_plannings.length > 1)
                this.setInfoMultiplePlannings(selected_plannings);
        }
    }
    mergeDrivenWithPlanned() {
        for (const i in this.plannedRoutes) {
            const $pl = this.plannedRoutes[i];
            for (const j in this.drivenRoutes) {
                const $dr = this.drivenRoutes[j];
                if ($pl.ForDevice === $dr.DeviceName) {
                    for (const k in $pl.Routes) {
                        const $pr = $pl.Routes[k];
                        if (($pr.RouteFromTime > $dr.From && $pr.RouteFromTime < $dr.To) ||
                            ($pr.RouteToTime > $dr.From && $pr.RouteToTime < $dr.To) ||
                            ($dr.From > $pr.RouteFromTime && $dr.From < $pr.RouteToTime) ||
                            ($dr.To > $pr.RouteFromTime && $dr.To < $pr.RouteToTime)) {
                            let totalDistance = $dr.DistDone + $dr.DistToDo;
                            this.plannedRoutes[i].Routes[k].PercentageDone = Math.round(($dr.DistDone / totalDistance));
                        }
                    }
                }
            }
        }
    }
    setSeriesColors() {
        let series = this.chart.series;
        const { plannedRoutes, waySegmentColors } = this;
        for (const s in series) {
            let data = this.chart.series[s].data;
            for (const key in data) {
                const obj = data[key];
                if (obj['routeId'] == "1") { // AKA break
                    obj.color = waySegmentColors.lime.hexi;
                }
                else { // Other
                    const $dr = plannedRoutes[obj['device']].Routes[obj['j']];
                    if ($dr.PercentageDone && $dr.PercentageDone > 0) {
                        obj.color = interpolateService.colorHSV(waySegmentColors.red, waySegmentColors.green, $dr.PercentageDone).hexi;
                    }
                    else if (new Date($dr.RouteToTime) < new Date()) { // past
                        obj.color = waySegmentColors.red.hexi;
                    }
                    else if (new Date($dr.RouteFromTime) < new Date() && new Date($dr.RouteToTime) > new Date()) {
                        obj.color = waySegmentColors.aqua.hexi;
                    }
                    else {
                        obj.color = waySegmentColors.blue.hexi;
                    }
                }
            }
        }
    }
    resetInfo() {
        this.routeMapHelperService.removeWaySegments(this.planning_map);
        this.planning_map.fit();
        $('#info_planning_device').text("-");
        $('#info_planning_name').text("-");
        $('#info_planning_distance').text("-").text("-");
        $('#info_planning_date').text("-");
        $('#info_planning_from').text("-");
        $('#info_planning_to').text("-");
        $('#info_planning_info').prop('disabled', true);
        $('#info_planning_edit').prop('disabled', true);
        $('#info_planning_delete').prop('disabled', true);
        $('#info_planning_send').prop('disabled', true);
    }
    setInfoMultiplePlannings($plannings) {
        this.resetInfo();
        let $txt_device = $('#info_planning_device');
        let $txt_routename = $('#info_planning_name');
        let $txt_date = $('#info_planning_date');
        let $txt_from = $('#info_planning_from');
        let $txt_to = $('#info_planning_to');
        let $txt_distance = $('#info_planning_distance');
        let devices = [];
        let routeNames = [];
        let minTime, maxTime;
        let totalDistance = 0;
        for (const $p in $plannings) {
            const $planning = $plannings[$p];
            const $route = this.Routes[$planning.RouteId + "_" + $planning.CarNumber];
            const $request = this.Requests[$planning.RouteId];
            if (!minTime || $planning.RouteFromTime < minTime)
                minTime = $planning.RouteFromTime;
            if (!maxTime || $planning.RouteToTime > maxTime)
                maxTime = $planning.RouteToTime;
            if ($planning.RouteId == "1" || (!$route && !$request) || !$planning)
                continue;
            if (devices.indexOf($planning.Device) === -1)
                devices.push($planning.Device);
            routeNames.push($planning.RouteName);
            if ($route) { // route exist
                totalDistance += $route.Length;
                this.drawRouteOnMap(this.planning_map, $route);
            }
            else { // only request availb
                totalDistance += this.getParkingDistanceFromWaySegmentIds($request.ParkingStreetIds);
                this.drawRequestOnMap(this.planning_map, $request);
            }
        }
        let str_devices = "";
        if (devices.length >= 1) {
            str_devices = devices[0];
            for (let dev = 1; dev < devices.length; dev++) {
                str_devices += ", " + devices[dev];
            }
            if (str_devices.length > 25)
                $txt_device.text("Multiple Devices");
            else
                $txt_device.text(str_devices);
        }
        $txt_distance.text(metersToKilometerText(totalDistance));
        $txt_routename.text("Multiple Routes");
        $txt_date.text(AInputDate(new Date(minTime)));
        $txt_from.text(AInputTime(new Date(minTime)));
        $txt_to.text(AInputTime(new Date(maxTime)));
    }
    setInfoSinglePlanning($planning) {
        this.resetInfo();
        const $route = this.Routes[$planning.RouteId + "_" + $planning.CarNumber];
        const $request = this.Requests[$planning.RouteId];
        if ((!$route && !$request) || !$planning)
            return;
        let $txt_device = $('#info_planning_device');
        let $txt_routename = $('#info_planning_name');
        let $txt_date = $('#info_planning_date');
        let $txt_from = $('#info_planning_from');
        let $txt_to = $('#info_planning_to');
        let $txt_distance = $('#info_planning_distance');
        $txt_device.text($planning.Device);
        $txt_routename.text($planning.RouteName);
        $txt_date.text(AInputDate(new Date($planning.RouteFromTime)));
        $txt_from.text(AInputTime(new Date($planning.RouteFromTime)));
        $txt_to.text(AInputTime(new Date($planning.RouteToTime)));
        if ($route) { // route exist
            $txt_distance.text(metersToKilometerText($route.Length));
            this.drawRouteOnMap(this.planning_map, $route);
        }
        else { // only request availb
            $txt_distance.text("-");
            this.drawRequestOnMap(this.planning_map, $request);
        }
        const inPast = new Date($planning.RouteFromTime) < new Date();
        $('#info_planning_info').prop('disabled', false);
        $('#info_planning_info').off();
        $('#info_planning_info').on('click', _ => this.alert_infoPlanning($planning.RouteId));
        if (!inPast) {
            $('#info_planning_edit').prop('disabled', false);
            $('#info_planning_edit').off();
            $('#info_planning_edit').on('click', _ => this.alert_editPlanning($planning));
            if ($route) {
                $('#info_planning_send').prop('disabled', false);
                $('#info_planning_send').off();
                $('#info_planning_send').on('click', _ => this.alert_resendPlanning($planning));
            }
            $('#info_planning_delete').prop('disabled', false);
            $('#info_planning_delete').off();
            $('#info_planning_delete').on('click', _ => this.alert_removePlanning($planning));
        }
    }
    setInfoSingleBreak($planning) {
        this.resetInfo();
        let $txt_device = $('#info_planning_device');
        let $txt_routename = $('#info_planning_name');
        let $txt_date = $('#info_planning_date');
        let $txt_from = $('#info_planning_from');
        let $txt_to = $('#info_planning_to');
        $txt_device.text($planning.Device);
        $txt_routename.text($planning.RouteName);
        $txt_date.text(AInputDate(new Date($planning.RouteFromTime)));
        $txt_from.text(AInputTime(new Date($planning.RouteFromTime)));
        $txt_to.text(AInputTime(new Date($planning.RouteToTime)));
        $('#info_planning_edit').prop('disabled', false);
        $('#info_planning_edit').off();
        $('#info_planning_edit').on('click', _ => this.alert_editBreak($planning));
        $('#info_planning_delete').prop('disabled', false);
        $('#info_planning_delete').off();
        $('#info_planning_delete').on('click', _ => this.alert_removeBreak($planning));
    }
    getParkingDistanceFromWaySegmentIds($waysegmentIds) {
        let distance = 0;
        for (const id in $waysegmentIds) {
            if (this.geoMap.WaySegments[$waysegmentIds[id]] != undefined) {
                distance += this.geoMap.WaySegments[$waysegmentIds[id]].Distance;
            }
        }
        return distance;
    }
    onPlanningClicked(point) {
        const $route = this.Routes[point.routeId];
        const $request = this.Requests[point.routeId];
        const $device = this.plannedRoutes[point.device];
        const $planning = $device.Routes[point.j];
        if (!$planning)
            return;
        if ($planning.RouteId == "1") {
            this.setInfoSingleBreak($planning);
            return;
        }
        else if ((!$route && !$request) || !$device)
            return;
        this.setInfoSinglePlanning($planning);
        $('#btn_reset_view').prop('disabled', false);
    }
    async alert_newPlanningRoutes() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        var $routehtml = /*html*/ `<select class="form-input" id="routes-dropdown" disabled><option selected value=""></option>`;
        for (const routeid in this.Routes) {
            if (this.Routes[routeid].Active) {
                $routehtml += `<option value=${routeid}>${this.Routes[routeid].RouteName}</option>`;
            }
        }
        $routehtml += `</select>`;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" for="routes">${this.translations['Route']}</label>
                  ${$routehtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" disabled id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" disabled id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" disabled id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-send" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Send']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['New Planning'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $routes = $('#routes-dropdown');
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            let routeValid = false;
            $devices.addClass('is-error');
            $routes.prop('disabled', true);
            $routes.removeClass('is-error');
            $date.prop('disabled', true);
            $date.removeClass('is-error');
            $from.prop('disabled', true);
            $from.removeClass('is-error');
            $to.prop('disabled', true);
            $to.removeClass('is-error');
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            if (deviceValid) {
                $routes.prop('disabled', false);
                if ($routes.val()) {
                    routeValid = true;
                }
                else {
                    $routes.addClass('is-error');
                }
            }
            if (routeValid) {
                let duration_route = 0;
                const routeId = $('#routes-dropdown').val();
                let route;
                if (typeof routeId == "string" || typeof routeId == "number") {
                    route = $page.Routes[routeId];
                    duration_route = estimateRouteDurationFromDistance(route.Length);
                }
                $date.prop('disabled', false);
                $from.prop('disabled', false);
                if ($from.val() && $date.val() && new Date($date.val() + " " + $from.val()) > new Date()) {
                    var $fromdate = new Date($date.val() + " " + $from.val());
                    $fromdate.setSeconds($fromdate.getSeconds() + duration_route);
                    $to.val($fromdate.toTimeString().substring(0, 5));
                    $date.removeClass('is-error');
                    $from.removeClass('is-error');
                    //$to.removeClass('is-error')
                    dateValid = true;
                }
                else {
                    $to.val("-:--");
                    $date.addClass('is-error');
                    $from.addClass('is-error');
                }
            }
            let $sendbtn = $('#btn-send');
            if (deviceValid && routeValid && dateValid) {
                $sendbtn.prop('disabled', false);
            }
            else {
                $sendbtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $('#devices-dropdown').on("change", (e) => {
            verifyInput();
        });
        $('#routes-dropdown').on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        let page = this;
        $('#btn-send').on("click", function () {
            const sendtoname = $('#devices-dropdown').val();
            const routeId = $('#routes-dropdown').val();
            let route;
            let routeName = "";
            if (typeof routeId == "string" || typeof routeId == "number") {
                route = page.Routes[routeId];
                routeName = route.RouteName;
            }
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data = {
                "SendToName": sendtoname,
                "RouteId": route.RouteId,
                "CarNumber": route.CarNumber,
                "RouteName": routeName,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_SendRouteToCar_Response`, response => {
                page.refresh();
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_newPlanningRouteArea() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        // <div class="col-10" style="margin-top: 10px; visibility: hidden;">
        const html = /*html*/ `
    <div style="margin: 20px;">
      <div class="columns">
        <div class="col-12">
          <h9 id="alert_estimates_distance"></h9>
          <h9 id="alert_estimates_duration"></h9>
        </div>
      </div>

      <div id="form_1_2" class="columns" style="height: 800px; overflow-y: hidden;">
        <div id="form_1" class="column col-6 col-md-12">
          <div class="col-12 fh">

            <label class="form-label" for="devices">${this.translations['Device']}</label>
            ${deviceshtml}

            <div class="columns col col-12">
              <div class="column col-12">
                <label class="form-label">${this.translations['Date Start']}</label>
              </div>
              <div class="column col-6">
                <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date_from">
              </div>
              <div class="column col-6">
                <input class="form-input" type="time" disabled id="planning_time_from">
              </div>              
              <div class="column col-6">
                <label class="form-label">${this.translations['Date Finish']}</label>
              </div>
              <div class="column col-6" style="text-align: left; margin-top: 10px">
                <i class="fa fa-arrow-down" aria-hidden="true"></i>
              </div>
              <div class="column col-6">
                <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date_to">
              </div>
              <div class="column col-6">
                <input class="form-input" type="time" id="planning_time_to">
              </div>
              <div class="column col-12">
                <label class="form-switch" disabled>
                  <input id="regimes_switch" type="checkbox">
                  <i class="form-icon"></i>
                  <label for="regimes_switch">${this.translations['Filter Regimes']}</label>
                </label>
              </div>
            </div>  

            <!--<div class="col-10" style="margin-top: 10px; visibility: hidden;">-->
            <div class="col-12" style="margin-top: 10px;">
              <h8>${this.translations['Sort RouteAreas on']}: </h8>
              <label class="form-switch">
                <input id="lastseen_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="lastseen_switch">${this.translations['Last Enforcement']}</label>
              </label>
              <label class="form-switch">
                <input id="compliancy_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="compliancy_switch">${this.translations['Compliancy']}</label>
              </label>
              <label class="form-switch">
                <input id="occupancy_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="occupancy_switch">${this.translations['Occupancy']}</label>
              </label>
              
              <label class="form-switch">
                <input id="visitorrate_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="visitorrate_switch">${this.translations['Visitor Rate']}</label>
              </label>
              <label class="form-switch">
                <input id="enforcementintensity_switch" type="checkbox">
                <i class="form-icon"></i>
                <label for="enforcementintensity_switch">${this.translations['Enforcement Intensity']}</label>
              </label>
            </div>

            <div class="fixTableHead" style="height: 500px; margin-top: 15px;">
              <table class="table">
                <thead>
                  <tr>
                    <th><b>RoutesAreas</b></th>
                  </tr>
                </thead>
                <tbody id="routeareas_list">
                </tbody>
              </table>         
            </div>
          </div>
        </div>

        <div class="column col-6 col-md-12">

          <div style="height: 5%; margin-bottom: 10px">
            <button id="btn-parking-routearea" class="btn"">Show ParkingStreets</button> 
          </div>

          <div id="routearea_map" class="aci-map" style="height: 80%; width: 100%"></div>
          
          <div class="legend legend-opaque" id="routearea_map_legend">
            <div id="routearea_map_legend_title" class="legend-label label-height-lg">RouteArea ${this.translations['Name']}</div>
            <div id="routearea_map_legend_content" style="white-space: pre-line;"></div>
          </div>

          <div style="text-align: center; height: 10%; margin-top: 25px;">
            <button id="btn-create" class="btn btn-primary" style="width: 50%;">${this.translations['Create']}</button> 
          </div>      
        </div>
      </div>
    </div>
    `.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['New Planning'],
            content: html,
            type: ALERTS.Mega,
            buttons: ALERT_BUTTONS.okCancel
        });
        function onLowerLayerClicked(type, id) {
            if (type == "routearea") {
                $("#routeareas_list > tr").each(function (index, tr) {
                    if (tr.id == id)
                        tr.click();
                });
            }
        }
        function onLowerLayerMouseOver(type, id) {
            if (type == "routearea" && $page.geoMap.RouteAreas[id]) {
                $('#routearea_map_legend_title').text($page.geoMap.RouteAreas[id].Name);
                $('#routearea_map_legend_content').text(customRowData($page.geoMap.RouteAreas[id], false));
                legend.style.display = "";
            }
        }
        function onLowerLayerMouseOut() {
            legend.style.display = "none";
        }
        let selected_routeareas = [];
        function getOrderNumber($id) {
            let index = selected_routeareas.indexOf($id);
            if (index != -1)
                return (index + 1) + "";
            return "";
        }
        function removeOrderNumber($id) {
            let temp_order_numbers = [];
            for (const i in selected_routeareas) {
                if (selected_routeareas[i] != $id)
                    temp_order_numbers.push(selected_routeareas[i]);
            }
            selected_routeareas = temp_order_numbers;
        }
        function addOrderNumber($id) {
            selected_routeareas.push($id);
        }
        function getTotalLengthSelectedRouteAreas() {
            let total_length = 0;
            for (const $index in selected_routeareas) {
                total_length += estimateRouteDistanceFromParkingStreetDistance($page.geoMap.RouteAreas[selected_routeareas[$index]].ParkingDistance);
            }
            return total_length;
        }
        function getTotalDurationSelectedRouteAreas() {
            let total_duration = 0;
            for (const $index in selected_routeareas) {
                total_duration += estimateRouteDurationFromParkingStreetDistance($page.geoMap.RouteAreas[selected_routeareas[$index]].ParkingDistance);
            }
            return total_duration;
        }
        function customRowData($routeArea, strange_newline) {
            let str = "";
            let newLine = "\n\r";
            if (strange_newline)
                newLine = "&#10;";
            if ($switch_lastseen.prop('checked')) {
                let last_seen = "-";
                if ($routeArea.LastSeen) {
                    last_seen = new Date($routeArea.LastSeen).toDateString();
                }
                str += $page.translations['Last Enforcement'] + ": " + last_seen + newLine;
            }
            if ($switch_compliancy.prop('checked')) {
                let compliancy_perc = "- ";
                if ($routeArea.Compliancy) {
                    compliancy_perc = Math.round($routeArea.Compliancy * 100) + "% ";
                }
                str += $page.translations['Compliancy'] + ": " + compliancy_perc + newLine;
            }
            if ($switch_occupancy.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.Occupancy) {
                    occ_perc = Math.round($routeArea.Occupancy * 100) + "% ";
                }
                str += $page.translations['Occupancy'] + ": " + occ_perc + newLine;
            }
            if ($switch_visitorrate.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.VisitorRate) {
                    occ_perc = Math.round($routeArea.VisitorRate * 100) + "% ";
                }
                str += $page.translations['VisitorRate'] + ": " + occ_perc + newLine;
            }
            if ($switch_enforcementintensity.prop('checked')) {
                let occ_perc = "- ";
                if ($routeArea.EnforcementIntensity) {
                    occ_perc = Math.round($routeArea.EnforcementIntensity * 100) + "% ";
                }
                str += $page.translations['Enforcement Intensity'] + ": " + occ_perc + newLine;
            }
            return str;
        }
        function sortRouteAreasIds() {
            let routeAreaIds = [];
            let useScore = ($switch_lastseen.prop('checked') || $switch_compliancy.prop('checked') || $switch_occupancy.prop('checked') ||
                $switch_visitorrate.prop('checked') || $switch_enforcementintensity.prop('checked'));
            // Use score as sorting
            for (const routeareaid in $page.geoMap.RouteAreas) {
                const routeArea = $page.geoMap.RouteAreas[routeareaid];
                if ($switch_regimes.prop('checked')) {
                    //check filter regimes
                    if ($page.regimes["RouteAreas"] && ($page.regimes["RouteAreas"][routeareaid] != undefined && $page.regimes["RouteAreas"][routeareaid] == false)) {
                        routeAreaIds[routeareaid] = {
                            score: 666,
                            id: routeareaid
                        };
                        continue;
                    }
                }
                if (useScore) {
                    if (!routeArea.ParkingDistance || routeArea.ParkingDistance == 0) {
                        routeAreaIds[routeareaid] = {
                            score: 666,
                            id: routeareaid
                        };
                        continue;
                    }
                    let sum_score = 0;
                    let div = 0;
                    if ($switch_lastseen.prop('checked') && routeArea.LastSeenScore) {
                        div++;
                        sum_score += routeArea.LastSeenScore;
                    }
                    if ($switch_compliancy.prop('checked') && routeArea.Compliancy) {
                        div++;
                        sum_score += routeArea.Compliancy;
                    }
                    if ($switch_occupancy.prop('checked') && routeArea.Occupancy) {
                        div++;
                        sum_score += routeArea.Occupancy;
                    }
                    if ($switch_visitorrate.prop('checked') && routeArea.VisitorRate) {
                        div++;
                        sum_score += routeArea.VisitorRate;
                    }
                    if ($switch_enforcementintensity.prop('checked') && routeArea.EnforcementIntensity) {
                        div++;
                        sum_score += routeArea.EnforcementIntensity;
                    }
                    routeAreaIds[routeareaid] = {
                        score: (sum_score > 0 ? sum_score / div : 0),
                        id: routeareaid
                    };
                }
                else { // use name
                    if (!routeArea.ParkingDistance || routeArea.ParkingDistance == 0) {
                        routeAreaIds[routeareaid] = {
                            Name: '\u03A9',
                            id: routeareaid
                        };
                    }
                    else {
                        routeAreaIds[routeareaid] = {
                            Name: routeArea.Name,
                            id: routeareaid
                        };
                    }
                }
            }
            function compare(a, b) {
                if (useScore) {
                    if (a.score < b.score) {
                        return -1;
                    }
                    if (a.score > b.score) {
                        return 1;
                    }
                }
                else {
                    if (a.Name < b.Name) {
                        return -1;
                    }
                    if (a.Name > b.Name) {
                        return 1;
                    }
                }
                return 0;
            }
            routeAreaIds.sort(compare);
            return routeAreaIds;
        }
        function filterRouteAreas() {
            let $list = $('#routeareas_list');
            $list.children().remove();
            $page.routeMapHelperService.removePolygonsLowerlayer($page.routearea_map);
            $page.routeMapHelperService.removePolygons($page.routearea_map);
            $page.routeMapHelperService.removeWaySegments($page.routearea_map);
            let $routeAreaIds = sortRouteAreasIds();
            for (let i in $routeAreaIds) {
                let id = $routeAreaIds[i].id;
                let selected = (selected_routeareas.indexOf(id) != -1) ? "selected" : "";
                let disabled = ($routeAreaIds[i].score == 666 || $routeAreaIds[i].Name == '\u03A9') ? "disabled" : "";
                if (selected && disabled) {
                    removeOrderNumber(id);
                }
                let color_txt = "";
                if (disabled) {
                    color_txt = "#E4E4E4";
                    selected = "";
                }
                var Name = "";
                if ($page.geoMap.RouteAreas[id].Name.length) {
                    Name = $page.geoMap.RouteAreas[id].Name;
                }
                if (!Name.length)
                    Name = id;
                var $row = $(/*html*/ `
        <tr id=${id} class='tableRow columns fw ${selected} ${disabled}'>
          <td class="col-10" style="color:${color_txt};" title="${customRowData($page.geoMap.RouteAreas[id], true)}">${Name}</td>
          <td id="order_number_row" class="col-2" style="text-align: right; color:${color_txt};">${getOrderNumber(id)}</td>
        </tr>
        `.replace(/\s\s+/g, ' '));
                $list.append($row);
                if (!disabled) {
                    let $score = 1;
                    if ($routeAreaIds[i].score != undefined)
                        $score = $routeAreaIds[i].score;
                    const red = new AColorHSV(0, 100, 100);
                    const green = new AColorHSV(120, 100, 100);
                    const colorHSV = interpolateService.colorHSV(red, green, $score);
                    const stroke_color = new AColorHSV(0, 0, 32);
                    $page.routeMapHelperService.drawPolygonOnMap($page.routearea_map, $page.geoMap.RouteAreas[id], "routearea", id, colorHSV, stroke_color, 0, onLowerLayerClicked, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    for (const $waySegmentId of $page.geoMap.RouteAreas[id].WaySegments) {
                        const $waySegment = $page.geoMap.WaySegments[$waySegmentId];
                        if ($waySegment && ($waySegment.ParkingCountLeft || $waySegment.ParkingCountRight)) {
                            $page.routeMapHelperService.drawWaySegment($page.routearea_map, $waySegment, colorHSV, 5);
                        }
                    }
                }
                else {
                    const colorHSV = new AColorHSV(0, 0, 62);
                    const stroke_color = new AColorHSV(0, 0, 32);
                    $page.routeMapHelperService.drawPolygonOnMap($page.routearea_map, $page.geoMap.RouteAreas[id], "routearea", id, colorHSV, stroke_color, 0, null, null, null);
                }
                $("#routeareas_list > tr").each(function () {
                    $(this).find("#order_number_row").text(getOrderNumber(this.id));
                    if ($(this).hasClass('selected')) {
                        let $item = this;
                        const $id = this.id;
                        const color = new AColorHSV(216, 100, 100);
                        $page.routeMapHelperService.drawPolygonOnMapBorderOnly($page.routearea_map, $page.geoMap.RouteAreas[$id], "routearea", $id, color, 1.0, function () { $item.click(); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            }
            $('#routeareas_list').off();
            $('#routeareas_list').on('click', 'tr', function () {
                if ($(this).hasClass('selected')) {
                    $(this).removeClass('selected');
                    removeOrderNumber(this.id);
                }
                else {
                    $(this).addClass('selected');
                    addOrderNumber(this.id);
                }
                $page.routeMapHelperService.removePolygons($page.routearea_map);
                $("#routeareas_list > tr").each(function () {
                    $(this).find("#order_number_row").text(getOrderNumber(this.id));
                    if ($(this).hasClass('selected')) {
                        let $item = this;
                        const $id = this.id;
                        const color = new AColorHSV(216, 100, 100);
                        $page.routeMapHelperService.drawPolygonOnMapBorderOnly($page.routearea_map, $page.geoMap.RouteAreas[$id], "routearea", $id, color, 1.0, function () { $item.click(); }, onLowerLayerMouseOver, onLowerLayerMouseOut);
                    }
                });
                verifyInput();
            });
            if ($parkingstreets_visible) {
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.1);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, true);
            }
            else {
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.5);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, false);
            }
        }
        function verifyInput() {
            let routeAreasValid = false;
            let deviceValid = false;
            let dateValid = false;
            routeAreasValid = ($("#routeareas_list > tr.selected").length > 0);
            let $devices = $('#devices-dropdown');
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($from_date.val() && (new Date($from_date.val() + " " + $from_time.val()) > new Date())) {
                $from_date.removeClass('is-error');
                $from_time.removeClass('is-error');
                dateValid = true;
            }
            else {
                $from_date.addClass('is-error');
                $from_time.addClass('is-error');
            }
            if (routeAreasValid) {
                const $duration = getTotalDurationSelectedRouteAreas();
                const $length = getTotalLengthSelectedRouteAreas();
                $estimates_distance.text($page.translations['Estimate length'] + ": " + metersToKilometerText($length));
                $estimates_duration.text(" " + $page.translations['Estimate duration'] + ": " + secondsToDurationTextHHMM($duration));
                if (dateValid) {
                    var $fromdate = new Date($from_date.val() + " " + $from_time.val());
                    let $total_duration = getTotalDurationSelectedRouteAreas();
                    var $todate = $fromdate;
                    $todate.setSeconds($todate.getSeconds() + $total_duration);
                    $to_date.val(moment($todate).format("YYYY-MM-DD"));
                    $to_time.val(moment($todate).format("HH:mm"));
                }
            }
            else {
                $estimates_distance.text($page.translations['Estimate length'] + ": 0 km");
                $estimates_duration.text(" " + $page.translations['Estimate duration'] + ": 0m");
                $to_date.val(0);
                $to_time.val("-:--");
            }
            if (routeAreasValid && deviceValid && dateValid) {
                $createbtn.prop('disabled', false);
            }
            else {
                $createbtn.prop('disabled', true);
            }
        }
        function sendNewRequestSelectedRouteAreas() {
            let $fromTime = new Date($from_date.val() + " " + $from_time.val());
            for (const index in selected_routeareas) {
                let $routeareaId = selected_routeareas[index];
                let $routearea = $page.geoMap.RouteAreas[$routeareaId];
                let $parkingptreetIds = [];
                for (const ws_index in $routearea.WaySegments) {
                    if ($routearea.WaySegments[ws_index] && $page.geoMap.WaySegments[$routearea.WaySegments[ws_index]]) {
                        if ($page.geoMap.WaySegments[$routearea.WaySegments[ws_index]].ParkingCountLeft || $page.geoMap.WaySegments[$routearea.WaySegments[ws_index]].ParkingCountRight) {
                            $parkingptreetIds.push($routearea.WaySegments[ws_index]);
                        }
                    }
                }
                let $toTime = new Date($fromTime);
                $toTime.setSeconds($toTime.getSeconds() + estimateRouteDurationFromParkingStreetDistance($page.geoMap.RouteAreas[selected_routeareas[index]].ParkingDistance));
                let $settings = {};
                $settings["Areas"] = [];
                $settings["Zones"] = [];
                $settings["RouteAreas"] = [$routeareaId];
                $settings["Occupancy"] = "0/1";
                $settings["VisitorRate"] = "0/1";
                $settings["Compliancy"] = "0/1";
                $settings["CompliancyVisitors"] = "0/1";
                $settings["EnforcementIntensity"] = "0/1";
                $settings["AllStreets"] = false;
                $settings["WithoutStatistics"] = true;
                let $existingRequest = undefined;
                for (const $Id in $page.Requests) {
                    let $req = $page.Requests[$Id];
                    if ($req && $req.Settings &&
                        $req.Settings.RouteAreas.length == 1 && $req.Settings.RouteAreas[0] == $routeareaId &&
                        $req.Settings.Zones.length == 0 && $req.Settings.Areas.length == 0 &&
                        $req.Settings.Occupancy == $settings["Occupancy"] &&
                        $req.Settings.VisitorRate == $settings["VisitorRate"] &&
                        $req.Settings.Compliancy == $settings["Compliancy"] &&
                        $req.Settings.CompliancyVisitors == $settings["CompliancyVisitors"] &&
                        $req.Settings.EnforcementIntensity == $settings["EnforcementIntensity"]) {
                        let parking_same = true;
                        if ($req.ParkingStreetIds.length != $parkingptreetIds.length)
                            parking_same = false;
                        else {
                            for (const $parking_i in $parkingptreetIds) {
                                if ($req.ParkingStreetIds.indexOf($parkingptreetIds[$parking_i]) == -1) {
                                    parking_same = false;
                                    break;
                                }
                            }
                        }
                        if (parking_same && $req.NumRoutes == 1) {
                            $existingRequest = $req;
                            break;
                        }
                    }
                }
                if ($existingRequest != undefined && $existingRequest.RouteId && $existingRequest.RouteName) { // Use existing route
                    const data = {
                        "ProjectNr": CCCClient.NodeProjectNumber,
                        "CustomerNr": CCCClient.NodeCustomerNumber,
                        "SendToName": $('#devices-dropdown').val(),
                        "RouteId": $existingRequest.RouteId,
                        "RouteName": $existingRequest.RouteName,
                        "RouteFromTime": $fromTime,
                        "RouteToTime": $toTime
                    };
                    CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data, 0, {
                        Type: "ControlCenter",
                        IndexNumber: 1,
                        CustomerNumber: CCCClient.NodeCustomerNumber,
                        ProjectNumber: CCCClient.NodeProjectNumber
                    });
                }
                else { // New request 
                    const routename = $routearea.Name;
                    const fordevice = $('#devices-dropdown').val();
                    const optimizedeadendstreet = true;
                    const preferright = true;
                    const numroutes = 1;
                    // default values
                    const maxvalue = 1000;
                    const maxiteration = 20000;
                    const initialcoolingrate = 1000;
                    const planrequest = {
                        "RouteName": routename,
                        "ForDevice": fordevice,
                        "PreferRight": preferright,
                        "ParkingStreetIds": $parkingptreetIds,
                        "Settings": $settings,
                        "NumRoutes": numroutes,
                        "MaxValue": maxvalue,
                        "MaxIteration": maxiteration,
                        "InitialCoolingRate": initialcoolingrate,
                        "RouteFromTime": $fromTime,
                        "RouteToTime": $toTime
                    };
                    Loading.waitForPromises(new Promise((resolve, reject) => {
                        CCCClient.SendMessage("PlanRoute_CreateRoute_Request", 0, planrequest, 0, {
                            Type: "ControlCenter",
                            IndexNumber: 1,
                            CustomerNumber: CCCClient.NodeCustomerNumber,
                            ProjectNumber: CCCClient.NodeProjectNumber
                        });
                        Events.once(`PlanRoute_CreateRoute_Response`, response => {
                            if (response.message == 'ok') {
                                resolve(true);
                            }
                        });
                        sleep(5000).then(() => {
                            reject();
                        });
                    }));
                }
                $fromTime = $toTime;
                //$fromTime.setMinutes($fromTime.getMinutes() + 1)
            }
        }
        let $createbtn = $('#btn-create');
        $createbtn.prop('disabled', true);
        let $parkingrouteareabtn = $('#btn-parking-routearea');
        let $estimates_distance = $('#alert_estimates_distance');
        let $estimates_duration = $('#alert_estimates_duration');
        $page.routearea_map = createMap('routearea_map', { zoom: 100 });
        $page.routearea_map.fit();
        const legend = document.getElementById("routearea_map_legend");
        this.routearea_map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
        legend.style.display = "none";
        let $switch_regimes = $('#regimes_switch');
        let $switch_lastseen = $('#lastseen_switch');
        let $switch_compliancy = $('#compliancy_switch');
        let $switch_occupancy = $('#occupancy_switch');
        let $switch_visitorrate = $('#visitorrate_switch');
        let $switch_enforcementintensity = $('#enforcementintensity_switch');
        let $from_date = $("#planning_date_from");
        let $from_time = $("#planning_time_from");
        $switch_enforcementintensity.prop('checked', false);
        let $to_date = $("#planning_date_to");
        let $to_time = $("#planning_time_to");
        let $parkingstreets_visible = false;
        $switch_regimes.prop('checked', false);
        $switch_lastseen.prop('checked', false);
        $switch_compliancy.prop('checked', false);
        $switch_occupancy.prop('checked', false);
        $switch_visitorrate.prop('checked', false);
        $page.regimes = [];
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $from_date.prop('min', minDate);
        $('#devices-dropdown').on("change", (e) => {
            verifyInput();
        });
        $to_date.prop("readonly", true);
        $to_time.prop("readonly", true);
        $from_date.on("change", async (e) => {
            if ($from_time.prop('disabled')) {
                $from_time.prop('disabled', false);
                $from_time.val("06:00");
            }
            if ($from_date.val() == minDate) {
                let other_date = now;
                other_date.setMinutes(other_date.getMinutes() + 1);
                $from_time.val(moment(other_date).format("HH:mm"));
            }
            const $fromdate = new Date($from_date.val() + " " + $from_time.val());
            let $todate = new Date($fromdate);
            $todate.setHours($todate.getHours() + 2);
            await Loading.waitForPromises(this.fetchAndCacheRegime($fromdate, $todate));
            filterRouteAreas();
            verifyInput();
        });
        $from_time.on("change", async (e) => {
            const $fromdate = new Date($from_date.val() + " " + $from_time.val());
            let $total_duration = getTotalDurationSelectedRouteAreas();
            let $todate = new Date($fromdate);
            $todate.setSeconds($todate.getSeconds() + $total_duration);
            $to_date.val(moment($todate).format("YYYY-MM-DD"));
            $to_time.val(moment($todate).format("HH:mm"));
            if ($total_duration == 0)
                $todate.setHours($todate.getHours() + 2); // if nothing selected, get 2 hours
            await Loading.waitForPromises(this.fetchAndCacheRegime($fromdate, $todate));
            filterRouteAreas();
            verifyInput();
        });
        //   verifyInput()
        // });
        $switch_regimes.on('change', filterRouteAreas);
        $switch_lastseen.on('change', filterRouteAreas);
        $switch_compliancy.on('change', filterRouteAreas);
        $switch_occupancy.on('change', filterRouteAreas);
        $switch_visitorrate.on('change', filterRouteAreas);
        $switch_enforcementintensity.on('change', filterRouteAreas);
        filterRouteAreas();
        verifyInput();
        $page.routeMapHelperService.fitBoundsPolygonsLowerLayer($page.routearea_map);
        $createbtn.on("click", async function () {
            const $fromTime = new Date($from_date.val() + " " + $from_time.val());
            const $toTime = new Date($to_date.val() + " " + $to_time.val());
            const $device = $('#devices-dropdown').val();
            const overlappingPlanning = await Loading.waitForPromises($page.getPlannerRoutesOverlap($fromTime, $toTime, $device));
            if ((!overlappingPlanning || !overlappingPlanning.length) ||
                (overlappingPlanning && overlappingPlanning.length && await $page.alert_sendOverlap(overlappingPlanning))) {
                await Loading.waitForPromises(sendNewRequestSelectedRouteAreas()).then(response => {
                    $page.getRoutesAndRequests();
                }).then(response => {
                    $page.refresh();
                    Alerts.closeAllActiveModals();
                });
            }
        });
        $parkingrouteareabtn.on("click", async function () {
            if ($parkingstreets_visible) {
                $parkingrouteareabtn.text("Show ParkingStreets");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.5);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, false);
                $parkingstreets_visible = false;
            }
            else {
                $parkingrouteareabtn.text("Show Heatmap");
                $page.routeMapHelperService.setFillOpacityPolygonsLowerLayer($page.routearea_map, 0.1);
                $page.routeMapHelperService.setVisiblityWaySegments($page.routearea_map, true);
                $parkingstreets_visible = true;
            }
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_newBreak() {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Add Break'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date() && $from.val() < $to.val())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let $savebtn = $('#btn-save');
            if (deviceValid && dateValid) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        //$devices.val($planning.Device);
        //$date.val(moment(new Date($planning.RouteFromTime)).format('yyyy-MM-DD'))
        //$from.val(moment(new Date($planning.RouteFromTime)).format('HH:mm'))
        //$to.val(moment(new Date($planning.RouteToTime)).format('HH:mm'))
        $devices.on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            if ($from.prop('disabled')) {
                $from.prop('disabled', false);
                $to.prop('disabled', false);
            }
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        $to.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", function () {
            const sendtoname = $('#devices-dropdown').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data_new = {
                "SendToName": sendtoname,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_AddBreak_Request", 0, data_new, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_AddBreak_Response`, response => {
                $page.refresh();
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_editPlanning($planning) {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $request = this.Requests[$planning.RouteId];
        let $route = this.Routes[$planning.RouteId];
        let $page = this;
        if ($request === undefined)
            return;
        var $routehtml = /*html*/ `<select class="form-input" id="routes-dropdown" disabled><option selected value=${$planning.RouteName}>${$planning.RouteName}</option></select>`;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" for="routes">${this.translations['Route']}</label>
                  ${$routehtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="planning_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="planning_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Planning'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $date = $("#planning_date");
        let $from = $("#planning_fromtime");
        let $to = $("#planning_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date() && $from.val() < $to.val())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let changed = ($devices.val() != $planning.Device) ||
                (new Date($date.val() + " " + $from.val()).toString() != new Date($planning.RouteFromTime).toString()) ||
                (new Date($date.val() + " " + $to.val()).toString() != new Date($planning.RouteToTime).toString());
            let $savebtn = $('#btn-save');
            if (deviceValid && dateValid && changed) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $devices.val($planning.Device);
        $date.val(moment(new Date($planning.RouteFromTime)).format('yyyy-MM-DD'));
        $from.val(moment(new Date($planning.RouteFromTime)).format('HH:mm'));
        $to.val(moment(new Date($planning.RouteToTime)).format('HH:mm'));
        $devices.on("change", (e) => {
            verifyInput();
        });
        function getParkingStreetDistance($ParkingStreetIds) {
            let total_length = 0;
            for (const $index in $ParkingStreetIds) {
                total_length += $page.geoMap.WaySegments[$ParkingStreetIds[$index]].Distance;
            }
            return total_length;
        }
        let $total_duration = 0;
        if ($route != undefined) {
            $total_duration = estimateRouteDurationFromDistance($route.Length);
        }
        else {
            $total_duration = estimateRouteDurationFromParkingStreetDistance(getParkingStreetDistance($request.ParkingStreetIds));
        }
        $to.prop("readonly", true);
        $date.on("change", (e) => {
            if ($from.prop('disabled')) {
                $from.prop('disabled', false);
                $to.prop('disabled', false);
                $from.val("06:00");
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + $total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            if ($date.val() == minDate) {
                let other_date = now;
                other_date.setMinutes(other_date.getMinutes() + 1);
                $from.val(other_date.toTimeString().substring(0, 5));
                var $fromdate = new Date($date.val() + " " + $from.val());
                $fromdate.setSeconds($fromdate.getSeconds() + $total_duration);
                $to.val($fromdate.toTimeString().substring(0, 5));
            }
            verifyInput();
        });
        $from.on("change", (e) => {
            var $fromdate = new Date($date.val() + " " + $from.val());
            $fromdate.setSeconds($fromdate.getSeconds() + $total_duration);
            $to.val($fromdate.toTimeString().substring(0, 5));
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", function () {
            const sendtoname = $('#devices-dropdown').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data_remove = {
                "ShareId": $planning.ShareId
            };
            const data_new = {
                "SendToName": sendtoname,
                "RouteId": $planning.RouteId,
                "RouteName": $planning.RouteName,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_DeleteRouteFromCarByShareId_Request", 0, data_remove, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            CCCClient.SendMessage("PlanRoute_SendRouteToCar_Request", 0, data_new, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_SendRouteToCar_Response`, response => {
                $page.refresh();
            });
            Alerts.closeAllActiveModals();
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_editBreak($planning) {
        let deviceshtml = createSelectScanDeviceListHtmlAll("devices-dropdown");
        let $page = this;
        if ($planning.RouteId != "1")
            return;
        const html = /*html*/ `
                <div class="form-group">
                  <label class="form-label" for="devices">${this.translations['Device']}</label>
                  ${deviceshtml}
                  <label class="form-label" style="margin-top: 10px">${this.translations['Date']}</label>
                  <div class="columns col col-12">
                    <div class="column col-4">
                      <input class="form-input" min="2000-01-01" max="2099-12-31" type="date" id="break_date">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="break_fromtime">
                    </div>
                    <div class="column col-4">
                      <input class="form-input" type="time" id="break_totime">
                    </div>
                    <div class="col-12" style="text-align: center; margin-top: 50px;">
                      <button id="btn-save" class="col-3 btn btn-primary" style="width: 100px;">${this.translations['Save']}</button>
                    </div>
                  </div>
                </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Edit Break'],
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.okCancel
        });
        let $devices = $('#devices-dropdown');
        let $date = $("#break_date");
        let $from = $("#break_fromtime");
        let $to = $("#break_totime");
        function verifyInput() {
            let deviceValid = false;
            let dateValid = false;
            if ($devices.val()) {
                deviceValid = true;
                $devices.removeClass('is-error');
            }
            else {
                $devices.addClass('is-error');
            }
            if ($date.val() && (new Date($date.val() + " " + $from.val()) > new Date() && $from.val() < $to.val())) {
                $date.removeClass('is-error');
                $from.removeClass('is-error');
                $to.removeClass('is-error');
                dateValid = true;
            }
            else {
                $date.addClass('is-error');
                $from.addClass('is-error');
                $to.addClass('is-error');
            }
            let changed = ($devices.val() != $planning.Device) ||
                (new Date($date.val() + " " + $from.val()).toString() != new Date($planning.RouteFromTime).toString()) ||
                (new Date($date.val() + " " + $to.val()).toString() != new Date($planning.RouteToTime).toString());
            let $savebtn = $('#btn-save');
            if (deviceValid && dateValid && changed) {
                $savebtn.prop('disabled', false);
            }
            else {
                $savebtn.prop('disabled', true);
            }
        }
        var now = new Date(), minDate = now.toISOString().substring(0, 10);
        $date.prop('min', minDate);
        $devices.val($planning.Device);
        $date.val(moment(new Date($planning.RouteFromTime)).format('yyyy-MM-DD'));
        $from.val(moment(new Date($planning.RouteFromTime)).format('HH:mm'));
        $to.val(moment(new Date($planning.RouteToTime)).format('HH:mm'));
        $devices.on("change", (e) => {
            verifyInput();
        });
        $date.on("change", (e) => {
            verifyInput();
        });
        $from.on("change", (e) => {
            verifyInput();
        });
        $to.on("change", (e) => {
            verifyInput();
        });
        verifyInput();
        $('#btn-save').on("click", function () {
            const data_old = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteBreakByShareId_Request", 0, data_old, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            const sendtoname = $('#devices-dropdown').val();
            let routefrom = "";
            let routeto = "";
            if ($date.val()) {
                routefrom = $date.val() + " " + $from.val();
                routeto = $date.val() + " " + $to.val();
            }
            const data_new = {
                "SendToName": sendtoname,
                "RouteFromTime": routefrom,
                "RouteToTime": routeto
            };
            CCCClient.SendMessage("PlanRoute_AddBreak_Request", 0, data_new, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_AddBreak_Response`, response => {
                $page.refresh();
                Alerts.closeAllActiveModals();
            });
        });
        events.$ele.find('.modal-footer').addClass('hidden');
    }
    async alert_resendPlanning($planning) {
        const html = /*html*/ `
      <div id="${$planning.RouteId}" class="form-group">
              <label class="form-label">${this.translations['Resend Planning']}: '${$planning.RouteName}'</label>
              <label class="form-label">${this.translations['To Car']}: '${$planning.Device}'?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Resend Planning'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.okCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_ResendRouteToCarByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
        });
    }
    async alert_removePlanning($planning) {
        const html = /*html*/ `
      <div id="${$planning.RouteId}" class="form-group">
              <label class="form-label">${this.translations['Delete Planning']}: '${$planning.RouteName}'?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Delete Planning'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        let page = this;
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteRouteFromCarByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteRouteFromCarByShareId_Response`, response => {
                page.refresh();
            });
            // NEW FUNCTIONS
            // const data = {
            //   "ShareId": $planning.ShareId
            // }
            // CCCClient.SendMessage("PlanRoute_DeleteRouteFromCarByShareId_Request", 0, data, 0, {
            //   Type: "ControlCenter",
            //   IndexNumber: 1,
            //   CustomerNumber: CCCClient.NodeCustomerNumber,
            //   ProjectNumber: CCCClient.NodeProjectNumber
            // })
            // Events.once(`PlanRoute_DeleteRouteFromCarByShareId_Response`, response => {
            //   page.refresh()
            // })
        });
    }
    async alert_removeBreak($planning) {
        const html = /*html*/ `
      <div id="${$planning.RouteId}" class="form-group">
              <label class="form-label">${this.translations['Delete Break']}?</label>
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: this.translations['Delete Break'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        let $page = this;
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const data = {
                "ShareId": $planning.ShareId
            };
            CCCClient.SendMessage("PlanRoute_DeleteBreakByShareId_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteBreakByShareId_Response`, response => {
                $page.refresh();
            });
        });
    }
    async alert_infoPlanning($routeId) {
        if (this.Requests[$routeId] === undefined || this.Requests[$routeId].Settings === undefined)
            return;
        const $route = this.Routes[$routeId];
        const $request = this.Requests[$routeId];
        const $settings = $request.Settings;
        let areazoneHtml = ``;
        if ($settings.Areas != undefined && $settings.Areas.length) {
            areazoneHtml += /*html*/ `<h6>Areas</h6>`;
            if (this.geoMap.Areas[$settings.Areas[0]] === undefined)
                areazoneHtml += /*html*/ `<h9>n/a`;
            else
                areazoneHtml += /*html*/ `<h9>${this.geoMap.Areas[$settings.Areas[0]].Name}`;
            for (let i = 1; i < $settings.Areas.length; i++) {
                if (this.geoMap.Areas[$settings.Areas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Areas[$settings.Areas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</h9>`;
        }
        else if ($settings.Zones != undefined && $settings.Zones.length) {
            areazoneHtml += /*html*/ `<h6>Zones</h6>`;
            if (this.geoMap.Zones[$settings.Zones[0]] === undefined)
                areazoneHtml += /*html*/ `<h9>n/a`;
            else
                areazoneHtml += /*html*/ `<h9>${this.geoMap.Zones[$settings.Zones[0]].Name}`;
            for (let i = 1; i < $settings.Zones.length; i++) {
                if (this.geoMap.Zones[$settings.Zones[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.Zones[$settings.Zones[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</h9>`;
        }
        else if ($settings.RouteAreas != undefined && $settings.RouteAreas.length) {
            areazoneHtml += /*html*/ `<h6>RouteAreas</h6>`;
            if (this.geoMap.RouteAreas[$settings.RouteAreas[0]] === undefined)
                areazoneHtml += /*html*/ `<h9>n/a`;
            else
                areazoneHtml += /*html*/ `<h9>${this.geoMap.RouteAreas[$settings.RouteAreas[0]].Name}`;
            for (let i = 1; i < $settings.RouteAreas.length; i++) {
                if (this.geoMap.RouteAreas[$settings.RouteAreas[i]] === undefined)
                    areazoneHtml += /*html*/ `, n/a`;
                else
                    areazoneHtml += /*html*/ `, ${this.geoMap.RouteAreas[$settings.RouteAreas[i]].Name}`;
            }
            areazoneHtml += /*html*/ `</h9>`;
        }
        function parseSettingsString(string) {
            if (string.includes('/')) {
                const array = string.split("/");
                if (array.length === 2) {
                    return (parseFloat(array[0]) * 100) + "% - " + (parseFloat(array[1]) * 100) + "%";
                }
            }
            return "-";
        }
        const strOccupancy = parseSettingsString($settings.Occupancy);
        const strVisitorRate = parseSettingsString($settings.VisitorRate);
        const strCompliancy = parseSettingsString($settings.Compliancy);
        const strCompliancyVisitors = parseSettingsString($settings.CompliancyVisitors);
        const strEnforcementIntensity = parseSettingsString($settings.EnforcementIntensity);
        let strGeoDataTimeStamp = "-";
        if ($route)
            strGeoDataTimeStamp = $route.GeoDataTimeStamp;
        const html = /*html*/ `
      <div class="columns" style="min-height: 300px; overflow-y: auto;">
        
        <div class="col-12">
          ${areazoneHtml}
        </div>

        <div class="col-12">
          <h6>GeoDataTimeStamp</h6>
          <h9>${strGeoDataTimeStamp}</h9>
        </div>
        
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Occupancy']}</h6>
          <h9>${strOccupancy}</h9>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['VisitorRate']}</h6>
          <h9>${strVisitorRate}</h9>
        </div>
        <div class="col-4" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['Compliancy']}</h6>
          <h9>${strCompliancy}</h9>
        </div>
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['CompliancyVisitors']}</h6>
          <h9>${strCompliancyVisitors}</h9>
        </div>  
        <div class="col-6" style="text-align: center; margin-top: 40px">
          <h6>${this.translations['EnforcementIntensity']}</h6>
          <h9>${strEnforcementIntensity}</h9>
        </div> 
    </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: $request.RouteName,
            content: html,
            type: ALERTS.Info,
            buttons: ALERT_BUTTONS.ok
        });
    }
    transformPlannedRoutes() {
        const output = [];
        let y = 0;
        for (const i in this.plannedRoutes) {
            const $device_routes = this.plannedRoutes[i];
            for (const j in $device_routes.Routes) {
                const $route = $device_routes.Routes[j];
                const FromDate = new Date($route.RouteFromTime);
                const ToDate = new Date($route.RouteToTime);
                output.push({
                    x: FromDate.getTime(),
                    x2: ToDate.getTime(),
                    y: y,
                    j: j,
                    duration: AConvertMillisecondsToHM(ToDate.getTime() - FromDate.getTime()),
                    device: $device_routes.ForDevice,
                    routename: $route.RouteName,
                    routeId: $route.RouteId,
                    plannedRouteIndex: i,
                    partialFill: 0.99
                });
            }
            y++;
        }
        return output;
    }
    drawRequestOnMap($map, $request) {
        if (this.geoMap == undefined || this.geoMap.WaySegments == undefined)
            return;
        let WaysegmentsParking = [];
        if ($request.ParkingStreetIds != undefined && $request.ParkingStreetIds.length) {
            for (const i in $request.ParkingStreetIds) {
                let WaySegmentId = Math.abs($request.ParkingStreetIds[i]);
                if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                    WaysegmentsParking.push(WaySegmentId);
                    if (this.geoMap.WaySegments[WaySegmentId])
                        this.routeMapHelperService.drawWaySegment($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                    //this.routeMapHelperService.drawWaySegmentRed($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments($map);
        }
    }
    drawRouteOnMap($map, $route) {
        if (this.geoMap == undefined || this.geoMap.WaySegments == undefined)
            return;
        let WaysegmentsParking = [];
        let WaysegmentsOther = [];
        if ($route.RouteSegments != undefined && $route.RouteSegments.length) {
            for (const i in $route.RouteSegments) {
                const $s = $route.RouteSegments[i];
                let WaySegmentId = Math.abs($s.WaySegmentId);
                if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                    if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                        WaysegmentsParking.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                        //this.routeMapHelperService.drawWaySegmentRed($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                    }
                }
                else {
                    if (WaysegmentsOther.indexOf(WaySegmentId) === -1) {
                        WaysegmentsOther.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment($map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.orange);
                        //this.routeMapHelperService.drawWaySegmentOrange($map, this.geoMap.WaySegments[WaySegmentId], 1.0)
                    }
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments($map);
        }
    }
    async getPlannedRoutes() {
        let query = `SELECT 
      p.ShareId,
      p.RouteId, 
      p.RouteName,
      p.CarNumber, 
      p.ForDevice, 
      p.ForCustomerNumber, 
      p.ForProjectNumber,
      p.RouteFromTime, 
      p.RouteToTime
    FROM planroute_planning p`;
        let where_clause = ` WHERE (((RouteFromTime between :FromDate AND :ToDate) OR (RouteFromTime between :FromDate AND :ToDate)) OR
                               ((:FromDate between RouteFromTime AND RouteToTime) OR (:ToDate between RouteFromTime AND RouteToTime)))`;
        if ($('#DeviceName').val() != "%") {
            where_clause += " AND p.ForDevice = :DeviceName";
        }
        let order_clause = ` ORDER BY p.RouteFromTime, p.RouteToTime`;
        query = query + where_clause + order_clause;
        let response = await requestService.query({
            //Query: (query + where_clause + order_clause),
            Query: query,
            Params: FilterManager.saveExplicit()
        });
        let temp_plannedRoutes = [];
        for (let row of response.Rows) {
            temp_plannedRoutes[row[0]] = {
                ShareId: row[0],
                RouteId: row[1],
                RouteName: row[2],
                CarNumber: row[3],
                ForDevice: row[4],
                ForCustomerNumber: row[5],
                ForProjectNumber: row[6],
                RouteFromTime: row[7],
                RouteToTime: row[8]
            };
        }
        this.plannedRoutes = [];
        for (const i in temp_plannedRoutes) {
            let route = {
                ShareId: temp_plannedRoutes[i].ShareId,
                Device: temp_plannedRoutes[i].ForDevice,
                RouteId: temp_plannedRoutes[i].RouteId,
                RouteName: temp_plannedRoutes[i].RouteName,
                CarNumber: temp_plannedRoutes[i].CarNumber,
                RouteFromTime: temp_plannedRoutes[i].RouteFromTime,
                RouteToTime: temp_plannedRoutes[i].RouteToTime
            };
            if (this.plannedRoutes[temp_plannedRoutes[i].ForDevice]) {
                this.plannedRoutes[temp_plannedRoutes[i].ForDevice].Routes.push(route);
            }
            else {
                let device = {
                    ForDevice: temp_plannedRoutes[i].ForDevice,
                    ForCustomerNumber: temp_plannedRoutes[i].ForCustomerNumber,
                    ForProjectNumber: temp_plannedRoutes[i].ForProjectNumber,
                    Routes: [route]
                };
                this.plannedRoutes[temp_plannedRoutes[i].ForDevice] = device;
            }
        }
        return Promise.resolve();
    }
    async getPlannerRoutesOverlap(from, to, device) {
        let query = `SELECT 
      p.RouteName,
      p.RouteFromTime, 
      p.RouteToTime
    FROM planroute_planning p
    WHERE (((RouteFromTime between :FromDate AND :ToDate) OR (RouteFromTime between :FromDate AND :ToDate)) OR
          ((:FromDate between RouteFromTime AND RouteToTime) OR (:ToDate between RouteFromTime AND RouteToTime)))
    AND p.ForDevice = :DeviceName`;
        let response = await requestService.query({
            //Query: (query + where_clause + order_clause),
            Query: query,
            Params: { FromDate: from, ToDate: to, DeviceName: device }
        });
        let result = [];
        let i = 0;
        for (let row of response.Rows) {
            const RouteName = row[0];
            const RouteFromTime = row[1];
            const RouteToTime = row[2];
            const oMax = Math.min(new Date(RouteToTime).getTime(), to.getTime());
            const oMin = Math.max(new Date(RouteFromTime).getTime(), from.getTime());
            if ((oMax - oMin) >= 60000) { // in milliseconds -> 60 sec -> 1 min
                result[i++] = {
                    RouteName: RouteName,
                    RouteFromTime: RouteFromTime,
                    RouteToTime: RouteToTime
                };
            }
        }
        return result;
    }
    async alert_sendOverlap(response) {
        let $page = this;
        let $routeList = ``;
        for (const row in response) {
            const cont = response[row];
            const fromTime = AInputTime(new Date(new Date(cont.RouteFromTime)));
            const toTime = AInputTime(new Date(new Date(cont.RouteToTime)));
            $routeList += `<li style="margin-left: 2px; list-style-position: outside;">                       
                      <div style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; max-width: 300px;">
                      ${fromTime} - ${toTime}  ${cont.RouteName}
                      </div>
                     </li>`;
        }
        const html = /*html*/ `
      <div class="form-group">
           <div>${$page.translations['New Planning Overlaps With']}:</div>
           <ul>
            ${$routeList}
           </ul>
          <div>${$page.translations['Continue']}?</div>
      </div>`.replace(/\s\s+/g, ' ');
        const alert = Alerts.show({
            translatedTitle: $page.translations['Warning: Overlapping Planning'],
            buttons: ALERT_BUTTONS.okCancel,
            content: html
        });
        return new Promise((resolve) => {
            alert.on(ALERT_STATUS.ON_ACTION_PROCEED, () => {
                resolve(true);
            });
            alert.on(ALERT_STATUS.ON_MODAL_CLOSED, () => {
                resolve(false);
            });
        });
    }
    async getRoutes() {
        let response = await requestService.query(`
      SELECT 
        r.RouteId, 
        r.RouteName, 
        r.CarNumber, 
        r.Cost, 
        r.Length,
        r.Requestcreated_datetime, 
        ST_AsGeoJSON(r.RouteGpsLine),
        r.Active,
        r.RouteSegments,
        r.GeoDataTimeStamp
      FROM planroute_routes r 
      WHERE r.Finished = 1
      ORDER BY Active DESC, RouteName ASC
    `);
        this.Routes = [];
        for (let row of response.Rows) {
            this.Routes[row[0] + "_" + row[2]] = {
                "RouteId": parseInt(row[0]),
                "RouteName": row[1],
                "CarNumber": parseInt(row[2]),
                "Cost": parseInt(row[3]),
                "Length": parseInt(row[4]),
                "Requestcreated_datetime": row[5],
                "RouteGpsLine": row[6],
                "Active": row[7],
                "RouteSegments": row[8],
                "GeoDataTimeStamp": row[9]
            };
        }
        return Promise.resolve();
    }
    async getRequests() {
        try {
            this.Requests = [];
            const response = await requestService.query(`
          SELECT
            Id as RouteId,
            Status,
            StatusText,
            RouteName, 
            ParkingStreetIds,
            Settings,
            PreferRight,
            NumRoutes,
            planroute_requests.MaxValue,
            MaxIteration,
            InitialCoolingRate,
            Created,
            Finished
          FROM planroute_requests
          ORDER BY Status DESC, Created
      `);
            if (response.Rows.length === 0) {
                $('#requestlist tbody').children().remove();
                return;
            }
            for (let row of response.Rows) {
                var Request = {
                    "RouteId": parseInt(row[0]),
                    "Status": row[1],
                    "StatusText": row[2],
                    "RouteName": row[3],
                    "ParkingStreetIds": row[4],
                    "Settings": row[5],
                    "PreferRight": row[6],
                    "NumRoutes": parseInt(row[7]),
                    "MaxValue": parseInt(row[8]),
                    "MaxIteration": parseInt(row[9]),
                    "InitialCoolingRate": parseInt(row[10]),
                    "Created": row[11],
                    "Finished": row[12]
                };
                this.Requests[Request.RouteId] = Request;
            }
            //this.createRows()
        }
        catch (err) {
            AError.handle(err);
        }
        return Promise.resolve();
    }
    fetchAndCacheRegime(from, to) {
        const data = {
            "From": from,
            "To": to,
        };
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_ValidateRegimeTime_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_ValidateRegimeTime_Response`, response => {
                this.regimes = response;
                console.log("regimes: ", this.regimes);
                resolve(true);
            });
        });
    }
    getGeoMap() {
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetGeoMap_Request", 1, null, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetGeoMap_Response`, response => {
                this.geoMap = response.GeoMap;
                resolve(true);
            });
        });
    }
    getDrivenRoutes() {
        const data = FilterManager.saveExplicit();
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetDrivenRoutes_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetDrivenRoutes_Response`, response => {
                this.drivenRoutes = [];
                if (response && response.error == null) {
                    this.drivenRoutes = response;
                }
                resolve(true);
            });
        });
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .fixTableHead {
        overflow-y: auto;
        height: 100%;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }
      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .tableRow.disabled{
        pointer-events: none;
      }

      .footer {
        background: rgb(248, 248, 248);
        border-top: 1px solid #eeeeee;
        width: calc(100% + 8px);
        padding: 7px 0;
      }

      .footer .text {
        padding-left: 8px;
        line-height: 35px;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }

      .mapWrapper {
        height: calc(100% - 100px);
        overflow-y: auto;
      }
    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div class="flex-child">
      <div id="timeline" style="height: 45%"></div>

      <div class="columns" style="height: 5%">
        <div style="text-align: center;" class="col-2">
          <button class="btn btn-primary" id="btn_reset_view" disabled>Reset view</button>
        </div>
        <div style="text-align: center;" class="col-4">
          <button class="btn btn-primary col-10" id="btn_add_planning_routes">Add Planning From Existing Routes</button>
        </div>
        <div style="text-align: center;" class="col-4">
          <button class="btn btn-primary col-10" id="btn_add_planning_routearea">Add Planning From RouteArea</button>
        </div>
        <div style="text-align: center;" class="col-2">
          <button class="btn btn-primary" id="btn_add_break">Add Break</button>
        </div>
      </div>

      <div id="planning_info" class="columns" style="height: 49%">
        <div class="col-6 columns">
          
          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_device">Device</h6>
            <h9 id="info_planning_device">DEVICE</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_name">Route Name</h6>
            <h9 id="info_planning_name">ROUTENAME</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_distance">Route distance</h6>
            <h9 id="info_planning_distance">DISTANCE ROUTE</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_date">DATE</h6>
            <h9 id="info_planning_date">DATE</h9>
          </div>          

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_from">From</h6>
            <h9 id="info_planning_from">FROM TIME</h9>
          </div>

          <div class="col-4" style="text-align: center; margin-top: 20px;">
            <h6 id="label_planning_to">To</h6>
            <h9 id="info_planning_to">TO TIME</h9>
          </div>          

          <div class="col-3" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_info">Info</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_info" class="btn btn-primary">
              <i class="fa fa-info" aria-hidden="true"></i>
            </button> 
          </div>

          <div class="col-3" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_edit">Edit</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_edit" class="btn btn-orange">
              <i class="fa fa-edit" aria-hidden="true"></i>
            </button>  
          </div>

          <div class="col-3" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_send">Resend</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_send" class="btn btn-success">
              <i class="fa fa-share-square" aria-hidden="true"></i>
            </button>  
          </div>

          <div class="col-3" style="text-align: center; margin-top: 30px;">
            <label for="info_planning_delete">Delete</label><br>
            <button style="width: 36px; height: 36px; margin-top: 5px;" id="info_planning_delete" class="btn btn-error">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>  
          </div>

        </div>
        
        <div class="col-6 columns">
          <div class="mapWrapper" style="height: 100%">
            <div id="planning_map" class="aci-map"></div>
            <div class="legend legend-opaque" id="planning_map_legend">
            <div class="legend-label label-height-lg hidden">Legend</div>
            
            <div class="legend-item">
              <div class="route-preview" style="background-color: rgba(255, 0, 0, 0.7); border-color: #a30000"></div>
              <span>With Parking</span>
            </div>
            <div class="legend-item">
              <div class="route-preview" style="background-color: rgba(255, 143, 15, 0.7); border-color: #e08722"></div>
              <span>Without Parking</span>
            </div>
          </div>
          </div>
        </div>        
      </div>
    </div>
  `);
}

import { AError } from "../../classes/AError.js";
import { COLUMN_NUMBER, COLUMN_TEXT, DATA_DATETIME, DATA_NUMBER } from "../../classes/AGridTypes.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { MAP_OPTIONS } from "../../services/AMapHelperService.js";
import { AStatisticsService } from "../../services/AStatisticsService.js";
import { AGridColumns, AGridData, AShowTable } from "../../utils/tools.js";
function ColumnToSelect(group) {
    let output = { select: [], group: [], joins: '' };
    switch (group) {
        case 'Area':
            output = {
                select: [`gz.AreaId`, `gz.Name as Area`],
                group: [`gz.AreaId`, `Area`],
                joins: (`
          LEFT JOIN geo_segments2areas geo_sz ON geo_sz.SegmentId = df.SegmentId
          LEFT JOIN geo_areas gz ON gz.AreaId = geo_sz.AreaId
        `).trim()
            };
            break;
        case 'Zone':
            output = {
                select: [`CONCAT(gz.ZoneId, '-', gz.Name) as Zone`],
                group: [`Zone`],
                joins: (`
          LEFT JOIN geo_segments2zones geo_sz ON geo_sz.SegmentId = df.SegmentId
          LEFT JOIN geo_zones gz ON gz.ZoneId = geo_sz.ZoneId
        `).trim()
            };
            break;
        case 'DetectionDevice':
            output = {
                select: [`DetectionDeviceId`],
                group: [`DetectionDeviceId`],
                joins: (``)
            };
            break;
        case 'DetectionUser':
            output = {
                select: [`DetectionUser`],
                group: [`DetectionUser`],
                joins: (``)
            };
            break;
        case 'FinalVerificationUser':
            output = {
                select: [`FinalVerificationUser`],
                group: [`FinalVerificationUser`],
                joins: (``)
            };
            break;
        case 'None':
            output = {
                select: [],
                group: [],
                joins: (``)
            };
            break;
        default:
            throw new Error(`Unexpected Column `);
    }
    return output;
}
/**
 * @param {string} timespanKey
 * @returns {{ select: string[], group: string[] }}
 */
function TimeSpanToSelect(timespanKey) {
    const options = {
        Year: () => {
            return {
                select: [`DATE(DetectionTime) as _Date`],
                group: [`_Date`]
            };
        },
        Month: () => {
            return {
                select: [`DATE_FORMAT(DetectionTime, '%Y-%m') _Date`],
                group: [`_Date`]
            };
        },
        Week: () => {
            return {
                select: [`YEAR(DetectionTime) _Year`, `WEEK(DetectionTime, 3) _Week`],
                group: [`_Year`, `_Week`]
            };
        },
        Day: () => {
            return {
                select: [`DATE_FORMAT(DATE(DetectionTime), '%Y-%m-%d') as _Date`],
                group: [`_Date`]
            };
        },
        Hour: () => {
            return {
                select: [`DATE_FORMAT(DetectionTime, CONCAT('%Y-%m-%d %H', :zero_minutes)) as _Date`],
                group: [`_Date`]
            };
        }
    };
    if (!options.hasOwnProperty(timespanKey)) {
        throw new Error(`No TimeSpan For Key="${timespanKey}"`);
    }
    return options[timespanKey]();
}
export class APage {
    constructor() {
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    findZone(strZoneId) {
        const zoneId = parseInt(strZoneId);
        if (!isNaN(zoneId)) {
            const geoObjects = mapHelperService.geoObjectMapper(MAP_OPTIONS.Zone);
            const zones = Object.values(geoObjects);
            for (let zoneGroup of zones) {
                if (zoneGroup.hasOwnProperty(zoneId)) {
                    return zoneGroup[zoneId].Name;
                }
            }
        }
        return strZoneId;
    }
    createInClause({ FirstIndex, LastIndex }) {
        let indexes = [];
        for (var i = FirstIndex; i <= LastIndex; i++) {
            indexes.push(i);
        }
        return indexes;
    }
    buildQuery({ filters }) {
        const { TimeSpan, Group } = filters;
        const { select, group } = TimeSpanToSelect(TimeSpan);
        const optional = ColumnToSelect(Group);
        return {
            select: select.concat(optional.select).join(', '),
            group: group.concat(optional.group).join(', '),
            joins: optional.joins
        };
    }
    async getFetchColumns(filters) {
        const joinTables = [];
        const columns = [];
        switch (filters.TimeSpan) {
            case 'Year':
                columns.push({
                    select: `YEAR(DetectionTime)`,
                    name: '_Year',
                    groupBy: true
                });
                break;
            case 'Month':
                columns.push({
                    select: `DATE_FORMAT(DetectionTime, '%Y-%m')`,
                    name: '_Date',
                    groupBy: true
                });
                break;
            case 'Week':
                columns.push({
                    select: `YEAR(DetectionTime)`,
                    name: '_Year',
                    groupBy: true
                });
                columns.push({
                    select: `WEEK(DetectionTime, 3)`,
                    name: '_Week',
                    groupBy: true
                });
                break;
            case 'Day':
                columns.push({
                    select: `DATE_FORMAT(DATE(DetectionTime), '%Y-%m-%d')`,
                    name: '_Date',
                    groupBy: true
                });
                break;
            case 'Hour':
                columns.push({
                    select: `DATE_FORMAT(DetectionTime, CONCAT('%Y-%m-%d %H', :zero_minutes))`,
                    name: '_Date',
                    groupBy: true
                });
                break;
            default:
                AError.handle(`Unexpected Filter TimeSpan: "${filters.TimeSpan}"`);
                break;
        }
        switch (filters.Group) {
            case 'Area':
                joinTables.push(`
          LEFT JOIN geo_segments2areas geo_sz USING (SegmentId)
          LEFT JOIN geo_areas gz ON gz.AreaId = geo_sz.AreaId
        `.trim());
                columns.push({
                    select: `gz.AreaId`,
                    name: '_AreaId',
                    groupBy: true
                });
                columns.push({
                    select: `gz.Name`,
                    name: '_Area',
                    groupBy: true
                });
                break;
            case 'Zone':
                joinTables.push(`
          LEFT JOIN geo_segments2zones geo_sz USING (SegmentId)
          LEFT JOIN geo_zones gz USING (ZoneId)
        `.trim());
                columns.push({
                    select: `CONCAT(gz.ZoneId, '-', gz.Name)`,
                    name: `_Zone`,
                    groupBy: true
                });
                break;
            case 'DetectionDevice':
                columns.push({
                    select: 'DetectionDeviceId',
                    name: `_DetectionDeviceId`,
                    groupBy: true
                });
                break;
            case 'DetectionUser':
                columns.push({
                    select: `DetectionUser`,
                    name: '_DetectionUser',
                    groupBy: true
                });
                break;
            case 'FinalVerificationUser':
                columns.push({
                    select: `IF(FinalVerificationUser='',NULL,FinalVerificationUser)`,
                    name: '_FinalVerificationUser',
                    groupBy: true
                });
                break;
            case 'None':
                break;
            default:
                AError.handle(`Unexpected Filter Group By: "${filters.Group}"`);
                break;
        }
        return { joinTables, columns };
    }
    async refresh() {
        FilterManager.setActive(false);
        const filters = FilterManager.saveExplicit();
        const statisticsService = AEngine.get(AStatisticsService);
        const { columns, joinTables } = await this.getFetchColumns(filters);
        return await Loading.waitForPromises(statisticsService.fetchDynamic({ zero_minutes: ':00', ...filters }, { columns, joinTables })).then(async (res) => {
            FilterManager.setActive(true);
            if (res.isEmpty) {
                return Alerts.noResults();
            }
            const dynamicGridColumns = {};
            columns.map(col => { dynamicGridColumns[col.name] = COLUMN_TEXT; });
            Object.assign(dynamicGridColumns, {
                'Total': COLUMN_NUMBER,
                'Suspects': COLUMN_NUMBER,
                'Followups': COLUMN_NUMBER,
                'Sanctions': COLUMN_NUMBER,
            });
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: await AGridColumns(dynamicGridColumns),
                data: AGridData(res.toJSON({ replaceNullWithUnknown: true }), {
                    'DetectionTime': DATA_DATETIME,
                    'ZoneId': (value) => `${this.findZone(value)}(${value})`,
                    'Sanctions': DATA_NUMBER,
                    'Followups': DATA_NUMBER,
                    'Suspects': DATA_NUMBER,
                    'Detections': DATA_NUMBER,
                })
            });
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="TimeSpan">TimeSpan</label>
          <select class="form-select" id="TimeSpan">
            <option value="Year">Year</option>
            <option value="Month" selected>Month</option>
            <option value="Week">Week</option>
            <option value="Day">Day</option>
            <option value="Hour">Hour</option>
          </select>
        </div>
        <div class="form-group">
          <label class="form-label" for="Group">Group By</label>
          <select class="form-select" id="Group">
            <option value="Area" selected>Area</option>
            <option value="Zone">Zone</option>
            <!-- <option value="ParkingSpace">Parking Space</option> -->
            <option disabled>──────────</option>
            <option value="DetectionDevice">DetectionDevice</option>
            <option value="DetectionUser">Detection User</option>
            <option value="FinalVerificationUser">VerificationUser</option>
            <option disabled>──────────</option>
            <option value="None">None</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div template="${ATemplates.WaitingForInput}"></div>
      <div id="table-bryntum" class="hidden"></div>

      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Rows</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <button id="export" class="btn btn-primary col-12" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}

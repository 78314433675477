import { AEngine } from "../core/AEngine.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AChartTooltipFormatter, MakeColumnChart, QueryResponseToSeries, ResizeChart } from "./charts.js";
import { AConvertMatrixToResponse, ARound, createMatrix } from "../utils/tools.js";
import { ADynamicChartUtils } from "./ADynamicChartUtils.js";
import { EVENTS } from "../services/AEventService.js";
import { AError } from "../classes/AError.js";
export class ADynamicChartSnapshotAggregation {
    get Horizontal() { return this.cachedOptions.chartOpt.Horizontal; }
    get Series() { return this.cachedOptions.chartOpt.Series ?? this.cachedOptions.chartOpt.Vertical; }
    get KeyY() { return this.cachedOptions.chartOpt.KeyY; }
    get IgnoreOutsideSegment() { return this.cachedOptions.chartOpt.IgnoreOutsideSegment; }
    get OnlyAllowFinalized() { return this.cachedOptions.chartOpt.OnlyAllowFinalized; }
    constructor(chartId, opt) {
        this.chartId = chartId;
        this.opt = opt;
        this.chartUtils = new ADynamicChartUtils();
        this.statisticService = AEngine.get(AStatisticsService);
        Events.on(EVENTS.CONTENT_RESIZE, this.resize.bind(this));
    }
    isMounted() {
        return this.chart !== undefined && Object.keys(this.chart).length > 0 && this.chart.container.isConnected === true;
    }
    resize() {
        ResizeChart(this.chart, this.opt.calcDimensions(), true, { silentErrors: true });
    }
    setTitle(title) {
        this.chart?.setTitle({ text: title });
    }
    verticalAxisToUnit(verticalAxis) {
        const vAxisToUnit = {
            Detections: { label: 'Detections', unit: 'Detections', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            Suspects: { label: 'Suspects', unit: 'Suspects', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            Sanctions: { label: 'Sanctions', unit: 'Sanctions', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            FollowupRate: { label: 'FollowupRate', unit: 'Detections', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            SuspectRate: { label: 'SuspectRate', unit: 'Detections', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            SanctionRate: { label: 'SanctionRate', unit: 'Detections', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: false },
            // Capacity: {label: 'Capacity', unit: 'ParkingSpaces', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true},
            // Occupancy: {label: 'Occupancy', unit: '%', isPerc: true, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true},
            VisitorRate: { label: 'VisitorRate', unit: '%', isPerc: true, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true },
            PermitRate: { label: 'PermitRate', unit: '%', isPerc: true, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true },
            Compliancy: { label: 'Compliancy', unit: '%', isPerc: true, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true },
            CompliancyVisitor: { label: 'CompliancyVisitor', unit: '%', isPerc: true, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true },
            EnforcementIntensity: { label: 'EnforcementIntensity', unit: ' ', isPerc: false, onlyAllowFinalized: true, ignoreDetectionsOutsideSegment: true },
        };
        const vAxis = (verticalAxis == null) ? Object.keys(vAxisToUnit)[0] : verticalAxis;
        if (!vAxisToUnit.hasOwnProperty(vAxis)) {
            AError.handleSilent(new Error(`ADynamicChartSnapshotAggregation.verticalAxisToUnit unexpected axis: ${vAxis}`));
        }
        return vAxisToUnit[vAxis] ?? {};
    }
    dynamicDataToRowData(name, streetEntry) {
        const Name = name.replace(/,/g, '|'), Detections = streetEntry.getTotal(), Occupancy = streetEntry.getOccupancy(), VisitorRate = streetEntry.getVisitorRate(), PermitRate = streetEntry.getPermitRate(), Compliancy = streetEntry.getCompliancy(), CompliancyVisitor = streetEntry.getCompliancyVisitors(), EnforcementIntensity = streetEntry.getEnforcementIntensity(), Capacity = streetEntry.getCapacity(), Suspects = streetEntry.getSuspects(), Sanctions = streetEntry.getSanctions();
        return {
            Name,
            Detections,
            Occupancy: Occupancy !== null ? ARound(Occupancy * 100, 2) : null,
            VisitorRate: VisitorRate !== null ? ARound(VisitorRate * 100, 2) : null,
            PermitRate: PermitRate !== null ? ARound(PermitRate * 100, 2) : null,
            Compliancy: Compliancy !== null ? ARound(Compliancy * 100, 2) : null,
            CompliancyVisitor: CompliancyVisitor !== null ? ARound(CompliancyVisitor * 100, 2) : null,
            EnforcementIntensity: EnforcementIntensity !== null ? ARound(EnforcementIntensity, 2) : null,
            Capacity: Capacity !== null ? ARound(Capacity, 2) : null,
            Suspects: Suspects !== null ? ARound(Suspects, 2) : null,
            Sanctions: Sanctions !== null ? ARound(Sanctions, 2) : null,
        };
    }
    async fetchChartData(filters) {
        const { groups, mapTo, xKeys, yKeys } = await this.chartUtils.genGroupMapping([
            { group: this.Series, sort: true },
            { group: this.Horizontal, sort: true }
        ]);
        const { statistics, processedDetections } = await Loading.waitForPromises(this.statisticService.fetch(filters, {
            groups,
            mapTo,
            ignoreDetectionsOutsideSegment: this.IgnoreOutsideSegment ?? false,
            onlyAllowFinalized: this.OnlyAllowFinalized ?? false,
        }));
        // TODO: Implement this in all other kpis too
        if (processedDetections === 0) {
            return { hasData: false, chartData: { Columns: [], ColumnsTranslated: [], Rows: [] } };
        }
        const matrix = createMatrix(xKeys.length + 1, yKeys.length + 1, () => 0);
        Object.keys(statistics).map((key) => {
            const [xKey, yKey] = key.split(' ');
            const xIndex = xKeys.indexOf(xKey);
            const yIndex = yKeys.indexOf(yKey);
            const data = this.dynamicDataToRowData(key, statistics[key]);
            // values[xIndex+1][yIndex+1] = statistics[key].getTotal()
            matrix[xIndex + 1][yIndex + 1] = data[this.KeyY];
        });
        matrix[0][0] = 'Bars';
        xKeys.map((_, i) => { matrix[i + 1][0] = xKeys[i]; });
        yKeys.map((_, i) => { matrix[0][i + 1] = yKeys[i]; });
        const output = await AConvertMatrixToResponse(matrix, false);
        return {
            hasData: output.Rows.length > 0,
            chartData: output
        };
    }
    async update(chartOpt, filterOpt, predicate) {
        const { label, unit, isPerc, ignoreDetectionsOutsideSegment, onlyAllowFinalized } = this.verticalAxisToUnit(chartOpt.KeyY ?? 'Detections');
        Object.assign(chartOpt, {
            IgnoreOutsideSegment: ignoreDetectionsOutsideSegment,
            OnlyAllowFinalized: onlyAllowFinalized,
        });
        this.cachedOptions = { chartOpt, filterOpt };
        // const success = (predicate !== undefined) ? predicate(chartData) : true
        // const { ChartType, ExcludeDone, Horizontal, IncludeTotals, Inverted, Polar } = chartOpt
        // const success = true
        // const chartType: AColumnType = this.chartType ?? 'bar'
        // const chart = this.chart as AChart|undefined
        // const Inverted = false
        // const Polar = false
        // const allowExport = true
        const { hasData, chartData } = await this.fetchChartData(filterOpt);
        const success = (predicate !== undefined) ? predicate(chartData) : true;
        if (!success) {
            return { hasData: false, chartData: chartData, instantiated: false };
        }
        const series = QueryResponseToSeries(chartData, {
            chartType: chartOpt.ChartType,
            unit
        });
        const formatter = await AChartTooltipFormatter({
            unit,
            label,
            addPercentage: !isPerc,
            addTotal: !isPerc,
            hideZeroValues: true
        });
        const plotOptions = {
            column: {
                stacking: unit === '%' ? undefined : 'normal',
                dataLabels: {
                    // format: `{point.y} ${unit === '%' ? '%' : ''}`,
                    formatter: function () {
                        if (this.y != 0) {
                            return `${this.y} ${unit === '%' ? '%' : ''}`;
                        }
                        return null;
                    }
                }
            },
        };
        const categories = chartData.Columns.slice(1);
        // const categories = hSelection.Translate || hSelection.TransformData ? chartData.ColumnsTranslated.slice(1) : chartData.Columns.slice(1)
        const chart = this.chart;
        const isMounted = this.isMounted();
        if (!isMounted) {
            this.chart = await MakeColumnChart({
                series: series,
                allowExport: this.opt?.allowExport ?? true,
                bind: this.chartId,
                type: chartOpt.ChartType,
                flat: true,
                unit: unit,
                inverted: chartOpt.Inverted,
                polar: chartOpt.Polar,
                xAxis: { categories },
                yAxis: { title: { text: unit } },
                tooltip: {
                    formatter
                },
                plotOptions,
                legend: {
                    enabled: chartOpt.ShowLegend ?? true
                },
                // translate: hSelection.Translate,
                // ...vSelection,
            });
        }
        else {
            chart.update({
                chart: {
                    inverted: chartOpt.Inverted,
                    polar: chartOpt.Polar,
                    type: chartOpt.ChartType
                },
                series: series.map(s => {
                    // If new serie is added, make sure it's visible!
                    const foundSerie = chart?.series?.find(oldSerie => oldSerie.name === s.name);
                    if (foundSerie !== undefined) {
                        s.visible = foundSerie.visible ?? true;
                    }
                    return s;
                }),
                xAxis: { categories },
                yAxis: { title: { text: label } },
                tooltip: {
                    // valueSuffix: ` ${unit}`
                    formatter
                },
                plotOptions: plotOptions,
                legend: {
                    enabled: chartOpt.ShowLegend ?? true
                },
            }, true, true, true);
        }
        // this.chart?.setSize(this.getWidthFromKpi(), this.getHeightFromKpi())
        return { chartData, hasData, instantiated: !isMounted };
    }
    destroy() {
        this.chart?.destroy();
        delete this.chart;
    }
}

import { AError } from "../../classes/AError.js";
import { AForm } from "../../core/form/AForm.js";
import { COLUMN_ACTION, COLUMN_BOOLEAN, COLUMN_MULTI_SELECT, COLUMN_MULTI_SELECT_TRANSLATE, DATA_DURATION } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AChannelOrm } from "../../orm/AChannelOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
import { AFormInstance } from "../../core/form/AFormInstance.js";
export class APage {
    set state(value) {
        this._state = value;
        switch (this._state) {
            case "tab-grid-view":
                filterService.setActive(true);
                break;
            case "tab-form-view":
                filterService.setActive(false, { silent: true });
                break;
        }
    }
    constructor() {
        this.channelOrm = new AChannelOrm();
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        $('#create-btn').on('click', (e) => this.displayCreateModal());
        await permissionService.refetchUsergroups();
        Loading.waitForPromises(this.refresh());
    }
    addPrefixInput(opt) {
        const { $input, id, text } = opt;
        const $formGroup = $input.parent();
        const $inputGroup = $(/*html*/ `
      <div class="input-group">
        <span id="${id}" class="input-group-addon">${text}</span>
      </div>
    `);
        $formGroup.append($inputGroup);
        $input.appendTo($inputGroup);
        const $optionCodePrefix = $inputGroup.find(`#${id}`);
        return $optionCodePrefix;
    }
    async cloneTranslationInput(opt) {
        const { $form, $input, id, lang, inputId } = opt;
        const $formGroup = $input.parent();
        const $inputGroup = $(/*html*/ `
      <div class="input-group">
        <span id="${id}" class="input-group-addon">${lang}</span>
      </div>
    `);
        if (opt.$before && opt.$before.length > 0) {
            $inputGroup.insertBefore(opt.$before);
        }
        else {
            $formGroup.append($inputGroup);
        }
        $input.addClass('hidden');
        const $clone = $input.clone();
        $clone.attr('id', inputId);
        $clone.attr('name', inputId);
        $clone.removeClass('hidden');
        $clone.appendTo($inputGroup);
        if (opt.inputValue) {
            $clone.val(opt.inputValue);
        }
        await AForm.initFormValidation($form, [{
                id: inputId,
                type: 'text',
                minlength: 1,
                maxlength: 512,
                value: opt.inputValue,
            }]);
        return $inputGroup;
    }
    async initTranslationInputs({ $form, $code, ChannelCode }) {
        const $channelName = $form.find('#ChannelName');
        const { Translations, AvailableLanguages } = await Translate.fetchTranslationFor(ChannelCode);
        const keys = [...new Set(['en', Language, ...Object.keys(Translations)])];
        const $inputGroupArr = await Loading.waitForPromises(keys.map(async (lang) => {
            const $inputGroup = await this.cloneTranslationInput({
                $form: $form,
                lang: lang,
                id: `ChannelNamePrefix-${lang}`,
                $input: $channelName,
                inputId: `ChannelCode-${lang}`,
                inputValue: Translations[lang] ? Translations[lang].Translation || '' : ''
            });
            return $inputGroup;
        }));
        const $addLangGroup = $(/*html*/ `
      <div class="input-group">
        <select class="form-select">
          ${AvailableLanguages.filter(v => !keys.includes(v)).map(v => `<option value="${v}">${v}</option>`).join('')}
        </select>
        <button class="btn btn-grey input-group-btn">
          <i class="fa-solid fa-plus"></i>
        </button>
      </div>
    `);
        const $lang = $addLangGroup.find('select');
        const $btnAdd = $addLangGroup.find('.btn');
        $btnAdd.on('click', async (e) => {
            const lang = $lang.val();
            const $selectedOption = $lang.find('option:selected');
            if (($selectedOption.val() || '').length > 0) {
                $selectedOption.remove();
                await this.cloneTranslationInput({
                    $form: $form,
                    lang: lang,
                    id: `ChannelNamePrefix-${lang}`,
                    $input: $channelName,
                    inputId: `ChannelCode-${lang}`,
                    inputValue: '',
                    $before: $addLangGroup,
                });
            }
            $code.trigger('focusout');
        });
        $addLangGroup.before($addLangGroup);
        $inputGroupArr[0].parent().append($addLangGroup);
        return $inputGroupArr[0].closest('.form-group');
    }
    async updateTranslations({ translationKey, $formGroup }) {
        const $inputs = $formGroup.find('.input-group input').toArray().map(inp => $(inp));
        const $langInputs = $inputs.filter($inp => $inp.attr('id')?.startsWith('ChannelCode-'));
        await Promise.all($langInputs.map($inp => {
            const $lang = $inp.prev();
            if (!$lang.is('span')) {
                console.log({ $lang });
                throw new Error(`Couldn't find lang prefix!`);
            }
            const lang = $inp.attr('id')?.split('-').pop();
            if (!lang) {
                throw new Error(`Couldn't find language for id=${$inp.attr('id')}`);
            }
            const translation = $inp.val();
            return Translate.updateTranslation(lang, translationKey, translation, true);
        }));
    }
    async genFormInputs(record) {
        const inputs = [
            { id: 'ChannelCode', type: 'text', minlength: 1, maxlength: 64, disabled: record !== undefined },
            { id: 'IsFineCancelation', type: 'checkbox' },
            { id: 'ChannelName', type: 'text', minlength: 1, maxlength: 64 },
            { id: 'DetectionLifespan', type: 'duration', value: '04:00:00' },
            { id: 'ExternalId', type: 'text', value: '', minlength: 0, maxlength: 50 },
            // ENUM('Enforce', 'FollowUp')
            {
                id: 'SessionModes',
                type: 'multiselect',
                disallowNone: true,
                options: [
                    { id: 'Enforce', text: 'Enforce', checked: (record) ? ((record.SessionModes || []).includes('Enforce') || record.SessionModes === null) : true },
                    { id: 'FollowUp', text: 'FollowUp', checked: (record) ? ((record.SessionModes || []).includes('FollowUp') || record.SessionModes === null) : true },
                ]
            },
            // ENUM('CentralVerification', 'Pda')
            {
                id: 'DeviceTypes',
                type: 'multiselect',
                disallowNone: true,
                options: [
                    { id: 'CentralVerification', text: 'CentralVerification', checked: (record) ? ((record.DeviceTypes || []).includes('CentralVerification') || record.DeviceTypes === null) : true },
                    { id: 'Pda', text: 'Pda', checked: (record) ? ((record.DeviceTypes || []).includes('Pda') || record.DeviceTypes === null) : true },
                ]
            },
            // SELECT DISTINCT usergroups
            {
                id: 'Usergroups',
                type: 'multiselect',
                disallowNone: true,
                options: Usergroups.map(g => {
                    return {
                        id: g.UserGroup,
                        text: g.UserGroupText,
                        checked: (record) ? ((record.Usergroups || []).includes(g.UserGroup) || record.Usergroups === null) : true
                    };
                })
            },
        ];
        return inputs;
    }
    async displayCreateModal(record) {
        const formInputs = await this.genFormInputs(record);
        const form = new AFormInstance({ formInputs, ignoreWildcards: true });
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(form.generate({ translate: true }))
        });
        const $form = events.$ele.find('form');
        await form.injectFormData({ formData: record });
        await form.initFormValidation();
        // await AForm.injectFormData($form, { formData: record, formInputs })
        // await AForm.initFormValidation($form, formInputs)
        const $channelCode = $form.find('#ChannelCode');
        $channelCode.on('keypress', function (e) {
            const c = e.which; // const key: string = String.fromCharCode(c).toLowerCase()
            const isNumeric = (c) => (c >= 48 && c <= 57);
            const isAlphabetic = (c) => (c >= 97 && c <= 122) || (c >= 65 && c <= 90);
            const isSpaceOrBackspace = (c) => (c === 95 || c === 8);
            const charAllowed = (isNumeric(c) || isAlphabetic(c) || isSpaceOrBackspace(c));
            if (!charAllowed || e.ctrlKey) {
                e.preventDefault();
                return false;
            }
        });
        const $codePrefix = record ? $() : this.addPrefixInput({
            id: 'CodePrefix',
            text: 'CH|',
            $input: $channelCode
        });
        const $formGroup = await Loading.waitForPromises(this.initTranslationInputs({ $form, $code: $channelCode, ChannelCode: record?.ChannelCode }));
        // Translation Initializer
        $channelCode.on('focusout', async (e) => {
            $channelCode.val($channelCode.val().toUpperCase());
            const textToTranslate = $channelCode.val() || '';
            try {
                if (textToTranslate.length === 0)
                    return;
                const filterEmpty = ($inp) => $inp.attr('id').includes('-') && ($inp.val() || '').length === 0;
                const $inputs = $formGroup.find('input.is-error').toArray().map(inp => $(inp));
                // Translate 
                const inputs = await Loading.waitForPromises($inputs.filter(filterEmpty).map(async ($inp) => {
                    const lang = $inp.attr('id').split('-').pop();
                    return {
                        $inp,
                        lang,
                        val: await Translate.fetch(textToTranslate, { lang: lang })
                        // .then(v => v.toLowerCase().startsWith('ch|') ? v.substring(3) : v)
                    };
                }));
                // Set Translations
                inputs.map(({ $inp, val }) => { $inp.val(val); $inp.trigger('change'); });
            }
            catch (err) {
                AError.handleSilent(err);
            }
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if ($form.find('.is-error:not(.hidden)').length > 0) {
                    Alerts.incomplete();
                    return false;
                }
                const options = form.extractFormData({ cleanData: true });
                // const options: AChannelWithRestrictions = AForm.extractFormData($form, { ignoreWildcards: true }) as any
                options.ChannelCode = ($codePrefix.text().trim() || '') + options.ChannelCode;
                const success = await Loading.waitForPromises(record === undefined ?
                    this.channelOrm.create(options) : this.channelOrm.update(options));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.ChannelCode || 'Entry'} Already Exists!`)
                    });
                }
                await Loading.waitForPromises(this.updateTranslations({ translationKey: options.ChannelCode, $formGroup }));
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete Channel')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this channel?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.channelOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.warnAboutFollowUpOptions(record.ChannelName));
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async warnAboutFollowUpOptions(channelName) {
        try {
            Alerts.show({
                title: ALERT_TITLES.Warning,
                content: channelName + ' ' + await Loading.waitForPromises(Translate.get(/*html*/ `
          Channel Deleted!
          Please note that the FollowUpOptions for the deleted channel may still exist. This has to be manually!
        `))
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.channelOrm.fetchAll();
            ares.addColumns(['ActionEdit', 'ActionDelete']);
            // await Loading.waitForPromises(ares.map(v => Translate.fetch(v.ChannelCode)))
            // Process translations for ChannelCodes, SessionModes, DeviceTypes
            const textToTranslate = ares.map(v => ([
                v.ChannelCode,
                ...(v.SessionModes || []),
                ...(v.DeviceTypes || []),
            ])).flat().filter(v => v != null);
            await Loading.waitForPromises(Translate.get(textToTranslate));
            const response = ares.Original;
            // appendResponseRows(response, ['ActionEdit', 'ActionDelete'])
            this.grid = AShowTable({
                appendTo: 'bryntum-table',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    skipResizeColumns: [0, 1],
                    flex: 1,
                },
                features: {
                    search: true
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        showHitIndex: false,
                        listeners: {
                            // input: 'onSearchFieldInput',
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this.searchHandler
                        }
                    },
                ],
                selectionMode: {
                    multiSelect: false
                },
                columns: AConvertToGridColumns(response, {
                    'ChannelName': {
                        htmlEncode: false,
                        renderer: ({ value, record }) => {
                            // const { ChannelCode } = record
                            // return (/*html*/`${ChannelCode ? Translate.getCache(ChannelCode) || '' : ''}`)
                            return value;
                        }
                    },
                    'IsFineCancelation': COLUMN_BOOLEAN,
                    'Usergroups': {
                        htmlEncode: false,
                        renderer: ({ record, value }) => {
                            return COLUMN_MULTI_SELECT.renderer({ record, value: Array.isArray(value) ? value.map(v => Usergroups.find(ug => ug.UserGroup == v)?.UserGroupText) : value });
                        }
                    },
                    'SessionModes': COLUMN_MULTI_SELECT_TRANSLATE,
                    'DeviceTypes': COLUMN_MULTI_SELECT_TRANSLATE,
                    'ActionEdit': COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                    'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
                }),
                data: AConvertToGridData(response, {
                    'DetectionLifespan': DATA_DURATION
                }),
            });
            this.grid.on('search', () => this.searchHandler.applyFilter());
            this.grid.on('cellclick', ({ record, column }) => {
                console.log(record.originalData);
                if (record.id && record.id.indexOf('group-header') !== -1) {
                    return;
                }
                switch (column.data.field) {
                    case 'ActionEdit':
                        this.displayCreateModal(record.data);
                        break;
                    case 'ActionDelete':
                        this.displayDeleteModal(record.data);
                        break;
                }
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function css() {
    return ( /*html*/`
  <style>
    #ChannelCode {
      text-transform: UPPERCASE;
    }
  </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Channels</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12">Create Channel</button>
          </div>
        </div>
      </div>
    </div>
  `);
}

import { ACrypto } from "../classes/ACrypto.js";
import { CHART_TYPE_ENUM } from "../classes/ADetectionStatistics.js";
import { AError } from "../classes/AError.js";
import { AStatisticsChart } from "../charts/AStatisticsChart.js";
import { AEngine, sleep } from "../core/AEngine.js";
import { EVENTS } from "../services/AEventService.js";
import { AStatisticsService } from "../services/AStatisticsService.js";
import { AFormatNumber, ARound, hoursToHHMMSS, mainColor } from "../utils/tools.js";
export class APage {
    constructor() {
        this.$scans = $('#TScans').parent().children().last();
        this.$suspects = $('#TSuspects').parent().children().last();
        this.$fines = $('#TFines').parent().children().last();
        this.$scanhours = $('#TScanHours').parent().children().last();
        this.$followuphours = $('#TFollowupHours').parent().children().last();
        this.$successfulfollowups = $('#TSuccessfulFollowUps').parent().children().last();
        this.$travelDistance = $('#TTravelDistance').parent().children().last();
        this.$scansperfine = $('[aid=TScansPerFine]');
        this.$followupsperfine = $('[aid=TFollowUpPerFine]');
        this.$scansperhour = $('[aid=TScansPerHour]');
        this.$finesperhour = $('[aid=TFinesPerHour]');
        this.$finesperfollowuphour = $('[aid=TFinesPerFollowUpHour]');
        this.$successfulfollowupsperhour = $('[aid=TSuccessfulFollowUpsPerHour]');
        this.$uptime = $('[aid=TUptime]');
        this.$meters = $('#meters');
        this.values = {};
        this.randoms = {};
    }
    async init() {
        FilterManager.load();
        this.translations = await Loading.waitForPromises(Translate.get([
            'FreeParking',
            'IllegallyParked',
            'NonPayingVisitor',
            'PayingVisitor',
            'PermitHolder',
            'Unknown',
            'WhiteListed',
            'Compliancy',
            'CompliancyVisitors',
            'Digitization',
            'FollowUpRate'
        ]));
        this.createMeters();
        const $autoRefresh = $('#AutoRefresh');
        $autoRefresh.on('change', _ => {
            if ($autoRefresh.is(':checked')) {
                FilterManager.setActive(false, { silent: true });
                $autoRefresh.removeAttr('disabled');
                this.loopId = ACrypto.randomHexString([0, 0, 0, 0, 0, 0]);
                this.refreshLoop(this.loopId);
            }
            else {
                FilterManager.setActive(true);
                $autoRefresh.removeAttr('disabled');
                this.loopId = null;
            }
        });
        $('#user-select').on('change', _ => this.updateScanHours());
        $('#RefreshButton').on('click', _ => {
            FilterManager.showFilterWarning()
                .then(_ => Loading.waitForPromises(this.refresh()))
                .catch(AError.handle);
        });
    }
    async refreshLoop(loopId) {
        try {
            if ($('#AutoRefresh').is(':checked') && this.loopId === loopId) {
                await this.refresh();
                await sleep(5000);
                this.refreshLoop(loopId);
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    get meterOptions() {
        return {
            chart: {
                type: 'solidgauge',
                width: 250
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                size: '100%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: Highcharts.defaultOptions.legend?.backgroundColor || '#EEE',
                    innerRadius: '80%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },
            tooltip: {
                enabled: false
            },
            // the value axis
            yAxis: {
                stops: [
                    [0.1, mainColor()],
                    [0.5, mainColor()],
                    [0.9, mainColor()]
                ],
                lineWidth: 0,
                minorTickInterval: null,
                tickAmount: 2,
                title: {
                    y: -70
                },
                labels: {
                    y: 16
                }
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 50,
                        borderWidth: 0,
                        useHTML: true
                    }
                }
            },
            exporting: {
                enabled: false
            }
        };
    }
    createMeters() {
        try {
            this.meters = {
                Compliancy: $(),
                CompliancyVisitors: $(),
                Digitization: $(),
                FollowUpRate: $()
            };
            const array = [
                'Compliancy',
                'CompliancyVisitors',
                'Digitization',
                'FollowUpRate'
            ];
            for (const identifier of array) {
                this.createMeter(identifier, this.translations[identifier], '%');
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    createMeter(identifier, title, unit = '%') {
        const $meter = $(`
      <div class="progress-custom progress-quarter column">
        <div class="barOverflow">
          <div class="bar-custom"></div>
        </div>
        <div class="spans">
          <span>0</span>
          <span>${unit}</span>
        </div>
        <span class="title">${title}</span>
      </div>
    `);
        this.updateMeter($meter, 0);
        this.$meters.append($meter);
        // this[`$${identifier}`] = $meter
        this.meters[identifier] = $meter;
        return $meter;
    }
    updateMeters() {
        $(".progress-custom").each(this.internalUpdateMeter);
    }
    updateMeter($meter, val) {
        return this.internalUpdateMeter.apply($meter, [val]);
    }
    internalUpdateMeter(val) {
        if (isNaN(val)) {
            val = 0;
        }
        var $bar = $(this).find(".bar-custom");
        var $val = $(this).find(".spans span:first-child");
        // @ts-ignore
        var perc = parseInt(typeof val === 'number' ? val : $val.text(), 10);
        $({ p: $bar.data('p') || 0 }).animate({ p: perc }, {
            duration: 3000,
            easing: "swing",
            step: function (p) {
                $bar.css({ transform: "rotate(" + (45 + (p * 1.8)) + "deg)" });
                $val.text(p | 0);
                $bar.data('p', p | 0);
            }
        });
    }
    setResult(identifier, res) {
        const $ele = (typeof identifier === 'string') ? this[`$${identifier}`] : identifier;
        if ($ele.css('opacity') === '0')
            $ele.css({ opacity: 1 });
        const amount = typeof res !== 'object' ? (res || 0) : parseInt(res.Rows[0][0]);
        $ele.html(amount);
        return amount;
    }
    deviceIdFromName(device) {
        return ScanDeviceIds[device];
    }
    getParams() {
        const params = FilterManager.save();
        const { FromDate, ToDate, DeviceName, Area } = params;
        const DetectionDeviceId = DeviceName === '%' ? '%' : this.deviceIdFromName(DeviceName); // DeviceIdFromDeviceName(DeviceName)
        return {
            DetectionDeviceId,
            FromDate,
            ToDate,
            Area: Area,
            AreaName: Area
        };
    }
    async fetchTravelData(params) {
        const additionalWhere = (params.DetectionDeviceId !== '%') ? 'AND DetectionDeviceId=:DetectionDeviceId' : '';
        const response = await Loading.waitForPromises(requestService.query({
            Query: (`
        SELECT
          SUM(TravelDuration) / 3600 AS OperationInHours,
          SUM(IF((EnforcingLeft > 0 OR EnforcingRight > 0), TravelDuration, 0)) / 3600 AS ScanInHours,
          SUM(TravelDistance) / 1000 AS DistanceInKM
        FROM waysegment_entries
        WHERE :ToDate > FromDateTime AND :FromDate < ToDateTime ${additionalWhere}
      `),
            Params: params
        }));
        if (response.Rows.length === 0) {
            return [{ formatted: '-', hours: 0 }, { formatted: '-', meters: 0 }, { formatted: '-', km: 0 }];
        }
        const [operationHours, scanHours, distanceInKM] = response.Rows[0];
        return [{
                formatted: hoursToHHMMSS(operationHours),
                hours: operationHours
            }, {
                formatted: hoursToHHMMSS(scanHours),
                hours: scanHours
            }, {
                formatted: AFormatNumber(ARound(distanceInKM)) + 'km',
                km: distanceInKM
            }];
    }
    setupChartResizing() {
        this.charts.map(chart => {
            chart.updateSize();
        });
        Events.on(EVENTS.CONTENT_RESIZE, _ => {
            this.charts.map(chart => {
                chart.updateSize();
            });
        });
    }
    getPrefferedSize() {
        return {
            height: ($('#AjaxContent').height() || 0) - ($('#panels .aci-tabs').height() || 0) - 214 - 23 - 10,
            width: ($('.flex-child').width() || 0) - 10
        };
    }
    async fetchUserPerformance(filters) {
        const statisticService = AEngine.get(AStatisticsService);
        const usernameMap = await statisticService.fetchUsernameMap();
        const statisticsMap = await statisticService.fetchGroupedByDetectionUser(filters, { usernameMap });
        const hoursMap = await statisticService.fetchDriverHours(filters, { usernameMap });
        const data = {};
        await Promise.all(Object.keys(statisticsMap).map(async (User) => {
            if (!hoursMap.hasOwnProperty(User)) {
                console.warn(`hoursMap, doesnt have key: ${User}`);
                return;
            }
            const { OperationHours, ScanHours, DistanceKM } = hoursMap[User];
            data[User] = {
                User: User ?? Translate.getCache('unknown'),
                Statistics: statisticsMap[User],
                OperationHours: OperationHours,
                ScanHours: ScanHours,
                DistanceKM: DistanceKM,
            };
        }));
        const $select = $('#user-select');
        $select.html('');
        Object.values(data).map(({ User }, i) => {
            if (i === 0) {
                $select.append(`<option selected="selected">${User}</option>`);
            }
            else {
                $select.append(`<option>${User}</option>`);
            }
        });
        // Jaap gaat nieuwe server bouwen waar user in de waysegment_entries staat!
        this.data = data;
        this.updateScanHours();
    }
    updateScanHours() {
        const scanUser = $('#user-select option:selected').val();
        const userData = this.data[scanUser];
        const $textArray = $('.scanhours-user-data');
        if (!userData) {
            $textArray.eq(0).text('');
            $textArray.eq(1).text('');
            $textArray.eq(2).text('');
            $textArray.eq(3).text('');
            $textArray.eq(4).text('');
            return;
        }
        const userStats = userData.Statistics;
        $textArray.eq(0).text(hoursToHHMMSS(parseFloat(userData.scanHours)));
        $textArray.eq(1).text(userStats.getTotal());
        $textArray.eq(2).text(userStats.getSuspects());
        $textArray.eq(3).text(userStats.getSanctions());
        $textArray.eq(4).text(userStats.getSuccessfulFollowUps());
    }
    async refresh() {
        const filters = this.getParams();
        const { statisticsTotal } = await AEngine.get(AStatisticsService).fetch(filters);
        await this.fetchUserPerformance(filters);
        const options = [
            CHART_TYPE_ENUM.PARKING_RIGHT,
            CHART_TYPE_ENUM.VERIFICATION,
            // CHART_TYPE_ENUM.DIGITAL,
            CHART_TYPE_ENUM.ILLEGALY_PARKED,
            CHART_TYPE_ENUM.OCCUPANCY,
            CHART_TYPE_ENUM.TIME_LIMITED_PARKING
        ];
        if (this.charts === undefined) {
            const translatedOptions = await Translate.get(options);
            this.charts = await Promise.all(options.map(chartType => {
                const chartOptions = {
                    id: `chart-${chartType}`,
                    title: translatedOptions[chartType],
                    statistics: statisticsTotal,
                    chartType: chartType
                };
                const chart = new AStatisticsChart(chartOptions).overrideReflow(this.getPrefferedSize);
                return chart.show();
            }));
            this.setupChartResizing();
        }
        else {
            this.charts.map(chart => {
                chart.updateChart(statisticsTotal);
            });
        }
        const Total = statisticsTotal.getTotal();
        const Suspects = statisticsTotal.getSuspects();
        const Sanctions = statisticsTotal.getSanctions();
        const SuccessfulFollowups = statisticsTotal.getSuccessfulFollowUps();
        const [operationHours, scanHours, distanceInKM] = await this.fetchTravelData(filters);
        const UpTime = 99.9;
        if (operationHours.hours) {
            $('.show-if-operation-hours').removeClass('hidden');
            $('.hide-if-operation-hours').addClass('hidden');
        }
        let compliancy = statisticsTotal.getCompliancy();
        let compliancyVisitors = statisticsTotal.getCompliancyVisitors();
        let digitizationRate = statisticsTotal.getDigitizationRate();
        let successfulFollowUpRate = statisticsTotal.getSuccessfulFollowUpRate();
        this.updateMeter(this.meters.Compliancy, compliancy !== null ? ARound(compliancy * 100, 1) : 0);
        this.updateMeter(this.meters.CompliancyVisitors, compliancyVisitors !== null ? ARound(compliancyVisitors * 100, 1) : 0);
        this.updateMeter(this.meters.Digitization, digitizationRate !== null ? ARound(digitizationRate, 1) : 0);
        this.updateMeter(this.meters.FollowUpRate, successfulFollowUpRate !== null ? ARound(successfulFollowUpRate, 1) : 0);
        this.setResult(this.$scans, AFormatNumber(Total));
        this.setResult(this.$suspects, AFormatNumber(Suspects));
        this.setResult(this.$fines, AFormatNumber(Sanctions));
        this.setResult(this.$scanhours, scanHours.formatted);
        this.setResult(this.$uptime, UpTime + '%');
        const operationOrFollowupHours = operationHours.hours || 0;
        const operationOrFollowupHoursText = (operationHours.hours) ? operationHours.formatted : '00:00:00';
        this.setResult(this.$followuphours, operationOrFollowupHoursText);
        const replaceInfinity = (v) => v === Infinity ? '-' : v;
        this.setResult(this.$scansperfine, AFormatNumber(replaceInfinity(ARound(Total / Sanctions, 1))));
        this.setResult(this.$followupsperfine, AFormatNumber(replaceInfinity(ARound(Suspects / Sanctions, 1))));
        this.setResult(this.$scansperhour, AFormatNumber(replaceInfinity(ARound(Total / scanHours.hours, 0))));
        this.setResult(this.$successfulfollowups, AFormatNumber(replaceInfinity(ARound(SuccessfulFollowups, 1))));
        this.setResult(this.$travelDistance, distanceInKM.formatted);
        this.setResult(this.$finesperfollowuphour, AFormatNumber(replaceInfinity(ARound(Sanctions / operationOrFollowupHours, 1))));
        this.setResult(this.$successfulfollowupsperhour, AFormatNumber(replaceInfinity(ARound(SuccessfulFollowups / operationOrFollowupHours, 1))));
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
    
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
    
        <div class="form-group">
          <label class="form-switch">
            <input id="AutoRefresh" type="checkbox">
            <i class="form-icon"></i> Auto Refresh
          </label>
        </div>
    
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="panels" class="container">
        <div style="height: calc(100% - 214px)">
          <div class="columns panel-def panel-type-2">
            <div class="column col-12">
              <div class="aci-tabs tabs-flex tabs-sticky tabs-fixed-md tabs-lg" tabgroup="operation-charts">
                <button class="aci-tab active" tab="parkingright">
                  <i class="fa-solid fa-truck-tow"></i>
                  <span>ParkingRight</span>
                </button>
                <button class="aci-tab" tab="verification">
                  <i class="fa-solid fa-user-police-tie"></i>
                  <span>Verification</span>
                </button>
                <button class="aci-tab hidden" tab="digital">
                  <i class="fa-solid fa-laptop-mobile"></i>
                  <span>Digital</span>
                </button>
                <button class="aci-tab" tab="illegallyparked">
                  <i class="fa-solid fa-gavel"></i>
                  <span>IllegallyParked</span>
                </button>
                <button class="aci-tab" tab="occupancy">
                  <i class="fa-solid fa-users"></i>
                  <span>Occupancy</span>
                </button>
                <button class="aci-tab" tab="timelimitedparking">
                  <i class="fa-solid fa-timer"></i>
                  <span>TimeLimitedParking</span>
                </button>
                <button class="aci-tab" tab="table">
                  <i class="fa-solid fa-table-rows"></i>
                  <span>Table</span>
                </button>
              </div>
              <div class="columns">
    
                <div class="column col-12" tabgroup="operation-charts" tabview="parkingright">
                  <div class="statistics-container statistics-border">
                    <div id="chart-ParkingRight" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="verification">
                  <div class="statistics-container statistics-border">
                    <div id="chart-Verification" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="digital">
                  <div class="statistics-container statistics-border">
                    <div id="chart-Digital" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="illegallyparked">
                  <div class="statistics-container statistics-border">
                    <div id="chart-IllegallyParked" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="occupancy">
                  <div class="statistics-container statistics-border">
                    <div id="chart-Occupancy" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="timelimitedparking">
                  <div class="statistics-container statistics-border">
                    <div id="chart-TimeLimitedParking" class="pie-chart"></div>
                    <div class="route"></div>
                  </div>
                </div>
                <div class="column col-12" tabgroup="operation-charts" tabview="table">
                  <div class="columns">
                    <div class="column col-5 col-mx-auto">
                      <table class="styled-table grid-like fw inline-block">
                        <thead>
                          <tr>
                            <th colspan="2">Scanhours Statistics</th>
                          </tr>
                        </thead>
                        <tbody style="background: #fff">
                          <tr>
                            <td>Scans / Hour</td>
                            <td aid="TScansPerHour"></td>
                          </tr>
                          <tr>
                            <td>Scans / Sanction</td>
                            <td aid="TScansPerFine"></td>
                          </tr>
                          <tr>
                            <td>Suspects / Sanction</td>
                            <td aid="TFollowUpPerFine"></td>
                          </tr>
    
    
                          <tr class="hide-if-operation-hours">
                            <td>Follow-Ups / Followup Hour</td>
                            <td aid="TSuccessfulFollowUpsPerHour"></td>
                          </tr>
                          <tr class="hide-if-operation-hours">
                            <td>Sanctions / Followup Hour</td>
                            <td aid="TFinesPerFollowUpHour"></td>
                          </tr>
    
    
    
                          <tr class="show-if-operation-hours">
                            <td>Suspects / Operation Hour</td>
                            <td aid="TSuccessfulFollowUpsPerHour"></td>
                          </tr>
                          <tr class="show-if-operation-hours">
                            <td>Fines / Operation Hour</td>
                            <td aid="TFinesPerFollowUpHour"></td>
                          </tr>
    
                          <tr>
                            <td>Yearly Uptime</td>
                            <td aid="TUptime"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
    
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 103px; overflow: auto">
          <div id="meters" class="columns">
          </div>
        </div>
        <div class="columns panel-def panel-type-1" style="border-top: 1px solid #f3f3f3;">
          <div class="column text-center">
            <span id="TScans" class="h4">Scans</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center">
            <span id="TSuspects" class="h4">Suspects</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center">
            <span id="TSuccessfulFollowUps" class="h4">Follow-Ups</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center">
            <span id="TFines" class="h4">Sanctions</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center shuffle-if-operation-hours">
            <span id="TOperationHours" class="h4 show-if-operation-hours">Operation Hours</span>
            <span id="TFollowupHours" class="h4 hide-if-operation-hours hidden">Follow-up Hours</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center">
            <span id="TScanHours" class="h4">Scan Hours</span>
            <span class="h3">?</span>
          </div>
          <div class="column text-center">
            <span id="TTravelDistance" class="h4">TravelDistance</span>
            <span class="h3">?</span>
          </div>
        </div>
      </div>
    </div>
  `);
}

export class APrdbOrm {
    async fetchAll(options) {
        return await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          PrdbCode,
          PrdbText,
          ModificationUser,
          ModificationDevice,
          ModificationTime
        FROM config_prdb
        WHERE IF(:PrdbCode IS NULL, true, PrdbCode=:PrdbCode)
        GROUP BY PrdbCode
      `),
            Params: options || {},
            Translate: [],
            Language: Language
        });
    }
    async create(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO config_prdb (PrdbCode, PrdbText, ModificationUser, ModificationDevice)
        VALUES (:PrdbCode, :PrdbText, :ModificationUser, :ModificationDevice)
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        return true;
    }
    async update(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: ( /*SQL*/`
        UPDATE config_prdb
        SET
          PrdbText=:PrdbText,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE PrdbCode=:PrdbCode
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        return true;
    }
    async delete(options) {
        await requestService.query({
            Query: (`DELETE FROM config_prdb WHERE PrdbCode=:PrdbCode`),
            Params: options
        });
    }
}

import { AError } from "../classes/AError.js";
import { EVENTS } from "./AEventService.js";
export class ATimeService {
    constructor() {
        this.translationsHistory = {};
    }
    autoInit() {
        Events.hardwire(EVENTS.PREFETCH, _ => {
            this.prefetch().catch(AError.handle);
        });
    }
    prefetch() {
        if (Object.keys(this.translationsHistory).length > 0)
            return Promise.resolve();
        if (this.prefetchPromise !== undefined) {
            return this.prefetchPromise;
        }
        this.prefetchPromise = this.prefetchTranslations().catch(AError.handle);
        return this.prefetchPromise;
    }
    prefetchTranslations() {
        const numericCursor = '987654321';
        return Loading.waitForPromises(Translate.get([
            `${numericCursor} days ago`,
            `${numericCursor} hours ago`,
            `${numericCursor} minutes ago`,
            `${numericCursor} seconds ago`,
        ])).then((timeunits) => {
            const translations = {};
            const categorizeKey = (key) => {
                const includes = ['days', 'hours', 'minutes', 'seconds'];
                for (const timeScale of includes) {
                    if (key.includes(timeScale)) {
                        return timeScale;
                    }
                }
            };
            const replaceCursor = (str) => str.replace(numericCursor, '%s');
            for (const key in timeunits) {
                const val = timeunits[key];
                Object.assign(translations, {
                    // @ts-ignore
                    [categorizeKey(key)]: replaceCursor(val)
                });
            }
            this.translationsHistory = translations;
            Events.tryInvoke('ATime->Initialized');
        });
    }
    async ago(today, date) {
        await this.prefetch();
        if (date === undefined) {
            date = today;
            today = new Date();
        }
        let diffMs = 0;
        if (today instanceof Date && date instanceof Date) {
            diffMs = today.getTime() - date.getTime();
        }
        else {
            diffMs = (today - date);
        }
        const diffDays = Math.floor(diffMs / 86400000);
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
        const diffMins = Math.floor(((diffMs % 86400000) % 3600000) / 60000);
        const diffSecs = Math.round((((diffMs % 86400000) % 3600000) % 60000) / 1000);
        if (diffDays > 0) {
            return this.translationsHistory['days'].replace('%s', diffDays).trim();
        }
        if (diffHrs > 0) {
            return this.translationsHistory['hours'].replace('%s', diffHrs).trim();
        }
        if (diffMins > 0) {
            return this.translationsHistory['minutes'].replace('%s', diffMins).trim();
        }
        if (diffSecs > 0) {
            return this.translationsHistory['seconds'].replace('%s', diffSecs).trim();
        }
        return this.translationsHistory['seconds'].replace('%s', 1).trim();
        // return await Translate.get('A few moments ago')
    }
}

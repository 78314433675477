import { AError } from "../classes/AError.js";
import { sleep } from "../core/AEngine.js";
import { mergeDeep } from "../utils/tools.js";
export class ATranslateService {
    constructor() {
        this.fetching = [];
        this.cache = {};
        Object.defineProperty(window, 'Translate', {
            get: () => this
        });
    }
    /**
     * Fetches and caches' the given text before using it
     * Used in order to save loadtime
     * @param textOrArray
     */
    prepare(textOrArray) {
        return Loading.waitForPromises(this.get(textOrArray));
    }
    async insert(key, value) {
        const cacheKey = this.toCacheKey(key);
        // if (this.isFetching(cacheKey)) {
        //     await this.waitForFetch(cacheKey)
        // }
        if (!this.hasKey(cacheKey)) {
            this.setCache(cacheKey, value);
        }
        // return this.getCache(cacheKey)
    }
    /**
     * Converts the value string to a cacheKey to fit in a cache object (hashtable)
     * @param key which needs to be converted
     */
    toCacheKey(key) {
        return key.toLowerCase();
    }
    /**
     * Whether the cacheKey has a corrosponding cached value
     * @param cacheKey key for the translation
     */
    hasKey(cacheKey) {
        return this.cache[cacheKey] !== undefined;
    }
    /**
     * Inserts a key-value pair into the cache for translations
     * @param cacheKey key for the translation
     * @param cacheValue translated key
     * @param tryInvoke whether it should invoke waitForFetch promises
     */
    setCache(cacheKey, cacheValue, tryInvoke = false) {
        if (cacheKey instanceof Object) {
            const translation = cacheKey;
            for (const key in translation) {
                const cacheKey = this.toCacheKey(key);
                this.setCache(cacheKey, translation[key], tryInvoke);
            }
        }
        else {
            this.cache[cacheKey] = cacheValue;
            this.fetching.splice(this.fetching.indexOf(cacheKey), 1);
            if (tryInvoke === true) {
                const eventKey = this.toEventKey(cacheKey);
                Events.tryInvoke(eventKey, cacheValue);
            }
        }
    }
    /**
     * Returns the translated cache value corrosponding to the cacheKey
     * @param cacheKey key for the translation
     */
    getCacheFast(cacheKey) {
        return this.cache[cacheKey];
    }
    /**
     * Returns the translated cache value corrosponding to the cacheKey
     * @param key key for the cacheKey
     */
    getCache(key) {
        let cacheKey = this.toCacheKey(key);
        if (!this.cache[cacheKey]) { // 
            cacheKey = cacheKey.replace(/(\d+)/g, ' $1');
        }
        if (!this.cache[cacheKey]) {
            AError.handle({
                err: `Couldn't find key=${key}, cacheKey=${cacheKey} in Translation Cache!`,
                adminAlertGroup: 'CACHE_ERROR'
            });
            return '!ERROR!';
        }
        return this.cache[cacheKey];
    }
    /**
     * Whether the text is being fetched already
     * @param cacheKey key for the translation
     */
    isFetching(cacheKey) {
        return (this.fetching.includes(cacheKey));
    }
    /**
     * Generates promise that will resolve once the fetch of the wanted key is finished
     * @param cacheKey key for the translation
     */
    waitForFetch(cacheKey) {
        return new Promise(resolve => Events.once(this.toEventKey(cacheKey), resolve));
    }
    /**
     * Converts cacheKey to an Event Key
     * @param cacheKey key for the translation
     */
    toEventKey(cacheKey) {
        return `Translation->waitForFetch->${cacheKey}`;
    }
    sanitize(translatedText) {
        return translatedText
            .replace(/([a-z])(\d+|[A-Z])/g, '$1 $2')
            .replace(/([0-9])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z]+)/g, (_, g1, g2) => g1 + g2.toLowerCase())
            .replace(/([ ])([a-z])/g, (_, g1, g2) => g1 + g2.toUpperCase())
            .trim();
    }
    async fetchFromApi(array, lang) {
        // Handle single translation
        if (!Array.isArray(array)) {
            const data = await requestService.translate(array, lang);
            return this.sanitize(data);
        }
        // Handle array of translations
        const dataMap = await requestService.translate(array, lang);
        Object.keys(dataMap).map(k => { dataMap[k] = this.sanitize(dataMap[k]); });
        return dataMap;
    }
    /**
     * Fetches translation for the input text
     * and uses the cache if operation has been executed before
     * @param textOrArray
     */
    async get(textOrArray) {
        let output = {};
        if (textOrArray === null || textOrArray === undefined) {
            return output;
        }
        if (textOrArray instanceof Array) {
            const keysToFetch = (await Promise.all(textOrArray.filter((elem, index, self) => // Remove double entries
             index == self.indexOf(elem)).map(async (key) => {
                const cacheKey = this.toCacheKey(key);
                const isFetching = this.isFetching(cacheKey);
                if (isFetching) {
                    await this.waitForFetch(cacheKey);
                }
                if (this.hasKey(cacheKey)) {
                    output[key] = this.getCache(cacheKey);
                    return null;
                }
                this.fetching.push(cacheKey);
                return key;
            }))).filter(key => key !== null);
            if (keysToFetch.length > 0) {
                const translation = await this.fetchFromApi(keysToFetch);
                this.setCache(translation, null, true);
                for (const key in translation) {
                    output[key] = translation[key];
                }
            }
        }
        else {
            const cacheKey = this.toCacheKey(textOrArray);
            const isFetching = this.isFetching(cacheKey);
            if (isFetching) {
                await this.waitForFetch(cacheKey);
            }
            if (this.hasKey(cacheKey)) {
                return this.getCache(cacheKey);
            }
            this.fetching.push(cacheKey);
            const translation = await this.fetchFromApi(textOrArray);
            output = translation;
            this.setCache(cacheKey, translation, !isFetching);
        }
        return output;
    }
    async fetch(text, opt) {
        if (opt?.lang !== undefined) {
            return await this.fetchFromApi(text, opt.lang);
        }
        const cacheKey = this.toCacheKey(text);
        const isFetching = this.isFetching(cacheKey);
        if (isFetching) {
            await this.waitForFetch(cacheKey);
        }
        this.fetching.push(cacheKey);
        const translation = await this.fetchFromApi(text, opt?.lang);
        this.setCache(cacheKey, translation, !isFetching);
        return translation;
    }
    async updateTranslation(lang, key, newValue, checked) {
        CCCClient.SendMessage("UpdateTranslation", 2, {
            Key: key,
            Value: newValue,
            Checked: checked,
            Language: lang
        });
        if (Language === lang) {
            const cacheKey = this.toCacheKey(key);
            this.setCache(cacheKey, newValue, true);
        }
        return await sleep(300);
    }
    async fetchTranslationFor(key) {
        const translations = mergeDeep({}, await Loading.waitForPromises(this.requestLanguage()));
        const found = (key !== undefined) ? translations[key] : undefined;
        const availableLanguages = [...new Set(['en', ...Object.values(translations).map((v) => Object.keys(v.Languages)).flat()])];
        return {
            Translations: found ? found.Languages : { 'en': '' },
            AvailableLanguages: availableLanguages
        };
    }
    requestLanguage() {
        return new Promise((resolve) => {
            Events.once(`LanguageResponse`, resolve);
            CCCClient.SendMessage("LanguageRequest", 3, {});
        });
    }
}

import { AError } from "../classes/AError.js";
import { MYSQL_FALSE, MYSQL_TRUE } from "../utils/query.js";
function tryParseJson(json) {
    if (!json) {
        return null;
    }
    if (typeof json !== 'string') {
        return json;
    }
    try {
        return JSON.parse(json);
    }
    catch (err) {
        AError.handleSilent(err);
        return null;
    }
}
export class AKpiOrm {
    async setDefaultView(opt) {
        await requestService.query({
            Query: (`
        INSERT INTO backoffice_widget_view_usergroup_defaults
          (UserGroup, DefaultView, ModificationUser, ModificationDevice)
        VALUES
          (:UserGroup, :DefaultView, :ModificationUser, :ModificationDevice)
        ON DUPLICATE KEY UPDATE
          UserGroup=:UserGroup,
          DefaultView=:DefaultView,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
      `),
            Params: {
                UserGroup: opt.UserGroup,
                DefaultView: opt.DefaultView,
                ModificationUser: _.getUser().User,
                ModificationDevice: _.getSession().DeviceName
            }
        });
    }
    async fetchDefaultViews() {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          g.UserGroup,
          IFNULL(d.DefaultView, 'Default') as DefaultView
        FROM usergroups g
        LEFT JOIN backoffice_widget_view_usergroup_defaults d USING (UserGroup)
      `),
            Params: { MYSQL_TRUE, MYSQL_FALSE },
            Translate: ['UserGroupText'],
            Language: Language
        });
        return ares;
    }
    /**
     * @deprecated
     */
    async fetchViewsForUserGroup(opt) {
        const ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          View,
          UserGroup,
          UserGroup AS UserGroupText,
          IF(f.IsDefault, :MYSQL_TRUE, :MYSQL_FALSE) as IsDefault
        FROM backoffice_widget_views v
        INNER JOIN backoffice_widget_view_usergroup_filters f USING (View)
        WHERE f.Usergroup=:UserGroup
      `),
            Params: {
                UserGroup: opt.UserGroup,
                MYSQL_TRUE,
                MYSQL_FALSE,
            },
            Translate: ['UserGroupText'],
            Language: Language
        });
        return ares;
    }
    async fetchAllViews(opt) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          'Default' as View,
          0 AS Widgets,
          NULL as Usergroups,
          'System' AS ModificationUser,
          'Control Center' AS ModificationDevice,
          '2020-01-01' AS ModificationTime,
          CAST('2020-01-01' AS DATETIME) AS CreatedAt
        UNION
        SELECT
          View,
          COUNT(*) AS Widgets,
          GROUP_CONCAT(DISTINCT r.Usergroup ORDER BY r.Usergroup ASC SEPARATOR ', ') AS Usergroups,
          v.ModificationUser,
          v.ModificationDevice,
          v.ModificationTime,
          v.CreatedAt
        FROM backoffice_widget_views v
        LEFT JOIN backoffice_widget_view_usergroup_filters r USING (View)
        LEFT JOIN backoffice_widgets USING (View)
        GROUP BY View
        ORDER BY CreatedAt ASC
      `),
            Params: {
                AllowDefault: opt.allowDefaultView
            },
            Language: Language
        }, {
            valueMapper: {
                Usergroups: (i) => i != null ? i.split(', ') : i,
            }
        });
        return rows;
    }
    async createOrUpdateView(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO backoffice_widget_views (View, ModificationUser, ModificationDevice)
        VALUES (:View, :ModificationUser, :ModificationDevice)
        ON DUPLICATE KEY UPDATE
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
      `),
            Params: Object.assign({ ModificationUser, ModificationDevice }, options)
        });
        await this.applyRestrictions(options);
    }
    async applyRestrictions(options) {
        const { View, Usergroups } = options;
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await this.deleteRestrictions(options);
        const baseQueryParams = { View, ModificationUser, ModificationDevice };
        await Promise.all((Usergroups || []).map(group => {
            return requestService.query({
                Query: ( /*SQL*/`
            INSERT INTO backoffice_widget_view_usergroup_filters (View, Usergroup, ModificationUser, ModificationDevice)
            VALUES (:View, :Value, :ModificationUser, :ModificationDevice)
          `),
                Params: { Value: group, ...baseQueryParams }
            });
        }));
    }
    async deleteRestrictions({ View }) {
        await requestService.query({
            Query: ( /*SQL*/`
        DELETE FROM backoffice_widget_view_usergroup_filters
        WHERE View=:View
      `),
            Params: { View }
        });
    }
    async fetchByName(name) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          Name,
          DataSource,
          ChartType,
          Horizontal,
          Vertical,
          KeyY,
          Polar,
          Inverted,
          IncludeTotals,
          Filters,
          ShowLegend,
          CreatedAt,
          CreatedBy
        FROM backoffice_kpi_types
        WHERE Name=:Name
      `),
            Params: {
                Name: name
            }
        }, {
            valueMapper: {
                Filters: (input) => tryParseJson(input)
            }
        });
        return rows.First;
    }
    async fetchAll(opt) {
        const rows = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          Name,
          DataSource,
          ChartType,
          Horizontal,
          Vertical,
          KeyY,
          Polar,
          Inverted,
          IncludeTotals,
          Filters,
          ShowLegend,
          CreatedAt,
          CreatedBy
        FROM backoffice_kpi_types
        ${opt?.DataSource ? `WHERE DataSource=:DataSource` : ''}
        ORDER BY CreatedAt ASC
      `),
            Params: {
                ...opt
            },
            Language: Language
        }, {
            valueMapper: {
                Filters: (input) => tryParseJson(input)
            }
        });
        return rows;
    }
    async create(options) {
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO backoffice_kpi_types
          (Name, DataSource, ChartType, Horizontal, Vertical, KeyY, Polar, Inverted, IncludeTotals, Filters, ShowLegend, CreatedBy)
        VALUES
          (:Name, :DataSource, :ChartType, :Horizontal, :Vertical, :KeyY, :Polar, :Inverted, IFNULL(:IncludeTotals, false), :Filters, :ShowLegend, :CreatedBy)
        ON DUPLICATE KEY UPDATE
          DataSource=:DataSource,
          ChartType=:ChartType,
          Horizontal=:Horizontal,
          Vertical=:Vertical,
          KeyY=:KeyY,
          Polar=:Polar,
          Inverted=:Inverted,
          IncludeTotals=IFNULL(:IncludeTotals, false),
          Filters=:Filters,
          ShowLegend=:ShowLegend,
          CreatedBy=:CreatedBy
      `),
            Params: {
                ...options
            }
        });
    }
    async deleteView({ View }) {
        await requestService.query({
            Query: ( /*SQL*/`
        DELETE FROM backoffice_widget_views
        WHERE View=:View
      `),
            Params: { View }
        });
    }
    async deleteCustom({ Name }) {
        // await requestService.query({
        //   Query: (/*SQL*/`
        //     DELETE FROM backoffice_widgets WHERE KpiType='AKpiBlockKpiChart' AND Name='b'
        //   `)
        // })
        await requestService.query({
            Query: ( /*SQL*/`
        DELETE FROM backoffice_kpi_types
        WHERE Name=:Name
      `),
            Params: { Name }
        });
    }
}

// These page references are made in order to compile all pages when building
export const Routes = {
    "/login.js": import("./pages/login.js"),
    "/authenticator.js": import("./pages/authenticator.js"),
    "/profile.js": import("./pages/profile.js"),
    "/operations.js": import("./pages/operations.js"),
    "/overview.js": import("./pages/overview.js"),
    "/performance/pda-performance.js": import("./pages/performance/pda-performance.js"),
    "/performance/cvs-performance.js": import("./pages/performance/cvs-performance.js"),
    "/performance/driver-performance.js": import("./pages/performance/driver-performance.js"),
    "/maps/team.js": import("./pages/maps/team.js"),
    "/maps/scans.js": import("./pages/maps/scans.js"),
    "/maps/scans-advanced.js": import("./pages/maps/scans-advanced.js"),
    "/maps/thematic-map.js": import("./pages/maps/thematic-map.js"),
    "/maps/gps-statistics.js": import("./pages/maps/gps-statistics.js"),
    "/maps/invalid-parkingspaces.js": import("./pages/maps/invalid-parkingspaces.js"),
    "/maps/outside-parkingspaces.js": import("./pages/maps/outside-parkingspaces.js"),
    "/maps/search.js": import("./pages/maps/search.js"),
    "/reports/scans.js": import("./pages/reports/scans.js"),
    "/reports/fines.js": import("./pages/reports/fines.js"),
    //"/reports/unique-scans.js": import("./pages/reports/unique-scans.js"),
    "/reports/reports-advanced.js": import("./pages/reports/reports-advanced.js"),
    "/reports/thematic.js": import("./pages/reports/thematic.js"),
    "/reports/search.js": import("./pages/reports/search.js"),
    "/export/export_csv.js": import("./pages/export/export_csv.js"),
    "/charts/scans-chart.js": import("./pages/charts/scans-chart.js"),
    "/charts/unifications-chart.js": import("./pages/charts/unifications-chart.js"),
    "/charts/timeline.js": import("./pages/charts/timeline.js"),
    "/kpi/key-performance-indicators.js": import("./pages/kpi/key-performance-indicators.js"),
    "/kpi/kpi-views.js": import("./pages/kpi/kpi-views.js"),
    "/kpi/kpi-edit-views.js": import("./pages/kpi/kpi-edit-views.js"),
    // "/fines/fines-xptr.js": import("./pages/fines/fines-xptr.js"),
    // "/fines/fines.js": import("./pages/fines/fines.js"),
    // "/fines/fines-print.js": import("./pages/fines/fines-print.js"),
    "/trafficsigns/manage.js": import("./pages/trafficsigns/manage.js"),
    "/location-images/location-images-request.js": import("./pages/location-images/location-images-request.js"),
    "/routeplanner/routes.js": import("./pages/routeplanner/routes.js"),
    "/routeplanner/planning.js": import("./pages/routeplanner/planning.js"),
    "/routeplanner/routes-on-scanvehicle.js": import("./pages/routeplanner/routes-on-scanvehicle.js"),
    "/routeplanner/routes-history.js": import("./pages/routeplanner/routes-history.js"),
    "/log/mysql-update-log.js": import("./pages/log/mysql-update-log.js"),
    "/log/mysql-sync-log.js": import("./pages/log/mysql-sync-log.js"),
    "/log/device-log.js": import("./pages/log/device-log.js"),
    "/log/session-log.js": import("./pages/log/session-log.js"),
    "/log/cameras.js": import("./pages/log/cameras.js"),
    "/log/server-stats.js": import("./pages/log/server-stats.js"),
    "/log/user-action-log.js": import("./pages/log/user-action-log.js"),
    "/settings/api_access_token.js": import("./pages/settings/api_access_token.js"),
    "/enforcementprocess/enforceunits.js": import("./pages/enforcementprocess/enforceunits.js"),
    "/enforcementprocess/finegroups.js": import("./pages/enforcementprocess/finegroups.js"),
    "/enforcementprocess/channels.js": import("./pages/enforcementprocess/channels.js"),
    "/enforcementprocess/regimes.js": import("./pages/enforcementprocess/regimes.js"),
    "/enforcementprocess/parkingrighttypes.js": import("./pages/enforcementprocess/parkingrighttypes.js"),
    "/enforcementprocess/offences.js": import("./pages/enforcementprocess/offences.js"),
    "/enforcementprocess/followup.js": import("./pages/enforcementprocess/followup.js"),
    "/settings/language.js": import("./pages/settings/language.js"),
    "/settings/adminusers.js": import("./pages/settings/adminusers.js"),
    "/settings/adminusergroups.js": import("./pages/settings/adminusergroups.js"),
    "/settings/unify.js": import("./pages/settings/unify.js"),
    "/aci/admin_alerts.js": import("./pages/aci/admin_alerts.js"),
    "/aci/config.js": import("./pages/aci/config.js"),
    "/aci/page_popularity.js": import("./pages/aci/page_popularity.js"),
    "/aci/detections_viewport.js": import("./pages/aci/detections_viewport.js"),
    "/reload.js": import("./pages/reload.js"),
    "/test/config.js": import("./pages/test/config-new.js"),
    "/test/scans-chart-test.js": import("./pages/test/scans-chart-test.js"),
    "/test/prdb-create-edit.js": import("./pages/test/prdb-create-edit.js"),
    "/test/prdb-list.js": import("./pages/test/prdb-list.js"),
    "/test/advanced_key_indicators.js": import("./pages/test/advanced_key_indicators.js"),
    "/test/thematic-chart.js": import("./pages/test/thematic-chart.js"),
    "/test/routes-driven.js": import("./pages/test/routes-driven.js"),
    "/test/reports-scans-optimized.js": import("./pages/test/reports-scans-optimized.js"),
    "/test/live_view.js": import("./pages/test/live_view.js"),
    "/test/parkingsession_search.js": import("./pages/test/parkingsession_search.js"),
    "/test/time-distribution.js": import("./pages/test/time-distribution.js"),
    "/test/statistics.js": import("./pages/test/statistics.js"),
    "/test/chartsinmap.js": import("./pages/test/chartsinmap.js"),
    "/test/view-split.js": import("./pages/test/view-split.js"),
    "/test/dropdown-examples.js": import("./pages/test/dropdown-examples.js"),
    "/test/routebetweenpoints.js": import("./pages/test/routebetweenpoints.js"),
    "/test/license-plate-correction.js": import("./pages/test/license-plate-correction.js"),
    "/test/device-versions.js": import("./pages/test/device-versions.js"),
    "/test/location-images-overview.js": import("./pages/test/location-images-overview.js"),
};

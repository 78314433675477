import { AEngine } from "../../../core/AEngine.js";
import { clamp, createArray, lerp } from "../../../utils/tools.js";
import { AConfigComponent } from "./AConfigComponent.js";
import { AConfigTransitionCursorComponent } from "./cfg-comp-color-transition-cursor.js";
export class AConfigTransitionComponent extends AConfigComponent {
    get usePercentage() { return this.opt.unit === '%'; }
    constructor(opt) {
        super(opt);
        this.opt = opt;
        this.cursors = [];
        this.gradientPoints = 100;
    }
    removeCursor(item) {
        const index = this.cursors.indexOf(item);
        if (index === -1) {
            throw new Error(`Couldn't delete non existant cursor!`);
        }
        this.cursors.splice(index, 1);
        this.reconstruct();
    }
    reconstruct() {
        this.cfgData = this.toJSON();
        for (let i = 0; i < this.cfgData.length; i++) {
            this.cursors[i].set({ color: this.cfgData[i].color, xValue: this.cfgData[i].x, skipClamp: this.cursors[i].isLocked() });
        }
        this.redraw();
        super.validate(this.cfgData);
    }
    get srcScreenshot() {
        return `/img/screenshots/${this.opt.screenshot ?? 'segmentColorTransition'}.png`;
    }
    async preRender() {
        const cfgEntry = await configService.getVersioned(this.cfgPath);
        this.cfgData = cfgEntry ?? this.findConfigValueLegacy(this.cfgPath);
        this.cfgDataOriginal = this.cfgData.map(v => Object.assign({}, v));
    }
    async postRender() {
        const $ele = this.$ele();
        this.initCursorEvents();
        const { amountOfDecimals, unit } = Object.assign({ amountOfDecimals: 0, unit: '' }, this.opt);
        $ele.find('.cfg-span-array').html(createArray(6, () => 0).map((_, i) => {
            const suffix = (unit ? ` ${unit}` : '');
            return ( /*html*/`<span class="column col text-center">${(i * 20).toFixed(amountOfDecimals) + suffix}</span>`);
        }).join(''));
        $ele.find('.cfg-render-array').html(createArray(this.gradientPoints, () => 0).map((_, i) => `<div class=""></div>`).join(''));
        const $inputs = $ele.find('[type="color"],[type="text"],[type="number"]');
        $inputs.on('change keyup', (e) => { this.redraw(); });
        this.redraw();
        // $comp.data('cfgValGetter', () => {
        //   const colors: string[] = $comp.find('.cfg-colors-array input:not(.hidden)').toArray().map(e => $(e)).map($inp => $inp.val()) as any
        //   const bounds: string[] = $comp.find('.cfg-bounds-array input:not(.hidden)').toArray().map(e => $(e)).map($inp => $inp.val()) as any
        //   const transition: AColorLerpItem[] = colors.map((c, i) => {
        //     return { color: c, x: Number((bounds[i]) || '0') }
        //   })
        //   if (this.opt.useBounds && (colors.length !== bounds.length)) {
        //     throw new Error(`Color array doesn't match bounds array length: ${colors.length} != ${bounds.length}`)
        //   }
        //   transition.map((t, i) => {
        //     if (isNaN(t.x)) {
        //       throw new Error(`Color bound x value is NaN! transition[${i}] cfgPath=${this.cfgPath}`)
        //     }
        //   })
        //   return transition
        // })
    }
    redraw() {
        const $comp = super.$ele();
        const { amountOfDecimals, unit } = Object.assign({ amountOfDecimals: 0, unit: '' }, this.opt);
        const $spArr = $comp.find('.cfg-span-array span').toArray().map(ele => $(ele));
        const $bgArr = $comp.find('.cfg-render-array div').toArray().map(ele => $(ele));
        const min = this.cfgData[0].x;
        const max = this.cfgData[this.cfgData.length - 1].x;
        if (!this.usePercentage) {
            $spArr.map(($s, i) => {
                const suffix = (i === 0 || i === $spArr.length - 1) ? (unit ? ` ${unit}` : '') : '';
                $s.text(lerp(min, max, i / ($spArr.length - 1)).toFixed(amountOfDecimals) + suffix);
            });
        }
        $bgArr.map(($bg, i) => {
            const value = (this.usePercentage) ? (i / ($bgArr.length - 1) * 100) : (min + (max - min) / ($bgArr.length - 1) * i);
            const clr = interpolateService.lerpTo({ transition: this.cfgData, value: value });
            $bg.css('background', clr.rgba(1.0));
        });
        super.$ele().trigger('change', [this.cfgData]);
        super.$ele().trigger('aci-change', [this]);
    }
    initCursorEvents() {
        const $ele = this.$ele();
        const cfgData = this.CfgData;
        const $ruler = $ele.find('.cfg-gradient');
        let min = Math.min.apply(null, [100000, ...cfgData.map(item => item.x)]);
        let max = Math.max.apply(null, [0, ...cfgData.map(item => item.x)]);
        cfgData.map(({ color, x }, i) => {
            const cursor = new AConfigTransitionCursorComponent(this, {
                color,
                pValue: (x - min) * 100 / (max - min),
                xValue: x,
                // Lock first and last color cursors
                locked: (i === 0 || i === cfgData.length - 1)
            });
            this.cursors.push(cursor);
        });
        $ruler.on('click', (e) => {
            let mX = e.clientX - $ruler.offset().left;
            let p = clamp(mX / $ruler.outerWidth() * 100, 0, 100);
            let min = Math.min.apply(null, [100000, ...cfgData.map(item => item.x)]);
            let max = Math.max.apply(null, [0, ...cfgData.map(item => item.x)]);
            const xValue = (max - min) / 100.0 * p;
            const clr = interpolateService.lerpTo({ transition: cfgData, value: xValue });
            console.log({ xValue, p });
            // Insert
            const cursor = new AConfigTransitionCursorComponent(this, { xValue, pValue: p, color: clr.hexi });
            let insertAt = this.cursors.length - 1;
            AEngine.log(`insertAt s: `, insertAt);
            for (let i = 0; i < this.cursors.length; i++) {
                if (this.cursors[i].x >= xValue) {
                    AEngine.log(`insertAt break: `, insertAt);
                    break;
                }
                insertAt = i;
                AEngine.log(`insertAt i: `, insertAt);
            }
            AEngine.log(`insertAt: `, insertAt);
            this.cursors.splice(insertAt + 1, 0, cursor);
            this.reconstruct();
        });
        $(document).on('mousemove', (e) => {
            this.dragCursor?.dragStep(e.clientX);
        }),
            $(document).on('mouseup', (e) => {
                this.dragCursor !== undefined && this.onDragFinished();
            });
    }
    onDragFinished() {
        this.dragCursor?.dragStopped();
        this.dragCursor = undefined;
        this.reconstruct();
    }
    async render() {
        const title = super.getTitle();
        return ( /*html*/`
      <div comp="${this.getInternalId()}" cfg="${this.cfgPath}" class="column col-12">
        <h4 class="popover popover-bottom fw hidden mb-1">
          <i class="fa fa-info-circle" aria-hidden="true"></i> ${title}
          <div class="popover-container">
            <div class="card">
              <div class="card-body">
                <img src="${this.srcScreenshot}" style="width: 100%">
              </div>
            </div>
          </div>
        </h4>
        <label class="form-label" for="">${title}</label>
        <div class="columns fh" style="max-width: 100%; margin-left: auto; margin-right: auto; align-content: center">
          <div class="column col-12 col-mx-auto ${this.usePercentage ? 'percentage-based' : ''} mb-2">
            <div class="columns">
              <div class="column col-12 mb-1 cfg-gradient">
                <div class="columns cfg-span-array ${this.usePercentage ? 'span-percentage' : ''}"></div>
                <div class="columns cfg-render-array"></div>
              </div>
              <div class="column col-12 cfg-cursors">
                <div class="cfg-gradient-ruler"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `);
    }
    toJSON() {
        // return this.cursors.map(cursor => cursor.toJSON()).sort((a, b) => a.x - b.x)
        return this.cursors.sort((a, b) => {
            const delta = a.x - b.x;
            return (delta !== 0) ? delta : (a.isLocked() ? 1 : 0) - (b.isLocked() ? 1 : 0);
        }).map(cursor => cursor.toJSON());
    }
}

import { mergeDeep } from "../utils/tools.js";
import { AColor } from "./AColor.js";
import { AError } from "./AError.js";
import { ATableFormatter } from "../core/form/table/ATableFormatter.js";
import { AEngine } from "../core/AEngine.js";
/**
 * @deprecated
 */
export class AConfig {
    constructor() {
        throw new Error(`AConfig is a static class!`);
    }
    static get BackOfficeConfig() {
        try {
            return Config.BackOfficeConfig;
        }
        catch (err) {
            AError.handle(err);
        }
    }
    /**
     * @deprecated
     */
    static get CONFIG_FALLBACK() {
        return {
            // "streetView": {
            //   "position": {
            //     "lat": 58.9865685,
            //     "lng": 6.1889224
            //   },
            //   "pov": {
            //     "heading": 180,
            //     "pitch": 5
            //   },
            //   "zoom": 0
            // },
            "language": {
                "grid": 'En'
            },
            "infoWindow": {
                "default": {
                    "priorityFields": [
                        "DetectionTime",
                        "LicensePlate",
                        "Site",
                        "Area",
                        "ParkingAreaType",
                        "ParkingRightType",
                        "VerificationResultText",
                        "CardinalDirection",
                        "IsIllegallyParked",
                        "HasParkingRight",
                        "Label"
                    ],
                    "booleanFields": [
                        "TaxRequired",
                        "IsIllegallyParked",
                        "HasParkingRight"
                    ],
                    "excludedFields": [
                        "AreaId",
                        "Image",
                        "LPImage",
                        "ParkingAreaType",
                        "OverviewImages",
                        "CarImage",
                        "Success",
                        "VehicleBounds",
                        "Bounds",
                        "VehicleCenterLatitude",
                        "VehicleCenterLongitude",
                        "SessionId",
                        "id_mrs"
                    ],
                    "customFields": {
                        "Label": "generateLabelField"
                    },
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {}
                        }
                    }
                },
                "heatmap": {
                    "priorityFields": [
                        "DetectionTime",
                        "LicensePlate",
                        "Area",
                        "ParkingAreaType",
                        "ParkingRightType",
                        "VerificationResultText",
                        "IsIllegallyParked",
                        "HasParkingRight",
                        "Label",
                        "GpsPrecision",
                        "GpsPrecisionText",
                    ],
                    "booleanFields": [
                        "TaxRequired",
                        "IsIllegallyParked",
                        "HasParkingRight"
                    ],
                    "excludedFields": [],
                    "customFields": {
                        "Label": "generateLabelField"
                    },
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {
                                "GpsPrecision": "3"
                            }
                        }
                    }
                },
                "detectionManager": {
                    "priorityFields": [],
                    "booleanFields": [],
                    "excludedFields": [],
                    "format": {
                        "numbers": {
                            "amountOfDecimals": {}
                        }
                    }
                }
            },
            "statistics": {
                "fineIdentifiers": ["FINED%", "GIVEFINE", "NOK%"]
            },
            // "exportKml": {
            //   "defaultColor": "#ff00ff"
            // },
            // "thematicMaps": {
            //   "paymentRate": {
            //     "minBound": 80,
            //     "maxBound": 100
            //   },
            //   "digitizationRate": {
            //     "minBound": 0,
            //     "maxBound": 100
            //   },
            //   "percentageVisitors": {
            //     "minBound": 0,
            //     "maxBound": 100
            //   },
            //   "percentagePhone": {
            //     "minBound": 0,
            //     "maxBound": 10
            //   }
            // },
            "prdb": {
                "parkingRightTypes": {
                    "Day Permit": "",
                    "Free Parking": ""
                }
            },
            "locations": {
                "locationTypes": {
                    "Garbage": "",
                    "Obstruction": "",
                    "TrafficSign": "",
                    "Other": ""
                }
            },
            "vehicleTypes": {
                "Car": "",
                "Truck": ""
            },
            "colors": {
                "charts": [[205, 225, 248], [54, 84, 144]],
                "tables": {
                    "default": "#00afe3",
                    "green": "#07CF00",
                    "red": "#E30039"
                }
            },
            "tableformatter": {
                "default": {
                    "order": [
                        "DetectionTime",
                        "LicensePlate",
                        "IsSuspect",
                        "VerificationChannel",
                        "CardinalDirection",
                        "DetectionValid",
                        "Digital",
                        "TimeLimitedParking",
                        "IllegallyParked",
                        "ParkingRight",
                        "Verification",
                        "DetectionState",
                        "Side",
                        "Area",
                        "IsFine",
                        "SessionVerificationChannels",
                        "VerificationUserRemarks",
                        "Label",
                    ],
                    "definitions": ATableFormatter.fallbackDefinitions
                }
            }
        };
    }
    static get RESET_CONFIG_DEFAULTS() {
        return {
            "databaseDefinitions": {
                "vehicleTypes": [
                    "Car",
                    "Truck"
                ],
                "locationTypes": [
                    "Garbage",
                    "Other"
                ],
                "parkingRightTypes": [
                    "Day Permit",
                    "Free Parking"
                ]
            },
            "drawing & colors": {
                "speed": {
                    "bounds": [0, 30],
                    "colors": [
                        "#ff0000",
                        "#00ff00"
                    ]
                },
                "charts": {
                    "gradient": ["#cde1f8", "#365490"]
                },
                "thematic": {
                    "capacity": {
                        "colors": ['#32CD32', '#ff0000'],
                        "bounds": [0, 100]
                    },
                    "occupancy": {
                        "colors": ['#32CD32', '#ff0000'],
                        "bounds": [0, 100]
                    },
                    "visitorRate": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "compliancy": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "compliancyVisitor": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    },
                    "enforcementIntensity": {
                        "colors": ['#ff0000', '#32CD32'],
                        "bounds": [0, 100]
                    }
                },
                "tables": {
                    "error": "#E30039",
                    "success": "#07CF00",
                    "highlight": "#00afe3"
                },
                "precision": {
                    "bounds": [0, 2],
                    "colors": ["#00ff00", "#ff0000"]
                },
                "arduinoSensor": {
                    "RotationY": ["#ff0000", "#00ff00"],
                    "AccelerationX": ["#ff0000", "#00ff00"],
                    "AccelerationY": ["#ff0000", "#00ff00"]
                },
                "detections": {
                    "default": {
                        "fill": "#00ff00",
                        "outline": "#05A300"
                    },
                    "unknown": {
                        "fill": "#00ADFF",
                        "outline": "#0072E8"
                    },
                    "selected": {
                        "fill": "#FFFFFF",
                        "outline": "#C7C7C7"
                    },
                    "noParkingRight": {
                        "fill": "#F70000",
                        "outline": "#B90000"
                    },
                    "illegallyParked": {
                        "fill": "#FF8000",
                        "outline": "#C86400"
                    }
                },
                "strokeWidth": 3.5,
                "legendZoom": 1.0
            },
            "filters": {
                "maps": {
                    "maxResults": 2000
                },
                "tables": {
                    "maxResults": 250
                },
                "default": {
                    "maxResults": 250
                },
                "maxResultsCeiling": 100000,
                "enableFilterParkingRightType": false,
                "enableFilterVerifyResult": false,
                "showZonesInsteadOfAreas": false,
                "minWeeksWarning": 8,
                "overrideFilters": false,
                "override": {
                    "fromDate": null,
                    "fromTime": null,
                    "toDate": null,
                    "toTime": null,
                }
            },
            "general": {
                "map": {
                    "pos": {
                        "lat": 59.91447170000001,
                        "lng": 10.734125
                    },
                    "pov": {
                        "pitch": 2.776332195664267,
                        "heading": 2.797097632739451,
                        "zoom": 1
                    },
                    "mapZoom": 10,
                    "centerOnClick": false
                },
                "security": {
                    "useStrictPasswordPolicy": false
                },
                "overrideName": false,
                "name": "",
                "language": "En",
                "minEnforcingForOccupancy": .1 /// Added minEnforcingForOccupancy for themethic map and report
            },
            "tableformatter": {
                "default": {
                    "order": ATableFormatter.fallbackOrder,
                    "definitions": ATableFormatter.fallbackDefinitions
                },
                "AreaAttributes": {
                    "order": [],
                    "definitions": {
                        "ID": { type: "TEXT" },
                    }
                },
                "RegionAttributes": {
                    "order": [],
                    "definitions": {
                        "city": { type: "TEXT" },
                        "street": { type: "TEXT" },
                        "Geo-Area": { type: "NUMBER" },
                        "Geo-Length": { type: "NUMBER" },
                        "postcode": { type: "TEXT" },
                        "housenumber": { type: "TEXT" },
                    }
                },
                "AddressAttributes": {
                    "order": [],
                    "definitions": {
                        "city": { type: "TEXT" },
                        "street": { type: "TEXT" },
                        "Geo-Area": { type: "NUMBER" },
                        "Geo-Length": { type: "NUMBER" },
                        "postcode": { type: "TEXT" },
                        "housenumber": { type: "TEXT" },
                    }
                },
                "ParkingSpaceAttributes": {
                    "order": [],
                    "definitions": {
                        "type": { type: "TEXT" },
                        "layer": { type: "TEXT" },
                        "soort": { type: "TEXT" },
                        "functie": { type: "TEXT" },
                        "BO.Color": { type: "TEXT" },
                        "Capacity": { type: "TEXT" },
                        "Geo-Area": { type: "NUMBER" },
                        "Geo-Length": { type: "NUMBER" },
                        "Shape_Area": { type: "NUMBER" },
                        "Shape_Leng": { type: "NUMBER" },
                        "Regime:Default": { type: "TEXT" },
                        "CalculatedCapacity": { type: "NUMBER" }, // 0.948089006294542
                    }
                }
            }
        };
    }
    /**
     * Fetches and returns config value, defaultValue is returned when not found
     * @param key Path to traverse in the config
     * @param defaultValue return value when config entry is not found
     */
    static get(key, defaultValue) {
        if (Array.isArray(key)) {
            return key.map(args => {
                const [str, dflt] = args;
                return AConfig.get(str, dflt);
            });
        }
        else {
            let output = this.getRoot(AConfig.BackOfficeConfig, key);
            if (output !== undefined) {
                return output;
            }
            if (defaultValue !== undefined) {
                return defaultValue;
            }
            AEngine.warn(`AConfig.BackOfficeConfig: Couldn't find path "${key}", using AConfig.RESET_CONFIG_DEFAULTS instead!`, { key });
            let v = AConfig.RESET_CONFIG_DEFAULTS;
            const traversed = [];
            for (let part of key.split('.')) {
                AEngine.log('TRAVERSING', [...traversed, part].join('.'));
                if (!v.hasOwnProperty(part)) {
                    AEngine.log(`Couldn't traverse AConfig.RESET_CONFIG_DEFAULTS[%c${key}%n]%r!`);
                    debugger;
                    return null;
                }
                traversed.push(part);
                v = v[part];
            }
            return v;
        }
    }
    /**
     * Fetches and returns config value, defaultValue is returned when not found
     * @param cfgToUse config to traverse
     * @param key Path to traverse in the config
     */
    static getRoot(cfgToUse, key) {
        try {
            const parts = key.split('.');
            let output = cfgToUse;
            for (const part of parts) {
                if (!output.hasOwnProperty(part)) {
                    output = undefined;
                    break;
                }
                output = output[part];
            }
            return output;
        }
        catch (err) {
            AError.handle(err);
        }
    }
    static isDefined(val) {
        return val !== undefined;
    }
    static isDefinedArr(val) {
        if (val && Array.isArray(val)) {
            return val.reduce((p, c) => p !== undefined && c !== undefined);
        }
        return false;
    }
    static isPrimitive(test) {
        return (test !== Object(test));
    }
    /**
     * @deprecated
     */
    static convert(cfg) {
        const output = {
            "general": {},
            "drawing & colors": {},
            "filters": {},
            "infoWindows": {},
            "databaseDefinitions": {},
            "thematicMaps": {}
        };
        {
            const config = mergeDeep({}, cfg);
            delete config._;
            Object.assign(output["general"], {
                "name": (config && config.client && config.client.name) ? config.client.name : "?",
                "language": config.language.grid,
                "map": {
                    pos: config.streetView.position,
                    pov: config.streetView.pov,
                    mapZoom: 14,
                    streetZoom: 3
                }
            });
            Object.assign(output["drawing & colors"], {
                "charts": {
                    "gradient": config.colors.charts.map(rgb => new AColor(rgb).hexi),
                },
                "tables": {
                    "highlight": config.colors.tables.default,
                    "success": config.colors.tables.green,
                    "error": config.colors.tables.red
                },
                "speed": {
                    "colors": ["#ff0000", "#00ff00"],
                    "bounds": [0.0, 30]
                },
                "precision": {
                    "colors": ["#ff0000", "#00ff00"],
                    "bounds": [0.0, 2.0]
                },
                "detections": {
                    "default": {
                        "fill": "#00ff00",
                        "outline": "#05A300"
                    },
                    "illegallyParked": {
                        "fill": "#FF8000",
                        "outline": "#C86400"
                    },
                    "noParkingRight": {
                        "fill": "#F70000",
                        "outline": "#B90000"
                    },
                    "selected": {
                        "fill": "#FFFFFF",
                        "outline": "#C7C7C7"
                    },
                    "unknown": {
                        "fill": "#00ADFF",
                        "outline": "#0072E8"
                    }
                },
                "strokeWidth": 3.5
            });
            Object.assign(output["filters"], {
                "maps": { "maxResults": 2000 },
                "tables": { "maxResults": 250 },
                "showZonesInsteadOfAreas": false
            });
            try {
                delete config.infoWindow.detectionManager;
                delete config.infoWindow.heatmap;
            }
            catch (err) {
                console.error(err);
            }
            Object.assign(output["infoWindows"], config.infoWindow);
            Object.keys(output["infoWindows"]).map(key => {
                output["infoWindows"][key].amountOfDecimals = mergeDeep({}, config.infoWindow[key].format.numbers.amountOfDecimals);
                delete output["infoWindows"][key].format;
            });
            Object.assign(output["databaseDefinitions"], {
                "parkingRightTypes": Object.keys(config.prdb.parkingRightTypes),
                "locationTypes": Object.keys(config.locations.locationTypes),
                "vehicleTypes": Object.keys(config.vehicleTypes)
            });
            Object.assign(output["thematicMaps"], {
                "compliancy": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "compliancyVisitors": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "percentageVisitors": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                },
                "parkingPressure": {
                    "bounds": [0, 100],
                    "colors": ["#32CD32", "#FF0000"]
                }
            });
        }
        return output;
    }
    static update(rootKey, json) {
        return Loading.waitForPromises([
            requestService.query({
                Query: ( /*SQL*/`
          INSERT INTO backoffice_config
            (SettingId, \`Value\`)
          VALUES
            (:Key, :NewValue)
          ON DUPLICATE KEY UPDATE
            \`Value\`=:NewValue
        `),
                Params: {
                    Key: rootKey,
                    NewValue: (typeof json === 'string') ? json : JSON.stringify(json)
                }
            }).then(() => {
                AConfig.updateLocal(rootKey, json);
            })
        ]);
    }
    static updateLocal(rootKey, settings) {
        Config.BackOfficeConfig[rootKey] = settings;
    }
    /**
     * @deprecated
     */
    static transformConfig(cfg) {
        const config = JSON.parse(JSON.stringify(cfg));
        delete config._;
        config.vehicleTypes = Object.values(config.vehicleTypes);
        const locationTypes = config.locations.LocationTypes || config.locations.locationTypes;
        delete config.locations.LocationTypes;
        config.colors.charts = ['#cde1f8', '#365490'];
        config.prdb.parkingRightTypes = Object.values(config.prdb.parkingRightTypes);
        config.locations.locationTypes = Object.values(locationTypes);
        const { isDefined, isDefinedArr } = AConfig;
        const { streetView, language, infoWindow, statistics, exportKml, thematicMaps, client } = config;
        if (isDefined(streetView)) {
            if (isDefined(streetView.position)) {
                const { lat, lng } = streetView.position;
                if (isDefinedArr([lat, lng])) {
                    streetView.position = {
                        "editType": "latlng",
                        "value": { lat, lng }
                    };
                }
            }
            if (isDefined(streetView.pov)) {
                const { heading, pitch } = streetView.pov;
                if (isDefined(heading) && isDefined(pitch)) {
                    streetView.pov = {
                        "heading": {
                            "editType": "integer",
                            "value": heading || 90
                        },
                        "pitch": {
                            "editType": "integer",
                            "value": pitch || 0
                        }
                    };
                }
            }
            const { zoom } = streetView.pov;
            if (isDefined(zoom)) {
                streetView.zoom = {
                    "editType": "integer",
                    "value": zoom || 0
                };
            }
        }
        if (isDefined(language)) {
            if (isDefined(language.grid)) {
                language.grid = {
                    "editType": "text",
                    "value": language.grid
                };
            }
        }
        if (isDefined(infoWindow)) {
            for (const key of Object.keys(infoWindow)) {
                const { priorityFields, booleanFields, excludedFields, customFields, format } = infoWindow[key];
                const hashmap = {
                    'priorityFields': priorityFields,
                    'booleanFields': booleanFields,
                    'excludedFields': excludedFields
                };
                for (const hashid of Object.keys(hashmap)) {
                    if (isDefined(hashmap[hashid])) {
                        infoWindow[key][hashid] = {
                            "editType": "array",
                            "value": infoWindow[key][hashid]
                        };
                    }
                }
                if (isDefined(customFields)) {
                    // Nothing
                }
                if (isDefined(format)) {
                    // Change infoWindow->?->format->numbers->amountOfDecimals to
                    // infoWindow->?->format->amountOfDecimals
                    if (isDefined(format.numbers)) {
                        if (isDefined(format.numbers.amountOfDecimals)) {
                            format.amountOfDecimals = format.numbers.amountOfDecimals;
                            delete format.numbers.amountOfDecimals;
                            delete format.numbers;
                            Object.keys(format.amountOfDecimals).map(key => {
                                format.amountOfDecimals[key] = {
                                    "editType": "integer",
                                    "value": format.amountOfDecimals[key],
                                    "attributes": {
                                        "width": "6"
                                    }
                                };
                            });
                        }
                    }
                }
            }
            // Move format to amountOfDecimals
            for (const key of Object.keys(infoWindow)) {
                infoWindow[key].amountOfDecimals = infoWindow[key].format.amountOfDecimals;
                delete infoWindow[key].format;
            }
        }
        if (isDefined(statistics)) {
            if (isDefined(statistics.fineIdentifiers)) {
                statistics.fineIdentifiers = {
                    "editType": "array",
                    "value": statistics.fineIdentifiers
                };
            }
        }
        if (isDefined(thematicMaps)) {
            Object.keys(thematicMaps).map(key => {
                Object.keys(thematicMaps[key]).map(key2 => {
                    thematicMaps[key][key2] = {
                        "editType": "integer",
                        "value": thematicMaps[key][key2],
                        "attributes": {
                            "width": 6
                        }
                    };
                });
            });
        }
        if (!isDefined(config.definitions)) {
            config.definitions = {};
        }
        if (isDefined(config.prdb)) {
            if (isDefined(config.prdb.parkingRightTypes)) {
                config.definitions.parkingRightTypes = {
                    "editType": "array",
                    "value": config.prdb.parkingRightTypes
                };
                delete config.prdb;
            }
        }
        if (isDefined(config.locations)) {
            if (isDefined(config.locations.locationTypes)) {
                config.definitions.locationTypes = {
                    "editType": "array",
                    "value": config.locations.locationTypes
                };
                delete config.locations;
            }
        }
        if (isDefined(config.vehicleTypes)) {
            config.definitions.vehicleTypes = {
                "editType": "array",
                "value": config.vehicleTypes
            };
            delete config.vehicleTypes;
        }
        if (isDefined(exportKml)) {
            if (isDefined(exportKml.defaultColor)) {
                if (!isDefined(config.maps)) {
                    config.maps = {};
                }
                if (!this.isDefined(config.maps.exportKml)) {
                    config.maps.exportKml = {};
                }
                config.maps.exportKml.defaultColor = {
                    "editType": "color",
                    "value": exportKml.defaultColor
                };
            }
            delete config.exportKml;
        }
        if (isDefined(config.colors)) {
            if (isDefined(config.colors.charts)) {
                if (!isDefined(config.charts)) {
                    config.charts = {};
                }
                config.charts.colors = {
                    "editType": "colorTransition",
                    "value": config.colors.charts
                };
            }
            if (isDefined(config.colors.tables)) {
                if (!isDefined(config.tables)) {
                    config.tables = {
                        "colors": {}
                    };
                }
                Object.keys(config.colors.tables).map(key => {
                    config.tables.colors[key] = {
                        "editType": "color",
                        "value": config.colors.tables[key],
                        "attributes": {
                            "width": "4"
                        }
                    };
                });
            }
            delete config.colors;
        }
        if (isDefined(client)) {
            client.name = {
                "editType": "text",
                "value": isDefined(client.name) ? client.name : "Unknown"
            };
        }
        return config;
    }
    static prepareEditTypes(cfg, path = [], arr = []) {
        const needle = 'editType';
        let ref = cfg;
        path.map(p => ref = ref[p]);
        if (this.isPrimitive(ref)) {
            return false;
        }
        if (!ref.hasOwnProperty(needle)) {
            Object.keys(ref).map(key => {
                if (ref.hasOwnProperty(key)) {
                    this.prepareEditTypes(cfg, path.concat([key]), arr);
                }
            });
        }
        else {
            const { editType, value, attributes } = ref;
            arr.push({
                editType,
                value,
                path,
                attributes
            });
        }
        return arr;
    }
    /**
     * @deprecated
     */
    static async prepareDatabaseConfig() {
        const dbConfig = await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT SettingId FROM backoffice_config
      `)
        });
        await Loading.waitForPromises(Object.keys(Config.BackOfficeConfig).map(async (key) => {
            if (dbConfig.find(v => v.SettingId === key) === undefined) {
                await requestService.query({
                    Query: ( /*SQL*/`
              INSERT INTO backoffice_config (\`SettingId\`, \`Value\`, \`DefaultValue\`)
              VALUES (:Key, :Json, :DefaultValue)
              ON DUPLICATE KEY UPDATE SettingId=:Key
            `),
                    Params: {
                        Key: key,
                        Json: JSON.stringify(Config.BackOfficeConfig[key]),
                        DefaultValue: JSON.stringify({})
                    }
                });
            }
        }));
        const output = {};
        const settingsres = await Loading.waitForPromises(requestService.query(`SELECT settingid, value, defaultvalue FROM backoffice_config`));
        settingsres.Rows.map(row => {
            const [sid, val, defaultval] = row;
            output[sid] = Object.assign({}, val);
        });
        return output;
    }
}
globalThis.AConfig = AConfig;

import { AConfig } from "../classes/AConfig.js";
import { AError } from "../classes/AError.js";
import { AResponse } from "../classes/AResponse.js";
import { AEngine } from "../core/AEngine.js";
import { DetectionsFullMapOptimized } from "./query.js";
import { ARound, createArray, formatNodeName, mergeDeep, waitForChromeFrame } from "./tools.js";
export var DISPLAY_TYPES;
(function (DISPLAY_TYPES) {
    DISPLAY_TYPES[DISPLAY_TYPES["Polygon"] = 0] = "Polygon";
    DISPLAY_TYPES[DISPLAY_TYPES["Position"] = 1] = "Position";
    DISPLAY_TYPES[DISPLAY_TYPES["None"] = 2] = "None";
})(DISPLAY_TYPES || (DISPLAY_TYPES = {}));
export function RequestTrafficSigns(Filters, InnerQuery) {
    return requestService.query({
        Name: 'RequestTrafficSigns',
        Query: `${InnerQuery}`,
        Params: Filters,
        Language: Language,
        Translate: ['TrafficSignText']
    }, { cacheQuery: true });
}
export function RequestAndShowTrafficSign(marker) {
    const [DetectionDeviceId, DetectionId, TrafficSign] = marker.data.id.split('_');
    return requestService.query({
        Name: `RequestTrafficSign`,
        Query: (`
            SELECT
                ST_AsGeoJSON(TrafficSignCenter) as TrafficSignCenter,
                ST_AsGeoJSON(ScanDeviceCenter) as ScanDeviceCenter,
                DetectionId,
                DetectionDeviceId,
                TrafficSign,
                DetectionDevice,
                SessionId,
                SessionMode,
                SessionName,
                TrafficSignText,
                DetectionTime,
                DetectionValid,
                Confidence,
                Side,
                CameraId,
                ScanDeviceSpeed,
                ScanDeviceDirection,
                GpsPrecision
            FROM traffic_signs
            WHERE
                DetectionDeviceId=:DetectionDeviceId AND
                DetectionId=:DetectionId AND
                TrafficSign=:TrafficSign
            LIMIT 1
        `),
        Params: {
            DetectionDeviceId,
            DetectionId,
            TrafficSign
        },
        Language: Language,
        Translate: ['TrafficSignText']
    }).then((response) => {
        response.Rows = response.Rows.map((row) => {
            if (row[0] && row[0].coordinates && row[0].coordinates.length > 1) {
                row[0] = {
                    Longitude: row[0].coordinates[0],
                    Latitude: row[0].coordinates[1]
                };
            }
            if (row[1] && row[1].coordinates && row[1].coordinates.length > 1) {
                row[1] = {
                    Longitude: row[1].coordinates[0],
                    Latitude: row[1].coordinates[1]
                };
            }
            return row;
        });
        // return ATableBuilder.DisplayInfoWindowRealtime(marker, [priorityText, fullText])
        return response;
    }).catch(AError.handle);
}
/**
 * @deprecated
 * @param {*} lat
 * @returns
 */
export function groupMeterLat(lat) {
    return lat * 111111.111;
}
/**
 * @deprecated
 * @param {*} lng
 * @returns
 */
export function groupMeterLng(lng) {
    return lng * 111111.111;
}
export function ShowTrafficSigns(response, bounds) {
    const iDetectionId = response.Columns.indexOf('DetectionId'), iDetectionDeviceId = response.Columns.indexOf('DetectionDeviceId'), iTrafficSign = response.Columns.indexOf('TrafficSign'), iTrafficSignText = response.Columns.indexOf('TrafficSignText'), iTrafficSignCenter = response.Columns.indexOf('TrafficSignCenter'), iScanDeviceCenter = response.Columns.indexOf('ScanDeviceCenter'), iDetectionTime = response.Columns.indexOf('DetectionTime');
    const output = [];
    for (const r of response.Rows) {
        const DetectionId = r[iDetectionId], DetectionDeviceId = r[iDetectionDeviceId], TrafficSign = r[iTrafficSign], TrafficSignText = r[iTrafficSignText], TrafficSignLongitude = r[iTrafficSignCenter].coordinates[0], // Math.round(row[iTrafficSignLongitude] * 1111.111) / 1111.111
        TrafficSignLatitude = r[iTrafficSignCenter].coordinates[1], // Math.round(row[iTrafficSignLatitude] * 1111.111) / 1111.111
        ScanDeviceLongitude = r[iScanDeviceCenter] ? r[iScanDeviceCenter].coordinates[0] : TrafficSignLongitude, ScanDeviceLatitude = r[iScanDeviceCenter] ? r[iScanDeviceCenter].coordinates[1] : TrafficSignLatitude, DetectionTime = r[iDetectionTime];
        const point = new google.maps.LatLng(TrafficSignLatitude, TrafficSignLongitude);
        const image = {
            url: `/img/trafficsigns/nl/${TrafficSign}.png`,
            scaledSize: new google.maps.Size(32, 32),
            origin: new google.maps.Point(0, 0)
        };
        // let m: AMarker = new AMarker();
        let marker = new google.maps.Marker({
            // strokeColor: '#FF0000',
            // strokeOpacity: 0.8,
            // strokeWeight: 2,
            // fillColor: '#FF0000',
            // fillOpacity: 0.35,
            icon: image,
            animation: google.maps.Animation.DROP,
            position: point,
            // flat: true,
        });
        marker.data = {
            id: `${DetectionDeviceId}_${DetectionId}_${TrafficSign}`,
            DetectionId,
            DetectionDeviceId,
            TrafficSign,
            TrafficSignText,
            TrafficSignLongitude,
            TrafficSignLatitude,
            ScanDeviceLongitude,
            ScanDeviceLatitude,
            DetectionTime
        };
        if (bounds != null) {
            bounds.extend(point);
        }
        output.push(marker);
        // marker.setMap(map)
    }
    return output;
}
/**
 * Generates where clause for the following filters [ParkingRight, Verification, IllegallyParked, DeviceName, Area, Zone]
 * @param filters
 * @returns string with all the where clauses conjucted by ' AND '
 */
export function generateWhereClause({ filters, conjunction = 'AND' }) {
    const conditions = [];
    if (filters['ParkingRight'])
        conditions.push(`ParkingRight IN (${filters['ParkingRight'].join(', ')})`);
    if (filters['Verification'])
        conditions.push(`Verification IN (${filters['Verification'].join(', ')})`);
    if (filters['IllegallyParked'])
        conditions.push(`IllegallyParked IN (${filters['IllegallyParked'].join(', ')})`);
    if (filters['DetectionState'])
        conditions.push(`DetectionState IN (${filters['DetectionState'].join(', ')})`);
    if (filters['ParkingRightType'])
        conditions.push(`ParkingRightType ${FilterManager.buildQueryFindInArray(filters.ParkingRightType)}`);
    if (filters['VerifyResult'])
        conditions.push(`VerificationResult ${FilterManager.buildQueryFindInArray(filters.VerifyResult)}`);
    if (filters['DeviceMulti'])
        conditions.push(`DetectionDeviceId ${FilterManager.buildQueryFindInArray(filters.DeviceMulti)}`);
    if (filters['DeviceName'])
        conditions.push(`DetectionDevice=:DeviceName`);
    if (filters['Area'])
        conditions.push('Area=:Area');
    if (filters['Zone'])
        conditions.push('Zone=:Zone');
    if (filters['DetectionId'])
        conditions.push('DetectionId=:DetectionId');
    if (filters['DetectionDeviceId'])
        conditions.push('DetectionDeviceId=:DetectionDeviceId');
    if (filters['LicensePlate'])
        conditions.push(`LicensePlate LIKE CONCAT("%", :LicensePlate, "%")`);
    if (filters['Images'])
        conditions.push('Image IS NOT NULL');
    const whereClause = conditions.join(` ${conjunction} `);
    return (conditions.length > 0) ? whereClause : '1=1';
}
/**
 * Generates where clause for gps table
 * @param filters
 * @returns string with all the where clauses conjucted by ' AND '
 */
export function generateWhereClauseRoute({ filters, conjunction = 'AND' }) {
    const conditions = [
        `(GpsTime BETWEEN :FromDate AND :ToDate)`,
        filters.DeviceMulti ? `DeviceId ${FilterManager.buildQueryFindInArray(filters.DeviceMulti)}` : null,
        filters.DeviceName && filters.DeviceName !== '%' ? `DeviceName LIKE :DeviceName` : null,
        `Speed IS NOT NULL`,
        `ValidGps=1`,
    ].filter(c => c != null);
    const whereClause = conditions.join(` ${conjunction} `);
    return (conditions.length > 0) ? whereClause : '1=1';
}
export function RequestMapScansOptimized(filters) {
    return requestService.query({
        Name: 'MapScansOptimized',
        Query: DetectionsFullMapOptimized,
        Params: filters
    });
}
async function RequestMapScansCount(filters, subQuery) {
    const whereClause = generateWhereClause({ filters });
    const ares = await requestService.fetch({
        AssertValues: true,
        Name: 'MapScansCount',
        Query: (`
      SELECT COUNT(*)+0 as Count
      FROM (${subQuery}) full
      WHERE ${whereClause}
      ORDER BY DetectionId
    `),
        Params: filters,
        Language: Language,
        Translate: [] // ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked", "Digital", "IllegallyParked", "ParkingRight", "Verification", "DetectionState"]
    }, { cacheQuery: false, valueMapper: { Count: (v) => Number(v) } });
    return ares.First?.Count ?? 0;
}
/**
 * Not functional yet!
 */
export async function RequestMapScansAsync(filters, subQuery, pipeline) {
    let count = await RequestMapScansCount(filters, subQuery);
    const step = Math.ceil(count / 20);
    let output = createArray(count);
    for (let i = 0; i < count; i += step) {
        const f = mergeDeep({}, filters, {
            Limit: step,
            Offset: (i === 0) ? 0 : ARound(i / step, 0)
        });
        const ares = await RequestMapScans(f, subQuery);
        const pres = await pipeline(ares.Original, i);
        output.push(...pres);
        await waitForChromeFrame();
    }
    return output;
}
export function RequestMapScans(filters, subQuery) {
    const whereClause = generateWhereClause({ filters });
    const offsetClause = filters.Offset ? `OFFSET :Offset` : '';
    // const extraColumnsText = extraColumns.length ? extraColumns.join(',') + ',' : ''
    return requestService.fetch({
        AssertValues: true,
        Name: 'MapScans',
        Query: (`
      SELECT
        ScanDeviceLatitude,
        ScanDeviceLongitude,
        VehicleBounds,
        VehicleCenterLatitude,
        VehicleCenterLongitude,
        DetectionId,
        DetectionDeviceId,
        DetectionTime,
        LicensePlate,
        Area,
        Confidence,
        Side,
        CameraIds,
        GpsPrecision,
        VehicleType,
        VehicleMoving,
        HasParkingRight,
        IsIllegallyParked,
        ParkingAreaType,
        TaxRequired,
        ParkingRightType,
        ParkingRightResults,
        NearMatches,
        AreaId,
        AreaConfidence,
        ParkingSpaceId,
        ParkingSpaceConfidence,
        ZoneId,
        ZoneConfidence,
        SegmentId,
        SegmentConfidence,
        SegmentTimestamp,
        ScanDeviceDirection,
        CardinalDirection,

        Digital,
        IllegallyParked,
        ParkingRight,
        Verification,
        TimeLimitedParking,
        DetectionState,

        keyDigital,
        keyIllegallyParked,
        keyParkingRight,
        keyVerification,
        keyTimeLimitedParking,
        keyDetectionState,

        Label
      FROM (${subQuery}) full
      WHERE
        ${whereClause}
      ORDER BY DetectionId
      LIMIT :Limit
      ${offsetClause}
    `),
        Params: filters,
        Language: Language,
        Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType", "IsIllegallyParked", "Digital", "IllegallyParked", "ParkingRight", "Verification", "DetectionState"]
    }, { cacheQuery: true });
}
export function RequestParkingSpaceMapScans(Filters, InnerQuery) {
    CCCClient.SendMessage("QueryRequest", 2, {
        Name: "MapScans",
        Query: "SELECT VehicleBounds,VehicleCenterLatitude,VehicleCenterLongitude,DetectionTime, LicensePlate, Area, Confidence,Side,CameraIds,GpsPrecision,VehicleMoving,HasParkingRight,ParkingRightType,NearMatches,AreaId,AreaConfidence, ParkingSpaceId,ParkingSpaceConfidence,SegmentId,SegmentTimestamp,ScanDeviceDirection,CardinalDirection from (" + InnerQuery + ") full inner join(" + ParkingPlaceDetections + ") u using(DetectionId,DetectionDeviceId) where ABS(VehicleSpeed) between :MinSpeed and :MaxSpeed and GpsPrecision between :MinGpsPrecision and :MaxGpsPrecision and IF(:ParkingRightType='%',true, ParkingRightType=:ParkingRightType) and IF(:DeviceName='%',true, DetectionDevice=:DeviceName) and IF(:Area='%',true, Area=:Area) and IF(:VerifyResult='%',true, VerificationResult=:VerifyResult) ORDER BY DetectionId LIMIT :Limit",
        Params: Filters,
        Language: Language,
        Context: document.location.hash.substr(2),
        Translate: ["VehicleType", "ParkingAreaType", "ParkingRightType"]
    });
}
export async function RequestMapRouteFull(filters, opt) {
    if (filters?.ShowRoute !== true) {
        return AResponse.genEmpty();
    }
    if (opt?.clampBetween) {
        let { min, max } = opt.clampBetween;
        // Add 10 seconds margin because gps array get's sent every 5 seconds
        min.setSeconds(min.getSeconds() - 10);
        max.setSeconds(max.getSeconds() + 10);
        filters['FromDate'] = min.toJSON();
        filters['ToDate'] = max.toJSON();
    }
    const whereClause = generateWhereClauseRoute({ filters });
    return requestService.fetch({
        AssertValues: true,
        Name: "MapRouteFull",
        Query: ( /*SQL*/`
      SELECT GpsTime, DeviceName, DeviceId, Latitude, Longitude, Points, \`Precision\`, Speed
      FROM gps
      WHERE ${whereClause}
      ORDER BY DeviceName, GpsTime ASC
      LIMIT :RouteLimit
    `),
        Params: Object.assign({
            DeviceName: '%',
            RouteLimit: 5000,
        }, filters),
        Translate: ['DeviceName'],
        Language
    }, {
        valueMapper: {
            DeviceName: (input) => formatNodeName(input),
            GpsTime: (v) => new Date(Date.parse(v)),
        }
    });
}
function createDetectionBounds(center) {
    const detectionWidth = 1.8049227462465927 / 2.0;
    const detectionLength = 4.9325814100307 / 2.0;
    const heading = 0; //this.calcDetectionHeading(context.data.target as AParkingSpace) + Math.random() * 3
    const sideHeading = heading; // AGeoUtils.clampHeading(heading + 90)
    const front = google.maps.geometry.spherical.computeOffset(center, detectionLength, heading);
    const back = google.maps.geometry.spherical.computeOffset(center, -detectionLength, heading);
    const points = [
        google.maps.geometry.spherical.computeOffset(back, detectionWidth, sideHeading).toJSON(),
        google.maps.geometry.spherical.computeOffset(back, -detectionWidth, sideHeading).toJSON(),
        google.maps.geometry.spherical.computeOffset(front, -detectionWidth, sideHeading).toJSON(),
        google.maps.geometry.spherical.computeOffset(front, detectionWidth, sideHeading).toJSON(),
    ];
    const detectionBounds = { coordinates: points.map(({ lat, lng }) => ({ lat, lng })) };
    return detectionBounds;
}
/**
 * @param {{ DetectionId, DetectionDeviceId, VehicleBounds, VehicleCenterLatitude, VehicleCenterLongitude }} args
 * @returns {{ displayType: Number, center?: {lat: Number, lng: Number}, coordinates?: any }}
 */
export function getDetectionDisplay(args) {
    const { DetectionId, DetectionDeviceId, VehicleBounds, VehicleCenterLatitude, VehicleCenterLongitude } = args;
    if (VehicleBounds) {
        const { coordinates, center } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
        if (AIsLatLngValid([center.lat, center.lng])) {
            return {
                displayType: DISPLAY_TYPES.Polygon,
                coordinates,
                center
            };
        }
    }
    if (AIsLatLngValid([VehicleCenterLatitude, VehicleCenterLongitude])) {
        const center = { lat: VehicleCenterLatitude, lng: VehicleCenterLongitude };
        const { coordinates } = createDetectionBounds(center);
        return {
            displayType: DISPLAY_TYPES.Polygon,
            coordinates,
            center
        };
        // return {
        //   displayType: DISPLAY_TYPES.Position,
        //   center: {
        //     lat: Number(VehicleCenterLatitude),
        //     lng: Number(VehicleCenterLongitude)
        //   }
        // }
    }
    console.warn({ DetectionId, DetectionDeviceId, Err: `Couldn't draw detection because of no gps data` });
    return {
        displayType: DISPLAY_TYPES.None
    };
}
const detectionAttributes = { strokeOpacity: 0.733, strokeWeight: 3, fillOpacity: 0.35, zIndex: 10.0 };
export function ShowMapScans(opt) {
    const { response, map, bounds, infoParent, overrideClickHandler } = opt;
    const output = [], toRemove = [];
    const addEventListeners = (marker) => {
        if (overrideClickHandler !== true)
            google.maps.event.addListener(marker, "click", function (args) { purgatoryService.onMarkerClickRealtime(this, args); });
        google.maps.event.addListener(marker, "rightclick", OnRightClick);
    };
    const ares = (response instanceof AResponse) ? response : new AResponse(response);
    const processFunc = (detection, i) => {
        let marker = null;
        const { HasParkingRight, IsIllegallyParked, DetectionId, DetectionDeviceId, keyDigital, keyIllegallyParked, keyVerification, keyParkingRight, keyDetectionState } = detection;
        let { displayType, center, coordinates } = getDetectionDisplay(detection);
        switch (displayType) {
            case DISPLAY_TYPES.None:
                toRemove.push(i);
                return;
            case DISPLAY_TYPES.Position:
                if (bounds !== undefined && center !== undefined)
                    bounds.extend(center);
                marker = new google.maps.Marker({ position: center });
                break;
            case DISPLAY_TYPES.Polygon:
                if (bounds !== undefined && center !== undefined)
                    bounds.extend(center);
                marker = new google.maps.Polygon(Object.assign({}, detectionAttributes, {
                    paths: coordinates,
                    position: center
                }));
                break;
        }
        detectionService.setMarkerIdentifier(marker, DetectionDeviceId, DetectionId);
        detectionService.setMarkerFinal(marker, { HasParkingRight, IsIllegallyParked, keyParkingRight, keyVerification, keyDetectionState, keyDigital, keyIllegallyParked });
        marker.DetectionId = DetectionId;
        marker.DetectionDeviceId = DetectionDeviceId;
        marker.Index = output.length;
        marker.infoParent = infoParent;
        addEventListeners(marker);
        if (displayType === DISPLAY_TYPES.Polygon) {
            const clr = mapHelperService.calcLegendColor(marker._final) || "#000";
            const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr;
            marker.setOptions({
                strokeColor: stroke,
                fillColor: fill
            });
        }
        marker.setMap(map);
        output.push(marker);
    };
    ares.loop(processFunc);
    // TODO: Check whether toRemove needs to exist
    toRemove.reverse().map(index => { response.Rows.splice(index, 1); });
    if (toRemove.length > 0) {
        AEngine.warn(`skipped ${toRemove.length} detections`);
    }
    mapHelperService.updateCountLabel(output.length);
    return output;
}
// export async function ShowMapScansAsync<T extends {}>(opt: AShowMapScansOpt<T>) {
//   const { response, map, bounds, infoParent, overrideClickHandler } = opt
//   const output: AMapOptionTypes[] = [], toRemove: any[] = []
//   const addEventListeners = (marker: AMapOptionTypes) => {
//     if (overrideClickHandler !== true) google.maps.event.addListener(marker, "click", function (args: any) { purgatoryService.onMarkerClickRealtime(this, args) })
//     google.maps.event.addListener(marker, "rightclick", OnRightClick)
//   }
//   const ares = new AResponse(response instanceof AResponse ? response.Original : response)
//   const processFunc = (detection, i) => {
//     let marker: any = null
//     const {
//       HasParkingRight,
//       IsIllegallyParked,
//       DetectionId,
//       DetectionDeviceId,
//       keyDigital,
//       keyIllegallyParked,
//       keyVerification,
//       keyParkingRight,
//       keyDetectionState
//     } = detection
//     let { displayType, center, coordinates } = getDetectionDisplay(detection)
//     switch (displayType) {
//       case DISPLAY_TYPES.None:
//         toRemove.push(i)
//         return
//       case DISPLAY_TYPES.Position:
//         if (bounds !== undefined && center !== undefined) bounds.extend(center)
//         marker = new google.maps.Marker({ position: center })
//         break;
//       case DISPLAY_TYPES.Polygon:
//         if (bounds !== undefined && center !== undefined) bounds.extend(center)
//         marker = new google.maps.Polygon(Object.assign({}, detectionAttributes, {
//           paths: coordinates,
//           position: center
//         }))
//         break;
//     }
//     detectionService.setMarkerIdentifier(marker, DetectionDeviceId, DetectionId)
//     detectionService.setMarkerFinal(marker, { HasParkingRight, IsIllegallyParked, keyParkingRight, keyVerification, keyDetectionState, keyDigital, keyIllegallyParked })
//     marker.DetectionId = DetectionId
//     marker.DetectionDeviceId = DetectionDeviceId
//     marker.Index = output.length
//     marker.infoParent = infoParent
//     addEventListeners(marker)
//     if (displayType === DISPLAY_TYPES.Polygon) {
//       const clr = mapHelperService.calcLegendColor(marker._final) || "#000"
//       const { fill, stroke } = (typeof clr === 'string') ? { fill: clr, stroke: clr } : clr
//       marker.setOptions({
//         strokeColor: stroke,
//         fillColor: fill
//       })
//     }
//     marker.setMap(map)
//     output.push(marker)
//   }
//   // await asyncMapArray(ares.toArray(), 100, processFunc)
//   // TODO: Check whether toRemove needs to exist
//   toRemove.reverse().map(index => { response.Rows.splice(index, 1)  })
//   if (toRemove.length > 0) { AEngine.warn(`skipped ${toRemove.length} detections`) }
//   mapHelperService.updateCountLabel(output.length)
//   return output
// }
/**
 * @deprecated
 */
export function ShowMapScansOriginalBounds(response, map, bounds, offset) {
    let output = [];
    let VehicleBoundsIndex = response.Columns.indexOf("VehicleBounds");
    let OrgVehicleBoundsIndex = response.Columns.indexOf("OriginalVehicleBounds");
    let DetectionIdIndex = response.Columns.indexOf("DetectionId");
    let DetectionDeviceIdIndex = response.Columns.indexOf("DetectionDeviceId");
    for (let i = 0; i < response.Rows.length; i++) {
        let CurrentRow = response.Rows[i];
        let VehicleBounds = CurrentRow[VehicleBoundsIndex];
        let OrgVehicleBounds = CurrentRow[OrgVehicleBoundsIndex];
        let DetectionId = CurrentRow[DetectionIdIndex];
        let DetectionDeviceId = CurrentRow[DetectionDeviceIdIndex];
        let Marker = null;
        let OriginalMarker = null;
        if (VehicleBounds && OrgVehicleBounds) {
            const correctedGps = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
            if (AIsLatLngValid([correctedGps.center.lat, correctedGps.center.lng])) {
                bounds.extend({
                    lat: correctedGps.center.lat,
                    lng: correctedGps.center.lng
                });
                Marker = new google.maps.Polygon({
                    paths: correctedGps.coordinates,
                    strokeColor: '#00FF00',
                    strokeOpacity: 0.733,
                    strokeWeight: 3,
                    fillColor: '#00FF00',
                    fillOpacity: 0.35,
                    zIndex: 10.0,
                    // position: {
                    //     lat: correctedGps.center.lat,
                    //     lng: correctedGps.center.lng
                    // }
                });
            }
            const originalGps = mapHelperService.geoJsonToPolygonCoords(OrgVehicleBounds);
            if (AIsLatLngValid([originalGps.center.lat, originalGps.center.lng])) {
                OriginalMarker = new google.maps.Polygon({
                    paths: originalGps.coordinates.map((latLng) => {
                        latLng.lng += offset;
                        return latLng;
                    }),
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.733,
                    strokeWeight: 3,
                    fillColor: '#0000FF',
                    fillOpacity: 0.35,
                    zIndex: 10.0,
                    // position: {
                    //     lat: originalGps.center.lat,
                    //     lng: originalGps.center.lng + offset
                    // }
                });
            }
            const lineCoordinates = [
                Marker.position,
                OriginalMarker.position
            ];
            const line = new google.maps.Polyline({
                path: lineCoordinates,
                geodesic: true,
                strokeColor: "#0000FF",
                strokeOpacity: 1.0,
                strokeWeight: 10,
            });
            line.setMap(map);
            Marker.DetectionId = DetectionId;
            Marker.DetectionDeviceId = DetectionDeviceId;
            Marker.Index = output.length;
            OriginalMarker.setMap(map);
            output.push(OriginalMarker);
            Marker.setMap(map);
            output.push(Marker);
            google.maps.event.addListener(Marker, "click", () => PageScript.map?.focusOnMarker(OriginalMarker));
            google.maps.event.addListener(OriginalMarker, "click", () => PageScript.map?.focusOnMarker(Marker));
            google.maps.event.addListener(Marker, "rightclick", OnRightClick);
            google.maps.event.addListener(OriginalMarker, "rightclick", OnRightClick);
        }
    }
    mapHelperService.updateCountLabel(output.length);
    return output;
}
export function getCoordsbyAddress(address) {
    return new Promise((resolve, reject) => {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                return resolve(results !== null ? results[0] : null);
            }
            else {
                return reject(new Error('Geocode statuscode was not OK.'));
            }
        });
    });
}
export function OnRightClick() {
    // @ts-ignore
    const { zIndex } = this;
    // @ts-ignore
    this.setOptions({
        zIndex: (zIndex === null || zIndex === undefined) ? 0 : zIndex - 1
    });
}
export function extractBoundsFromStreamCVS(verify) {
    if (verify.VehicleBounds) {
        if (verify.VehicleBounds.Points && verify.VehicleBounds.Points.length) {
            return verify.VehicleBounds;
        }
        else if (verify.VehicleBounds.coordinates.length === 1) {
            return {
                Points: verify.VehicleBounds.coordinates[0].map(([lng, lat]) => {
                    return { X: lat, Y: lng };
                })
            };
        }
        else {
            console.error(`Unexpected coordinates array size!`);
        }
    }
    console.warn(`Didn't receive VehicleBounds from VerifyRequest, drawing straight square instead.`);
    return {
        Points: [
            { X: verify.Latitude + 0.000015, Y: verify.Longitude - 0.000015 },
            { X: verify.Latitude + 0.000015, Y: verify.Longitude + 0.000015 },
            { X: verify.Latitude - 0.000015, Y: verify.Longitude + 0.000015 },
            { X: verify.Latitude - 0.000015, Y: verify.Longitude - 0.000015 }
        ]
    };
}
export function extractCenterFromVehicleBoundsJson(vehicleBoundsJson) {
    let latCenter = 0.0, lngCenter = 0.0;
    for (let p = 0; p < vehicleBoundsJson.Points.length; p++) {
        let LatLon = {
            lat: vehicleBoundsJson.Points[p].X,
            lng: vehicleBoundsJson.Points[p].Y
        };
        latCenter += LatLon.lat;
        lngCenter += LatLon.lng;
    }
    return {
        lat: latCenter / vehicleBoundsJson.Points.length,
        lng: lngCenter / vehicleBoundsJson.Points.length
    };
}
/**
 * @deprecated
 */
export function ShowMapScansAdvancedLegacy(response, map, Bounds) {
    let mapScanResults = [];
    let VehicleBoundsIndex = response.Columns.indexOf("VehicleBounds");
    let VehicleCenterLatitudeIndex = response.Columns.indexOf("VehicleCenterLatitude");
    let VehicleCenterLongitudeIndex = response.Columns.indexOf("VehicleCenterLongitude");
    let HasParkingRightIndex = response.Columns.indexOf("HasParkingRight");
    let IsIllegallyParkedIndex = response.Columns.indexOf("IsIllegallyParked");
    let DetectionIdIndex = response.Columns.indexOf("DetectionId");
    let DetectionDeviceIdIndex = response.Columns.indexOf("DetectionDeviceId");
    let LabelIndex = response.Columns.indexOf("Label");
    let LicensePlateIndex = response.Columns.indexOf("LicensePlate");
    let ScanDeviceLatitudeIndex = response.Columns.indexOf("ScanDeviceLatitude");
    let ScanDeviceLongitudeIndex = response.Columns.indexOf("ScanDeviceLongitude");
    let DetectionTimeIndex = response.Columns.indexOf("DetectionTime");
    let LPImageIndex = response.Columns.indexOf("Image");
    let DigitalIndex = response.Columns.indexOf("Digital");
    let IllegallyParkedIndex = response.Columns.indexOf("IllegallyParked");
    let ParkingRightIndex = response.Columns.indexOf("ParkingRight");
    let VerificationIndex = response.Columns.indexOf("Verification");
    let DetectionStateIndex = response.Columns.indexOf("DetectionState");
    let toRemove = [];
    for (let i = 0; i < response.Rows.length; i++) {
        let CurrentRow = response.Rows[i];
        let VehicleCenterLatitude = CurrentRow[VehicleCenterLatitudeIndex];
        let VehicleCenterLongitude = CurrentRow[VehicleCenterLongitudeIndex];
        let VehicleBounds = CurrentRow[VehicleBoundsIndex];
        let HasParkingRight = CurrentRow[HasParkingRightIndex];
        let IsIllegallyParked = CurrentRow[IsIllegallyParkedIndex];
        let DetectionId = CurrentRow[DetectionIdIndex];
        let DetectionDeviceId = CurrentRow[DetectionDeviceIdIndex];
        let Label = CurrentRow[LabelIndex];
        let LicensePlate = CurrentRow[LicensePlateIndex];
        let ScanDeviceLatitude = CurrentRow[ScanDeviceLatitudeIndex];
        let ScanDeviceLongitude = CurrentRow[ScanDeviceLongitudeIndex];
        let DetectionTime = CurrentRow[DetectionTimeIndex];
        let LPImage = CurrentRow[LPImageIndex];
        let keyDigital = CurrentRow[DigitalIndex];
        let keyIllegallyParked = CurrentRow[IllegallyParkedIndex];
        let keyParkingRight = CurrentRow[ParkingRightIndex];
        let keyVerification = CurrentRow[VerificationIndex];
        let keyDetectionState = CurrentRow[DetectionStateIndex];
        let marker = null;
        if (VehicleBounds) {
            const { coordinates, center } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
            if (AIsLatLngValid([center.lat, center.lng])) {
                Bounds.extend(center);
                const { FillColor, StrokeColor } = mapHelperService.getColorLegacy({
                    HasParkingRight,
                    IsIllegallyParked
                });
                marker = new google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: StrokeColor,
                    strokeOpacity: 0.733,
                    fillColor: FillColor,
                    fillOpacity: 0.35,
                    strokeWeight: 3,
                    zIndex: 10.0,
                    // position: {
                    //     lat: center.lat,
                    //     lng: center.lng
                    // }
                });
            }
        }
        else if (AIsLatLngValid([VehicleCenterLatitude, VehicleCenterLongitude])) {
            let Pointer = {
                lat: VehicleCenterLatitude,
                lng: VehicleCenterLongitude,
                zIndex: 10
            };
            Bounds.extend(Pointer);
            marker = new google.maps.Marker({
                position: Pointer,
                map: map
            });
        }
        if (marker) {
            marker.DetectionId = DetectionId;
            marker.DetectionDeviceId = DetectionDeviceId;
            marker.Label = Label;
            marker.Index = mapScanResults.length;
            detectionService.setMarkerIdentifier(marker, DetectionDeviceId, DetectionId);
            detectionService.setMarkerFinal(marker, { HasParkingRight, IsIllegallyParked, keyDigital, keyIllegallyParked, keyParkingRight, keyVerification, keyDetectionState });
            marker.LicensePlate = LicensePlate;
            marker.IsIllegallyParked = IsIllegallyParked;
            marker.Date = new Date(Date.parse(DetectionTime));
            marker.LPImage = LPImage;
            if (ScanDeviceLatitude != null || ScanDeviceLongitude != null) {
                marker.scanDevicePos = {
                    lat: ScanDeviceLatitude,
                    lng: ScanDeviceLongitude
                };
            }
            marker._onclick = MarkerClickProxy;
            google.maps.event.addListener(marker, "click", () => {
                PageScript.DeskControl.markerClick(marker, false);
            });
            google.maps.event.addListener(marker, "rightclick", OnRightClick);
            marker.setMap(map);
            mapScanResults.push(marker);
        }
        else {
            toRemove.push(i);
        }
    }
    // TODO: Check whether toRemove needs to exist
    toRemove.reverse().map(index => {
        response.Rows.splice(index, 1);
    });
    mapHelperService.updateCountLabel(mapScanResults.length);
    return mapScanResults;
}
export function MarkerClickProxy(e) {
    return purgatoryService.onMarkerClickRealtime(e.target);
}
export function DefaultPosition() {
    return DefaultBounds().getCenter();
}
export function DefaultBounds() {
    let bounds = new google.maps.LatLngBounds();
    if (bounds.isEmpty()) {
        bounds.extend({ lng: Config.GpsBounds[0][0], lat: Config.GpsBounds[0][1] });
        bounds.extend({ lng: Config.GpsBounds[1][0], lat: Config.GpsBounds[1][1] });
    }
    return bounds;
}
export function GetFilterArea() {
    return (FilterSettings.Area != "%" && FilterSettings.Area != "Unknown") ? FilterSettings.Area : null;
}
export function _getEle(str) {
    let ele = null;
    if (str instanceof jQuery) {
        // @ts-ignore
        ele = str.get(0);
    }
    else if (str instanceof Element) {
        ele = str;
    }
    else if (typeof str === 'string') {
        let found = document.querySelector(str) || (!str.startsWith('#') ? document.querySelector('#' + str) : null);
        ele = found;
    }
    else {
        throw new Error(`_getEle(#${str}) is an unexpected value`);
    }
    return ele;
}
export function _getEle$(str) {
    let ele = _getEle(str);
    return $(ele);
}
/**
 * @deprecated
 */
export function AIsLatLngValid2(array) {
    let output = false;
    for (const item of array) {
        if (item === null || item === undefined) {
            return false;
        }
        if (item !== 0) {
            output = true;
        }
    }
    return output;
}
export function AIsLatLngValid(array) {
    const maxValue = Math.max.apply(this, [0, ...array]);
    if (maxValue === 0 || isNaN(maxValue)) {
        return false;
    }
    for (const item of array) {
        if (item === null || item === undefined) {
            return false;
        }
    }
    return true;
}
function setPanorama(panorama) {
    const map = this;
    map.setStreetView(panorama);
    if (panorama !== null) {
        google.maps.event.trigger(panorama, 'resize');
        if (!panorama.get('isInitialized')) {
            panorama.set('isInitialized', true);
            panorama.setOptions({ fullscreenControl: false });
        }
    }
    // let p = map.getStreetView()
    // if (p.get('isExternal') === undefined) {
    //   p.set('isExternal', panorama !== null)
    // }
    // google.maps.event.trigger(map, 'streetview_set', map)
}
export function createMap(id, options = {}) {
    const mapEle = _getEle(id);
    if (!mapEle) {
        throw new Error(`Couldn't find HTMLElement: #${id}`);
    }
    // const mapCfg = configService.get('general.map')!
    const mapOptions = $.extend(true, {}, {
        position: DefaultBounds(),
        zoom: AConfig.get('general.map.mapZoom', 14),
        fullscreenControl: false,
        panControl: false,
        streetViewControl: false,
        streetViewControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
        scaleControl: true,
        scaleControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
        mapTypeControl: false,
        mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
    }, options);
    const map = new google.maps.Map(mapEle, mapOptions);
    map.setPanorama = setPanorama.bind(map);
    let loaded = false;
    const waitForInit = new Promise(resolve => google.maps.event.addListenerOnce(map, 'idle', () => resolve()));
    let callbacks = [];
    map.set('onLoad', (callback) => {
        if (loaded)
            callback(map);
        else
            callbacks.push(callback);
    });
    waitForInit.then(() => {
        loaded = true;
        callbacks.map(cb => cb(map));
        callbacks = [];
    });
    // waitForInit.then(() => {
    //   // const $mapVisibility = $('<div id="map-visibility" style="width: 100%; height: 100%; top: 0; background: #fefefe; opacity: 0.5;"></div>')
    //   // const POS_CENTER = google.maps.ControlPosition['CENTER'] ?? 13
    //   // map.controls[POS_CENTER].push($mapVisibility.get(0))
    //   if (mapOptions.streetViewControl) {
    //     const $map = $(map.getDiv())
    //     let sv: () => AStreetView = () => (map.getStreetView() ?? mapOptions.streetView) as any
    //     const updateOverlayVisibility = async (args?: { sleep?: number }) => {
    //       await Promise.all([sleep(args?.sleep ?? 0), waitForChromeFrame()])
    //       let s = map.getStreetView()
    //       let hideOverlay = s.getVisible() && s.get('isExternal') !== true // $map.find('[aria-label="Street View"]').is(':visible')
    //       $map.toggleClass('is-streetview-overlay', hideOverlay)
    //       console.log('visible', s.getVisible(), 'isExternal', s.get('isExternal'), 'hideOverlay', hideOverlay, 'mapVisibility')
    //     }
    //     map.set('refreshOverlay', updateOverlayVisibility)
    //     map.addListener('streetview_set', (e) => updateOverlayVisibility())
    //     map.addListener('layout_changed', () => updateOverlayVisibility())
    //     map.setPanorama(mapOptions.streetView ?? null)
    //     sv().addListener('visible_changed', () => updateOverlayVisibility({ sleep: 10 }))
    //     sv().addListener('pano_changed', () => updateOverlayVisibility({ sleep: 10 }))
    //     sv().addListener('closeclick', () => updateOverlayVisibility())
    //     google.maps.event.addListener(map, 'idle', (e) => {
    //       updateOverlayVisibility()
    //     })
    //   }
    // })
    // Create link between HtmlElement and AMap instance
    $(map.getDiv()).data('map', map);
    return map;
}
export function createPano(id, options) {
    const mapCfg = AConfig.get(`general.map.pos`, { lat: 0, lng: 0 });
    // const mapCfg = configService.get('general.map')!
    const pano = new google.maps.StreetViewPanorama(_getEle(id), mergeDeep({
        position: new google.maps.LatLng(mapCfg.lat, mapCfg.lng),
        // pov: configService.get('general.streetview')!,
        pov: AConfig.get(`general.map.pov`, { heading: 90, pitch: 0, zoom: 1 }),
        visible: true,
        fullscreenControl: false
    }, options));
    return pano;
}
export function getCenterAny(markerOrPolygon) {
    if (markerOrPolygon instanceof google.maps.Polyline) {
        const path = markerOrPolygon.getPath();
        markerOrPolygon = path.getAt(Math.floor(path.getLength() / 2));
    }
    if (markerOrPolygon instanceof google.maps.Polygon) {
        return getPolygonCenter(markerOrPolygon);
    }
    if (markerOrPolygon instanceof google.maps.Marker) {
        return markerOrPolygon.getPosition();
    }
    if (markerOrPolygon instanceof google.maps.LatLng) {
        return markerOrPolygon.toJSON();
    }
    if (markerOrPolygon.pos) {
        return markerOrPolygon.pos;
    }
    if (markerOrPolygon.position) {
        return markerOrPolygon.position;
    }
    console.warn('Marker/Polygon: ', markerOrPolygon);
    throw new Error('Unexpected marker/polygon');
}
export function getPolygonCenter(polygon) {
    const path = polygon.getPath();
    const length = path.getLength();
    let bounds = new google.maps.LatLngBounds();
    for (let i = 0; i < length; i++) {
        let val = path.getAt(i);
        bounds.extend(val);
    }
    return bounds.getCenter();
}
export function increaseMapZoom(map = PageScript.map, amount = 1) {
    console.warn('increasing zoom');
    map.setZoom(map.getZoom() + amount);
}
export function moveMap(map = PageScript.map) {
    const { lat, lng } = map.center.toJSON();
    const latLng = { lat, lng: lng + 0.001 };
    map.setCenter(latLng);
}
export function hideMapLabels(map, styleJson) {
    if (styleJson === undefined) {
        styleJson = [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [
                    { visibility: "off" }
                ]
            }
        ];
    }
    map.set('styles', styleJson);
}
export function showMapLabels(map, styleJson) {
    if (styleJson === undefined) {
        styleJson = [
            {
                featureType: "all",
                elementType: "labels",
                stylers: [
                    { visibility: "on" }
                ]
            }
        ];
    }
    map.set('styles', styleJson);
}
globalThis.DefaultBounds = DefaultBounds;
globalThis.getCenterAny = getCenterAny;

import { Routes } from "./backoffice-routes.js";
import { AConfig } from "./classes/AConfig.js";
import { AError } from "./classes/AError.js";
import { ADetectionState, AIllegallyParked, AParkingRight, ATimeLimitedParking, AVerification } from "./classes/AUnificationTypes.js";
import { ACCCClientSettings } from "./core/ACCCClientSettings.js";
import { AEngine, sleep } from "./core/AEngine.js";
import { AGpsDevice } from "./core/AGpsDevice.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "./services/AAlertService.js";
import { AArrayService } from "./services/AArrayService.js";
import { AEventService, EVENTS } from "./services/AEventService.js";
import { AJsonService, JSON_FILES } from "./services/AJsonService.js";
import { MAP_OPTIONS, UNLOAD_OPTIONS } from "./services/AMapHelperService.js";
import { APolicyService } from "./services/APolicyService.js";
import { ROUTES } from "./services/ARouteService.js";
import { AValidateUnificationService } from "./services/AValidateUnificationService.js";
import { listenToTableAccordionEvents } from "./utils/table_accordions.js";
import { checkIfFullScreen, GetCookie, mergeDeep, SetCookie } from "./utils/tools.js";
const eventService = AEngine.getOrCreateInstance(AEventService);
class ImportError extends Error {
}
Object.assign(globalThis, {
    ACI_ADMIN: 'aci_admin',
    AllowLanguageChange: GetCookie('Language', () => {
        $('[action="TOGGLE_LANGUAGE"]').css('pointer-events', 'none');
        AEngine.warn(`Cookies Disabled In Server Configuration.`);
        return null;
    }) != null,
    Language: GetCookie('Language', () => {
        return (navigator?.language?.split('-').shift() || 'en');
    }),
    parkingRightRef: new AParkingRight(),
    verificationRef: new AVerification(),
    detectionStateRef: new ADetectionState(),
    illegallyParkedRef: new AIllegallyParked(),
    timeLimitedParkingRef: new ATimeLimitedParking(),
    InfoWindow: globalThis.google?.maps?.InfoWindow,
    InfoWindowInstance: globalThis.google?.maps?.InfoWindow !== undefined ? new globalThis.google.maps.InfoWindow() : undefined,
    Channels: [],
    Sessions: [],
    ServerStats: null,
    PageScript: null,
    script: null,
    Settings: ACCCClientSettings,
    Config: null,
    Translations: null,
    Menu: [],
    MenuAjax: [],
    FilterSettings: {},
    AllDevices: [],
    ScanDevices: {},
    ScanDeviceArray: [],
    ScanDeviceIds: {},
    DeviceIdToName: {},
    DetectionUsers: [],
    VerificationUsers: [],
    VerifyDevices: [],
    GpsDevices: [],
    Usergroups: [],
    AreasSRID: {},
    VerificationResults: {},
    ParkingRights: [],
    FineItems: [],
    Gps: null,
    LoginCredentials: null,
    QRImageData: null,
    VehicleTypes: {},
    ParkingRightTypes: {},
    LocationTypes: {},
    GeoSegmentConnections: [],
    ImportError: ImportError,
    baseTitle: document.title,
    STRING: 'String',
    ARRAY: 'Array',
    DeskControl: null,
    AError: AError,
    c: 0,
    msgDict: {}
});
// (s.DeviceId & 0x000000000000ffff) as DeviceType,
// (s.DeviceId & 0x00000000ffff0000) >> 16 as IndexNumber,
// (s.DeviceId & 0x0000ffff00000000) >> 32 as CustomerNumber,
// (s.DeviceId & 0xffff000000000000) >> 48 as ProjectNumber,
// ANameSpaceEnum(  ACCC, NodeType, ControlCenter, ScanAuto, ScanScooter, Pda, BackOffice, ScanBike, ScanSegway, ScanCam, RouteService, ExternalDevice, PolyEditor, CentralVerification );
export const DeviceTypes = [
    'ControlCenter',
    'ScanAuto',
    'ScanScooter',
    'Pda',
    'BackOffice',
    'ScanBike',
    'ScanSegway',
    'ScanCam',
    'RouteService',
    'ExternalDevice',
    'PolyEditor',
    'CentralVerification'
];
export const DeviceTypeOrder = {
    ControlCenter: 0,
    ScanAuto: 1,
    ScanScooter: 2,
    Pda: 3,
    CentralVerification: 4,
    ScanBike: 5,
    ScanSegway: 6,
    ScanCam: 7,
    RouteService: 7,
    BackOffice: 9,
    ExternalDevice: 11,
    PolyEditor: 10,
};
export function DeviceTypeIdToDeviceType(DeviceTypeId) {
    if (DeviceTypeId < 0 && DeviceTypeId >= DeviceTypes.length) {
        return undefined;
    }
    // AEngine.log(`DeviceTypeId %p${DeviceTypeId}%n =>%p ${DeviceTypes[DeviceTypeId]}`)
    return DeviceTypes[DeviceTypeId];
}
export function DeviceTypeToDeviceTypeId(DeviceType) {
    return DeviceTypes.indexOf(DeviceType);
}
export function DeviceNameToDeviceId(DeviceName) {
    let Pos = DeviceName.search(/[0-9]/);
    let DeviceType = DeviceName.substring(0, Pos);
    let IndexNumber = parseInt(DeviceName.substring(Pos));
    let CustomerNumber = parseInt(Settings.NodeCustomerNumber);
    let ProjectNumber = parseInt(Settings.NodeProjectNumber);
    let DeviceTypeId = DeviceTypeToDeviceTypeId(DeviceType) ?? 0;
    return "0x" +
        ProjectNumber.toString(16).padStart(4, '0') +
        CustomerNumber.toString(16).padStart(4, '0') +
        IndexNumber.toString(16).padStart(4, '0') +
        DeviceTypeId.toString(16).padStart(4, '0');
}
async function LoadPageModule({ meta }) {
    let moduleRef = null;
    try {
        const moduleHref = meta.moduleHref;
        moduleRef = Routes[moduleHref];
    }
    catch (err) {
        if (!err.message.startsWith('Failed to fetch dynamically imported module:')) {
            console.error(err);
        }
        else {
            console.warn(err);
        }
    }
    if (moduleRef == null) {
        throw new Error(`backoffice-routes.js doesn't include url "${meta.moduleHref}"`);
    }
    return moduleRef;
}
async function LoadAJAXPage(url, meta, container) {
    AEngine.log(`Loading Route %p"%c${meta.moduleHref}%p"%r`);
    Loading.reset();
    try {
        const moduleRef = await LoadPageModule({ meta }); // throws error
        if (!routeService.isLoginPage(meta)) {
            await validateUserPermission({ url, meta }); // throws error
        }
        await LoadHtmlContent({ container, meta, moduleRef });
        await RunScriptsInContainer(container);
        await RunPolicies();
        const instance = new moduleRef.APage();
        PageScript = instance;
        script = instance;
        Events.tryInvoke(EVENTS.CONSTRUCT);
        if (PageScript.init) {
            Loading.waitForPromises(Promise.resolve().then(_ => PageScript.init())).catch(err => {
                AError.handle(err);
            }).finally(() => {
                const $loadingIndicators = $('.loading-page-init');
                $loadingIndicators.fadeOut(300);
                sleep(300).then(() => $loadingIndicators.remove());
                Events.tryInvoke(EVENTS.PAGE_INITIALIZED);
            });
        }
        else {
            Events.tryInvoke(EVENTS.PAGE_INITIALIZED);
        }
    }
    catch (err) {
        console.error(err);
        container.innerHTML = '';
        routeService.navigateTo(ROUTES.Home, { setBrowserUrl: true });
        return; //routeService.navigateTo(redirectUponError)
    }
}
async function validateUserPermission({ url, meta }) {
    const menuitem = meta.menuItem;
    if (menuitem === undefined) {
        throw new Error(`Page Not Found "${meta.moduleHref}"`);
    }
    const required = [menuitem.id_mr];
    if (menuitem.parent && menuitem.parent.id_mr)
        required.push(menuitem.parent.id_mr);
    if (!permissionService.hasPermission(required)) {
        const message = await Loading.waitForPromises(Translate.get('You are not permitted to view this page!'));
        await new Promise((resolve, reject) => {
            const events = Alerts.show({ title: ALERT_TITLES.Warning, content: message });
            events.on(ALERT_STATUS.ON_MODAL_CLOSED, () => reject(new Error(`No Permissions For Page "${url.hash}"`)));
        });
        return false;
    }
    return true;
}
async function FetchAJAXPageInternal(page) {
    const responseText = await AEngine.fetch(page);
    return responseText;
}
async function LoadHtmlContent({ container, meta, moduleRef }) {
    let html = '';
    let css = '';
    if (moduleRef.render != null) {
        const htmlToTranslate = await Promise.resolve().then(() => moduleRef.render()).then(str => str.trim());
        try {
            // TODO: Maybe cache translated dom
            if (CCCClient.StreamRef.readyState === CCCClient.StreamRef.OPEN) {
                html = await requestService.translateDom(htmlToTranslate);
            }
            html = await InjectTemplates(html);
        }
        catch (err) {
            AError.handle(err);
        }
    }
    else {
        html = await FetchAJAXPageInternal(meta.moduleHref);
    }
    if (moduleRef.css != null) {
        css = moduleRef.css();
    }
    container.innerHTML = css + html;
}
async function InjectTemplates(html) {
    if (html.indexOf('template="') !== -1) {
        const $domTmp = $(`<div></div>`);
        $domTmp.html(html);
        const $templateAttrArr = $domTmp.find('[template]').toArray().map(e => $(e));
        await Promise.all($templateAttrArr.map(async ($ele) => {
            const keyStr = $ele.attr('template');
            if (!/^[0-9]+$/g.test(keyStr)) {
                return html;
            }
            const key = Number(keyStr);
            const renderedTemplate = await templateService.render(key);
            $ele.replaceWith(renderedTemplate);
        }));
        return $domTmp.html();
    }
    return html;
}
async function RunScriptsInContainer(container) {
    let ScriptItems = container.querySelectorAll(`script:not([type="module"])`);
    if (ScriptItems.length) {
        throw new Error(`RunInscriptsInContainer is deprecated!`);
    }
}
async function RunPolicies() {
    const policyService = AEngine.get(APolicyService);
    const policiesResult = await policyService.run();
    if (!policiesResult) {
        console.warn('One or more policies failed!');
    }
}
// TODO: Move logic elsewhere
async function destroyDeskControlIfNeeded() {
    if (!PageScript)
        return;
    if (PageScript.stopVerificationSession) {
        await PageScript.stopVerificationSession();
        return;
    }
    if (PageScript.DeskControl) {
        const { CVSProcess, svo } = PageScript.DeskControl;
        if (CVSProcess) {
            await (CVSProcess.readyToFollowUp ? CVSProcess.stopASync() : Promise.resolve(1));
        }
        if (svo) {
            svo.destroyListeners();
        }
    }
}
export async function LoadPage(url, meta) {
    if (PageScript) {
        await destroyDeskControlIfNeeded();
        await eventService.tryInvoke(EVENTS.DESTRUCT);
        await eventService.tryInvoke(EVENTS.DESTRUCT_DONE);
        PageScript = null;
        script = null;
    }
    return LoadAJAXPage(url, meta, $('#AjaxContent').get(0));
}
export function AfterLogin() {
    return Promise.all([
        Translate.prepare([
            'All', 'None', 'Unknown', 'System',
            'Area', 'Zone', 'Parking Space',
            'Year', 'Week', 'Day', 'Hour',
            'View all', 'View less', 'Show History', 'History',
            'Device', 'User', 'Status', 'last change',
            'Copy Text', 'Show In Popup Window', 'Show on map', 'Center on map',
            'Error', 'Detections', 'parking spaces',
            'Session', 'Disconnected',
            'Something Went Wrong!', 'No Data!',
            'Total', '... Read More',
            'Shortcuts', 'Today', 'Yesterday', 'This Week', 'This Month',
            'Save', 'Load'
        ]),
        adminAlertsService.checkAlerts(),
        mapHelperService.load(MAP_OPTIONS.Area | MAP_OPTIONS.Zone),
        requestService.fetch({
            AssertValues: true,
            Query: (`SELECT ChannelCode, ChannelCode as ChannelName FROM config_channels`),
            Translate: ['ChannelName'],
            Language,
        }).then(res => {
            Channels = res.map(v => v);
        }),
        requestService.query({
            Name: "AllDevices",
            Query: "SELECT DISTINCT DeviceName as K, DeviceName FROM sessionstatus ORDER BY DeviceName",
            Language: Language,
            Translate: ["DeviceName"]
        }).then((Data) => {
            AllDevices = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                AllDevices[Data.Rows[i][0]] = Data.Rows[i][1];
            }
        }),
        requestService.query({
            Name: "ScanDevices",
            Query: "SELECT DISTINCT DetectionDevice as K, DetectionDevice, DetectionDeviceId FROM Detections ORDER BY DetectionDeviceId",
            Language: Language,
            Translate: ["DetectionDevice"]
        }).then((Data) => {
            ScanDevices = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                ScanDevices[Data.Rows[i][0]] = Data.Rows[i][1];
                ScanDeviceIds[Data.Rows[i][0]] = Data.Rows[i][2];
                DeviceIdToName[Data.Rows[i][2]] = Data.Rows[i][0];
            }
            let scanDeviceArr = Data.Rows.map(([deviceKey, deviceName, deviceId]) => {
                return { deviceKey, deviceName, deviceId };
            });
            const getSortName = (deviceKey) => {
                const [nodeType, nodeIndex] = deviceKey.replace(/([0-9]+)/, ' $1').split(' ');
                return (nodeType + nodeIndex.padStart(4, '0'));
            };
            ScanDeviceArray = scanDeviceArr.sort((a, b) => getSortName(a.deviceKey).localeCompare(getSortName(b.deviceKey)));
        }),
        requestService.query({
            Name: "DetectionUser",
            Query: "SELECT DISTINCT DetectionUser FROM Detections ORDER BY DetectionUser",
            Language: Language,
        }).then((Data) => {
            DetectionUsers = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                DetectionUsers.push(Data.Rows[i][0]);
            }
        }),
        requestService.query({
            Name: "VerificationUser",
            Query: "SELECT DISTINCT VerificationUser FROM verifications WHERE VerificationUser IS NOT NULL",
            Language: Language,
        }).then((Data) => {
            VerificationUsers = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                VerificationUsers.push(Data.Rows[i][0]);
            }
        }),
        requestService.query({
            Name: "VerifyDevices",
            Query: "SELECT DISTINCT VerificationDevice as K, VerificationDevice FROM Verifications ORDER BY VerificationDevice",
            Language: Language,
            Translate: ["VerificationDevice"]
        }).then((Data) => {
            VerifyDevices = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                VerifyDevices[Data.Rows[i][0]] = Data.Rows[i][1];
            }
        }),
        requestService.query({
            Name: "GpsDevices",
            Query: "SELECT DISTINCT DeviceName as K,DeviceName FROM Gps where DeviceName IS NOT NULL ORDER BY DeviceName;",
            Language: Language,
            Translate: ["DeviceName"]
        }).then((Data) => {
            GpsDevices = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                GpsDevices[Data.Rows[i][0]] = Data.Rows[i][1];
            }
        }),
        requestService.query({
            Name: "ParkingRights",
            Query: "SELECT DISTINCT ParkingRightType as K, ParkingRightType from parkingrights where ParkingRightType is not null ORDER BY ParkingRightType",
            Language: Language,
            Translate: ["ParkingRightType"]
        }).then((Data) => {
            ParkingRights = [];
            for (let i = 0; i < Data.Rows.length; i++) {
                if (Data.Rows[i][1] != null && Data.Rows[i][1].length > 0) {
                    ParkingRights[Data.Rows[i][0]] = Data.Rows[i][1];
                }
            }
        }),
        requestService.query({
            Name: "VerificationResults",
            Query: "SELECT DISTINCT VerificationResult, VerificationResult as VerificationResultText from verifications where length(VerificationResult)>0 ORDER BY VerificationResultText;",
            Language: Language,
            Translate: ["VerificationResultText"]
        }).then((Data) => {
            VerificationResults = {};
            for (let i = 0; i < Data.Rows.length; i++) {
                VerificationResults[Data.Rows[i][0]] = Data.Rows[i][1];
            }
        }),
        requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`SELECT FromGeoType, ToGeoType FROM geo_connections_timestamps WHERE FromGeoType='Segment'`)
        }).then(ares => {
            GeoSegmentConnections = ares.map(v => v);
        }),
        permissionService.refetchUsergroups(),
        AEngine.get(AValidateUnificationService).validate(),
        // TODO: Remove dbconfig logic
        AConfig.prepareDatabaseConfig().then(async (newConfig) => {
            Config.BackOfficeConfig = newConfig;
            eventService.tryInvoke('UpdateClientName');
            await Promise.all([
                TranslateVehicleTypes(),
                TranslateParkingRightTypes(),
                TranslateLocationTypes()
            ]);
        })
    ]).then(async (_) => {
        // TODO: Remove temp Loading.reset() code and find out why loading icon doesnt go away
        Loading.reset();
        await eventService.tryInvoke(EVENTS.PREFETCH);
        return true;
    }).catch(AError.handle);
}
export async function TranslateVehicleTypes() {
    const toAssign = await Loading.waitForPromises(Translate.get(AConfig.get(`databaseDefinitions.vehicleTypes`, [])));
    if (Object.keys(toAssign).length === 0) {
        throw new Error(`Couldn't load databaseDefinitions from BackOfficeConfig properly`);
    }
    Object.assign(VehicleTypes, toAssign);
}
export async function TranslateParkingRightTypes() {
    const toAssign = await Loading.waitForPromises(Translate.get(AConfig.get(`databaseDefinitions.parkingRightTypes`, [])));
    if (Object.keys(toAssign).length === 0) {
        throw new Error(`Couldn't load databaseDefinitions from BackOfficeConfig properly`);
    }
    Object.assign(ParkingRightTypes, toAssign);
}
export async function TranslateLocationTypes() {
    let locationTypes = AConfig.get(`databaseDefinitions.locationTypes`, []);
    const used = await FilterManager.fetchUsedLocationTypes();
    used.map(({ LocationType }) => {
        if (!locationTypes.includes(LocationType)) {
            locationTypes.push(LocationType);
        }
    });
    locationTypes = AEngine.get(AArrayService).orderedSort(locationTypes, ['Other']).reverse();
    const toAssign = await Loading.waitForPromises(Translate.get(locationTypes));
    if (Object.keys(toAssign).length === 0) {
        throw new Error(`Couldn't load databaseDefinitions from BackOfficeConfig properly`);
    }
    Object.assign(LocationTypes, toAssign);
}
export function EnableItem(Item, options) {
    $("html").css({ 'cursor': 'default' });
    Item.prop('disabled', false);
    Item.trigger('aci-event-enabled', [options || {}]);
}
export function DisableItem(Item, options) {
    Item.prop('disabled', true);
    Item.trigger('aci-event-disabled', [options || {}]);
}
export function HandleStateStream(State) {
    stateService.handleStateStream(State);
}
function HandleSocketError(ErrorCode, ErrorText) {
    const err = new Error(`Unexpected Error Code=${ErrorCode} Error=${ErrorText}`);
    console.error(err);
    HandleStateStream({ Status: "Disconnected", StatusString: "ControlCenter Disconnected" });
}
function HandleComState(State) {
    // TODO: Find out if this is still needed!
    AEngine.log(`ComState->${State}`);
}
function HandleExit() {
    Loading.reset();
    $(".statusbar span").html("Stopped");
    let $container = $('#AjaxContent'); // routeService.navigateTo(ROUTES.Login, { setBrowserUrl: false })
    $container.html(/*html*/ `
    <div class="fh fw">
      <div class="display-once">
        <div class="empty empty-white" style="height: 100%">
          <div class="empty-icon">
            <i class="fa-regular fa-triangle-exclamation fa-6x"></i>
          </div>
          <p class="empty-title h5">Oh no!</p>
          <p class="empty-subtitle">Connection Lost with the server</p>
          <button id="ReloadButton" class="btn btn-primary" onclick="location.reload(true);">Refresh Page</button>
        </div>
      </div>
    </div>
  `);
    // $container.html(/*html*/`
    //   <div style="position:relative;width:100%;height:100%;padding:2.5vw;">
    //     <button id="ReloadButton" class="btn btn-primary" onclick="location.reload(true);">Reload</button>
    //   </div>
    // `)
}
Object.assign(globalThis, {
    HandleExit,
    DeviceTypeIdToDeviceType,
    DeviceTypeToDeviceTypeId,
    DeviceNameToDeviceId,
});
function HandleConfig(ConfigData) {
    Config = Object.assign({}, ConfigData);
    const foundConfig = Config.BackOfficeConfig;
    if (foundConfig) {
        Config.BackOfficeConfig = mergeDeep({}, AConfig.RESET_CONFIG_DEFAULTS, foundConfig);
    }
    else {
        Config.BackOfficeConfig = AConfig.RESET_CONFIG_DEFAULTS;
    }
    Config.ClientBuildInfo = AEngine.get(AJsonService).getFromCache({ url: JSON_FILES.BUILD_INFO });
    eventService.tryInvoke(EVENTS.CONFIG_LOADED, Config);
}
async function UpdateClientName() {
    let ConfigCustomerName = Config.Customer;
    if (Config.Project && Config.Project.length) {
        ConfigCustomerName += " " + await Translate.get(Config.Project);
    }
    const clientName = AConfig.get('general.overrideName') ? AConfig.get('general.name') : ConfigCustomerName;
    $('head title').text(`BackOffice | ${clientName}`);
    $('.clientname').text(clientName);
    $('.clientname').closest('li').removeClass('hidden');
}
export function createEventHandlers() {
    $(window).on('resize', () => {
        eventService.tryInvoke(EVENTS.CONTENT_RESIZE, { caller: 'window' });
    });
    $(window).on('fullscreenchange', () => {
        eventService.tryInvoke(EVENTS.TOGGLE_FULLSCREEN, checkIfFullScreen());
    });
    eventService.hardwire(EVENTS.STATE_CHANGED, async () => {
        const { state, prevState } = stateService;
        let $statusbar = $('.statusbar');
        let statusString = await Translate.get(state.StatusString.length > state.Status.length ? state.StatusString : state.Status);
        $statusbar.find('.fa-circle').toggleClass('fa-beat', state.Status === 'LoggedIn');
        $statusbar.attr('class', `statusbar ${state.Status}`);
        $statusbar.find('span').html(`${statusString}`);
        if (prevState.Status === 'Disconnected' && state.Status == 'Connected') {
            Loading.reset();
            // Show Login page
            routeService.reload();
        }
    });
    eventService.hardwire(`status->Connected`, () => { });
    eventService.hardwire(EVENTS.API_READY, () => { });
    eventService.hardwire(EVENTS.ROUTE_CHANGED, ([url, meta]) => {
        LoadPage(url, meta).then(_ => {
            $('body').toggleClass('login-page-active', routeService.isLoginPage(meta));
        }).catch(AError.handle);
    });
    eventService.hardwire(`Config`, (Data) => {
        return new Promise((resolve, reject) => {
            try {
                HandleConfig(Data);
                resolve();
            }
            catch (err) {
                reject(err);
            }
        });
    });
    eventService.hardwire(`ConfigLoaded`, (Config) => eventService.tryInvoke('UpdateClientName'));
    eventService.hardwire(`AuthenticatorSyncRequest`, (Data) => {
        globalThis.QRImageData = Data["QRImage"];
        // console.log("load authenticator page")
        // LoadPage("/authenticator.html");
        routeService.requireAuthenticator();
    });
    eventService.hardwire(EVENTS.STATE_STREAM, (Data) => {
        const name = `status->${Data.Status}`;
        eventService.tryInvoke(name, Data);
        HandleStateStream(Data);
    });
    eventService.hardwire(`UserInfoStream`, () => { });
    eventService.hardwire(`ServerStatsStream`, (Data) => {
        globalThis.ServerStats = Data;
    });
    eventService.hardwire(EVENTS.SESSION_CHANGE_STREAM, (Data) => {
        let found = false;
        for (let i = 0; i < Sessions.length; i++) {
            if (Sessions[i].NodeName == Data.NodeName) {
                Sessions[i] = Data;
                found = true;
                break;
            }
        }
        if (!found) {
            Sessions.push(Data);
        }
        eventService.tryInvoke('SessionsChanged', Sessions);
    });
    eventService.hardwire(`UpdateClientName`, () => UpdateClientName());
    eventService.hardwire(`LogStream`, (Data) => {
        switch (Data.Level) {
            case "Info":
                console.log(Data.Message);
                break;
            case "Warning":
                console.warn(Data.Message);
                break;
            case "Error":
            default:
                console.error(typeof Data.Message === 'string' ? Data.Message : JSON.stringify(Data.Message));
                break;
        }
    });
    eventService.hardwire(`SessionsInfo`, (Data) => {
        // console.warn('SessionsInfo')
        if (Data.length > 0) {
            Sessions = Data.filter(({ ComState }) => ComState !== 'SessionExpired');
            // console.log('Replacing All...', Data)
        }
        eventService.tryInvoke('SessionsChanged', Sessions);
    });
    eventService.hardwire(`LanguageResponse`, (Data) => {
        Loading.waitForPromises(Object.keys(Data).map((key) => {
            if (Data[key] !== undefined && Data[key][Language] !== undefined) {
                return Translate.insert(key, Data[key][Language].Value);
            }
            return Promise.resolve();
        }));
        // console.log('Setting globalThis.Translations = ', Data)
        globalThis.Translations = Data;
    });
    eventService.hardwire(`QueryResponse`, (Data) => {
        eventService.tryInvoke(`query->${Data.Name}`, Data);
        // if (eventService.hasRoute(name)) {
        //   if (Data.Success) {
        //     eventService.tryInvoke(name, Data)
        //   } else {
        //     console.warn(`error`, Data)
        //     eventService.tryInvoke(name, new Error(Data.Error))
        //   }
        // } else {
        //   const msg = `Received ${name} but it isn't linked to any function!`
        //   console.warn(new Error(msg))
        // }
    });
    eventService.hardwire(`ApiDownloadResponse`, (Data) => {
        eventService.tryInvoke(`ApiDownloadResponse->${Data.Name}`, Data);
    });
    eventService.hardwire(`ApiDownloadRequest`, (Data) => {
        eventService.tryInvoke(`ApiDownloadRequest->${Data.Name}`, Data);
    });
    eventService.hardwire(`ApiAccessTokenResponse`, (Data) => {
        eventService.tryInvoke(`ApiAccessTokenResponse->${Data.Name}`, Data);
    });
    // OpenApiJsonRequest / OpenApiJsonResponse
    eventService.hardwire(`OpenApiJsonResponse`, (Data) => {
        globalThis.apiJson = Data;
    });
    eventService.hardwire(`TranslationResponse`, (Data) => {
        eventService.tryInvoke(`TranslationResponse->${Data.Name}`, Data.Translations);
    });
    eventService.hardwire(`query->Error`, (name, err) => {
        console.warn(`query->Error received:`, name);
        console.warn(err);
        AError.handle(err);
    });
    eventService.hardwire(EVENTS.DESTRUCT, () => {
        if (!PageScript) {
            return;
        }
        if (PageScript.map) {
            purgatoryService.closeInfoWindow('route_changed');
            Object.keys(mapHelperService.cache).map((scale) => {
                const collection = mapHelperService.cache[scale];
                // Keeping the click event listeners for cached scales
                mapHelperService.unload(collection, UNLOAD_OPTIONS.None);
            });
            mapHelperService.destroy(mapHelperService.fetchMarkers());
        }
    });
    eventService.hardwire(EVENTS.DESTRUCT_DONE, () => {
        eventService.clear();
    });
}
function HandleMsg(Msg) {
    eventService.tryInvoke(Msg.Type, Msg.Data);
}
function handleLanguage() {
    const LanguageCodes = jsonService.getFromCache({ url: AJsonService.JSON_FILES.LANGUAGE });
    const allLanguageDivs = [];
    const $searchInput = $(`
    <input id="SearchLanguage" class="form-input" type="text" placeholder="">
  `);
    $searchInput.on('keyup', e => {
        const search = ($searchInput.val() || '').toString().toLowerCase();
        if (search.length === 0) {
            $searchInput.addClass('input-empty');
            allLanguageDivs.map($lang => $lang.show());
            return;
        }
        else {
            $searchInput.removeClass('input-empty');
        }
        const visibleLanguages = allLanguageDivs.filter($lang => {
            const visible = ($lang.data('language').includes(search));
            if (visible) {
                $lang.show();
            }
            else {
                $lang.hide();
            }
            return visible;
        });
        if (e.which == 13) {
            const firstLanguageInList = visibleLanguages.pop();
            if (firstLanguageInList !== undefined) {
                firstLanguageInList.find('input[type=radio]').trigger('click');
            }
        }
    });
    $('#LanguageFrame').append($searchInput);
    $searchInput.after(/*html*/ `<i id="SearchIcon" class="fa-regular fa-magnifying-glass no-select"></i>`);
    $searchInput.trigger('keyup');
    const topLanguagesKV = [
        { key: 'en', value: LanguageCodes['en'] },
        { key: 'nl', value: LanguageCodes['nl'] },
        { key: 'fr', value: LanguageCodes['fr'] },
        { spacer: true }
    ];
    const languages = [].concat(topLanguagesKV);
    const insertedLangs = [];
    $.each(LanguageCodes, function (key, value) { languages.push({ key, value }); });
    languages.map(({ key, value, spacer }) => {
        if (insertedLangs.indexOf(key) !== -1) {
            return;
        }
        insertedLangs.push(key);
        if (!spacer) {
            const attr = Language == key ? 'checked="checked"' : '';
            const $lang = $(/*html*/ `<label class="form-radio"><input type="radio" name="Language" value="${key}" ${attr}><i class="form-icon"></i> ${value}</label>`);
            $lang.data('language', value.toLowerCase());
            allLanguageDivs.push($lang);
            $("#LanguageFrame").append($lang);
        }
        else {
            $("#LanguageFrame").append(/*html*/ `
        <div class="column col-12">
          <div class="divider div-sm text-center" data-content=""></div>
        </div>
      `);
        }
    });
    $("#LanguageFrame input[type=radio]").on('click', function (e) {
        SetCookie("Language", $(this).val(), 366 * 10);
        CCCClient.Enabled = false;
        location.reload();
    });
}
function handleSettings() {
    //Settings.load()
    // const SettingsInfo = jsonService.getFromCache({ url: JSON_FILES.SETTINGS })
    // $.each(SettingsInfo, function (key, info) {
    //   let v = localStorage.getItem(key as string)
    //   Settings[key] = (v !== null) ? v : info.DefaultValue
    // })
}
// Printing background gradient fix
function createPrintHandlers() {
    window.addEventListener("beforeprint", function (ev) {
        $('body').addClass('printing');
    });
    window.addEventListener("afterprint", function (ev) {
        $('body').removeClass('printing');
    });
}
function initHandleStream() {
    return HandleStateStream({ Status: "Disconnected", StatusString: "ControlCenter Disconnected" });
}
async function genSettings() {
    let result = new ACCCClientSettings();
    await result.load();
    result.HandleMsg = HandleMsg;
    result.HandleExit = HandleExit;
    result.HandleComState = HandleComState;
    result.CustomAlert = async (message) => {
        Alerts.show({
            title: ALERT_TITLES.Info,
            content: message
        });
    };
    result.CustomConfirm = async (message, onConfirm, onCancel) => {
        const events = Alerts.show({
            title: ALERT_TITLES.Warning,
            buttons: ALERT_BUTTONS.yesNo,
            content: message.replace('\r\n', '<br>').replace('\n', '<br>'),
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, () => onConfirm());
        events.on(ALERT_STATUS.ON_ACTION_CANCEL, () => {
            if (onCancel) {
                onCancel();
            }
        });
    };
    return result;
}
export async function prerun() {
}
export async function run() {
    const jsonService = AEngine.get(AJsonService);
    Settings = await genSettings();
    createPrintHandlers();
    initHandleStream();
    listenToTableAccordionEvents();
    await jsonService.fetchAll();
    await menuService.manualInit({ translate: false });
    handleLanguage();
    handleSettings();
    Events.tryInvoke(EVENTS.JSONS_LOADED);
    globalThis.Gps = new AGpsDevice({});
}

import { ACrypto } from "../../classes/ACrypto.js";
import { AError } from "../../classes/AError.js";
import { sleep } from "../../core/AEngine.js";
import { AKpiSystem } from "../../kpi/AKpiSystem.js";
import { AKpiOrm } from "../../orm/AKpiOrm.js";
import { EVENTS } from "../../services/AEventService.js";
import { isUserACI } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.kpiOrm = new AKpiOrm();
        this.CurrentView = '';
        this.USE_FAKE_AREAS = false;
        this.USE_FAKE_DATE = false;
    }
    async init() {
        try {
            const allowedUsergroups = [null, ..._.getUser().UserGroups?.map(gr => gr.toLowerCase())];
            const allViews = await widgetService.fetchAllViews();
            const allowedViews = isUserACI() ? allViews : allViews.filter((row) => {
                let viewUserGroups = row.Usergroups?.map(gr => gr?.toLowerCase());
                if (!viewUserGroups || viewUserGroups.length === 0)
                    return true;
                for (let group of allowedUsergroups) {
                    if (viewUserGroups.includes(group)) {
                        return true;
                    }
                }
                return false;
            });
            allowedViews.map(({ View }, i) => {
                const attr = i === 0 ? `selected="selected"` : '';
                $('#KpiView').append(`<option value="${View}" ${attr}>${View}</option>`);
            });
            const defaultView = await this.fetchDefaultView();
            FilterSettings['KpiView'] = defaultView;
            $('#KpiName').val(defaultView);
            // $('#KpiView').on('change', (e) => {
            //   if (this.loopId !== null) {
            //     Loading.waitForPromises(this.refresh()).catch(AError.handle)
            //   }
            // })
            FilterManager.load();
            this.kpiSystem = new AKpiSystem({
                showSubtitle: false,
                getView: () => this.CurrentView,
                overrideSubtitle: () => '',
                filtersFallback: (kpi) => FilterManager.saveExplicit(),
                onWidgetCreated: (widget) => {
                    routeService.reload();
                },
                onWidgetDeleted: (widget) => { },
                isOperationsPage: false,
                $container: $('.kpis .configurable-kpis'),
                $showIfEmpty: $('#show-if-empty-widgets'),
                $btnCreate: $(),
                widgets: []
            });
            Loading.waitForPromises(this.refresh());
            // this.initAutoRefresh()
            $('#BuildVersion').text(Config ? Config.ServerBuildInfo || '-' : '-');
        }
        catch (err) {
            AError.handle(err);
        }
        $('#RefreshButton').on('click', _ => this.refresh());
    }
    async fetchDefaultView() {
        const defaultViews = (await this.kpiOrm.fetchDefaultViews()).toArray();
        const UserGroups = _.getUser().UserGroups;
        let defaultView = 'Default';
        for (const ug of UserGroups) {
            const index = defaultViews.map(dv => dv.UserGroup.toLowerCase()).indexOf(ug);
            if (index !== -1) {
                defaultView = defaultViews[index].DefaultView;
                break;
            }
        }
        return defaultView;
    }
    initAutoRefresh() {
        const $autoRefresh = $('#AutoRefresh');
        $autoRefresh.on('change', _ => {
            if ($autoRefresh.is(':checked')) {
                FilterManager.setActive(false, { silent: true });
                $autoRefresh.removeAttr('disabled');
                this.loopId = ACrypto.randomHexString([0, 0, 0, 0, 0, 0]);
                this.refreshLoop(this.loopId);
            }
            else {
                FilterManager.setActive(true);
                $autoRefresh.removeAttr('disabled');
                this.loopId = null;
            }
        });
    }
    async refreshLoop(loopId) {
        try {
            if ($('#AutoRefresh').is(':checked') && this.loopId === loopId) {
                await this.refresh();
                await sleep(5000);
                this.refreshLoop(loopId);
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    async refresh(opt) {
        const filters = FilterManager.saveExplicit();
        const viewChanged = this.CurrentView.length === 0 || this.CurrentView !== filters.KpiView;
        this.CurrentView = filters.KpiView;
        if (viewChanged) {
            const widgets = opt?.created?.length ? opt.created : await widgetService.fetchActiveWidgets({ View: filters.KpiView });
            await this.kpiSystem.loadAll({
                widgets: widgets,
                clearContainer: true
            });
        }
        else {
            await this.kpiSystem.loadAll({
                widgets: await widgetService.fetchActiveWidgets({ View: filters.KpiView }),
                clearContainer: false
            });
        }
        await Loading.waitForPromises(this.kpiSystem.invokeRefreshAll({ force: true, userInput: false, enableLoadingIndicators: true }, filters));
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
    }
}
export function render() {
    return ( /*html*/`
    <form id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="KpiView">View</label>
          <select class="form-select" id="KpiView" size="5">
          </select>
        </div>
    
        <div class="form-group invis invisible hidden">
          <label class="form-switch">
            <input id="AutoRefresh" type="checkbox">
            <i class="form-icon"></i> Auto Refresh
          </label>
        </div>

      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </form>
    <div id="Rapport" class="flex-child fh">
      <div class="kpis flex-content">
        <div class="panel-p ph -custom-scroll fh" style="overflow-y: auto">
          <div class="columns configurable-kpis" style="padding-bottom: 15px;">
          </div>
          <div id="show-if-empty-widgets" class="columns" style="height: 100%;">
            <div class="column col-3 col-mx-auto text-center " style="align-self: center">
              <i class="fa-regular fa-search fa-7x fa-fw mb-3"></i>
              <p class="empty-title h5 mb-1">This view has no widgets!</p>
            </div>
          </div>

          <div class="columns">
            <div class="column col-12">
              <div class="columns session-kpis kpi-block-list"></div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div id="empty-placeholder" class="hidden">
      <div class="text-center" style="margin: 0 auto;">
        <div class="empty">
          <div class="empty-icon">
            <i class="fa-solid fa-mug-hot fa-4x"></i>
          </div>
          <p class="empty-title h5">There is no data for today!</p>
        </div>
      </div>
    </div>

    <div class="menu-kpi sidebar-popover">
      <ul>
        <li class="kpi-label kpi-label-actions noselect">
          <a href="#" class="noselect">Actions</a>
        </li>
        <li>
          <a href="#">
            <i class="fa-solid fa-expand"></i>
            Fullscreen
          </a>
        </li>
        <li class="kpi-label kpi-label-filters noselect">
          <a href="#" class="noselect">Filters</a>
        </li>
        <li class="kpi-label kpi-label-settings noselect">
          <a href="#" class="noselect">Settings</a>
        </li>
      </ul>
    </div>
  `);
}

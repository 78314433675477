const defaultColors = {
    keyColor: 'dimgray',
    numberColor: 'var(--main-color)',
    stringColor: 'var(--main-color)',
    trueColor: 'var(--main-color)',
    falseColor: 'var(--main-color)',
    nullColor: '#000'
};
const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '`': '&#x60;',
    '=': '&#x3D;'
};
export function escapeHtml(html) {
    return String(html).replace(/[&<>"'`=]/g, function (s) {
        return entityMap[s];
    });
}
function jsonFormat(json, colorOptions = {}) {
    let colors = Object.assign({}, defaultColors, colorOptions);
    json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?)/g, (match) => {
        let color = colors.numberColor;
        let style = '';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                color = colors.keyColor;
            }
            else {
                color = colors.stringColor;
                match = '"' + escapeHtml(match.substr(1, match.length - 2)) + '"';
                style = 'word-wrap:break-word;white-space:pre-wrap;';
            }
        }
        else {
            color = /true/.test(match)
                ? colors.trueColor
                : /false/.test(match)
                    ? colors.falseColor
                    : /null/.test(match)
                        ? colors.nullColor
                        : color;
        }
        return `<span style="${style}color:${color}">${match}</span>`;
    });
}
export function stripError(str) {
    if (str.startsWith('Error: {')) {
        str = str.substring(str.indexOf('Error: {') + ('Error: {').length - 1);
        str = str.substring(0, str.lastIndexOf('}') + 1);
    }
    return str;
}
export function tryJsonFormat(jsonStr, colorOptions = {}) {
    let output = stripError(jsonStr);
    try {
        output = jsonFormat(output, colorOptions);
    }
    catch (err) {
        output = jsonStr;
    }
    return output;
}
function toJsonString(input, opt) {
    const valueType = typeof input;
    if (valueType !== 'string') {
        if (opt?.indendation === undefined) {
            input = JSON.stringify(input) || valueType;
        }
        else {
            input = JSON.stringify(input, null, opt.indendation) || valueType;
        }
    }
    return input;
}
export function embedJsonInHtml(clsName, input, opt) {
    const jsonStr = toJsonString(input, opt);
    const innerHtml = tryJsonFormat(jsonStr, opt)
        // .replace(/([\n\r\s]*[{])/g, '')
        // .replace(/([\n\r\s]*[}][\n\r\s]*)/g, '')
        .replace(/^\w*(\n)+/, '');
    const style = `display: block; white-space: pre-wrap; background-color: #f3f3f3; padding: 8px; border-radius: 7px;`;
    const htmlWrapper = (`<div class="${clsName} fw" style="${style}">${innerHtml}</div>`);
    return { htmlWrapper, innerHtml };
}

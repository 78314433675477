import { AColor } from "../../classes/AColor.js";
import { AConfig } from "../../classes/AConfig.js";
import { AError } from "../../classes/AError.js";
import { AEngine, sleep } from "../../core/AEngine.js";
import { ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { ADomEventService } from "../../services/ADomEventService.js";
import { createMap, createPano } from "../../utils/maps.js";
import { AInputDate, AInputTime, ARound, AShowTable, formatStringToPascal, lerp } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.gridMap = {};
        this.$subSettings = $('#subSettings .columns');
        this.$bryntum = $('#table-bryntum');
        this.translate().catch(AError.handle);
        this.domEventService = AEngine.get(ADomEventService);
    }
    async init() {
        const settings = await Loading.waitForPromises(this.fetchSettings());
        const priority = ["general", "drawing & colors", "filters"];
        const exclude = ["dashboard", "filter-profiles", "infoWindows"];
        const $tabs = $('.aci-tabs');
        await Promise.all([...new Set([...Object.keys(settings), "tableformatter"])].filter(key => !exclude.includes(key)).sort((a, b) => {
            if (priority.includes(a)) {
                return (priority.includes(b)) ? priority.indexOf(a) - priority.indexOf(b) : -1;
            }
            else if (priority.includes(b)) {
                return 1;
            }
            return a.localeCompare(b);
        }).map(async (k, index) => {
            const key = this.convertToKey(k);
            const translated = await Loading.waitForPromises(Translate.get(key.value));
            const $tab = $(`
        <button class="aci-tab" tab="${key.value}">
          <span>${translated}</span>
        </button>
      `);
            $tab.on('click', e => {
                $tabs.find('.aci-tab').toArray().map(e => $(e)).map($ele => {
                    $ele.toggleClass('active', $ele.is($tab));
                });
                Loading.waitForPromises(this.onRowClick(e, key, translated));
            });
            $tabs.append($tab);
            if (index === 0) {
                $tab.trigger('click');
            }
        }));
    }
    traverseJson(json, path) {
        const parts = path.split('.');
        for (let part of parts) {
            json = json[part];
        }
        return json;
    }
    get inputSelector() {
        return `:input[cfg], div.config-grid[cfg]`;
    }
    async translate() {
        const translations = await Loading.waitForPromises(Translate.get([
            "From",
            "To",
            "Preview",
            "Detection Colors",
            "Fill",
            "Stroke",
            "Add",
            "Remove",
            "Fields",
            "Priority Fields",
            "Boolean Fields",
            "Excluded Fields",
            "Amount Of Decimals"
        ]));
        this.formats = {
            colorTransition: ( /*html*/`
        <div class="column col-12">
          <div class="popover">
            {Title} <i class="fa fa-info-circle" aria-hidden="true"></i>
            <div class="popover-container">
              <div class="card">
                <div class="card-body">
                  <img src="/img/screenshots/{Screenshot}.png" style="width: 100%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-{container-width} col-mx-auto percentage-based">
          <div class="columns color-inputs">
            <div class="column col-12">
              <div class="columns">
                <label class="form-label column col-2 col-mr-auto text-center">${translations["From"]}</label>
                <label class="form-label column col-2 col-ml-auto text-center">${translations["To"]}</label>
              </div>
            </div>
            <div class="column col-12">
              <div class="columns">
                <input type="color" cfg="{ConfigColorA}" class="form-input column col-2 col-mr-auto" />
                <input type="color" cfg="{ConfigColorB}" class="form-input column col-2 col-ml-auto" />
              </div>
            </div>
            <div class="column col-12">
              <label class="form-label column col-2 col-mr-auto text-center">${translations["Preview"]}</label>
            </div>
            <div class="column col-12">
              <div class="columns span-percentage">
                <span class="column col-1 text-center">0</span>
                <span class="column col-1 text-center">10</span>
                <span class="column col-1 text-center">20</span>
                <span class="column col-1 text-center">30</span>
                <span class="column col-1 text-center">40</span>
                <span class="column col-2 text-center">50</span>
                <span class="column col-1 text-center">60</span>
                <span class="column col-1 text-center">70</span>
                <span class="column col-1 text-center">80</span>
                <span class="column col-1 text-center">90</span>
                <span class="column col-1 text-center">100</span>
              </div>
            </div>
            <div class="column col-12"><br></div>
            <div class="column col-12"><br></div>
          </div>
        </div>
      `),
            colorTransitionBounds: ( /*html*/`
        <div class="column col-12">
          <div class="popover">
            {Title} <i class="fa fa-info-circle" aria-hidden="true"></i>
            <div class="popover-container">
              <div class="card">
                <div class="card-body">
                  <img src="/img/screenshots/{Screenshot}.png" style="width: 100%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-{container-width} col-mx-auto percentage-based">
          <div class="columns color-inputs">
            <div class="column col-12">
              <div class="columns">
                <label class="form-label column col-2 col-mr-auto text-center">${translations["From"]}</label>
                <label class="form-label column col-2 col-ml-auto text-center">${translations["To"]}</label>
              </div>
            </div>
            <div class="column col-12">
              <div class="columns">
                <input type="color" cfg="{ConfigColorA}" class="form-input column col-2 col-mr-auto" />
                <input type="color" cfg="{ConfigColorB}" class="form-input column col-2 col-ml-auto" />
              </div>
            </div>
            <div class="column col-12">
              <div class="columns">
                <input type="text" cfg="{ConfigBoundA}" class="column col-2 col-mr-auto" />
                <input type="text" cfg="{ConfigBoundB}" class="column col-2 col-ml-auto" />
              </div>
            </div>
            <div class="column col-12">
              <label class="form-label column col-2 col-mr-auto text-center">${translations["Preview"]}</label>
            </div>
            <div class="column col-12">
              <div class="columns span-percentage">
                <span class="column col-1 text-center">0</span>
                <span class="column col-1 text-center">10</span>
                <span class="column col-1 text-center">20</span>
                <span class="column col-1 text-center">30</span>
                <span class="column col-1 text-center">40</span>
                <span class="column col-2 text-center">50</span>
                <span class="column col-1 text-center">60</span>
                <span class="column col-1 text-center">70</span>
                <span class="column col-1 text-center">80</span>
                <span class="column col-1 text-center">90</span>
                <span class="column col-1 text-center">100</span>
              </div>
            </div>
            <div class="column col-12"><br></div>
            <div class="column col-12"><br></div>
          </div>
        </div>
      `),
            colorTransitionBoundsAbsolute: ( /*html*/`
        <div class="column col-12">
          <div class="popover">
            {Title} <i class="fa fa-info-circle" aria-hidden="true"></i>
            <div class="popover-container">
              <div class="card">
                <div class="card-body">
                  <img src="/img/screenshots/{Screenshot}.png" style="width: 100%">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-{container-width} col-mx-auto">
          <div class="columns color-inputs">
            <div class="column col-12">
              <div class="columns">
                <label class="form-label column col-2 col-mr-auto text-center">${translations["From"]}</label>
                <label class="form-label column col-2 col-ml-auto text-center">${translations["To"]}</label>
              </div>
            </div>
            <div class="column col-12">
              <div class="columns">
                <input type="color" cfg="{ConfigColorA}" class="form-input column col-2 col-mr-auto" />
                <input type="color" cfg="{ConfigColorB}" class="form-input column col-2 col-ml-auto" />
              </div>
            </div>
            <div class="column col-12">
              <div class="columns">
                <input type="text" cfg="{ConfigBoundA}" class="column col-2 col-mr-auto" />
                <input type="text" cfg="{ConfigBoundB}" class="column col-2 col-ml-auto" />
              </div>
            </div>
            <div class="column col-12">
              <label class="form-label column col-2 col-mr-auto text-center">${translations["Preview"]}</label>
            </div>
            <div class="column col-12">
              <div class="columns color-steps">
                <span class="column col-1 text-center">0</span>
                <span class="column col-1 text-center">10</span>
                <span class="column col-1 text-center">20</span>
                <span class="column col-1 text-center">30</span>
                <span class="column col-1 text-center">40</span>
                <span class="column col-2 text-center">50</span>
                <span class="column col-1 text-center">60</span>
                <span class="column col-1 text-center">70</span>
                <span class="column col-1 text-center">80</span>
                <span class="column col-1 text-center">90</span>
                <span class="column col-1 text-center">100</span>
              </div>
            </div>
            <div class="column col-12"><br></div>
            <div class="column col-12"><br></div>
          </div>
        </div>
      `),
            tableformatter: ( /*html*/`
        <div class="column col-12">
          <label class="form-label" for="S_IW_{key}">{Title}</label>
        </div>
        <div class="column col-12 config-grid-parent">
          <div class="columns">
            <div class="column col-11 col-mx-auto">
              <div class="form-group" style="margin-bottom: 0;">
                <div type="OBJECT" size="8" class="config-grid" id="S_IW_{key}" cfg="{key}.definitions" cfg-order="{key}.order">
                </div>
              </div>
              <div class="input-group">
                <input class="form-input config-grid-input" type="text" placeholder="...">
                <button class="btn btn-primary input-group-btn config-grid-insert">
                  <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
                  ${translations['Add']}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="column col-12"><br></div>
      `),
            amountOfDecimals: ( /*html*/`
        <div class="columns">
          <div class="column col-3">
            <label class="form-label" for="S_IW4_{key}_{keyDecimal}">{keyDecimal}</label>
          </div>
          <div class="column col-9 col-mr-auto">
            <input class="form-input" id="S_IW4_{key}_{keyDecimal}" cfg="{key}.amountOfDecimals.{keyDecimal}" type="number" placeholder="3" pattern="[0-9-.]+$">
          </div>
        </div>
      `),
            colorDetections: ( /*html*/`
        <div class="column col-12">
          <label class="form-label" for="S_StrokeColor">${translations['Detection Colors']}</label>
        </div>
        <div class="column col-11 col-mx-auto">
          <div class="columns">
            <div class="column col-8 col-ml-auto">
              <div class="columns">
                <div class="column col-6 text-center" >
                  <label class="form-label">${translations['Fill']}</label>
                </div>
                <div class="column col-6 text-center">
                  <label class="form-label">${translations['Stroke']}</label>
                </div>
              </div>
            </div>
            {Inputs}
          </div>
        </div>
      `),
            colorDetection: ( /*html*/`
        <div class="column col-12">
          <div class="columns">
            <div class="column col-4">
              <label class="form-label">{Title}</label>
            </div>
            <div class="column col-4">
              <input class="form-input" type="color" cfg="{ConfigFill}" style="{style1}">
            </div>
            <div class="column col-4">
              <input class="form-input" type="color" cfg="{ConfigStroke}" style="{style2}">
            </div>
          </div>
        </div>
      `)
        };
    }
    get $settingsBody() {
        return this.$subSettings.find('.toplevel > .columns');
    }
    async fetchSettings() {
        const output = {};
        const settingsres = await requestService.query({
            Query: (`SELECT settingid, value, defaultvalue FROM backoffice_config`)
        });
        const excluded = ['thematicMaps'];
        settingsres.Rows.map(row => {
            const [sid, val, defaultval] = row;
            if (!excluded.includes(sid)) {
                output[sid] = Object.assign({}, val);
            }
        });
        return output;
        // const [sid, val, defaultval] = settingsres.Rows.pop()
        // return Object.assign({}, val)
    }
    convertToKey(k) {
        return {
            value: k,
            formatted: this.formatKey(k)
        };
    }
    isObject(val) {
        if (val === null)
            return false;
        return ((typeof val === 'function') || (typeof val === 'object'));
    }
    calcInputSize(container) {
        const { attributes } = container;
        if (attributes && attributes.width) {
            return attributes.width;
        }
        return 12;
    }
    format(format, params) {
        let output = format;
        Object.keys(params).map(key => {
            output = output.replace(new RegExp(`{${key}}`, 'g'), params[key]);
        });
        return output.replace(new RegExp(`{container-width}`, 'g'));
    }
    formatKey(settingsKey) {
        if (!settingsKey || settingsKey.length === 0) {
            return settingsKey;
        }
        const temp = settingsKey.replace(/([A-Z])/g, ' $1').trim();
        return temp[0].toUpperCase() + temp.substr(1);
    }
    getVal($input) {
        if ($input.is('select')) {
            return this.selectToArray($input);
        }
        else if ($input.is('[type=checkbox]')) {
            return $input.get(0).checked;
        }
        else if ($input.is('[type=OBJECT][cfg]')) {
            const gridId = $input.find('.cfg-bryntum-table').attr('id');
            const storeRecords = PageScript.gridMap[gridId].store.records;
            const order = storeRecords.map(({ column, isPriority, type, custom }) => column);
            const definitions = {};
            storeRecords.map(({ column, isPriority, type, custom }) => {
                definitions[column] = { isPriority, type, data: custom };
            });
            return { order, definitions };
        }
        else {
            return $input.attr('cfg-type') === 'number' ? Number($input.val()) : $input.val();
        }
    }
    tryMoveRecordUp({ grid, record }) {
        const offset = -1;
        return this.tryMoveRecord({
            grid,
            record,
            offset
        });
    }
    tryMoveRecordDown({ grid, record }) {
        const offset = +1;
        return this.tryMoveRecord({
            grid,
            record,
            offset
        });
    }
    tryMoveRecord({ grid, record, offset }) {
        let foundIndex = -1;
        const foundRecord = grid.store.find((r, index) => {
            if (r === record) {
                foundIndex = index;
                return true;
            }
            return false;
        });
        try {
            if (!foundRecord) {
                throw new Error(`Couldn't find record "${JSON.stringify(record)}"`);
            }
            return this.flipRows(grid, foundIndex, foundIndex + offset);
        }
        catch (err) {
            AError.handle(err);
            return false;
        }
    }
    flipRows(grid, rowIndexA, rowIndexB) {
        const indexes = [rowIndexA, rowIndexB];
        const STORE_SIZE = grid.store.records.length;
        for (const index of indexes) {
            if (rowIndexA < 0 || rowIndexA >= STORE_SIZE) {
                console.warn(`indexA ${rowIndexA} is out of bounds for grid.store ARRAY(${STORE_SIZE})`);
                return false;
            }
            if (rowIndexB < 0 || rowIndexB >= STORE_SIZE) {
                console.warn(`indexB ${rowIndexB} is out of bounds for grid.store ARRAY(${STORE_SIZE})`);
                return false;
            }
        }
        const { records } = grid.store;
        const temp = records[rowIndexA];
        records[rowIndexA] = records[rowIndexB];
        records[rowIndexB] = temp;
        grid.refreshRows(false);
        return true;
    }
    copyArrayOrder(arrayToSort, sortedArray, compareCallback) {
        if (compareCallback === undefined) {
            compareCallback = (arrayToSortItem, sortedArrayItem) => {
                return arrayToSortItem == sortedArrayItem;
            };
        }
        const result = [];
        sortedArray.forEach(function (key) {
            var found = false;
            arrayToSort = arrayToSort.filter(function (item) {
                if (!found && compareCallback(item, key) == true) {
                    result.push(item);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });
        return result.concat(arrayToSort);
    }
    selectToObject($select, json, cfgPath) {
        const cfgOrderPath = $select.attr('cfg-order');
        const order = this.traverseJson(json, cfgOrderPath);
        this.traverseJson(json, cfgPath);
        const output = {};
        const options = this.copyArrayOrder($select.find('option').toArray(), order, (toSort, sorted) => {
            return $(toSort).attr('key') == sorted;
        });
        options.map(option => {
            const $option = $(option);
            const key = $option.attr('key');
            const type = $option.attr('type');
            const isPriority = $option.attr('isPriority');
            output[key] = { type, isPriority };
        });
        return output;
    }
    selectToArray($select) {
        return $select.find('option').get().map(e => e.innerText);
    }
    generateCustomColumn(dataType, column, record, redraw = () => { }) {
        const typeRouter = {
            'DATETIME': (dataType, column, record, redraw) => {
                const items = [];
                const value = record.custom && typeof record.custom === 'string' ? record.custom : null;
                // @ts-ignore
                const input = new bryntum.grid.Combo({
                    items,
                    value,
                    onChange: ({ userAction, value }) => {
                        if (userAction) {
                            throw new Error(`deprecated`);
                            // @ts-ignore
                            const gridId = $input.find('.cfg-bryntum-table').attr('id');
                            const toChange = PageScript.gridMap[gridId].store.find((obj) => obj.column === record.column);
                            toChange.custom = value;
                            redraw();
                        }
                    }
                });
                return input;
            },
            'NUMBER': () => {
                const items = [];
                const value = record.custom && typeof record.custom === 'string' ? record.custom : null;
                // @ts-ignore
                const input = new bryntum.grid.Combo({ items, value });
                return input;
            },
            'CUSTOM': () => {
                const items = [];
                const value = record.custom && typeof record.custom === 'string' ? record.custom : null;
                // @ts-ignore
                const input = new bryntum.grid.Combo({ items, value });
                return input;
            }
        };
        if (typeRouter.hasOwnProperty(dataType)) {
            const widget = typeRouter[dataType](dataType, column, record, redraw);
            return {
                widget,
                element: widget.element
            };
        }
        else {
            return { element: '...' };
        }
    }
    async onRowClick(e, key, translatedKey) {
        const { $subSettings } = this;
        // Translate button text
        const translations = await Loading.waitForPromises(Translate.get(['Apply Changes', 'Revert To Defaults']));
        const htmlParts = [];
        htmlParts.push(`<h2 class="column col-12">${translatedKey}</h2>`);
        htmlParts.push(`<div class="column col-12 divider"></div>`);
        // Fetch settings from mysql
        const settings = await Loading.waitForPromises(this.fetchSettings());
        const json = settings[key.value];
        console.log('json ', json);
        // Create html elements
        const created = await Loading.waitForPromises(this.createSettingsElements(key, json));
        // Get settings for specific row
        const { html, init, afterInit } = created;
        htmlParts.push(html || created);
        htmlParts.push(`<div class="column col-12"><br></div>`);
        htmlParts.push(`<code class="column col-12 col-mx-auto hidden">${JSON.stringify(json)}</code>`);
        htmlParts.push(`<div class="column col-12"><br></div>`);
        htmlParts.push(`
      <div class="column col-12 divider"></div>
      <div class="column col-12">
        <div class="columns">
          <button id="Reset" class="btn btn-secondary col-ml-auto">${translations['Revert To Defaults']}</button>
          <button id="Apply" class="btn btn-primary" disabled>${translations['Apply Changes']}</button>
        </div>
      </div>
    `);
        htmlParts.push(`<div class="column col-12"><br></div>`);
        const cache = {};
        PageScript.__cache = cache;
        console.log('cache ', cache);
        const $newElement = $(`<div class="toplevel column col-7 col-mx-auto"><br/><div class="columns">${htmlParts.join('')}</div></div>`);
        // Find and define inputs & default values
        $newElement.find('[cfg]').each((i, ele) => {
            let $ele = $(ele);
            let value = json;
            const cfgPath = $ele.attr('cfg');
            try {
                let hadMissingParts = false;
                cfgPath.split('.').map(p => {
                    if (!value.hasOwnProperty(p)) {
                        hadMissingParts = true;
                        const parsed = parseInt(p);
                        if (isNaN(parsed)) {
                            value[p] = {};
                        }
                        else {
                            value[p] = [];
                        }
                    }
                    return value = value[p];
                });
                if (hadMissingParts) {
                    const val = AConfig.get(`${key.value}.${cfgPath}`);
                    value = val;
                    cache[cfgPath] = null;
                }
            }
            catch (err) {
                console.warn(err);
                $ele.closest('.form-group').addClass('has-error');
                value = null;
                cache[cfgPath] = null;
            }
            if ($ele.is('select')) {
                if (value instanceof Array) {
                    value.map(v => $ele.append(`<option>${v}</option`));
                }
                const $inputGroup = $ele.parent().parent().find('.input-group');
                const $textfield = $inputGroup.find('input[type="text"]');
                const $btnAdd = $inputGroup.find('button.btn-primary');
                const $btnRmv = $inputGroup.find('button.btn-error');
                const $btnUpDown = $inputGroup.find('button.btn-secondary');
                $btnAdd.on('click', e => {
                    const val = $textfield.val();
                    const regex = /^\s*$/;
                    if (regex.test(val)) {
                        Alerts.show({ title: ALERT_TITLES.Error, content: 'Please fill in the textfield!' });
                        return;
                    }
                    $ele.append(`<option>${val}</option>`);
                    console.warn('TODO: Implement config mutation');
                    $textfield.val('');
                });
                $btnRmv.on('click', e => {
                    const $selected = $ele.find(':selected');
                    if ($selected.length === 0) {
                        Alerts.show({
                            title: ALERT_TITLES.Error,
                            content: 'Please select a list item!'
                        });
                        return;
                    }
                    // const text = $selected.text()
                    // const val = $selected.val()
                    console.warn('TODO: Implement config mutation');
                    $selected.remove();
                });
                $btnUpDown.on('click', e => {
                    const $btn = $(e.target).is('.btn') ? $(e.target) : $(e.target).closest('.btn');
                    const action = $btn.attr('action');
                    const $selected = $ele.find(':selected');
                    if ($selected.length === 0) {
                        Alerts.show({ title: ALERT_TITLES.Warning, content: 'Please select a list item!' });
                        return;
                    }
                    if (action === 'up') {
                        $selected.prev().insertAfter($selected);
                    }
                    else {
                        $selected.next().insertBefore($selected);
                    }
                });
            }
            else if ($ele.is('.config-grid')) {
                const unsorted = Object.keys(value || {});
                const cfgOrderPath = $ele.attr('cfg-order');
                const orderArray = this.traverseJson(json, cfgOrderPath) || [];
                const sortedKeys = this.copyArrayOrder(unsorted, orderArray, (unsortedItem, sortedItem) => {
                    return unsortedItem == sortedItem;
                });
                let redrawCallback = () => { };
                const generatedId = Date.now().toString(16);
                this.domEventService.add(generatedId, $(`<div id="${generatedId}" class="cfg-bryntum-table height-500"></div>`));
                const columns = [
                    {
                        field: 'isPriority',
                        text: 'Is Priority',
                        type: 'widget',
                        widgets: [{
                                type: 'checkbox',
                                field: 'Checked',
                                onAction: (data) => {
                                    if (data.userAction) {
                                        const { record } = data.source.cellInfo;
                                        record.Checked = record.Checked = !record.Checked;
                                    }
                                }
                            }]
                    },
                    {
                        field: 'column',
                        text: 'Column'
                    },
                    {
                        field: 'type',
                        text: 'Type',
                        type: 'widget',
                        cellCls: 'np-i',
                        renderer(abc) {
                            const { cellElement, value } = abc;
                            const toRemove = [];
                            cellElement.classList.forEach((item) => {
                                if (item.startsWith('combo-'))
                                    toRemove.push(item);
                            });
                            toRemove.map(className => cellElement.classList.remove(className));
                            cellElement.classList.add(`combo-${value}`);
                        },
                        widgets: [{
                                type: 'combo',
                                editable: false,
                                items: ['TEXT', 'BOOLEAN', 'OBJECT', 'ARRAY', 'DATETIME', 'TIME', 'NUMBER', 'CUSTOM'],
                                onChange: (abc) => {
                                    const { userAction, value, source, column } = abc;
                                    const { cellInfo } = source;
                                    const toRemove = [];
                                    cellInfo.cellElement.classList.forEach((item) => {
                                        if (item.startsWith('combo-'))
                                            toRemove.push(item);
                                    });
                                    toRemove.map(className => cellInfo.cellElement.classList.remove(className));
                                    cellInfo.cellElement.classList.add(`combo-${value}`);
                                    if (userAction) {
                                        PageScript.gridMap[generatedId].store.find((obj) => obj.column === cellInfo.record.column).type = value;
                                        redrawCallback();
                                    }
                                }
                            }]
                    },
                    {
                        field: 'custom',
                        text: 'Custom',
                        type: 'widget',
                        cellCls: 'np-i',
                        renderer({ record, widgets }) {
                            widgets.map(widget => widget.hidden = true);
                            const widgetsOrder = ['NUMBER', 'DATETIME', 'CUSTOM'];
                            const widgetIndex = widgetsOrder.indexOf(record.type);
                            if (widgetIndex !== -1) {
                                const widget = widgets[widgetIndex];
                                widget.hidden = false;
                                const allowedItems = widget.items.map(i => i.value);
                                if (allowedItems.indexOf(record.custom) === -1) {
                                    widget.value = '';
                                }
                            }
                        },
                        widgets: [{
                                type: 'combo',
                                editable: false,
                                items: ['DONT ROUND', 'ROUND 1 DECIMAL', 'ROUND 2 DECIMALS', 'ROUND 3 DECIMALS', 'ROUND 4 DECIMALS'],
                                onChange: ({ userAction, value, source }) => {
                                    const { cellInfo } = source;
                                    if (userAction) {
                                        console.log('change 1!', value);
                                        PageScript.gridMap[generatedId].store.find((obj) => obj.column === cellInfo.record.column).custom = value;
                                    }
                                }
                            }, {
                                type: 'combo',
                                editable: false,
                                items: ['YYYY-MM-DD HH:MM:SS'],
                                onChange: ({ userAction, value, source }) => {
                                    const { cellInfo } = source;
                                    if (userAction) {
                                        console.log('change 2!', value);
                                        PageScript.gridMap[generatedId].store.find((obj) => obj.column === cellInfo.record.column).custom = value;
                                    }
                                }
                            }, {
                                type: 'combo',
                                editable: false,
                                items: ['generateLabelField'],
                                hidden: true,
                                onChange: ({ userAction, value, source }) => {
                                    const { cellInfo } = source;
                                    if (userAction) {
                                        console.log('change 3!', value);
                                        PageScript.gridMap[generatedId].store.find((obj) => obj.column === cellInfo.record.column).custom = value;
                                    }
                                }
                            }]
                    },
                    {
                        field: 'controls',
                        type: 'action',
                        actions: [{
                                cls: 'fa fa-chevron-up',
                                tooltip: 'Move Up',
                                onClick: ({ record }) => {
                                    // Move up
                                    const { grid } = PageScript;
                                    this.tryMoveRecordUp({ grid, record });
                                }
                            }, {
                                cls: 'fa fa-chevron-down',
                                tooltip: 'Move Down',
                                onClick: ({ record }) => {
                                    // Move down
                                    const { grid } = PageScript;
                                    this.tryMoveRecordDown({ grid, record });
                                }
                            }]
                    }
                ].map((item, i, array) => {
                    return Object.assign(item, {
                        width: ARound(100 / array.length, 2) + '%'
                    });
                });
                const grid = AShowTable({
                    appendTo: generatedId,
                    columns,
                    data: sortedKeys.map(key => {
                        const { type, isPriority, data } = value[key];
                        return {
                            column: key,
                            type,
                            isPriority,
                            custom: data,
                            controls: ['Up', 'Down']
                        };
                    })
                });
                // @ts-ignore
                redrawCallback = () => grid.refreshRows(false);
                PageScript.gridMap[generatedId] = grid;
                const $grid = this.domEventService.remove(generatedId);
                $ele.append($grid);
            }
            else if ($ele.is('[type=checkbox]')) {
                const $inputs = this.getToggleChildren($newElement, $ele);
                const onChange = (e) => {
                    const { checked } = e.target;
                    if (checked) {
                        $inputs.map($input => $input.removeAttr('disabled'));
                    }
                    else {
                        $inputs.map($input => $input.attr('disabled', 'disabled'));
                    }
                };
                $ele.prop('checked', value);
                $ele.on('change', e => onChange(e));
                onChange({ target: $ele.get(0) });
            }
            else if ($ele.is('[type=date]')) {
                console.log(value);
                $ele.val(value);
            }
            else if ($ele.is('[type=time]')) {
                console.log(value);
                $ele.val(value);
            }
            else {
                $ele.get(0).value = value;
                $ele.attr('value', value);
            }
        });
        if (init)
            init($newElement);
        // Initialize inputs
        $newElement.find(this.inputSelector).each((i, input) => {
            const $input = $(input);
            const cfgPath = $input.attr('cfg');
            // let changed = false
            if ($input.is('.config-grid[type="OBJECT"]')) {
                const cfgOrderPath = $input.attr('cfg-order');
                cache[cfgPath] = this.traverseJson(json, cfgPath);
                cache[cfgOrderPath] = this.traverseJson(json, cfgOrderPath);
                $input.on('DOMSubtreeModified', () => {
                    // const defAndOrder = this.getVal($input)
                    // console.log(defAndOrder)
                    $('#Apply').removeAttr('disabled');
                });
            }
            else if ($input.is('select')) {
                cache[cfgPath] = this.selectToArray($input);
                $input.on('DOMSubtreeModified', () => {
                    const arr = this.selectToArray($input);
                    if (JSON.stringify(cache[cfgPath]) !== JSON.stringify(arr)) {
                        $('#Apply').removeAttr('disabled');
                    }
                });
            }
            else if ($input.is('[type=checkbox]')) {
                cache[cfgPath] = $input.get(0).checked;
                $input.on('change', e => {
                    const { checked } = $input.get(0);
                    if (cache[cfgPath] !== checked) {
                        $('#Apply').removeAttr('disabled');
                    }
                });
            }
            else if ($input.is('[type=date]') || $input.is('[type=time]')) {
                cache[cfgPath] = $input.val();
                $input.on('change', e => {
                    if (cache[cfgPath] !== $input.val()) {
                        $('#Apply').removeAttr('disabled');
                    }
                });
            }
            else if ($input.is('[type="color"]')) {
                cache[cfgPath] = $input.val();
                $input.on('input change', (e) => {
                    const a = cache[cfgPath];
                    const b = $input.val();
                    if (a !== b) {
                        $('#Apply').removeAttr('disabled');
                    }
                });
            }
            else {
                cache[cfgPath] = $input.val();
                $input.on('keyup', e => {
                    const a = cache[cfgPath];
                    const b = $input.val();
                    if (a !== b) {
                        $('#Apply').removeAttr('disabled');
                    }
                });
            }
        });
        $subSettings.html('');
        $subSettings.append($newElement);
        if (afterInit)
            afterInit($newElement);
        $('#Apply').on('click', e => {
            return Loading.waitForPromises(this.applyBtnClick(e, key, cache, $newElement).catch(AError.handle));
        });
        $('#Reset').on('click', _ => {
            const defaults = AConfig.RESET_CONFIG_DEFAULTS[key.value];
            return Loading.waitForPromises([
                requestService.query({
                    Query: (`UPDATE backoffice_config SET \`Value\`=:NewValue  WHERE SettingId=:Key`),
                    Params: {
                        Key: key.value,
                        NewValue: defaults
                    }
                }).then(() => {
                    AConfig.updateLocal(key.value, defaults);
                }),
                sleep(400)
            ]).then(_ => {
                this.onRowClick(null, key, translatedKey);
            }).catch(AError.handle);
        });
    }
    async applyBtnClick(e, key, cache, $newElement) {
        const settings = await Loading.waitForPromises(this.fetchSettings());
        const json = settings[key.value];
        let changes = 0;
        let errors = [];
        $newElement.find(this.inputSelector).get().map(ele => {
            const $input = $(ele);
            const cfgPath = $input.attr('cfg');
            const potentialNewVal = this.getVal($input);
            // If is object
            if ($input.is('.config-grid')) {
                const cfgOrderPath = $input.attr('cfg-order');
                if (potentialNewVal === Object(potentialNewVal)) {
                    try {
                        const objectKeys = Object.keys(potentialNewVal);
                        const { order, definitions } = potentialNewVal;
                        if (!order || !definitions) {
                            throw new Error(`config-grid error!`);
                        }
                        const [succes1, success2] = [
                            this.editJson(json, cfgPath, definitions),
                            this.editJson(json, cfgOrderPath, order)
                        ];
                        if (!succes1 || !success2) {
                            throw new Error(`couldn't edit config-grid json!`);
                        }
                    }
                    catch (err) {
                        errors.push(err);
                    }
                    changes++;
                }
            }
            else {
                const equals = (a, b) => JSON.stringify(a) === JSON.stringify(b);
                if (cache[cfgPath] !== potentialNewVal || !equals((cache[cfgPath]), potentialNewVal)) {
                    try {
                        const success = this.editJson(json, cfgPath, potentialNewVal);
                        if (success) {
                            cache[cfgPath] = potentialNewVal;
                        }
                        else {
                            // Make path or someshit idk...
                        }
                    }
                    catch (err) {
                        errors.push(err);
                    }
                    changes++;
                }
            }
        });
        if (errors.length > 0) {
            AError.handle(errors.pop());
        }
        if (changes) {
            AEngine.log(`Saving ${changes} amount of changed in the config!`);
            await Loading.waitForPromises([
                requestService.query({
                    Query: (`UPDATE backoffice_config SET \`Value\`=:NewValue WHERE SettingId=:Key`),
                    Params: {
                        Key: key.value,
                        NewValue: json
                    }
                }).then(() => {
                    AConfig.updateLocal(key.value, json);
                }),
                sleep(400)
            ]);
            $('#Apply').attr('disabled', 'disabled');
            eventService.tryInvoke('UpdateClientName');
        }
        return changes > 0;
    }
    editJson(json, cfgPath, newValue) {
        let ref = json;
        const path = cfgPath.split('.');
        let i = 0;
        for (i = 0; i < path.length - 1; i++) {
            const key = path[i];
            const nextKey = path[i + 1];
            if (!ref.hasOwnProperty(key)) {
                const parsed = parseInt(nextKey);
                if (isNaN(parsed)) {
                    ref[key] = {};
                }
                else {
                    ref[key] = [];
                    for (let x = 0; x < parsed; x++) {
                        ref[key].push(null);
                    }
                }
            }
            ref = ref[key];
        }
        const lastKey = path[path.length - 1];
        ref[lastKey] = newValue;
        return true;
    }
    initColorTransitions($parent) {
        const $inputs = $parent.find('.color-inputs').find('[type="color"], [type="text"]');
        $inputs.on('change input', (e) => {
            // console.log('color transition change ', e)
            const $clicked = $(e.target);
            const percentageBased = $clicked.closest('.percentage-based').length > 0;
            const $parent = $clicked.closest('.color-inputs');
            const $colors = $parent.find('[type="color"]');
            const $bounds = $parent.find('[type="text"]');
            const $spans = $parent.find('span');
            const [input1, input2] = [$colors.eq(0).val(), $colors.eq(1).val()];
            let [input3, input4] = [$bounds.eq(0).val(), $bounds.eq(1).val()];
            if ($bounds.length === 0) {
                input3 = 0;
                input4 = 100;
            }
            const [start, end] = [new AColor(input1).hsv, new AColor(input2).hsv];
            const [minBound, maxBound] = [parseFloat(input3) / 100.0, parseFloat(input4) / 100.0];
            if (!percentageBased) {
                $spans.each((i, ele) => {
                    $(ele).text(ARound(lerp(minBound, maxBound, i / ($spans.length - 1)) * 100, 2));
                });
            }
            $spans.each((i, ele) => {
                const value = i / ($spans.length - 1);
                $(ele).attr('percentage', value);
                let t = value;
                if (percentageBased) {
                    t = (value - minBound) / (maxBound - minBound);
                }
                if (t < 0)
                    t = 0;
                else if (t > 1)
                    t = 1;
                const clr = interpolateService.colorHSV(start, end, t);
                // console.log(i, clr)
                $(ele).css({ "background-color": clr.rgb.hexi });
            });
        });
        $inputs.change();
    }
    getToggleChildren($parent, $ele) {
        let $inputs = [];
        const childrenInputSelectors = $ele.attr('toggle');
        if (childrenInputSelectors) {
            if (!childrenInputSelectors.startsWith('[') || !childrenInputSelectors.endsWith(']')) {
                AError.handle(`[toggle] attribute doesn't contain array square brackets`);
            }
            else {
                $inputs = childrenInputSelectors
                    .substr(1, childrenInputSelectors.length - 2)
                    .split(',').map(selector => $parent.find(selector.trim()));
            }
        }
        return $inputs;
    }
    createSettingsElements(key, json) {
        const routes = {
            'general': async (a) => {
                return {
                    html: ( /*html*/`
            <div class="column col-12">
              <div class="form-group">
                <label class="form-checkbox checkbox-left">
                  <input type="checkbox" id="S_OverrideClient" toggle="[#S_ClientName]" cfg="overrideName">
                  <i class="form-icon"></i>
                </label>
                <label class="form-label" for="S_OverrideClient">Override Client Name</label>
              </div>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="form-group">
                <label class="form-label" for="S_ClientName">Client Name</label>
                <input class="form-input" id="S_ClientName" type="text" placeholder="Client Name" cfg="name">
              </div>
            </div>
            
            <div class="column col-12 col-mx-auto divider"></div>

            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_PasswordPolicy" cfg="security.useStrictPasswordPolicy">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_PasswordPolicy">Use Strict Password Policy (Minimum of 10 characters)</label>
            </div>
            
            <div class="column col-12 col-mx-auto divider"></div>
            
            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_CenterOnClick" cfg="map.centerOnClick">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_CenterOnClick">Center Map On Marker Click</label>
            </div>
            
            <div class="column col-12 col-mx-auto divider"></div>
            
            <div class="column col-12">
              <label class="form-label" for="S_MinEnforcingForOccupancy">Min Enforcing For Occupancy</label>
              <input class="form-input" id="S_MinEnforcingForOccupancy" type="text" placeholder="Client Name"
                cfg="minEnforcingForOccupancy">
              <i class="form-icon"></i>
              </label>
            </div>
            
            <div class="column col-12 col-mx-auto divider"></div>
            
            <div class="column col-12">
              <div class="form-group">
                <label class="form-label" for="S_Language">Table Library Language</label>
                <input class="form-input" id="S_Language" type="text" placeholder="Language" cfg="language">
              </div>
            </div>
            
            <div class="column col-12"><br></div>
            
            <div class="column col-12">
              <label class="form-label" for="S_Latitude">Map Location</label>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="columns">
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_Latitude">Latitude</label>
                    <input class="form-input" id="S_Latitude" type="text" placeholder="Latitude" cfg="map.pos.lat"
                      cfg-type="number">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_Longitude">Longitude</label>
                    <input class="form-input" id="S_Longitude" type="text" placeholder="Longitude" cfg="map.pos.lng"
                      cfg-type="number">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_MapZoom">Zoom</label>
                    <input class="form-input" id="S_MapZoom" type="text" placeholder="15.0" cfg="map.mapZoom" cfg-type="number">
                  </div>
                </div>
                <div class="column col-12" style="height: 180px; margin-top: 8px;">
                  <div id="map-sm" class="aci-map"></div>
                </div>
              </div>
            </div>
            
            <div class="column col-12"><br></div>
            
            <div class="column col-12">
              <label class="form-label" for="S_Street">Streetview</label>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="columns">
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_Heading">Heading</label>
                    <input class="form-input" id="S_Heading" type="text" placeholder="Heading" cfg="map.pov.heading"
                      cfg-type="number">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_Pitch">Pitch</label>
                    <input class="form-input" id="S_Pitch" type="text" placeholder="Pitch" cfg="map.pov.pitch" cfg-type="number">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_StreetZoom">Zoom</label>
                    <input class="form-input" id="S_StreetZoom" type="text" placeholder="12.0" cfg="map.pov.zoom" cfg-type="number">
                  </div>
                </div>
                <div class="column col-12" style="height: 360px; margin-top: 8px;">
                  <div id="street-sm"></div>
                </div>
              </div>
            </div>
          `),
                    afterInit: ($ele) => {
                        const setMarkerPos = (latLng, updateStreetview) => {
                            const { lat, lng } = latLng;
                            const inputs = [$ele.find('#S_Latitude'), $ele.find('#S_Longitude')];
                            const coords = [lat, lng].map(v => (typeof v === 'function') ? v() : v);
                            inputs.map(($input, i) => {
                                $input.val(coords[i]);
                                $input.change();
                            });
                            this.selectionMarker.setPosition(latLng, {
                                animation: google.maps.Animation.DROP
                            });
                            if (updateStreetview) {
                                this.panorama.setPosition(latLng);
                            }
                        };
                        const $heading = $ele.find('#S_Heading');
                        const $pitch = $ele.find('#S_Pitch');
                        const $zoom = $ele.find('#S_StreetZoom');
                        this.panorama = createPano('street-sm');
                        this.panorama.addListener('position_changed', () => {
                            setMarkerPos(this.panorama.getPosition());
                        });
                        this.panorama.addListener('pov_changed', () => {
                            const { heading, pitch, zoom } = this.panorama.getPov();
                            $heading.val(heading);
                            $pitch.val(pitch);
                            $zoom.val(zoom);
                            const $changeElements = [$heading, $pitch, $zoom];
                            $changeElements.map($input => {
                                $input.trigger('keyup');
                            });
                        });
                        const mapPos = AConfig.get('general.map.pos', { lat: 0, lng: 0 });
                        this.map = createMap($ele.find('#map-sm'), {
                            position: mapPos,
                            zoom: AConfig.get('general.map.mapZoom', 14),
                            streetViewControl: true,
                            streetView: this.panorama
                        });
                        const $mapZoom = $('#S_MapZoom');
                        this.map.addListener('zoom_changed', () => {
                            const zoom = this.map.getZoom();
                            if (zoom !== undefined) {
                                $mapZoom.val(zoom);
                                $mapZoom.trigger('change');
                            }
                        });
                        this.selectionMarker = new google.maps.Marker({
                            position: mapPos,
                            map: this.map,
                            animation: google.maps.Animation.DROP
                        });
                        google.maps.event.addListener(this.map, 'click', (event) => {
                            setMarkerPos(event.latLng, true);
                        });
                        this.map.fit();
                        this.map.focusOnMarker(this.selectionMarker);
                    }
                };
            },
            'drawing & colors': async (a) => {
                const translations = await Loading.waitForPromises(Translate.get([
                    'Chart Color Transition',
                    'Speed Pathway',
                    'Precision Pathway'
                ]));
                const inputs = Object.keys(AConfig.get('drawing & colors.detections'));
                const inputsTranslated = await Loading.waitForPromises(Translate.get(inputs));
                const partialHtml = [
                    this.format(this.formats.colorDetections, {
                        Inputs: inputs.map((key, i) => {
                            console.log('KEY', key);
                            return this.format(this.formats.colorDetection, {
                                Title: inputsTranslated[key],
                                ConfigFill: `detections.${key}.fill`,
                                ConfigStroke: `detections.${key}.outline`,
                                style1: (key === 'illegallyParked') ? 'visibility: hidden' : '',
                                style2: ''
                            });
                        }).join('') + ( /*html*/`
              <div class="column col-12">
                <div class="columns">
                  <div class="column col-8">
                    <label class="form-label">
                      <div class="popover">
                        Legend Scale <i class="fa fa-info-circle" aria-hidden="true"></i>
                        <div class="popover-container">
                          <div class="card">
                            <div class="card-body">
                              <img src="/img/screenshots/legendScale.png" style="width: 100%" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="column col-4">
                    <input class="form-input" type="number" cfg="legendScale" value="1" step="0.1">
                  </div>
                </div>
              </div>
            `)
                    }),
                    (['occupancy', 'visitorRate', 'compliancy', 'compliancyVisitor']).map(thematicKey => {
                        return this.format(this.formats.colorTransition, {
                            Title: formatStringToPascal(thematicKey),
                            Screenshot: `segmentColorTransition`,
                            ConfigColorA: `thematic.${thematicKey}.colors.0`,
                            ConfigColorB: `thematic.${thematicKey}.colors.1`,
                            ConfigBoundA: `thematic.${thematicKey}.bounds.0`,
                            ConfigBoundB: `thematic.${thematicKey}.bounds.1`
                        });
                    }).join(''),
                    this.format(this.formats.colorTransitionBounds, {
                        Title: formatStringToPascal('enforcementIntensity'),
                        Screenshot: `segmentColorTransition`,
                        ConfigColorA: `thematic.${'enforcementIntensity'}.colors.0`,
                        ConfigColorB: `thematic.${'enforcementIntensity'}.colors.1`,
                        ConfigBoundA: `thematic.${'enforcementIntensity'}.bounds.0`,
                        ConfigBoundB: `thematic.${'enforcementIntensity'}.bounds.1`
                    }),
                    `<br><br><br>`,
                    this.format(this.formats.colorTransition, {
                        Title: translations['Chart Color Transition'],
                        Screenshot: 'chartColorTransition',
                        ConfigColorA: 'charts.gradient.0',
                        ConfigColorB: 'charts.gradient.1'
                    }),
                    this.format(this.formats.colorTransitionBoundsAbsolute, {
                        Title: translations['Precision Pathway'],
                        Screenshot: `pathColorTransition`,
                        ConfigColorA: `precision.colors.0`,
                        ConfigColorB: `precision.colors.1`,
                        ConfigBoundA: `precision.bounds.0`,
                        ConfigBoundB: `precision.bounds.1`
                    }),
                    this.format(this.formats.colorTransitionBoundsAbsolute, {
                        Title: translations['Speed Pathway'],
                        Screenshot: `pathColorTransition`,
                        ConfigColorA: `speed.colors.0`,
                        ConfigColorB: `speed.colors.1`,
                        ConfigBoundA: `speed.bounds.0`,
                        ConfigBoundB: `speed.bounds.1`
                    }),
                    ( /*html*/`
            <div class="column col-12">
              <label class="form-label" for="S_TableColorA">Table</label>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="columns">
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_TableColorA">Highlight</label>
                    <input class="form-input" id="S_TableColorA" type="color" cfg="tables.highlight">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_TableColorB">Success</label>
                    <input class="form-input" id="S_TableColorB" type="color" cfg="tables.success">
                  </div>
                </div>
                <div class="column col-4">
                  <div class="form-group">
                    <label class="form-label" for="S_TableColorC">Error</label>
                    <input class="form-input" id="S_TableColorC" type="color" cfg="tables.error">
                  </div>
                </div>
              </div>
            </div>
          `)
                ].join('<div class="column col-12 col-mx-auto divider"></div>');
                return {
                    // *                    = GROEN
                    // HasNoParkingRight    = ROOD
                    // IsIllegallyParked    = ROOD
                    html: partialHtml,
                    init: ($ele) => {
                        this.initColorTransitions($ele);
                    }
                };
            },
            'filters': async (a) => {
                const translations = await Loading.waitForPromises(Translate.get([
                    'FromDate',
                    'ToDate',
                    'Max Results',
                    'Maps',
                    'Tables',
                    'Default',
                    'Show Zones Instead of Areas',
                    'Enable Filter ParkingRightType',
                    'Enable Filter VerifyResult',
                    'Filter Selection Warning (weeks)',
                    'Override Filters',
                    'maxResultsCeiling'
                ]));
                return {
                    html: ( /*html*/`
            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_EnableFilterParkingRightType" cfg="enableFilterParkingRightType">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_EnableFilterParkingRightType">${translations['Enable Filter ParkingRightType']}</label>
            </div>
            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_EnableFilterVerifyResult" cfg="enableFilterVerifyResult">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_EnableFilterVerifyResult">${translations['Enable Filter VerifyResult']}</label>
            </div>

            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_ShowZonesInsteadOfAreas" cfg="showZonesInsteadOfAreas">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_ShowZonesInsteadOfAreas">${translations['Show Zones Instead of Areas']}</label>
            </div>
            
            <div class="column col-12">
              <label class="form-checkbox checkbox-left">
                <input type="checkbox" id="S_OverrideFilters" toggle="[#FromDate, #FromTime, #ToDate, #ToTime]" cfg="overrideFilters">
                <i class="form-icon"></i>
              </label>
              <label class="form-label" for="S_OverrideFilters">${translations['Override Filters']}</label>
            </div>
            <div class="column col-6">
              <div class="form-group">
              </div>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="columns">
                <div class="column col-12">
                  <div class="form-group form-group-datetime">
                    <label class="form-label" for="">${translations['FromDate']}</label>
                    <input class="form-input" disabled="disabled" id="FromDate" type="date" cfg="override.fromDate">
                    <input class="form-input" disabled="disabled" id="FromTime" type="time" cfg="override.fromTime">
                  </div>
                </div>
                <div class="column col-12">
                  <div class="form-group form-group-datetime">
                    <label class="form-label" for="">${translations['ToDate']}</label>
                    <input class="form-input" disabled="disabled" id="ToDate" type="date" cfg="override.toDate">
                    <input class="form-input" disabled="disabled" id="ToTime" type="time" cfg="override.toTime">
                  </div>
                </div>
              </div>
            </div>
            <div class="column col-12"> <br> </div>

            <div class="column col-12">
              <label class="form-label" for="S_MapMaxResults">${translations['Max Results']}</label>
            </div>
            <div class="column col-11 col-mx-auto">
              <div class="columns">
                <div class="column col-12">
                  <div class="form-group">
                    <label class="form-label" for="S_MapMaxResults">${translations['Maps']}</label>
                    <input class="form-input" id="S_MapMaxResults" type="text" cfg="maps.maxResults">
                  </div>
                </div>
                <div class="column col-12">
                  <div class="form-group">
                    <label class="form-label" for="S_TableMaxResults">${translations['Tables']}</label>
                    <input class="form-input" id="S_TableMaxResults" type="text" cfg="tables.maxResults">
                  </div>
                </div>
                <div class="column col-12">
                  <div class="form-group">
                    <label class="form-label" for="S_DefaultMaxResults">${translations['Default']}</label>
                    <input class="form-input" id="S_DefaultMaxResults" type="text" cfg="default.maxResults">
                  </div>
                </div>
              </div>
            </div>
            <div class="column col-12"> <br> </div>
            <div class="column col-12">
              <div class="form-group">
                <label class="form-label" for="S_MaxResultsCeil">${translations['maxResultsCeiling']}</label>
                <input class="form-input" id="S_MaxResultsCeil" type="number" placeholder="100000" cfg="maxResultsCeiling">
              </div>
            </div>
            <div class="column col-12"> <br> </div>
            <div class="column col-12">
              <div class="form-group">
                <label class="form-label" for="S_ClientName">${translations['Filter Selection Warning (weeks)']}</label>
                <input class="form-input" id="S_ClientName" type="text" placeholder="8" cfg="minWeeksWarning">
              </div>
            </div>
          `),
                    afterInit: ($ele) => {
                        const $overrideFilters = $ele.find('#S_OverrideFilters');
                        const $inputs = this.getToggleChildren($ele, $overrideFilters);
                        $inputs.map($input => {
                            const type = $input.attr('type');
                            const value = (type === 'date') ? AInputDate(new Date()) : AInputTime(new Date());
                            if ($input.val().length === 0)
                                $input.val(value);
                        });
                    }
                };
            },
            'tableformatter': async (a) => {
                // const tf = AConfig.get('tableformatter', undefined)
                // if (tf === undefined) {
                //   await AConfig.update('tableformatter', {
                //     default: {
                //       definitions: ATableFormatter.fallbackDefinitions,
                //       order: ATableFormatter.fallbackOrder
                //     }
                //   })
                // }
                const keys = Object.keys(AConfig.get('tableformatter', {}));
                const translatedKeys = await Loading.waitForPromises(Translate.get(keys));
                const html = keys.map(key => {
                    return this.format(this.formats.tableformatter, {
                        Title: translatedKeys[key],
                        key: key
                    });
                }).join('<div class="column col-12 col-mx-auto divider"></div>');
                return {
                    html,
                    afterInit: ($ele) => {
                        const $configGridParents = $ele.find('.config-grid-parent');
                        $configGridParents.each((i, gridParent) => {
                            const $configGridParent = $(gridParent);
                            const gridId = $configGridParent.find('.config-grid').attr('id');
                            const $input = $configGridParent.find('.config-grid-input');
                            const $insert = $configGridParent.find('.config-grid-insert');
                            const createRow = () => {
                                try {
                                    const column = $input.val();
                                    if (column.length === 0) {
                                        return Alerts.show({ title: ALERT_TITLES.Warning, content: 'Please fill in a column name' });
                                    }
                                    const grid = PageScript.gridMap[gridId];
                                    const { store } = grid;
                                    if (store.find((obj) => obj.column.toLowerCase() === column.toLowerCase()) !== undefined) {
                                        return Alerts.show({ title: ALERT_TITLES.Warning, content: `Column "${column}" already exists!` });
                                    }
                                    const record = store.createRecord({
                                        column,
                                        type: "TEXT",
                                        isPriority: false,
                                        custom: null,
                                        controls: ['Up', 'Down']
                                    });
                                    store.records.push(record);
                                    const events = Alerts.show({ title: ALERT_TITLES.Info, content: `Column "${column}" has been added!` });
                                    events.on(ALERT_STATUS.ON_MODAL_CLOSED, _ => {
                                        grid.scrollToBottom();
                                    });
                                    return true;
                                }
                                catch (err) {
                                    AError.handle(err);
                                    return false;
                                }
                            };
                            $input.on('keyup', e => {
                                if ((e.keyCode ? e.keyCode : e.which) == 13) {
                                    createRow();
                                }
                            });
                            $insert.on('click', _ => createRow());
                        });
                    }
                };
            },
            'databaseDefinitions': async (a) => {
                return ( /*html*/`
          <div class="column col-6">
            <div class="form-group" style="margin-bottom: 0;">
              <label class="form-label" for="S_ParkingRightTypes">Parking Right Types</label>
              <select size="4" class="form-select" id="S_ParkingRightTypes" cfg="parkingRightTypes">
              </select>
            </div>
            <div class="input-group">
              <input class="form-input input-sm" type="text" placeholder="...">
              <button class="btn btn-primary input-group-btn btn-sm">
                <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
                Add
              </button>
              <button class="btn btn-error input-group-btn btn-sm">
                <i class="fa fa-minus fa-sm" aria-hidden="true"></i>
                Remove
              </button>
            </div>
          </div>
          <div class="column col-6">
            <div class="form-group" style="margin-bottom: 0;">
              <label class="form-label" for="S_LocationTypes">Location Types</label>
              <select size="4" class="form-select" id="S_LocationTypes" cfg="locationTypes">
              </select>
            </div>
            <div class="input-group">
              <input class="form-input input-sm" type="text" placeholder="...">
              <button class="btn btn-primary input-group-btn btn-sm">
                <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
                Add
              </button>
              <button class="btn btn-error input-group-btn btn-sm">
                <i class="fa fa-minus fa-sm" aria-hidden="true"></i>
                Remove
              </button>
            </div>
          </div>
          <div class="column col-6">
            <div class="form-group" style="margin-bottom: 0;">
              <label class="form-label" for="S_VehicleTypes">Vehicle Types</label>
              <select size="4" class="form-select" id="S_VehicleTypes" cfg="vehicleTypes">
              </select>
            </div>
            <div class="input-group">
              <input class="form-input input-sm" type="text" placeholder="...">
              <button class="btn btn-primary input-group-btn btn-sm">
                <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
                Add
              </button>
              <button class="btn btn-error input-group-btn btn-sm">
                <i class="fa fa-minus fa-sm" aria-hidden="true"></i>
                Remove
              </button>
            </div>
          </div>
        `);
            }
        };
        if (routes.hasOwnProperty(key.value)) {
            const route = routes[key.value];
            return route('SUCCES!');
        }
        else {
            AError.handle(`Setting ${key.value} doesn't exist!`);
            return null;
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
    .color-inputs span.column.text-center {
      font-size: 12pt;
      padding: 5px 0;
    }

    .color-inputs span.column.text-center:first-child {
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }

    .color-inputs span.column.text-center:last-child {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }

    .popover .popover-container.popover-large {
      width: 540px;
    }

    .fa.fa-info-circle {
      color: var(--main-color);
    }

    .form-group-datetime>input {
      display: inline-block;
      width: 49.5%
    }

    .form-checkbox.checkbox-left {
      display: inline-block;
      margin: 0;
    }

    .form-checkbox.checkbox-left .form-icon {
      height: 1rem;
      width: 1rem;
      margin: 0
    }

    .form-checkbox.checkbox-left+.form-label {
      display: inline-block;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="wrapper postscan fh no-h-overflow">
      <div class="aci-tabs tabs-flex tabs-sticky" tabgroup="config" style="margin-top: 0;"></div>
      <div class="columns col-12 col-mx-auto">
        <div id="subSettings" class="column col-12">
          <div class="columns"></div>
        </div>
      </div>
    </div>
  `);
}

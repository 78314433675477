import { sleep } from "../core/AEngine.js";
import { embedJsonInHtml } from "../utils/json.js";
import { AKpiBlock } from "./AKpiBlock.js";
export class AKpiBlockDeprecated extends AKpiBlock {
    get btnId() { return `${this.idFilter}-btn`; }
    constructor(opt) {
        super({
            refreshImplemented: true,
            optionsImplemented: true,
            TranslateTitle: false,
            ...opt
        });
    }
    async init() {
        super.init();
        this.$kpiView.find(`#${this.btnId}`).on('click', (e) => {
            this.$kpiView.html(/*html*/ `
        <div class="mb-2">${this.getOptionsJSON()}</div>
      `);
        });
    }
    getOptionsJSON() {
        // const {KpiType, Name, optionsImplemented, Settings, TranslateTitle, widgetOptions} = this.opt
        // const dataToShow = {KpiType, Name, optionsImplemented, Settings, TranslateTitle, widgetOptions}
        const { htmlWrapper, innerHtml } = embedJsonInHtml('clsname', this.opt.widgetOptions, {
            indendation: 2,
            numberColor: `var(--color-red-bg)`,
            stringColor: `var(--color-red-bg)`,
            trueColor: `var(--color-red-bg)`,
            falseColor: `var(--color-red-bg)`,
        });
        return htmlWrapper;
    }
    async refresh(filters = this.filterOption, context) {
        // if (context?.userInput === true) {}
        await sleep(5000);
    }
    async render() {
        return await super.renderView({
            title: this.Name,
            viewHtml: ( /*html*/`
        <div class="text-red" style="width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <i class="fa-regular fa-circle-exclamation fa-5x mb-2 fa-beat aci-animation aci-fade-in aci-play-animation" style="--fa-animation-iteration-count: 1; --fa-beat-scale: 1.1;"></i>
          <span class="kpi-text mb-2">Deprecated KPI</span>
          <button id="${this.btnId}" class="btn btn-white btn-shadow-sm btn-sm text-red mt-1" style="margin-bottom: 36px;">Show Details</button>
        </div>
      `)
        });
    }
}

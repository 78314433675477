import { AError } from "../../classes/AError.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { isUserACI } from "../../utils/tools.js";
export class APage {
    constructor() {
        Translate.prepare('Usergroup');
        // TODO: Use method from tools to check this
        if (isUserACI()) {
            const $btnCreate = $('#create-group');
            $btnCreate.on('click', _ => this.createForm());
            $btnCreate.removeClass('hidden');
            const $btnReset = $('#reset-groups');
            $btnReset.on('click', _ => this.resetForm());
            $btnReset.removeClass('hidden');
        }
    }
    async init() {
        validateEnforcementProcessService.validate();
        await permissionService.refetchUsergroups();
        const $table = $('#usergroups tbody');
        Usergroups.map(usergroup => $table.append(this.createRow(usergroup)));
    }
    createRow(opt) {
        const { UserGroup, UserGroupText } = opt;
        const $row = $(`<tr><td>${UserGroupText}</td></tr>`);
        const $edit = $(`<button class="btn btn-primary"><i class="fa-regular fa-pencil"></i></button>`);
        const $delete = $(`<button class="btn btn-error"><i class="fa-regular fa-trash"></i></button>`);
        $edit.on('click', async (e) => {
            const $editUsergroup = $('#editUsergroup');
            $editUsergroup.find('.panel-header h4').text(UserGroupText);
            const $table = $editUsergroup.find('table');
            // $table.find('thead tr th').text(await Translate.get('Enabled'))
            const $tbody = $table.find('tbody');
            $tbody.html('');
            const rights = await requestService.query({
                Query: `SELECT Id_MR FROM usergroup_rights WHERE UserGroup=:UserGroup`,
                Name: 'FetchRights',
                Params: { UserGroup }
            }).then(response => response.Rows.map(val => val[0]));
            const id_mr_menuItem = {};
            const id_mrs = _.getUser().id_mrs // Loop through menu items
                .filter((elem, index, self) => // Remove double entries
             index == self.indexOf(elem)).map(id_mr => {
                const menuItem = menuHelperService.getMenuItem(id_mr);
                if (menuItem !== undefined && menuItem !== null) {
                    id_mr_menuItem[id_mr] = menuItem;
                    return id_mr;
                }
                return null;
            }).filter(v => v !== null);
            const translations = await Loading.waitForPromises(Translate.get(id_mrs.map(id_mr => id_mr_menuItem[id_mr].title)));
            id_mrs.map(id_mr => {
                const attr = (rights.includes(id_mr)) ? 'checked="checked"' : '';
                const enabled = (id_mr === 'admin' && !isUserACI()) || id_mr == '_testing' ? ' disabled' : '';
                const parent = permissionService.getParent(id_mr);
                const menuItem = permissionService.getMenuItem(id_mr);
                const className = parent !== null ? 'prefix' : '';
                let $parent = null;
                const premiumClass = menuItem && menuItem.premium ? 'goldStroke' : '';
                if (parent !== null) {
                    if (parent.id_mr) {
                        // @ts-ignore
                        $parent = $(`[id_mr="${parent.id_mr}"]`);
                    }
                }
                const $tr = $(`
          <tr>
            <td>
              <div class="form-group ${className} ${premiumClass}">
                <label class="form-checkbox">
                  <input id_mr="${id_mr}" type="checkbox" ${attr} ${enabled}>
                  <i class="form-icon"></i> ${translations[id_mr_menuItem[id_mr].title]}
                </label>
              </div>
            </td>
          </tr>
        `);
                $tr.find('input[type="checkbox"]').on('change', e => {
                    const checked = $(e.target).is(':checked');
                    if ($parent === null && menuItem.hasOwnProperty('children')) {
                        if (checked === true) {
                            menuItem.children.map(child => {
                                $(`[id_mr="${child.id_mr}"]`).removeAttr('disabled');
                            });
                        }
                        else {
                            menuItem.children.map(child => {
                                $(`[id_mr="${child.id_mr}"]`).attr('disabled', '');
                            });
                        }
                    }
                    // prevent jquery invoke to execute queries
                    if (e.originalEvent === undefined) {
                        return;
                    }
                    const query = (checked === true) ? (`
            INSERT INTO
                usergroup_rights (UserGroup, Id_MR)
            VALUES
                (:Usergroup, :ID_MR)
            ON DUPLICATE KEY UPDATE
                UserGroup=:Usergroup, Id_MR=:ID_MR
          `) : `DELETE FROM usergroup_rights WHERE UserGroup=:Usergroup AND Id_MR=:ID_MR`;
                    Loading.waitForPromises(requestService.query({
                        Query: query,
                        Name: 'UpdateUsergroupRights',
                        Params: {
                            Usergroup: UserGroup,
                            ID_MR: id_mr
                        }
                    })).finally(() => {
                        validateEnforcementProcessService.validate();
                    });
                });
                $tbody.append($tr);
                return $tr;
            });
            $('input[type="checkbox"]').trigger('change');
        });
        $delete.on('click', async (e) => {
            const events = Alerts.show({
                title: ALERT_TITLES.Warning,
                content: `Are you sure you want to delete usergroup ${UserGroup}?`,
                buttons: ALERT_BUTTONS.yesNo
            });
            events.on(ALERT_STATUS.ON_ACTION_PROCEED, _ => Loading.waitForPromises([
                requestService.query({
                    Query: `DELETE FROM usergroups WHERE UserGroup=:Usergroup`,
                    Params: { Usergroup: UserGroup }
                }),
                requestService.query({
                    Query: `DELETE FROM usergroup_rights WHERE UserGroup=:Usergroup`,
                    Params: { Usergroup: UserGroup }
                }),
                requestService.query({
                    Query: `DELETE FROM user_usergroups WHERE UserGroup=:Usergroup`,
                    Params: { Usergroup: UserGroup }
                })
            ]).then(_ => {
                $row.remove();
                validateEnforcementProcessService.validate();
            }));
        });
        const $controls = $(`<td class="text-right"></td>`);
        $controls.append($edit);
        if (isUserACI()) {
            $controls.append($delete);
        }
        $row.append($controls);
        return $row;
    }
    async resetForm() {
        const textAreYouSure = await Translate.get('Are you sure that you want to reset all the usergroups? This means that nobody will have a usergroup anymore!');
        Alerts.show({
            title: ALERT_TITLES.Warning,
            content: textAreYouSure,
            buttons: ALERT_BUTTONS.yesNo
        }).on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            await Loading.waitForPromises([
                requestService.query(`DELETE FROM usergroup_rights`),
                requestService.query(`DELETE FROM usergroups`),
                requestService.query(`INSERT INTO usergroups (UserGroup) VALUES ('admin'), ('analyst'), ('operator'), ('driver')`),
                requestService.query(`
          INSERT INTO usergroup_rights
            (UserGroup, Id_MR) 
          VALUES
            ('admin', '_advanced scans'),
            ('admin', '_cameras'),
            ('admin', '_charts'),
            ('admin', '_connection log'),
            ('admin', '_device versions'),
            ('admin', '_gps precision & speed'),
            ('admin', '_gps statistics'),
            ('admin', '_language'),
            ('admin', '_log'),
            ('admin', '_manage users'),
            ('admin', '_maps'),
            ('admin', '_mapscans'),
            ('admin', '_mapsearch'),
            ('admin', '_reports'),
            ('admin', '_route & scans'),
            ('admin', '_uniquemapscans'),
            ('admin', '_scans'),
            ('admin', '_scans per hour'),
            ('admin', '_search'),
            ('admin', '_session'),
            ('admin', '_settings'),
            ('admin', '_tablescans'),
            ('admin', '_uniquetablecans'),
            ('admin', '_tablesearch'),
            ('admin', '_team'),
            ('admin', '_timeline'),
            ('admin', '_unique scans'),
            ('admin', '_server stats'),
            ('analyst', '_advanced scans'),
            ('analyst', '_charts'),
            ('analyst', '_gps precision & speed'),
            ('analyst', '_gps statistics'),
            ('analyst', '_maps'),
            ('analyst', '_mapscans'),
            ('analyst', '_mapsearch'),
            ('analyst', '_reports'),
            ('analyst', '_route & scans'),
            ('analyst', '_uniquemapscans'),
            ('analyst', '_scans'),
            ('analyst', '_scans per hour'),
            ('analyst', '_search'),
            ('analyst', '_tablescans'),
            ('analyst', '_uniquetablecans'),
            ('analyst', '_tablesearch'),
            ('analyst', '_team'),
            ('analyst', '_timeline'),
            ('analyst', '_unique scans')
        `)
            ]);
            routeService.reload();
        });
    }
    async createForm(id = 'create-group-form') {
        const html = `
      <div id="${id}" class="form-group">
          <label class="form-label" for="input1">User Group</label>
          <input class="form-input" autocomplete="off" type="text" id="input1" placeholder="${await Translate.get('Name')}" maxlength="32">
      </div>`.replace(/\s\s+/g, ' ');
        const events = Alerts.show({
            translatedTitle: await Translate.get('Create Usergroup'),
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.createCancel
        });
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            const $form = $(`#${id}`);
            const group = ($form.find('#input1').val() || '').toString() || null;
            if (group === null) {
                Alerts.show({
                    title: ALERT_TITLES.Warning,
                    content: await Translate.get(`Please write a name for the usergroup!`)
                });
                return false;
            }
            Loading.waitForPromises(this.createUsergroup(group.toLowerCase())).then(row => {
                const $tbody = $('#usergroups tbody');
                $tbody.append(this.createRow(row));
            }).catch(AError.handle);
        });
    }
    async createUsergroup(name) {
        await requestService.query({
            Query: `INSERT INTO usergroups (UserGroup) VALUES (:Name)`,
            Name: 'CreateUsergroup',
            Params: {
                Name: name
            }
        });
        await permissionService.refetchUsergroups();
        return Usergroups.find(v => v.UserGroup === name);
    }
}
export function css() {
    return ( /*html*/`
  <style>
  .form-group.prefix {
    margin-left: 10px;
  }

  .form-group.goldStroke::after {
    content: '$$$';
    display: inline-block;
    border-radius: 3px;
    padding: 3px 9px;
    margin: 0 3px;
    background: gold;
  }

  #RefreshButton,
  #Limit {
    float: right;
    width: 8vw;
  }

  #userlist {
    width: 100%;
    height: calc(100% - 80px);
    /* Chrome */
    overflow: auto;
  }

  #userlist table {
    width: 100%;
    overflow: visible;
  }

  #userlist table th {
    text-align: left;
  }

  #userlist table img {
    width: 4.5vw;
    height: 1.5vw;
  }

  #userlist td {
    white-space: nowrap;
  }

  .tableWrapper {
    height: 100%;
    overflow-y: auto;
  }

  .wrapper-panels {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 15px 0;
  }
  .wrapper-panels .panel {
    height: 100%;
    background: #fff;
  }
  </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div class="columns wrapper-panels">
      <div class="column col-5 col-mx-auto panel">
        <div class="panel-header">
          <div class="panel-title text-center">
            <h4 class="mb-0">Usergroups</h4>
          </div>
        </div>
        <div class="divider div-sm text-center" data-content=""></div>
        <div class="panel-nav"></div>
        <div class="panel-body">
          <table id="usergroups" class="table table-hover" style="max-height: 100%">
            <tbody>
            </tbody>
          </table>
          <br>
          <div class="columns">
            <div class="column col-6">
              <button id="reset-groups" class="btn btn-error col-12 hidden">
                Reset all usergroups
              </button>
            </div>
            <div class="column col-6">
              <button id="create-group" class="btn btn-success col-12 hidden">
                <i class="fa fa-plus" aria-hidden="true"></i>
                Create new usergroup
              </button>
            </div>
          </div>
        </div>
        <div class="panel-footer">
        </div>
      </div>
      <div id="editUsergroup" class="column col-5 col-mx-auto panel">
        <div class="panel-header">
          <div class="panel-title text-center">
            <h4 class="mb-0">Select a usergroup</h4>
          </div>
        </div>
        <div class="divider div-sm text-center" data-content=""></div>
        <div class="panel-nav"></div>
        <div class="panel-body">
          <table class="table table-hover" style="max-height: 100%">
            <tbody>
            </tbody>
          </table>
        </div>
        <div class="panel-footer"></div>
      </div>
    </div>
  `);
}

import { DisableItem, EnableItem } from "../backoffice-initializer-legacy.js";
import { AConfig } from "../classes/AConfig.js";
import { ADropDown } from "../core/form/ADropDown.js";
import { ADropDownTree } from "../core/form/ADropDownTree.js";
import { AError } from "../classes/AError.js";
import { AExclusionArray } from "../classes/AExclusionArray.js";
import { AEngine } from "../core/AEngine.js";
import { _getEle$ } from "../utils/maps.js";
import { AInputDate, AInputDateTime, AInputTime, formatNodeName, weeksPassed } from "../utils/tools.js";
import { ALERT_BUTTONS, ALERT_TITLES } from "./AAlertService.js";
import { AMapHelperService } from "./AMapHelperService.js";
import { APreferenceService } from "./APreferenceService.js";
import { EVENTS } from "./AEventService.js";
export class AFilterService {
    constructor() {
        this.multiSelectCache = {};
        this.dropdowns = {};
        Object.defineProperty(globalThis, 'FilterManager', {
            get: () => this
        });
    }
    autoInit() {
        AEngine.log('AFilterService.autoInit() called!');
        Events.hardwire(EVENTS.PAGE_INITIALIZED, () => {
            let $filters = $('#Filters');
            $filters.find('label[for]').toArray().map(e => $(e)).map($label => {
                let id = $label.attr('for');
                let $filter = $filters.find(`#${id}`);
                if ($filter.hasClass('wrapper-dropdown')) {
                    $label.on('click', (e) => {
                        $filter.trigger('click');
                    });
                }
            });
        });
    }
    addKeyPressHandler($ele, filter) {
        $ele.keyup(filter);
        $ele.keydown(filter);
    }
    validateLabel(text) {
        return text.replace(/[^0-9a-zA-Z]+/g, '');
    }
    applyNumericExpressions() {
        let regex = /[0-9]/g;
        const $collection = $('[Numeric]');
        $collection.on('keydown', (e) => {
            if ([8, 13, 16].includes(e.keyCode))
                return true;
            if (!e.key.match(regex)) {
                return false;
            }
        });
        $collection.removeAttr('Numeric');
    }
    applyRegularExpressions() {
        let regex = /[A-Za-z0-9?%*]/g;
        const $collection = $('[LicensePlate]');
        $collection.on('keydown', (e) => {
            if ([8, 13, 16, 37, 63, 42].includes(e.keyCode))
                return true;
            if (!e.key.match(regex)) {
                return false;
            }
        });
        $collection.on('keyup change paste', function (e) {
            $(this).val(($(this).val() || '').toString().toUpperCase().trim());
        });
        $collection.removeAttr('LicensePlate');
    }
    createVehicleTypeOptions(selected, id, className) {
        if (selected != null)
            selected = selected.toLowerCase();
        let options = [];
        Object.keys(VehicleTypes).map((type) => {
            options.push(`<option value="${type}"${selected == type.toLowerCase() ? ' selected' : ''}>${VehicleTypes[type]}</option>`);
        });
        return (`<select id="${id}" class="form-select ${className}">${options.join('')}</select>`);
    }
    createParkingRightTypeOptions(selected, id, className) {
        if (selected != null)
            selected = selected.toLowerCase();
        let options = Object.keys(ParkingRightTypes).map((type) => {
            return `<option value="${type}"${selected == type.toLowerCase() ? ' selected' : ''}>${ParkingRightTypes[type]}</option>`;
        });
        return (`<select id="${id}" class="form-select ${className}">${options.join('')}</select>`);
    }
    async fetchUsedLocationTypes() {
        const res = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT l.LocationType, l.LocationType as LocationTypeTranslated FROM (
          SELECT LocationType FROM locations
          UNION SELECT LocationType FROM location_images
          GROUP BY LocationType
        ) l
      `),
            Translate: ['LocationTypeTranslated']
        });
        return res.map(v => v);
    }
    createAllLocationTypeOptions({ id = "LocationType" }) {
        const options = Object.keys(LocationTypes).map((type) => `<option value="${type}">${LocationTypes[type]}</option>`);
        return (`<select id="${id}" class="form-select">${options.join('')}</select>`);
    }
    async createUsedLocationTypeOptions({ id = "LocationType" }) {
        const opts = await this.fetchUsedLocationTypes();
        const options = opts.map(({ LocationType, LocationTypeTranslated }) => `<option value="${LocationType}">${LocationTypeTranslated}</option>`);
        return (`<select id="${id}" class="form-select">${options.join('')}</select>`);
    }
    showFilterWarning($filters = $('#Filters')) {
        const requirements = [
            this.isDateCorrect,
            this.areUnificationsFilled,
            this.areDropdownsFilled
        ];
        for (let req of requirements) {
            const passedRequirement = req.apply(this, [$filters]);
            if (passedRequirement == false) {
                return new Promise(() => { });
            }
        }
        return this.checkMinWeeksWarning();
    }
    isDateCorrect($filters) {
        const $from = $filters.find(`#FromDate`);
        const $to = $filters.find(`#ToDate`);
        if ($from.length && $to.length) {
            if (($from.val() || 0) > ($to.val() || 0)) {
                $from.addClass('is-error');
                $to.addClass('is-error');
                Alerts.incorrectDate();
                return false;
            }
            else {
                $from.removeClass('is-error');
                $to.removeClass('is-error');
            }
        }
        return true;
    }
    areDropdownsFilled($filters) {
        const $dds = $filters.find('.dropdown:not(.dropdown-tree)');
        if ($dds.length === 0)
            return true;
        const $ddws = $dds.toArray().map(dd => $(dd).closest('.wrapper-dropdown'));
        for (let $dd of $ddws) {
            const dd = $dd.data('DropDown');
            if (!dd.validate()) {
                Alerts.emptyUnification();
                return false;
            }
        }
        return true;
    }
    areUnificationsFilled($filters) {
        const $dds = $filters.find('.dropdown.dropdown-tree');
        if ($dds.length === 0)
            return true;
        const $dropdowns = $dds.toArray().map(dd => $(dd));
        for (let $dd of $dropdowns) {
            if (!this.isUnificationFilled($dd)) {
                Alerts.emptyUnification();
                return false;
            }
        }
        return true;
    }
    isUnificationFilled($ddw_or_$dd) {
        const $dd = $ddw_or_$dd.is('.dropdown.dropdown-tree') ? $ddw_or_$dd : $ddw_or_$dd.find('.dropdown.dropdown-tree');
        const unificationOptions = $dd.find('[unificationindex]').toArray();
        const unificationIndexes = unificationOptions.filter(c => c.checked).map(c => Number($(c).attr('unificationindex')));
        if (unificationIndexes.length === 0) {
            return false;
        }
        return true;
    }
    checkMinWeeksWarning() {
        const minWeeksWarning = AConfig.get('filters.minWeeksWarning', 8);
        const timeframe = `${minWeeksWarning} weeks`;
        return new Promise(async (resolve, reject) => {
            const dates = this.getDateFilters();
            // @ts-ignore
            if (dates === null)
                return resolve();
            const { FromDate, ToDate } = dates;
            const weeks = weeksPassed(FromDate, ToDate);
            if (weeks < minWeeksWarning) {
                return resolve();
            }
            try {
                const toTranslate = [
                    `You have selected a timeframe over${timeframe}`,
                    `This action may take some time to process`,
                    `Are you sure you want to continue`,
                    `Yes`,
                    `No`
                ];
                const promises = toTranslate.map(text => Translate.get(text));
                const [line1, line2, line3, yes, no] = await Loading.waitForPromises(promises);
                const html = (`${line1}<br>${line2}<br>${line3}`);
                const events = Alerts.show({
                    title: ALERT_TITLES.Warning,
                    content: html,
                    buttons: ALERT_BUTTONS.yesNo
                });
                // @ts-ignore
                events.on('option1', async () => resolve());
            }
            catch (err) {
                reject(err);
            }
        });
    }
    load($filters = $('#Filters')) {
        this.initFilters($filters);
        this.loadSettingsInto($filters);
        this.listenToChanges($filters);
    }
    initFilters($filters) {
        this.applyRegularExpressions();
        this.applyNumericExpressions();
        this.dropdowns = {};
        const mapHelperService = AEngine.get(AMapHelperService);
        $filters.find('.wrapper-dropdown:not([late-init="true"])').each((i, ele) => { this.initDropDown($(ele)); });
        // Object.keys(ADynamicChartSnapshot.selections).map(key => {
        //   return (/*HTML*/`
        //     <option value="${key}">${key.substring(6)}</option>
        //   `)
        // }).join('')
        const $usergroups = $filters.find('#Usergroups');
        if ($usergroups.length > 0) {
            $usergroups.each((i, ele) => {
                const options = Usergroups.map(g => {
                    return { id: g.UserGroup, text: g.UserGroupText, checked: true };
                }).filter(({ id }) => id !== null && id !== '');
                this.fillDropDownTemplate(ele, options);
            });
        }
        $filters.find('#VerificationUser').each((i, ele) => {
            // const VerificationUsers = []
            const options = VerificationUsers.map((user) => {
                return {
                    id: user,
                    text: user,
                    checked: true
                };
            }).filter(({ id }) => id !== null && id !== '');
            this.fillDropDownTemplate(ele, options);
        });
        $filters.find('#AreaMulti').each((_, ele) => {
            const options = mapHelperService.getAreaPairs().map(({ Index, Name, }) => {
                return {
                    id: Index,
                    text: Name,
                    checked: true
                };
            });
            this.fillDropDownTemplate(ele, options);
        });
        $filters.find('#ZoneMulti').each((_, ele) => {
            const zones = mapHelperService.getZones();
            const options = zones.map(({ Index, Area, Name, }) => {
                return {
                    id: Index,
                    text: Name,
                    checked: true
                };
            });
            this.fillDropDownTemplate(ele, options);
        });
        const $verifyResult = $filters.find('#VerifyResult');
        if ($verifyResult.is('select')) {
            for (let k in VerificationResults) {
                $verifyResult.append(`<option value='${k}'>${VerificationResults[k]}</option>`);
            }
        }
        else {
            const options = Object.keys(VerificationResults).map(key => {
                return {
                    id: key,
                    text: VerificationResults[key],
                    checked: true
                };
            }).filter(({ id }) => id !== null || id !== '');
            $verifyResult.each((i, ele) => {
                this.fillDropDownTemplate(ele, options);
            });
        }
        const $parkingRightType = $filters.find('#ParkingRightType');
        if ($parkingRightType.is('select')) {
            for (let k in ParkingRights) {
                $parkingRightType.append(`<option value='${k}'>${ParkingRights[k]}</option>`);
            }
        }
        else {
            const options = Object.keys(ParkingRights).map(key => {
                return {
                    id: key,
                    text: ParkingRights[key],
                    checked: true
                };
            }).filter(({ id }) => id !== null || id !== '');
            $parkingRightType.each((i, ele) => {
                this.fillDropDownTemplate(ele, options);
            });
        }
        $filters.find('#DeviceName[load="all"]').each(function () {
            for (let k in AllDevices) {
                $(this).append(`<option value="${k}">${formatNodeName(AllDevices[k])}</option>`);
            }
        });
        $filters.find('#DeviceName[load="VehicleDevices"]').each(function () {
            for (let k in ScanDevices) {
                if (k.startsWith('Scan')) {
                    $(this).append(`<option value="${k}">${formatNodeName(ScanDevices[k])}</option>`);
                }
            }
        });
        $filters.find('#DeviceName:not([load="all"]):not([load="VehicleDevices"])').each(function () {
            for (let k in ScanDevices) {
                $(this).append(`<option value="${k}">${formatNodeName(ScanDevices[k])}</option>`);
            }
        });
        // $('#ParkingRightType').each(function () {
        //   if ($(this).find('option[value="%"]').length === 0) {
        //     $(this).append(`<option value="%">${Translate.getCache('all')}</option>`)
        //   }
        //   for (let k in ParkingRights) {
        //     $(this).append(`<option value="${k}">${ParkingRights[k]}</option>`)
        //   }
        // })
        $filters.find('#VehicleType').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let k in VehicleTypes) {
                $(this).append(`<option value="${k}">${VehicleTypes[k]}</option>`);
            }
        });
        $filters.find('#DetectionUser').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let k in DetectionUsers) {
                $(this).append(`<option value="${DetectionUsers[k]}">${DetectionUsers[k]}</option>`);
            }
        });
        $filters.find('#LocationType').each(function () {
            $(this).append(`<option value="%">${Translate.getCache('all')}</option>`);
            for (let k in LocationTypes) {
                $(this).append(`<option value="${k}">${LocationTypes[k]}</option>`);
            }
        });
        let $areas = $filters.find('#Area');
        if (AConfig.get('filters.showZonesInsteadOfAreas', false)) {
            // if (configService.get('filters.showZonesInsteadOfAreas')) {
            $areas.attr("id", "Zone");
            const $zones = $areas;
            const zones = mapHelperService.getZones();
            zones.map(zone => {
                const { Index, Name } = zone;
                $zones.append(`<option data-id="${Index}" value="${Name}">${Name}</option>`);
            });
            $zones.prev().find('label').text(Translate.getCacheFast('zone'));
        }
        else {
            for (let area of mapHelperService.getAreaPairs()) {
                const { Index, Name } = area;
                $areas.append(`<option data-id="${Index}" value='${Name}'>${Name}</option>`);
            }
        }
    }
    async fillDropDownTemplate(elementOrId, options) {
        try {
            const $dropdownWrapper = (typeof elementOrId === 'string') ? $(`#${elementOrId}`) : _getEle$(elementOrId);
            const elementId = $dropdownWrapper.attr('id');
            if (elementId === undefined)
                throw new Error(`Dropdown isn't configured correctly!`);
            const $ul = $dropdownWrapper.find('.dropdown');
            $dropdownWrapper.addClass('wrapper-dropdown');
            // Quick fix for clients that don't have areas/zones
            if (options.length === 0) {
                $dropdownWrapper.removeClass('dd-disallow-none');
            }
            const translations = await Loading.waitForPromises(Translate.get([
                'Select All',
                'Select None'
            ]));
            const $selectAll = $(`<li> <label class="form-checkbox">${translations['Select All']}</label> </li>`);
            const $selectNone = $(`<li> <label class="form-checkbox">${translations['Select None']}</label> </li>`);
            $ul.append($selectAll);
            $ul.append($selectNone);
            $selectAll.on('click', e => {
                const dropdown = $dropdownWrapper.data('DropDown');
                dropdown.selectAll();
            });
            $selectNone.on('click', e => {
                const dropdown = $dropdownWrapper.data('DropDown');
                dropdown.selectNone();
            });
            for (let { id, text, checked } of options) {
                id = elementId + '->' + id;
                const $ele = $(`
          <li>
            <label class="form-checkbox">
              <input type="checkbox" id="${id}" key="${id}" ${checked === true ? 'checked="checked"' : ''}>
              <i class="form-icon"></i> ${text}
            </label>
          </li>
        `);
                $ul.append($ele);
            }
            this.initDropDown($dropdownWrapper);
        }
        catch (err) {
            console.warn('context', elementOrId, options);
            AError.handle(err);
        }
    }
    initDropDown(ele, opt) {
        let { state } = opt || {};
        const $ddw = _getEle$(ele);
        if (state === undefined) {
            state = $ddw.is('.tree-config') ? [] : this.multiSelectCache;
        }
        let dropdown = ($ddw.is('.tree-config')) ?
            new ADropDownTree($ddw, opt).restoreState(state) : new ADropDown($ddw).restoreState(state || {});
        const id = $ddw.attr('id');
        this.dropdowns[id] = dropdown;
        return this.dropdowns;
    }
    setDefaultLimit() {
        if (window.location.href.includes('/location-images/')) {
            return;
        }
        // const maxResults = configService.get('filters.maxResults')!
        if (window.location.href.includes('/reports/')) {
            FilterSettings.Limit = AConfig.get('filters.tables.maxResults', 250);
            // FilterSettings.Limit = maxResults.maxResultsReports
        }
        else if (window.location.href.includes('/maps/')) {
            FilterSettings.Limit = AConfig.get('filters.maps.maxResults', 2000);
            // FilterSettings.Limit = maxResults.maxResultsMaps
        }
        else {
            FilterSettings.Limit = AConfig.get('filters.default.maxResults', 2000);
            // FilterSettings.Limit = maxResults.maxResultsDefault
        }
    }
    setDefaultDate() {
        if (AConfig.get('filters.overrideFilters', false)) {
            const { fromDate, fromTime, toDate, toTime } = AConfig.get('filters.override', {});
            FilterSettings.FromDate = new Date(`${fromDate} ${fromTime}`).toJSON();
            FilterSettings.ToDate = new Date(`${toDate} ${toTime}`).toJSON();
        }
        else {
            const now = new Date();
            const today = new Date((now.getTimezoneOffset() * 60 * 1000) + now.getTime() - (now.getTime() % (24 * 60 * 60 * 1000)));
            const tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
            FilterSettings.FromDate = today.toJSON();
            FilterSettings.ToDate = tomorrow.toJSON();
        }
    }
    loadFromSavedProfile(name) {
        // TODO: Rethink filter-profiles implementation
        const filterProfiles = AConfig.get('filter-profiles', {});
        if (!filterProfiles.hasOwnProperty(routeService.url.hash)) {
            return;
        }
        const profileMap = filterProfiles[routeService.url.hash];
        if (!profileMap.hasOwnProperty(name)) {
            return;
        }
        console.log('loading profile', name, profileMap[name]);
        Object.assign(FilterSettings, profileMap[name]);
        this.loadSettingsInto($('#Filters'));
        // Load multi-select tree dropdown filters
        $('.wrapper-dropdown.tree-config').toArray().map(e => $(e)).map($ddw => {
            const $dd = $ddw.find('.dropdown.dropdown-tree');
            const dd = $ddw.data('DropDown');
            if ($ddw.length && $dd.length && dd !== undefined) {
                const id = $ddw.attr('id');
                if (profileMap[name]?.hasOwnProperty(id)) {
                    dd.restoreState(profileMap[name][id]);
                    // loadTreeDropDownFilters({ $ddw, $dd, $title: $ddw.find(' > span') })
                }
            }
        });
        // Load multi-select dropdown filters
        $('.wrapper-dropdown:not(.tree-config)').toArray().map(e => $(e)).map($ddw => {
            const id = $ddw.attr('id');
            const dd = $ddw.data('DropDown');
            if (id && dd !== undefined) {
                if (profileMap[name]?.hasOwnProperty(id)) {
                    dd.restoreState(profileMap[name][id]);
                }
            }
        });
        $('#Filters :input').trigger('change');
    }
    loadSettingsInto($filters, data = FilterSettings, reset = false) {
        if (!data)
            throw new Error(`FilterSettings is missing!`);
        try {
            if (!data.Limit) {
                this.setDefaultLimit();
            }
            if (data.FromDate == null || data.ToDate == null) {
                this.setDefaultDate();
            }
            const excludedInputs = ['FromDate', 'ToDate'];
            // Set filter inputs
            Object.keys(data).map((key) => {
                if (!excludedInputs.includes(key)) {
                    let $input = $filters.find(key);
                    if ($input.length === 0 && key.length > 0) {
                        $input = $filters.find(`#${key}`);
                    }
                    $input.val(data[key]);
                }
            });
            // Set filter select inputs
            $filters.find('select').each((i, ele) => {
                const $select = $(ele);
                const id = $select.attr('id');
                if (data.hasOwnProperty(id)) {
                    const $selection = $select.find(`option[value="${data[id]}"]`);
                    if ($selection.length) {
                        $select.val(data[id]);
                    }
                    else {
                        $select.val($select.find('option').first().val() || '');
                    }
                }
            });
            $filters.find('#FromDate').val(AInputDate(new Date(data.FromDate)));
            $filters.find('#FromTime').val(AInputTime(new Date(data.FromDate)));
            $filters.find('#ToDate').val(AInputDate(new Date(data.ToDate)));
            $filters.find('#ToTime').val(AInputTime(new Date(data.ToDate)));
            $filters.find('input[type="checkbox"][id]').toArray().map(e => $(e)).map($checkbox => {
                if (data.hasOwnProperty($checkbox.attr('id'))) {
                    $checkbox.prop('checked', data[$checkbox.attr('id')]);
                    $checkbox.trigger('change');
                }
            });
            if (reset === true) {
                this.reset();
            }
        }
        catch (err) {
            AError.handle(err);
        }
    }
    isElementAllowedToEnable({ $filters, $watchEle, ele }) {
        if (!$watchEle && ele) {
            const id = $(ele).attr('enabled-if');
            $watchEle = $filters.find(`#${id}`);
        }
        if ($watchEle.is('[type="checkbox"]')) {
            const isChecked = $watchEle.prop('checked');
            const isDisabled = $watchEle.is('[disabled]');
            return (isChecked && !isDisabled);
        }
        else {
            const val = $watchEle.val();
            const hasValue = val && val.toString().length && val !== '%';
            const isDisabled = $watchEle.is('[disabled]');
            return (hasValue && !isDisabled);
        }
    }
    listenToChanges($filters) {
        $filters.find('[type="checkbox"]').on('change', function () {
            // @ts-ignore
            const $checkbox = $(this);
            const $listener = $filters.find(`[enabled-if="${$checkbox.attr('id')}"]`);
            if ($listener.length === 0) {
                return;
            }
            // @ts-ignore
            $listener.prop('disabled', !this.checked);
        });
        $filters.find('select').on('change', function () {
            // @ts-ignore
            const $select = $(this);
            const $listener = $filters.find(`[enabled-if="${$select.attr('id')}"]`);
            if ($listener.length === 0) {
                return;
            }
            if ($listener.is('[type="checkbox"]')) {
                const val = $select.val();
                const hasValue = val && val.toString().length && val !== '%';
                if (!hasValue) {
                    $listener.prop('checked', false);
                }
            }
            else {
                console.error(`$listener for [type="${$listener.attr('type')}"] Not Implemented Yet!`);
            }
        });
        $filters.find('[enabled-if]').each((_, ele) => {
            const $watchEle = $filters.find(`#${$(ele).attr('enabled-if')}`);
            $(ele).prop('disabled', !this.isElementAllowedToEnable({ $filters, $watchEle }));
            $watchEle.on('FilterManager.change change', () => {
                const isAllowed = this.isElementAllowedToEnable({ $filters, $watchEle });
                $(ele).prop('disabled', !isAllowed);
            });
        });
    }
    get betweenLastWeekAndNow() {
        return { FromDate: AInputDateTime(this.weekAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastWeekAndNowDay() {
        return {
            FromDate: AInputDateTime(moment(this.weekAgoDate).startOf('day').toDate()),
            ToDate: AInputDateTime(moment(new Date()).add(1, 'day').startOf('day').toDate())
        };
    }
    get betweenLastMonthAndNow() {
        return { FromDate: AInputDateTime(this.monthAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastMonthAndNowDay() {
        return {
            FromDate: AInputDateTime(moment(this.monthAgoDate).startOf('day').toDate()),
            ToDate: AInputDateTime(moment(new Date()).add(1, 'day').startOf('day').toDate())
        };
    }
    get betweenLastHalfYearAndNow() {
        return { FromDate: AInputDateTime(this.sixMonthsAgoDate), ToDate: AInputDateTime(new Date()) };
    }
    get betweenLastHalfYearAndNowDay() {
        return {
            FromDate: AInputDateTime(moment(this.sixMonthsAgoDate).startOf('day').toDate()),
            ToDate: AInputDateTime(moment(new Date()).add(1, 'day').startOf('day').toDate())
        };
    }
    get betweenLastYearAndNowDay() {
        return {
            FromDate: AInputDate(moment(new Date()).subtract(12, 'months').startOf('day').toDate()),
            ToDate: AInputDateTime(moment(new Date()).add(1, 'day').startOf('day').toDate())
        };
    }
    get betweenYesterdayAndNow() {
        const { yesterdayDate } = FilterManager;
        return { FromDate: yesterdayDate, ToDate: new Date() };
    }
    get betweenYesterdayAndToday() {
        const { yesterdayDate, todayDate } = FilterManager;
        return { FromDate: yesterdayDate, ToDate: todayDate };
    }
    get betweenTodayAndTomorrow() {
        const { todayDate, tomorrowDate } = FilterManager;
        return { FromDate: todayDate, ToDate: tomorrowDate };
    }
    get betweenNowAndHourAgo() {
        return {
            FromDate: moment(new Date()).subtract(1, 'hour').toDate(),
            ToDate: moment(new Date()).toDate(),
        };
    }
    get nextWeekDate() {
        return moment(new Date()).add(1, 'weeks').toDate();
    }
    get todayDate() {
        const now = new Date();
        return new Date((now.getTimezoneOffset() * 60 * 1000) + now.getTime() - (now.getTime() % (24 * 60 * 60 * 1000)));
    }
    get tomorrowDate() {
        return new Date(this.todayDate.getTime() + this.ONE_DAY);
    }
    get yesterdayDate() {
        return new Date(this.todayDate.getTime() - this.ONE_DAY);
    }
    get weekAgoDate() {
        return moment(new Date()).subtract(1, 'weeks').toDate();
    }
    get monthAgoDate() {
        return moment(new Date()).subtract(1, 'months').toDate();
    }
    get sixMonthsAgoDate() {
        return moment(new Date()).subtract(6, 'months').toDate();
    }
    get startOfWeek() {
        return moment(new Date()).startOf('isoWeek').toDate();
    }
    get endOfWeek() {
        return moment(new Date()).endOf('isoWeek').toDate();
    }
    get startOfNextWeek() {
        // @ts-ignore
        return moment(new Date()).add(1, 'weeks').startOf('isoWeek').toDate();
    }
    get endOfNextWeek() {
        // @ts-ignore
        return moment(new Date()).add(1, 'weeks').endOf('isoWeek').toDate();
    }
    get startOfMonth() {
        return moment(new Date()).startOf('month').toDate();
    }
    get endOfMonth() {
        return moment(new Date()).endOf('month').toDate();
    }
    get startOfPrevMonth() {
        return moment(new Date()).subtract(1, 'months').startOf('month').toDate();
    }
    get endOfPrevMonth() {
        return moment(new Date()).subtract(1, 'months').endOf('month').toDate();
    }
    get ONE_DAY() {
        return (24 * 60 * 60 * 1000);
    }
    get ONE_HOUR() {
        return (60 * 60 * 1000);
    }
    get today() {
        return AInputDate(new Date(this.todayDate));
    }
    get yesterday() {
        const yesterday = new Date(this.todayDate.getTime() - this.ONE_DAY);
        return AInputDate(new Date(yesterday));
    }
    get tomorrow() {
        const tomorrow = new Date(this.todayDate.getTime() + this.ONE_DAY);
        return AInputDate(new Date(tomorrow));
    }
    get unixYesterday() {
        return new Date(this.todayDate.getTime() - (24 * 60 * 60 * 1000));
    }
    get unixYesterday1() {
        return new Date(this.todayDate.getTime() - (24 * 60 * 60 * 1000)).getTime();
    }
    get unixToday() {
        return Date.now();
    }
    get unixTomorrow() {
        return new Date(this.todayDate.getTime() + (24 * 60 * 60 * 1000));
    }
    get unixTomorrow1() {
        return new Date(this.todayDate.getTime() + (24 * 60 * 60 * 1000)).getTime();
    }
    get unixMonthAgo() {
        const lastMonth = this.todayDate;
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return (lastMonth);
    }
    subtractMonth(date) {
        return moment(date).subtract(1, 'months').toDate();
    }
    getDateFilters() {
        const $FromDate = $('#FromDate'), $FromTime = $('#FromTime'), $ToDate = $('#ToDate'), $ToTime = $('#ToTime');
        const hasAllInputs = (inputs) => { for (let $input of inputs) {
            if ($input.length === 0)
                return false;
        } return true; };
        if (hasAllInputs([$FromDate, $FromTime, $ToDate, $ToTime])) {
            const FromDate = new Date($FromDate.val() + ' ' + $FromTime.val());
            const ToDate = new Date($ToDate.val() + ' ' + $ToTime.val());
            return { FromDate, ToDate };
        }
        else {
            return null;
        }
    }
    save(options) {
        const { $filters, cacheFilters, maxLimit } = Object.assign({}, {
            $filters: $('#Filters'),
            cacheFilters: true,
            maxLimit: AConfig.get('filters.maxResultsCeiling', 100000)
            // maxLimit: configService.get('filters.maxResults').maxResultsCeiling
        }, options);
        const output = {};
        if ($('#FromDate').length && $('#ToDate').length) {
            const dates = this.getDateFilters();
            if (dates) {
                const FromDate = dates.FromDate.toJSON();
                const ToDate = dates.ToDate.toJSON();
                Object.assign(output, { FromDate, ToDate });
                if (cacheFilters) {
                    Object.assign(FilterSettings, { FromDate, ToDate });
                }
            }
        }
        $filters.find(':input').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            let value = $ele.is(':checkbox') ? $ele.prop('checked') : $ele.val(); // $ele.val()
            if (id === 'Limit' && Number(value) > maxLimit) {
                value = maxLimit;
            }
            const hasDropdown = $ele.parents('.dropdown').length > 0;
            if (!hasDropdown && !['FromDate', 'FromTime', 'ToDate', 'ToTime', 'RefreshButton'].includes(id)) {
                // this.log(id + ' ' + value)
                output[id] = value;
                if (cacheFilters) {
                    FilterSettings[id] = value;
                }
            }
        });
        $filters.find('.wrapper-dropdown:not(.tree-config)').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            const { selectedTextsQuery } = $ele.data('DropDown');
            output[id] = selectedTextsQuery;
            if (cacheFilters) {
                FilterSettings[id] = selectedTextsQuery;
            }
        });
        $filters.find('.wrapper-dropdown:not(.tree-config) :input').each((i, ele) => {
            const $ele = $(ele);
            const id = $ele.attr('id');
            const value = $ele.is(':checkbox') ? $ele.prop('checked') : undefined;
            if (id && value !== undefined) {
                this.multiSelectCache[id] = value;
            }
        });
        $filters.find('.wrapper-dropdown.tree-config').each((_, ddw) => {
            const $ddw = $(ddw);
            const id = $ddw.attr('id');
            const { selectedTextsQuery } = $ddw.data('DropDown');
            output[id] = selectedTextsQuery;
            if (cacheFilters) {
                FilterSettings[id] = selectedTextsQuery;
            }
        });
        return output;
    }
    /**
     * Created "IN (..., ..., ...) Statement"
     * Example: WHERE username IN ('ivan', 'jaap', 'wil')
     * Exclusion example: WHERE username NOT IN ('ivan', 'jaap', 'wil')
     * @param {*} array array of values to look for (or not to look for)
     */
    buildQueryFindInArray(array) {
        if (array instanceof AExclusionArray) {
            return (`NOT IN ('${array.join(`','`)}')`);
        }
        if (array instanceof Array) {
            return (`IN ('${array.join(`','`)}')`);
        }
        throw new Error(`array is not an instance of Array or AExclusionArray`);
    }
    /**
     * Only return the filled in values.
     * So the '%' values are excluded
     */
    saveExplicit(options) {
        const filters = this.save(options);
        Object.keys(filters).filter(key => filters[key] === '%' || filters[key].length === 0).map(key => {
            delete filters[key];
        });
        if (options?.parseNumbers) {
            Object.keys(filters).map(key => {
                if (/[0-9]+/g.test(filters[key]) && !isNaN(Number(filters[key]))) {
                    filters[key] = Number(filters[key]);
                }
            });
        }
        return filters;
    }
    /**
     * Transforms array of conditions to where clause seperated with 'AND' operator
     * @param {*} conditions
     */
    toWhere(conditions) {
        if (conditions.length === 0) {
            return '1=1';
        }
        return conditions.join(' AND ');
    }
    /**
     * Extract filters like ['FromDate', 'ToDate', 'Images', 'Limit]
     * @param {*} filters
     * @param {*} keys
     */
    extractFromFilters(filters, keys) {
        const extracted = {};
        keys.map(key => {
            if (filters.hasOwnProperty(key)) {
                extracted[key] = filters[key];
            }
        });
        return extracted;
    }
    /**
     * Generate SQL where clause conditions for query filters
     * EXAMPLE:
     * ['ParkingRightType = :ParkingRightType', 'DetectionDevice = :DeviceName', 'VerificationResult = :VerifyResult']
     * @param {*} filters filters created by FilterManager.save() or FilterManager.saveExplicit()
     */
    generateConditionsSQL(filters) {
        const output = [];
        const specialConditions = this.extractFromFilters(filters, ['FromDate', 'ToDate', 'Images', 'Limit']);
        const { FromDate, ToDate } = specialConditions;
        if (FromDate && ToDate) {
            output.push(`DetectionTime BETWEEN :FromDate AND :ToDate`);
        }
        Object.keys(filters).map(key => {
            // Skip conditions that are special like ['FromDate', 'ToDate', 'Images', 'Limit']
            if (!specialConditions.hasOwnProperty(key)) {
                output.push(`${key} = :${key}`);
            }
        });
        return output;
    }
    /**
     *
     * @param {*} filters
     */
    generateWhereSQL(filters) {
        const conditions = this.generateConditionsSQL(filters);
        if (conditions.length > 0) {
            return `WHERE ${conditions.join(' AND ')}`;
        }
        return '';
    }
    createToggle($toggleShortcut, $eleToToggle, isCollapsed, prefKey = 'filters-shortcut-collapsed') {
        const preferenceService = AEngine.get(APreferenceService);
        const toggle = (hide) => {
            $toggleShortcut.find('i').toggleClass('fa-rotate-180', hide);
            $eleToToggle.toggleClass('hidden', hide);
        };
        $toggleShortcut.on('click', (e) => {
            const isHidden = $eleToToggle.hasClass('hidden');
            toggle(!isHidden);
            if (prefKey)
                preferenceService.save(prefKey, isHidden);
        });
        toggle(!isCollapsed);
    }
    selectShortcut(classIdentifier) {
        this.removeSelectedShortcutHighlight();
        this.selectedShortcut = classIdentifier;
        this.highlightSelectedShortcut();
    }
    unselectShortcut() {
        this.removeSelectedShortcutHighlight();
        this.selectedShortcut = null;
    }
    highlightSelectedShortcut() {
        if (this.selectedShortcut) {
            $('#Filters').find(this.selectedShortcut).addClass('active');
        }
    }
    removeSelectedShortcutHighlight() {
        $('#Filters').find(this.selectedShortcut).removeClass('active');
    }
    /**
     * Set Filter Inputs Active or Inactive
     */
    setActive(active, options = { silent: false }) {
        const $filters = options.$filters ?? $('#Filters');
        const $input = $filters.find(':input:not([enabled-if])').filter(function () {
            return $(this).closest('.filter-profile-buttons').length === 0;
        });
        const $allInputs = $filters.find(':input');
        if (active === true) {
            $filters.removeClass('disabled');
            EnableItem($input, options);
        }
        else {
            $filters.addClass('disabled');
            DisableItem($input, options);
        }
        $allInputs.trigger('FilterManager.change', [options]);
    }
    reset() {
        if (!FilterSettings)
            throw new Error(`Coudln't find FilterSettings!`);
        for (let key in FilterSettings) {
            delete FilterSettings[key];
        }
    }
}

import { AError } from "../../classes/AError.js";
import { AForm } from "../../core/form/AForm.js";
import { COLUMN_ACTION, COLUMN_DATETIME, COLUMN_HIDDEN, COLUMN_PRICE_NO_CURR, COLUMN_TEXT, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { ASearchHandler } from "../../classes/grid/ASearchHandler.js";
import { AOffenceOrm } from "../../orm/AOffenceOrm.js";
import { ALERT_BUTTONS, ALERT_STATUS, ALERT_TITLES } from "../../services/AAlertService.js";
import { AConvertToGridColumns, AConvertToGridData, appendResponseRows, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.offenceOrm = new AOffenceOrm();
        this.searchHandler = new ASearchHandler();
    }
    async init() {
        // this.translations = await Loading.waitForPromises(Translate.get(['All']))
        $('#create-btn').on('click', (e) => this.displayCreateModal());
        Loading.waitForPromises(this.refresh());
    }
    async genFormInputs(record) {
        const inputs = [
            { id: 'OffenceCode', type: 'text', minlength: 1, maxlength: 64, disabled: record !== undefined },
            { id: 'OffenceText', type: 'text', minlength: 1, maxlength: 255 },
            { id: 'OffenceDescription', type: 'textarea', minlength: 3, maxlength: 10000 },
            { id: 'OffencePrice', type: 'number', step: '0.01' },
        ];
        return inputs;
    }
    async displayCreateModal(record) {
        const formInputs = await this.genFormInputs(record);
        const events = Alerts.show({
            title: ALERT_TITLES.Info,
            buttons: ALERT_BUTTONS.saveCancel,
            content: await Loading.waitForPromises(AForm.genForm(formInputs, { translate: true }))
        });
        const $form = events.$ele.find('form');
        await AForm.injectFormData($form, { formData: record, formInputs });
        await AForm.initFormValidation($form, formInputs);
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                if ($form.find('.is-error:not(.hidden)').length > 0) {
                    Alerts.incomplete();
                    return false;
                }
                const options = AForm.extractFormData($form, { ignoreWildcards: false });
                const success = await Loading.waitForPromises(record === undefined ?
                    this.offenceOrm.create(options) : this.offenceOrm.update(options));
                if (!success) {
                    Alerts.show({
                        title: ALERT_TITLES.Error,
                        content: await Translate.get(`${options.OffenceCode || 'Entry'} Already Exists!`)
                    });
                }
                return success;
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async displayDeleteModal(record) {
        const alert = Alerts.show({
            translatedTitle: await Loading.waitForPromises(Translate.get('Delete PRDB Item')),
            buttons: ALERT_BUTTONS.yesNo,
            content: await Loading.waitForPromises(Translate.get(/*html*/ `
        Are you sure you want to delete this item?
      `))
        });
        alert.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            try {
                await Loading.waitForPromises(this.offenceOrm.delete(record));
            }
            catch (err) {
                AError.handle(err);
            }
            finally {
                Loading.waitForPromises(this.refresh());
            }
        });
    }
    async refresh() {
        try {
            validateEnforcementProcessService.validate();
            const ares = await this.offenceOrm.fetchAll();
            const response = ares.Original;
            appendResponseRows(response, ['ActionEdit', 'ActionDelete']);
            this.grid = AShowTable({
                appendTo: 'bryntum-table',
                aci: {
                    resizeToFit: true,
                    resizeToFitReverse: true,
                    skipResizeColumns: [0, 1],
                    flex: 1,
                },
                features: {
                    search: true
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        showHitIndex: false,
                        listeners: {
                            // input: 'onSearchFieldInput',
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this.searchHandler
                        }
                    },
                ],
                selectionMode: {
                    multiSelect: false
                },
                columns: AConvertToGridColumns(response, {
                    'OffenceCode': COLUMN_TEXT,
                    'OffenceText': COLUMN_TEXT,
                    'OffenceDescription': COLUMN_HIDDEN,
                    'OffencePrice': COLUMN_PRICE_NO_CURR,
                    'ModificationUser': COLUMN_TEXT,
                    'ModificationDevice': COLUMN_TEXT,
                    'ModificationTime': COLUMN_DATETIME,
                    'ActionEdit': COLUMN_ACTION({
                        iconCls: 'fa-solid fa-pencil text-primary',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            this.displayCreateModal(record.data);
                        },
                    }),
                    'ActionDelete': COLUMN_ACTION({
                        iconCls: 'fa-regular fa-trash text-red',
                        btnCls: 'btn-white',
                        onClick: ({ record }) => {
                            this.displayDeleteModal(record.data);
                        },
                    }),
                }),
                data: AConvertToGridData(response, {
                    'ModificationTime': DATA_DATETIME
                }),
            });
            this.grid.on('search', () => this.searchHandler.applyFilter());
            this.grid.store.addSorter((a, b) => {
                const l = a.OffenceCode.split('|');
                const r = b.OffenceCode.split('|');
                const v = l[0].localeCompare(r[0]);
                return v !== 0 ? v : l[1].localeCompare(r[1]);
                // return b.OffenceCode.padEnd(10, '0').localeCompare(a.OffenceCode.padEnd(10, '0'))
            });
        }
        catch (err) {
            AError.handle(err);
        }
    }
}
export function css() {
    return ( /*html*/`
  <style>
    #ChannelCode {
      text-transform: UPPERCASE;
    }
  </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Rapport" class="flex-child bryntum-container has-footer-2" style="width: 100%">
      <div class="fh">
        <div class="fh" style="overflow-y: auto">
          <div class="aci-tabs hidden" tabgroup="views">
            <button class="aci-tab active" tab="tab-grid-view"><span>Grid View</span></button>
            <button class="aci-tab" tab="tab-form-view"><span>Form View</span></button>
          </div>
          <div class="columns col-gapless fh">
            <div class="column col-12">
              <div tabgroup="views" tabview="tab-grid-view" class="fh">
                <div id="bryntum-table" class="fh"></div>
              </div>
              <div tabgroup="views" tabview="tab-form-view" class="fh" style="overflow-y: auto;">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div tabgroup="views" tabview="tab-grid-view">
        <div class="columns footer aci">
          <div class="column col-2">
            <div id="count" class="text">Viewing <span>0</span> Offences</div>
          </div>
          <div class="column col-2 col-ml-auto">
            <button id="create-btn" class="btn btn-primary col-12">Create Offence</button>
          </div>
        </div>
      </div>
    </div>
  `);
}

import { AError } from "../../classes/AError.js";
import { escapeHtml } from "../../utils/json.js";
import { _getEle$ } from "../../utils/maps.js";
import { AInputDate, AInputTime, AIsDate, debounce, generateTreeDropdown, isValidJson, mergeDeep } from "../../utils/tools.js";
import { AForm } from "./AForm.js";
import { AFormMapper } from "./AFormMapper.js";
export class AFormInstance {
    get formInputs() { return this.opt.formInputs; }
    constructor(opt) {
        this.opt = opt;
        // this.formData = mergeDeep({}, opt.defaultValue)
        this.formData = mergeDeep({}, this.opt.defaultValue);
        this.setInternalFormData();
    }
    setInternalFormData(formData) {
        this.formData = mergeDeep({}, this.opt.defaultValue, formData);
        return this.formData;
    }
    $form() {
        if (this.$ele === undefined) {
            throw new Error(`$form is only accessible after calling AFormInstance.generate!`);
        }
        return this.$ele;
    }
    async generate(opt) {
        if (this.$ele !== undefined) {
            throw new Error(`$form is already generated, use AFormInstance.$form() to access the reference!`);
        }
        this.$ele = await AForm.genForm(this.formInputs, opt);
        this.$ele.find(':input').on('change input keyup', (originalEvent) => {
            this.$ele?.trigger('aci-change', [this]);
        });
        return this.$ele;
    }
    async injectFormData(opt) {
        const $form = this.$form();
        // const formData = mergeDeep(this.formData, this.opt.defaultValue, opt?.formData)
        const formData = this.setInternalFormData(opt?.formData);
        const triggerChange = opt?.triggerChange ?? true;
        const promises = $form.find('[name]').toArray().map(inp => $(inp)).map(async ($inp) => {
            const key = $inp.attr('name');
            const type = $inp.attr('aci-type');
            const hasData = formData.hasOwnProperty(key);
            const value = formData[key];
            switch (type) {
                case 'number':
                case 'text':
                case 'jsontext':
                case 'password':
                case 'textarea':
                case 'color':
                    if (hasData) {
                        $inp.val(value);
                    }
                    break;
                case 'checkbox':
                    if (hasData) {
                        $inp.prop('checked', value);
                    }
                    break;
                case 'time':
                    if (hasData) {
                        if (value === null) {
                            $inp.val(AInputTime(new Date()));
                        }
                        else if (Object.prototype.toString.call(value) === '[object Date]') {
                            $inp.val(AInputTime(value));
                        }
                        else if (typeof value === 'string' && AIsDate(value)) {
                            $inp.val(AInputTime(new Date(value)));
                        }
                        else {
                            $inp.val(value);
                        }
                        // $inp.val(AInputTime(val ?? new Date()))
                    }
                    break;
                case 'date':
                    if (hasData) {
                        if (value === null) {
                            $inp.val(AInputDate(new Date()));
                        }
                        else if (Object.prototype.toString.call(value) === '[object Date]') {
                            $inp.val(AInputDate(value));
                        }
                        else if (typeof value === 'string' && AIsDate(value)) {
                            $inp.val(AInputDate(new Date(value)));
                        }
                        else {
                            $inp.val(value);
                        }
                        // const val = (value instanceof Date) ? value : new Date(value)
                        // $inp.val(AInputDate(val ?? new Date()))
                    }
                    break;
                case 'select':
                    if (hasData) {
                        $inp.val(value);
                        if ($inp.val() === null) {
                            $inp.val($inp.find('option:first-child').val());
                        }
                    }
                    break;
                case 'multiselect':
                    const inputConf = this.formInputs.find(conf => conf.type === 'multiselect' && conf.id === key);
                    await filterService.fillDropDownTemplate($inp, inputConf?.options || []);
                    break;
                case 'treedropdown':
                    const inputConfTree = this.formInputs.find(conf => conf.type === 'treedropdown' && conf.id === key);
                    const dd = await generateTreeDropdown($inp, inputConfTree.options);
                    dd.context.useIndices = false;
                    if (value) {
                        dd.context.restoreState(value);
                    }
                    break;
                case 'duration':
                    if (hasData) {
                        // console.log('duration', value)
                        const [hh, mm] = (value ?? '00:00').split(':');
                        const $eles = $inp.find('input');
                        if ($eles.length > 1) {
                            $eles.eq(0).val(hh);
                            $eles.eq(1).val(mm);
                        }
                    }
                    break;
                case 'rangeslider':
                    if (hasData) {
                        $inp.prop('value1', value.min);
                        $inp.prop('value2', value.max);
                    }
                    break;
                case 'button':
                    break;
                default:
                    AError.handleSilent(`AForm.injectFormData Unexpected form input type! key=${key} aci-type=${type}`);
                    break;
            }
            if (triggerChange) {
                $inp.trigger('change');
            }
        });
        await Loading.waitForPromises(promises);
    }
    async initFormValidation() {
        const $form = this.$form();
        const _genFormInputsObj = {};
        const _genFormInputs = this.formInputs.map((formInput) => {
            formInput.$input = $form.find(`#${formInput.id}`);
            formInput.toggle = (visible) => {
                let $inputWrapper = formInput.$input.closest(`[contains="${formInput.id}"]`);
                $inputWrapper = $inputWrapper.length ? $inputWrapper : formInput.$input.closest(`.form-group`);
                $inputWrapper.toggleClass('hidden', !visible);
            };
            formInput.setActive = (enabled) => {
                formInput.$input.prop('disabled', !enabled);
                formInput.$input?.toggleClass('disabled', !enabled);
                if (formInput.type === 'treedropdown') {
                    let dd = formInput.$input?.data('DropDown');
                    console.log('dd', dd);
                    dd.updateState();
                }
            };
            _genFormInputsObj[formInput.id] = formInput;
            return formInput;
        });
        const onChangeMap = {
            'number': () => 'aci change',
            'text': () => 'aci change keyup keypress',
            'jsontext': () => 'aci change keyup keypress',
            'password': () => 'aci change',
            'textarea': () => 'aci change',
            'button': () => 'aci click',
            'date': () => 'aci change',
            'time': () => 'aci change',
            'duration': () => 'aci change keyup',
            'checkbox': () => 'aci change',
            'color': () => 'aci change',
            'select': () => 'aci change',
            'multiselect': () => 'aci change',
            'treedropdown': () => 'aci change',
            'rangeslider': () => 'aci debounce',
            'seperator': () => 'aci',
        };
        for (let input of _genFormInputs) {
            const $inp = input.$input;
            const { minlength, maxlength, regexAllow } = input;
            if (input.disabled === true) {
                $inp.prop('disabled', true);
            }
            if (regexAllow !== undefined) {
                $inp.on('keypress', (e) => {
                    // console.log(e.key, regexAllow.test(e.key))
                    onChange(input, _genFormInputs);
                    return regexAllow.test(e.key);
                });
            }
            // const $inp = $form.find(`#${input.id}`) as JQuery
            // const overrideDisabled = input.overrideDisabled ?? (($inp, inputs) => (false))
            const overrideHasError = input.overrideHasError ?? (($inp, hasError) => hasError);
            const onChange = input.onChange ?? ((input, formInputs) => { });
            const isDisabled = input.isDisabled ?? ((opt) => input.disabled ?? false);
            const hasErrorPremises = [];
            const isDisabledPremises = [];
            if (input.type === 'jsontext') {
                hasErrorPremises.push(() => {
                    const formData = this.extractFormData({ cleanData: true });
                    const jsontext = formData[input.id];
                    return !isValidJson(jsontext);
                });
            }
            else if (input.type === 'select') {
                hasErrorPremises.push(() => {
                    if (input.skipTranslate === true) {
                        const $optArr = input.$input.find('option').toArray().map(e => $(e));
                        $optArr.map($opt => {
                            const attr = $opt.attr('safetext') || '';
                            if (attr.length > 0) {
                                const decodedText = decodeURIComponent(attr);
                                const escapedText = escapeHtml(decodedText);
                                $opt.text(escapedText);
                            }
                        });
                    }
                    const v = ($inp.val() || '');
                    const isError = (input.disallowNone === true) ? !(v && v.length && v !== 'null') : false;
                    return isError;
                });
            }
            else if (input.type === 'multiselect') {
                hasErrorPremises.push(() => {
                    const dd = $inp.data('DropDown');
                    const disabled = dd.isDisabled();
                    const validated = dd.validate();
                    const isError = !disabled && !validated;
                    // if (input.disallowNone) {}
                    return isError;
                });
            }
            else if (input.type === 'rangeslider') {
                $inp.on('change', (e) => {
                    const trigger = debounce(() => $inp.trigger('debounce'), 300);
                    trigger();
                });
            }
            const textValidationInputs = ['text', 'jsontext', 'password', 'textarea'];
            if (textValidationInputs.includes(input.type)) {
                if (minlength !== undefined) {
                    hasErrorPremises.push(() => {
                        const isError = ($inp.val().length || 0) < minlength;
                        return isError;
                        // onChange(input, _genFormInputs)
                        // $inp.toggleClass('is-error', isError)
                    });
                }
                if (maxlength !== undefined) {
                    hasErrorPremises.push(() => {
                        const isError = ($inp.val().length || 0) >= maxlength;
                        return isError;
                        // onChange(input, _genFormInputs)
                        // $inp.toggleClass('is-error', isError)
                    });
                }
            }
            if (input.isDisabled !== undefined) {
                isDisabledPremises.push(() => {
                    return isDisabled({
                        $inp,
                        formInputs: _genFormInputs,
                        formInputsMap: _genFormInputsObj,
                        formData: this.extractFormData({ cleanData: true })
                    });
                });
            }
            // Cover all events
            const eventKey = onChangeMap[input.type]();
            $inp.on(eventKey, (e) => {
                const hasErrorInternal = hasErrorPremises.map(hasErrorFn => {
                    return hasErrorFn();
                }).includes(true);
                const hasError = overrideHasError($inp, hasErrorInternal);
                const isDisabled = isDisabledPremises.map(isDisabledFn => {
                    return isDisabledFn();
                }).includes(true);
                if (input.type === 'checkbox') {
                    // this.formData[input.id] = this.extractFormField(input.id)
                    let newFormData = this.extractFormData({ cleanData: false });
                    _genFormInputs.map(input => {
                        if (input.isDisabled) {
                            const disabled = input.isDisabled({
                                $inp,
                                formInputs: _genFormInputs,
                                formInputsMap: _genFormInputsObj,
                                formData: newFormData,
                            });
                            let prevDisabledState = input.$input.prop('disabled');
                            input.$input.prop('disabled', disabled);
                            input.$input.toggleClass('disabled', disabled);
                            if (input.$input.is('.input-group')) {
                                const $subInputs = input.$input.find(':input');
                                $subInputs.prop('disabled', disabled);
                                $subInputs.toggleClass('disabled', disabled);
                            }
                            if (prevDisabledState !== disabled) {
                                input.$input.trigger('change');
                            }
                        }
                    });
                }
                else if (input.type === 'duration') {
                    const $inpArr = $inp.find('input').toArray().map(inp => $(inp));
                    $inpArr.map(($inp, i) => {
                        $inp.on('change keyup', (e) => {
                            $inp.val($inp.val().padStart(2, '0'));
                            // onChange(input, _genFormInputs)
                        });
                    });
                }
                else if (input.type === 'button') {
                    if (e.type === 'click') {
                        return input.onClick($form);
                    }
                }
                if (input.isDisabled !== undefined) {
                    input.$input.prop('disabled', isDisabled);
                    input.$input.toggleClass('disabled', isDisabled);
                }
                onChange(input, _genFormInputs);
                $inp.toggleClass('is-error', hasError);
                // TODO: Find out if this is needed
                $inp.find(':input').toggleClass('is-error', hasError);
            });
        }
        this.genFormInputs = _genFormInputs;
        this.genFormInputsObj = _genFormInputsObj;
        // const intialFormData = this.extractFormData({ cleanData: false })
        for (let input of _genFormInputs) {
            const eventKeys = onChangeMap[input.type]();
            const [eventKey] = eventKeys.split(' ');
            // TODO: Find out if i should use input.onChange or trigger
            input.$input.trigger(eventKey); // Uses 'aci' as event key
            if (input.onChange) {
                input.onChange(input, _genFormInputs);
            }
        }
    }
    extractFormData(opt) {
        const $form = this.$form();
        const keyValuePairs = {};
        let $inputArr = $form.find('[name]').toArray().map(ele => $(ele));
        if (this.opt.ignoreInvisible) {
            $inputArr = $inputArr.filter($inp => $inp.is(':visible'));
        }
        $inputArr.map(($input) => {
            const key = $input.attr('name');
            const genFormInput = this.genFormInputsObj[key];
            if (Events.logLevel === 1) {
                console.log(key, genFormInput);
            }
            let keyValue = this.convertInputToKeyValue($input, this.opt);
            if (keyValue !== undefined) {
                let { key, value } = keyValue;
                // let input = this.genFormInputsObj[key as keyof T]!
                // const isDisabled = $input.prop('disabled')
                // if (input.nullIfDisabled && isDisabled) {
                //   keyValuePairs[key] = null
                // } else {
                if (!this.opt.ignoreWildcards || value !== '%') {
                    keyValuePairs[key] = value;
                }
                // if (this.opt.ignoreWildcards !== true) {
                //   keyValuePairs[key] = value
                // } else if (value !== '%') {
                //   keyValuePairs[key] = value
                // }
                // }
            }
        });
        if (opt.cleanData) {
            Object.keys(keyValuePairs).map(key => {
                if (this.genFormInputsObj.hasOwnProperty(key)) {
                    let input = this.genFormInputsObj[key];
                    if (input.nullIfDisabled && input.$input.prop('disabled')) {
                        keyValuePairs[key] = null;
                    }
                }
            });
        }
        if (opt.setInternalFormData) {
            this.setInternalFormData(keyValuePairs);
        }
        return keyValuePairs;
    }
    convertInputToKeyValue($input, opt) {
        const key = $input.attr('name');
        const type = $input.attr('aci-type') || $input.attr('type') || $input.prop('tagName')?.toLowerCase();
        if (!AFormMapper.formToValueMap.hasOwnProperty(type)) {
            throw new Error(`Unexpected aci-type for input: AFormMapper.formToValueMap[${type}]`);
        }
        const func = AFormMapper.formToValueMap[type];
        const output = func(key, $input, opt);
        if (output !== undefined) {
            return output;
        }
        AError.handleSilent(new Error(`Didn't get a valid mapped value for input! key=${key} type=${type} opt=${JSON.stringify(opt)}`));
        console.error(`Didn't get a valid mapped value for input!`, { $input, opt, key, type });
        return { key, value: $input.val() };
    }
    saveFormData(opt) {
        this.extractFormData({ cleanData: opt.cleanData, setInternalFormData: true });
        return this.formData;
    }
    validate() {
        return this.$form().find('.is-error:not(.hidden), :invalid:not(.hidden)').length === 0;
    }
    attachTo(parent) {
        const $p = _getEle$(parent);
        $p.html('');
        $p.append(this.$form());
        return this;
    }
    toJSON() {
        return this.extractFormData({ cleanData: true });
    }
}

import { AError } from "../../classes/AError.js";
import { COLUMN_TEXT, COLUMN_DATETIME, DATA_DATETIME, COLUMN_HIDDEN, COLUMN_ACTION } from "../../classes/AGridTypes.js";
import { AImageHelper } from "../../classes/AImageHelper.js";
import { AEngine } from "../../core/AEngine.js";
import { locationimagerequest_tableformatter } from "../../utils/table_formatter.js";
import { ADragService } from "../../services/ADragService.js";
import { EVENTS } from "../../services/AEventService.js";
import { MAP_OPTIONS, UNLOAD_OPTIONS } from "../../services/AMapHelperService.js";
import { createMap } from "../../utils/maps.js";
import { ACombineDateTime, AConvertToGridColumns, AConvertToGridData, AInputDate, AInputTime, appendResponseRows, AShowTable, ATryCombineDateTime, AUrlEncodedImageFromBase64, getMarkerBounds } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.locationMarkers = [];
        this.locationMarkerMap = {};
        this.prevSearch = '';
        FilterManager.setActive(false);
        this.imageHelper = new AImageHelper();
        this.map = createMap('map', {
            zoom: 24,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: true,
        });
        google.maps.event.addListener(this.map, "click", (event) => {
            this.transferLocation(event.latLng, true);
        });
        // $('#lastPanel .cGoBack button').on('click', (e) => this.goBack())
        // $('#lastPanel .cRequestPhoto').on('click', (e) => this.createForm({ type: 'create' }))
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    async init() {
        FilterManager.load();
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            showLegend: false
        }).catch(AError.handle);
        AEngine.get(ADragService).createDraggable();
        Events.on(EVENTS.CONTENT_RESIZE, _ => {
            google.maps.event.trigger(this.map, 'resize');
            google.maps.event.trigger(this.map.streetView, 'resize');
        });
        this.toggleImageView({ visible: false });
        FilterManager.setActive(true);
        this.map.fit();
        this.refresh(true);
    }
    footer$() {
        const $splitter = $('.splitter');
        const $toggle = $('#toggle-images');
        return { $splitter, $toggle };
    }
    toggleImageView(opt) {
        const { $toggle, $splitter, visible } = Object.assign({}, this.footer$(), opt);
        let show = (visible === undefined) ? $splitter.hasClass('v-expand') : visible;
        $toggle.toggleClass('toggled', !show);
        $toggle.children().map((i, e) => $(e).toggleClass('hidden', i === 0 ? !show : show));
        $splitter.toggleClass('v-expand', !show);
        Events.tryInvoke(EVENTS.CONTENT_RESIZE);
    }
    onSearchPerformed({ find, found }) {
        const me = this, search = me.search;
        // Needed when you search by calling search method manually.
        // Suspend events so as not to cause feedback and do the search twice.
        if (!ObjectHelper.isEqual(search.value, find)) {
            search.suspendEvents();
            search.value = find instanceof Date ? DateHelper.format(find, 'L') : find;
            search.resumeEvents();
        }
        search.badge = found.length;
        DomHelper.focusWithoutScrolling(me.grid.element);
        me.previousBtn.enable();
        me.nextBtn.enable();
        // this.applyFilter()
    }
    onClearPerformed() {
        const me = this;
        // need when you reset search by calling clear method manually
        me.search.value = '';
        me.search.badge = null;
        me.previousBtn.disable();
        me.nextBtn.disable();
    }
    onSearchFieldClear() {
        this.grid.features['search'].clear();
    }
    onSearchFieldChange(a) {
        // console.log('onSearchFieldChange', a)
        this.grid.store.removeFilter('searchFilter');
        this.grid.features['search'].search(a.value, false);
    }
    applyFilter() {
        const { found, find } = this.grid.features['search'];
        if (this.prevSearch === find || find === undefined) {
            return;
        }
        this.prevSearch = find;
        const ids = found.map(row => row.data.id);
        console.log('ids', ids);
        // const searchColumns = columns.visibleColumns.filter(c => c['searchable'] === true).map(c => c['field'])
        this.grid.store.filter({
            id: 'searchFilter',
            filterBy: (record) => ids.includes(record['id']),
        });
    }
    async onMarkerClick(record, context) {
        this.resetSelection(context);
        this.grid.selectedRecord = this.grid.store.find((gridRecord) => {
            return record.LocationId == gridRecord.data.LocationId
                && record.CreationDeviceId == gridRecord.data.CreationDeviceId;
        });
        this.selectedMarker = this.getMarkerByRecord(record);
        this.selectedMarker?.setOptions({
            icon: '/img/marker1-b.png',
        });
        this.smartZoom();
        await Loading.waitForPromises(Promise.all([
            this.showImages(record),
            this.showInfoWindow(record)
        ]));
    }
    async showImages(record) {
        const res = await requestService.fetch({
            AssertValues: true,
            Query: (`SELECT LocationId, CreationDeviceId, ImageDeviceId, ImageIndex, LocationType, Name, ImageTime, Image FROM location_images WHERE LocationId=:LocationId`),
            Params: { LocationId: record.LocationId }
        });
        this.imageHelper.clearImageSet();
        const imageOptions = {
            allowFilter: true,
            allowFullscreen: true,
            defaultSize: {
                height: this.imageHelper.$photos.height()
            }
        };
        const imgSet = res.map(({ Image }) => AUrlEncodedImageFromBase64(Image));
        this.imageHelper.addImageSet(imgSet, imageOptions);
        this.toggleImageView({ visible: imgSet.length > 0 });
    }
    getMarkerByRecord(record) {
        if (!record)
            return undefined;
        return this.locationMarkerMap[record.LocationId]?.find(v => v.data.CreationDeviceId === record.CreationDeviceId);
    }
    smartZoom() {
        if (this.map.getZoom() <= 16)
            this.map.setZoom(20);
    }
    resetSelection(context) {
        if (context === 'map') {
            this.grid.selectedRecord = null;
        }
        this.selectedMarker?.setOptions({ icon: '/img/marker1.png' });
        this.selectedMarker = undefined;
    }
    async showInfoWindow(record) {
        const pos = new google.maps.LatLng({ lat: record.Latitude, lng: record.Longitude });
        const iw = await purgatoryService.buildAndShowInfoWindow({
            marker: pos,
            data: Object.assign({}, record, { _Days: record._Days?.split(',') }),
            sorting: [
                'Status',
                'LocationType',
                'Images',
                'ExpiryDate',
                'BeginTimeFrame',
                'EndTimeFrame',
                'Days',
                '_Days',
                'CreationTime',
                'CreationUser',
                'Description',
                'Latitude',
                'Longitude',
                'LocationId',
                'CreationDeviceId',
            ],
            tableFormatter: locationimagerequest_tableformatter(),
            greyOutFields: true
        });
        this.map.setCenter(pos);
    }
    goBack() {
        if (this.formOpt?.record) {
            this.getMarkerByRecord(this.formOpt?.record)?.setOptions({ visible: true });
        }
        this.removeIndicator();
        $('#secondPanel').hide();
        $('#secondPanel').html();
        $('#firstPanel').show();
        $('#lastPanel .cGoBack').addClass('hidden');
        $('#lastPanel .cRequestPhoto').removeClass('hidden');
    }
    async transferLocation(latLng, updateCrudForm) {
        if ($('#firstPanel:visible').length) {
            this.resetSelection('map');
            return;
        }
        const { lat, lng } = latLng.toJSON();
        if (updateCrudForm) {
            $('#crud-form #Latitude').val(lat);
            $('#crud-form #Longitude').val(lng);
        }
        this.removeIndicator();
        const result = await Loading.waitForPromises(mapHelperService.fetchStreetData(latLng)).catch(err => AError.handleSilent(err));
        if (result.length) {
            $('#crud-form #Address').val(result[0].formatted_address);
        }
        const positionIndicator = new google.maps.Marker({
            position: latLng,
            animation: google.maps.Animation.DROP,
            icon: this.formOpt?.type === 'edit' ? '/img/marker1-b.png' : '/img/marker1-g.png',
            map: this.map,
            zIndex: 10
        });
        this.positionIndicator = positionIndicator;
    }
    removeIndicator() {
        if (this.positionIndicator) {
            mapHelperService.destroy([this.positionIndicator]);
        }
    }
    async copyForm(record) {
        this.getMarkerByRecord(record)?.setOptions({ zIndex: 8 });
        record = JSON.parse(JSON.stringify(record));
        record.CreationUser = undefined;
        record.CreationTime = undefined;
        record.CreationDeviceId = undefined;
        record.LocationId = undefined;
        // this.resetSelection('map')
        return await this.createForm({ type: 'copy', record });
    }
    async editForm(record) {
        this.getMarkerByRecord(record)?.setOptions({ visible: false });
        return await this.createForm({ type: 'edit', record });
    }
    async createForm(opt) {
        this.formOpt = opt;
        const $form = await this.showForm();
        const { record } = opt;
        this.fillOutForm($form, record);
        if (record) {
            this.smartZoom();
            this.transferLocation(new google.maps.LatLng({
                lat: record.Latitude,
                lng: record.Longitude
            }), true);
        }
        $form.find('#submitBtn').on('click', () => Loading.waitForPromises(this.submitForm($form)));
    }
    async showForm() {
        $('#lastPanel .cGoBack').removeClass('hidden');
        $('#lastPanel .cRequestPhoto').addClass('hidden');
        const $form = await this.genForm();
        const $lat = $form.find('#Latitude');
        const $lng = $form.find('#Longitude');
        $form.find('#Latitude, #Longitude').on('change keyup', (e) => {
            const lat = $lat.val();
            const lng = $lng.val();
            if (lat && lat.length > 0 && lng && lng.length > 0) {
                this.transferLocation(new google.maps.LatLng(Number(lat), Number(lng)), false);
            }
        });
        $form.find('.floating-close-btn').on('click', () => this.goBack());
        FilterManager.createToggle($form.find('.toggle-form-more'), $form.find('.form-more'), false, null);
        FilterManager.initDropDown($form.find('#Days'), {});
        $('#secondPanel').html('');
        $('#secondPanel').append($form);
        $('#secondPanel').show();
        $('#firstPanel').hide();
        $('#lastPanel button').removeAttr('disabled');
        return $form;
    }
    parseFormValue($input) {
        const id = $input.attr('id');
        if (id === undefined) {
            throw new Error(`id is undefined for element ${$input.html()}`);
        }
        const type = $input.attr('type');
        switch (type) {
            case 'text':
                return { id, value: $input.val() };
            case 'number':
                const num = Number($input.val());
                if (isNaN(num))
                    throw new Error(`Expected number but got value: ${$input.val()}`);
                return { id, value: num };
            case 'date':
                if (id?.endsWith('Date')) {
                    const $form = $input.closest('form');
                    if ($form.length) {
                        const newId = id.replace('Date', 'Time');
                        const $date = $form.find('#' + newId);
                        if ($date.length) {
                            return;
                        }
                    }
                }
                break;
            case 'time':
                if (id?.endsWith('Time')) {
                    const $form = $input.closest('form');
                    if ($form.length) {
                        const newId = id.replace('Time', 'Date');
                        const $date = $form.find('#' + newId);
                        if ($date.length) {
                            return { id: newId, value: new Date($date.val() + ' ' + $input.val()) };
                        }
                    }
                }
                break;
        }
        return { id, value: $input.val() };
    }
    fillOutForm($form, record) {
        const expiryDateTime = FilterManager.nextWeekDate;
        const dd = $form.find('#Days').data('DropDown');
        dd.selectAll();
        $form.find('#LocationId').val(record?.LocationId || '');
        $form.find('#CreationDeviceId').val(record?.CreationDeviceId || _.getSession().NodeId);
        $form.find('#CreationUser').val(record?.CreationUser || _.getUser().User);
        $form.find('#CreationDate').val(record?.CreationTime ? AInputDate(record?.CreationTime) : '');
        $form.find('#CreationTime').val(record?.CreationTime ? AInputTime(record?.CreationTime) : '');
        $form.find('#ExpiryDate').val(AInputDate(new Date(record?.ExpiryDate || expiryDateTime)));
        $form.find('#ExpiryTime').val(AInputTime(new Date(record?.ExpiryDate || expiryDateTime)));
        $form.find('#Name').val(record?.Name || '');
        if (!record) {
            return;
        }
        $form.find('#LocationType').val(record.LocationType);
        $form.find('#Latitude').val(record.Latitude || '');
        $form.find('#Longitude').val(record.Longitude || '');
        $form.find('#BeginTimeFrame').val(parseAMPM(record.BeginTimeFrame));
        $form.find('#EndTimeFrame').val(parseAMPM(record.EndTimeFrame));
        $form.find('#Description').val(record.Description || '');
        const daysMap = {};
        record.Days?.split(',').map(d => daysMap[d] = true);
        dd.selectNone();
        dd.restoreState(daysMap);
    }
    isFilledOut($form) {
        const LocationId = $form.find('#LocationId').val() || Date.now() + '';
        const CreationDeviceId = $form.find('#CreationDeviceId').val();
        const LocationType = ($form.find('#LocationType').val() || '');
        const Latitude = ($form.find('#Latitude').val() || '');
        const Longitude = ($form.find('#Longitude').val() || '');
        const BeginTimeFrame = ($form.find('#BeginTimeFrame').val() || '');
        const EndTimeFrame = ($form.find('#EndTimeFrame').val() || '');
        const Days = $form.find('#Days').data('DropDown').selectedKeys.join(',');
        const ExpiryDate = ACombineDateTime($form.find('#ExpiryDate').val(), $form.find('#ExpiryTime').val());
        const CreationTime = ATryCombineDateTime($form.find('#CreationDate').val(), $form.find('#CreationTime').val(), {
            elseUse: new Date()
        });
        const CreationUser = $form.find('#CreationUser').val();
        const Name = $form.find('#Name').val();
        const Description = $form.find('#Description').val() || null;
        const begin = ACombineDateTime('1970-01-01', BeginTimeFrame);
        const end = ACombineDateTime('1970-01-01', EndTimeFrame);
        const values = [LocationId, CreationDeviceId, Name, Longitude, Latitude, Days, BeginTimeFrame, EndTimeFrame];
        const nullValues = values.filter(v => v === undefined || v.length === 0);
        if (CreationTime > ExpiryDate || begin > end) {
            Alerts.incorrectDate();
            return { success: false };
        }
        else if (nullValues.length > 0) {
            Alerts.incomplete();
            return { success: false };
        }
        return {
            success: true,
            rowData: {
                LocationId,
                CreationDeviceId,
                Name,
                LocationType,
                GpsPoint: `POINT(${Longitude} ${Latitude})`,
                BeginTimeFrame,
                EndTimeFrame,
                Days,
                ExpiryDate,
                CreationTime,
                CreationUser,
                Description
            }
        };
    }
    async submitForm($form) {
        const { success, rowData } = this.isFilledOut($form);
        try {
            if (success) {
                const record = await this.submitLocationImageRequest(rowData);
                let recordModel = this.grid.store.find((gridRecord) => {
                    return record.LocationId == gridRecord.data.LocationId
                        && record.CreationDeviceId == gridRecord.data.CreationDeviceId;
                });
                if (recordModel !== undefined) {
                    // @ts-ignore
                    recordModel.set(record, false);
                    this.grid.refreshRows();
                }
                else {
                    recordModel = this.grid.store.insert(0, record).pop();
                }
                this.onMarkerClick(recordModel.data, 'table');
                console.log({ record });
            }
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            if (success) {
                this.goBack();
            }
        }
    }
    async submitLocationImageRequest(rowData) {
        await requestService.query({
            Name: 'CRUD Location Image Request',
            Query: (`
        INSERT INTO locations (
          LocationId, CreationDeviceId, LocationType, Name, GpsPoint,
          BeginTimeFrame, EndTimeFrame, Days, ExpiryDate, CreationTime,
          CreationUser, Description
        ) VALUES (
          :LocationId, :CreationDeviceId, :LocationType, :Name, ST_GeomFromText(:GpsPoint, 4326),
          :BeginTimeFrame, :EndTimeFrame, :Days, :ExpiryDate, :CreationTime,
          :CreationUser, :Description
        ) ON DUPLICATE KEY UPDATE
          LocationType=:LocationType,
          Name=:Name,
          GpsPoint=ST_GeomFromText(:GpsPoint, 4326),
          BeginTimeFrame=:BeginTimeFrame,
          EndTimeFrame=:EndTimeFrame,
          Days=:Days,
          ExpiryDate=:ExpiryDate,
          Description=:Description
      `),
            Params: rowData,
            Language: Language
        });
        const res = await this.fetchList({
            LocationId: rowData?.LocationId,
            CreationDeviceId: rowData?.CreationDeviceId,
            Limit: 1
        });
        this.locationMarkers.push(...res.map((row) => this.createAndCacheMarker(row)));
        const originalRes = res.Original;
        appendResponseRows(originalRes, ['ActionCopy', 'ActionEdit', 'ActionDelete']);
        const [record] = AConvertToGridData(originalRes, {
            'ImageTime': DATA_DATETIME,
            'ExpiryDate': DATA_DATETIME,
            'CreationTime': DATA_DATETIME,
        });
        return record;
    }
    fetchList(filters) {
        const StatusSelect = `IF(i.LocationId IS NOT NULL, 'CLOSED', IF(ExpiryDate < NOW(), 'EXPIRED', 'OPEN'))`;
        const whereClause = [
            ...(filters.LocationId !== undefined ? ['LocationId=:LocationId'] : []),
            ...(filters.CreationDeviceId !== undefined ? ['l.CreationDeviceId=:CreationDeviceId'] : []),
            ...(filters.LocationType === undefined ? [] : ['IF(:LocationType IS NULL, true, l.LocationType=:LocationType)']),
        ];
        const where = whereClause.length ? `WHERE ${whereClause.join(' AND ')}` : ``;
        return Loading.waitForPromises(requestService.fetch({
            AssertValues: true,
            Query: (`
          SELECT * FROM (
            SELECT
              ${StatusSelect} Status,
              LocationId,
              l.CreationDeviceId,
              l.LocationType,
              l.Name,
              Y(l.GpsPoint) as Latitude,
              X(l.GpsPoint) as Longitude,
              TIME_FORMAT(BeginTimeFrame, '%h %i %p') as BeginTimeFrame,
              TIME_FORMAT(EndTimeFrame, '%h %i %p') as EndTimeFrame,
              Days,
              Days as _Days,
              ExpiryDate,
              CreationUser,
              CreationTime,
              Description,
              COUNT(i.Name) as Images,
              MAX(i.ImageTime) as ImageTime
            FROM locations l
            LEFT JOIN location_images i USING (LocationId)
            ${where}
            GROUP BY LocationId
            ORDER BY CreationTime ${filters.OrderBy || 'ASC'}, LocationId DESC, MAX(ImageTime)
          ) full
          ${filters.Status ? `WHERE (Status ${FilterManager.buildQueryFindInArray(filters.Status)})` : ``}
          LIMIT :Limit
        `),
            Params: filters,
            Language: Language,
            Translate: ["_Days", "Status"]
        }));
    }
    refresh(calledBySystem = false) {
        FilterManager.setActive(false);
        const filters = FilterManager.saveExplicit();
        this.locationMarkerMap = {};
        mapHelperService.destroy(this.locationMarkers);
        this.goBack();
        return this.fetchList(filters).then(async (res) => {
            FilterManager.setActive(true);
            if (!res.Rows.length) {
                Alerts.noResults({ calledBySystem });
            }
            const originalRes = res.Original;
            appendResponseRows(originalRes, ['ActionCopy', 'ActionEdit', 'ActionDelete']);
            // const COLUMN_SKIP_RESIZE = { skipResize: true }
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                features: {
                    group: 'Status',
                    search: true
                },
                aci: {
                    resizeToFit: true,
                    flex: 1,
                },
                tbar: [
                    {
                        type: 'text',
                        ref: 'searchField',
                        clearable: true,
                        label: '<i class="b-icon b-icon-search"></i>',
                        listeners: {
                            change: 'onSearchFieldChange',
                            clear: 'onSearchFieldClear',
                            thisObj: this
                        }
                    },
                    {
                        type: 'button',
                        ref: 'addButton',
                        text: await Translate.get('Add Location Image Request'),
                        icon: 'b-fa-plus',
                        onAction: ({ source }) => this.createForm({ type: 'create' })
                    },
                    //   {
                    //     type  : 'checkbox',
                    //     ref: 'showOnlyImages',
                    //     label: await Translate.get('Show only records with images'),
                    //     value: this.showOnlyImageRecords,
                    //     onChange: ({ checked }) => {
                    //       this.showOnlyImageRecords = checked
                    //     }
                    //   },
                ],
                columns: AConvertToGridColumns(originalRes, {
                    'Status': { ...COLUMN_TEXT, autoWidth: true, fitMode: 'exact' },
                    'LocationType': { ...COLUMN_TEXT, fitMode: 'exact' },
                    'Name': { ...COLUMN_TEXT, fitMode: 'exact' },
                    'CreationTime': { ...COLUMN_DATETIME, fitMode: 'exact' },
                    'CreationUser': { ...COLUMN_TEXT, fitMode: 'exact' },
                    'Images': COLUMN_HIDDEN,
                    'ImageTime': COLUMN_HIDDEN,
                    'LocationId': COLUMN_HIDDEN,
                    'CreationDeviceId': COLUMN_HIDDEN,
                    'ExpiryDate': COLUMN_HIDDEN,
                    'Latitude': COLUMN_HIDDEN,
                    'Longitude': COLUMN_HIDDEN,
                    'BeginTimeFrame': { ...COLUMN_HIDDEN },
                    'EndTimeFrame': { ...COLUMN_HIDDEN },
                    'Days': COLUMN_HIDDEN,
                    '_Days': COLUMN_HIDDEN,
                    'Description': COLUMN_HIDDEN,
                    'ActionCopy': COLUMN_ACTION({ iconCls: 'fa-solid fa-copy text-grey', btnCls: 'btn-white' }),
                    'ActionEdit': COLUMN_ACTION({ iconCls: 'fa-solid fa-pencil text-primary', btnCls: 'btn-white' }),
                    'ActionDelete': COLUMN_ACTION({ iconCls: 'fa-regular fa-trash text-red', btnCls: 'btn-white' }),
                    // 'ActionFocus': genBtn('btn btn-grey', 'fa fa-location-crosshairs'),
                    // 'ActionCopy': { ...genBtn('btn btn-grey', 'fa fa-copy'), autoWidth: 60, fitMode: 'exact' },
                    // 'ActionEdit': { ...genBtn('btn btn-primary', 'fa fa-pencil'), autoWidth: 60, fitMode: 'exact' },
                    // 'ActionDelete': { ...genBtn('btn btn-error', 'fa fa-trash'), autoWidth: 60, fitMode: 'exact' },
                    // 'LocationId': COLUMN_TEXT,
                    // 'Images': COLUMN_NUMBER,
                    // 'LocationType': COLUMN_TEXT,
                    // 'Name': COLUMN_TEXT,
                    // 'ImageTime': COLUMN_DATETIME,
                    // 'CreationDeviceId': { hidden: true },
                    // 'GpsPoint': { hidden: true },
                }),
                data: AConvertToGridData(originalRes, {
                    'ImageTime': DATA_DATETIME,
                    'ExpiryDate': DATA_DATETIME,
                    'CreationTime': DATA_DATETIME,
                })
            });
            this.search = this.grid.widgetMap['searchField'];
            this.grid.on('search', () => this.applyFilter());
            this.grid.columns.visibleColumns.slice(0, 3).map(c => c.flex = '1');
            this.grid.on('cellclick', ({ record, column }) => {
                // console.log(record.originalData)
                if (record.originalData.id && record.originalData.id.indexOf('group-header') !== -1) {
                    return;
                }
                this.map.setCenter({ lat: record.Latitude, lng: record.Longitude });
                switch (column.data.field) {
                    case 'ActionCopy':
                        this.copyForm(record.data);
                        break;
                    case 'ActionEdit':
                        this.editForm(record.data);
                        break;
                    case 'ActionDelete': break;
                }
                this.onMarkerClick(record.data, 'table');
            });
            this.locationMarkerMap = {};
            if (this.locationMarkers) {
                mapHelperService.unload(this.locationMarkers, UNLOAD_OPTIONS.Default);
                mapHelperService.destroy(this.locationMarkers);
            }
            this.locationMarkers = res.map((row) => this.createAndCacheMarker(row));
            this.map.fit(getMarkerBounds(this.locationMarkers));
        }).catch(AError.handle);
    }
    createAndCacheMarker(row) {
        const position = new google.maps.LatLng({ lat: Number(row.Latitude), lng: Number(row.Longitude) });
        let marker = new google.maps.Marker({
            position: position,
            icon: '/img/marker1.png',
            map: this.map,
            // zIndex: row.Images > 0 ? 8 : undefined
        });
        marker.addListener('click', () => this.onMarkerClick(row, 'map'));
        marker.data = { CreationDeviceId: row.CreationDeviceId };
        if (this.locationMarkerMap.hasOwnProperty(row.LocationId)) {
            for (let i in this.locationMarkerMap[row.LocationId]) {
                const v = this.locationMarkerMap[row.LocationId][i];
                if (v.data.CreationDeviceId === row.CreationDeviceId) {
                    const delIndex = this.locationMarkers.indexOf(v);
                    this.locationMarkers.splice(delIndex, 1, marker);
                    mapHelperService.unload([v], UNLOAD_OPTIONS.Default);
                    this.locationMarkerMap[row.LocationId][i] = marker;
                    return marker;
                }
            }
        }
        else {
            this.locationMarkerMap[row.LocationId] = [];
        }
        this.locationMarkers.push(marker);
        this.locationMarkerMap[row.LocationId].push(marker);
        return marker;
    }
    async genForm() {
        return $(await requestService.translateDom(/*html*/ `
      <form id="crud-form" class="form-group" onsubmit="return false" style="padding: 15px;max-width: 500px;margin: 0 auto;">
        <div class="floating-close-btn"><i class="fa-solid fa-xmark fa-fw"></i></div>
        <div class="columns">
          <div class="form-group col-12 text-center">
            <span class="label label-info label-rounded">Click on the map to change the location</span>
          </div>
          <div class="form-group col-12">
            <label class="form-label" for="address">Address</label>
            <input class="form-input fw" autocomplete="off" type="text" id="Address" placeholder="..." value="" disabled>
          </div>

          <div class="form-group col-12">
            <label class="form-label" for="LocationType">LocationType</label>
            ${FilterManager.createAllLocationTypeOptions({ id: "LocationType" })}
          </div>
          <div class="form-group col-12">
            <label class="form-label" for="Days">Take picture on</label>
            <div id="Days" class="col-12 wrapper-dropdown noselect" maxlength="64">
              <span>None</span>
              <ul class="dropdown c-scroll">
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Monday" key="Monday" checked="checked">
                    <i class="form-icon"></i>Monday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Tuesday" key="Tuesday" checked="checked">
                    <i class="form-icon"></i>Tuesday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Wednesday" key="Wednesday" checked="checked">
                    <i class="form-icon"></i>Wednesday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Thursday" key="Thursday" checked="checked">
                    <i class="form-icon"></i>Thursday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Friday" key="Friday" checked="checked">
                    <i class="form-icon"></i>Friday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Saturday" key="Saturday" checked="checked">
                    <i class="form-icon"></i>Saturday
                  </label>
                </li>
                <li>
                  <label class="form-checkbox">
                    <input type="checkbox" id="Sunday" key="Sunday" checked="checked">
                    <i class="form-icon"></i>Sunday
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <div class="column col-12">
            <div class="columns">
              <div class="col-12">
                <label class="form-label col-12" for="BeginTimeFrame">Take picture between</label>
              </div>
              <div class="form-group col-6">
                <input class="form-input" autocomplete="off" type="time" id="BeginTimeFrame" value="09:00">
              </div>
              <div class="form-group col-6">
                <input class="form-input" autocomplete="off" type="time" id="EndTimeFrame" value="17:00">
              </div>
            </div>
          </div>
          <div class="column col-12">
            <div class="columns">
              <div class="col-12">
                <label class="form-label" for="ExpiryDate">Expiry Date</label>
              </div>
              <div class="form-group col-6">
                <input class="form-input" autocomplete="off" type="date" id="ExpiryDate" placeholder="" value="">
              </div>
              <div class="form-group col-6">
                <input class="form-input" autocomplete="off" type="time" id="ExpiryTime" placeholder="" value="">
              </div>
            </div>
          </div>
          
        <div class="form-group col-12">
          <label class="form-label" for="Name">Name</label>
          <input class="form-input" autocomplete="off" type="text" id="Name" placeholder="" value="">
        </div>

          <div class="form-group col-12">
            <label class="form-label col-12" for="lat">Description (optional)</label>
            <textarea class="form-input col-12" id="Description" autocomplete="off" placeholder="..." rows="5" maxlength="255"></textarea>
          </div>
          
          <div class="form-group">
            <div class="children-inline-block toggle-form-more">
              <label class="form-label">More Info</label>
              <i class="fa-solid fa-chevron-up fa-xs"></i>
            </div>
          </div>

          <div class="form-more" class="col-12">
            <div class="columns col-gapless">
              <!-- hidden fields -->
              <div class="form-group col-6">
                <label class="form-label" for="Latitude">Latitude</label>
                <input class="form-input" autocomplete="off" type="number" id="Latitude" placeholder="0.00" value="" formnovalidate="formnovalidate" step="0.00000001">
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="Longitude">Longitude</label>
                <input class="form-input" autocomplete="off" type="number" id="Longitude" placeholder="0.00" value="" formnovalidate="formnovalidate" step="0.00000001">
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="CreationDeviceId">CreationDeviceId</label>
                <input class="form-input" autocomplete="off" type="text" id="CreationDeviceId" placeholder="" value="" disabled>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="CreationUser">CreationUser</label>
                <input class="form-input" autocomplete="off" type="text" id="CreationUser" placeholder="" value="" disabled>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="CreationDate">CreationDate</label>
                <input class="form-input" autocomplete="off" type="date" id="CreationDate" placeholder="" value="" disabled>
              </div>
              <div class="form-group col-6">
                <label class="form-label" for="CreationTime">CreationTime</label>
                <input class="form-input" autocomplete="off" type="time" id="CreationTime" placeholder="" value="" disabled>
              </div>
              <div class="form-group col-12 mb-2">
                <label class="form-label" for="LocationId">LocationId</label>
                <input class="form-input" autocomplete="off" type="text" id="LocationId" placeholder="" value="" disabled>
              </div>
            </div>
          </div>

          <div class="form-group col-12 mb-2">
            <button id="submitBtn" class="btn btn-primary col-12">${this.formOpt.type}</button>
          </div>
        </div>
      </form>`.replace(/\s\s+/g, ' ')));
    }
}
function parseAMPM(time = '09 00 AM') {
    let [shh, smm, ampm] = time.toLowerCase().split(' ');
    let hh = (ampm === 'pm') ? Number(shh) + 12 : Number(shh);
    let mm = Number(smm);
    return [
        (hh < 10) ? '0' + hh : hh,
        (mm < 10) ? '0' + mm : mm,
    ].join(':');
}
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
export function css() {
    return ( /*html*/`
    <style>
    #AjaxContent .wrapper.md {
      height: calc(50% - 79px);
    }

    .splitter #map {
      height: 100%;
    }

    .b-container .b-checkbox .b-checkbox-label:before {
      background: #fff;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="Status">Status</label>
          <div id="Status" class="col-12 wrapper-dropdown dd-disallow-none noselect" maxlength="64">
            <span>None</span>
            <ul class="dropdown c-scroll">
              <li>
                <label class="form-checkbox">
                  <input type="checkbox" id="Open" key="Open" checked="checked">
                  <i class="form-icon"></i>Open
                </label>
              </li>
              <li>
                <label class="form-checkbox">
                  <input type="checkbox" id="Closed" key="Closed" checked="checked">
                  <i class="form-icon"></i>Closed
                </label>
              </li>
              <li>
                <label class="form-checkbox">
                  <input type="checkbox" id="Expired" key="Expired">
                  <i class="form-icon"></i>Expired
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label" for="LocationType">LocationType</label>
          <select class="form-select" id="LocationType">
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="OrderBy">Sort</label>
          <select class="form-select" id="OrderBy">
            <option value="DESC" selected="selected">Descending (New - Old)</option>
            <option value="ASC">Ascending (Old - New)</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="5000">
        </div>
      
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div class="flex-child child-relative no-y-overflow fh" style="overflow: hidden">
      <button id="toggle-images" class="img-tab-toggle">
        <div>
          <i class="fa-regular fa-camera"></i>
          Images
        </div>
        <div class="hidden">
          <i class="fa-solid fa-ban"></i>
          No Images
        </div>
      </button>
      
      <div class="splitter two-split fh" style="overflow: hidden; height: calc(100% - 200px);">
        <div class="drag-section part-one">
          <div id="firstPanel" class="list-container" style="overflow-y: auto; height: calc(100%">
            <div id="table-bryntum" class="fh"></div>
          </div>
          <div id="secondPanel" class="list-container" style="overflow-y: auto; height: calc(100%; display: none">
          </div>
          <div id="lastPanel" class="list-container hidden" style="background: #f8f9fa; height: 36px;">
            <div class="columns cGoBack hidden">
              <button class="btn btn-link column col-11 col-mx-auto" disabled="disabled">
                <i class="fa fa-arrow-left"></i> Go Back</button>
            </div>
            <div class="columns col-gapless">
              <button class="cRequestPhoto btn btn-primary fw">
                <i class="fa fa-plus"></i> Request Location Image
              </button>
            </div>
          </div>
        </div>
        <div id="separator1" class="drag-seperator separator"></div>

        <div class="drag-section part-two">
          <div id="map" class="aci-map"></div>
        </div>
      </div>

      <div class="photos photos-scroll-container photos-size-200" style="border-top: 1px solid #c0c0c0">
        <div show-if-no-results class="v-padding-2">
          <div class="v-padding">
            <div class="fw text-center">
              <i class="fa-solid fa-folder-magnifying-glass fa-4x"></i>
            </div>
            <div class="no-images-warning">
              Could not find any photos!
            </div>
          </div>
        </div>
      </div>

    </div>
  `);
}

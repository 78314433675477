import { AError } from "../../classes/AError.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { createMap } from "../../utils/maps.js";
import { ALERTS, ALERT_BUTTONS, ALERT_STATUS } from "../../services/AAlertService.js";
import { AInputDate } from "../../utils/tools.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { AColorHSV } from "../../classes/AColorHSV.js";
export class APage {
    constructor() {
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        this.map = createMap('map', {
            zoom: 24
        });
        // Move legend to map
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push($("#map_legend").get(0));
        this.Devices = [];
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
    }
    async init() {
        this.map.fit();
        this.translations = await Loading.waitForPromises(Translate.get([
            'Delete route',
            'From',
            'Route will also be removed from planning'
        ]));
        Loading.waitForPromises(this.getGeoMap()).then(response => {
            //this.setFilters()
            this.refresh();
        }).catch(AError.handle);
    }
    async refresh() {
        await Loading.waitForPromises(this.getCars()).then(response => {
            this.showInfoScreen("splash");
            this.createCarList();
        }).catch(AError.handle);
    }
    showInfoScreen(screen) {
        $('#info_overview_splash').hide();
        $('#info_overview_driven').hide();
        $('#info_overview_planned').hide();
        if (screen === "splash") {
            $('#info_overview_splash').show();
        }
        else if (screen === "driven") {
            $('#info_overview_driven').show();
        }
        else if (screen === "planned") {
            $('#info_overview_planned').show();
        }
    }
    createCarList() {
        $('#car_list').children().remove();
        $('#route_list').children().remove();
        this.map.fit();
        this.routeMapHelperService.removeWaySegments(this.map);
        for (const index in this.CarRoutes) {
            const $DeviceName = this.CarRoutes[index].DeviceName;
            var $row = $(/*html*/ `
        <tr id="${$DeviceName}" class='tableRow'>
        <td>
            <div class='columns' style="margin: 2px;">
            <h7 class='col-12'>${$DeviceName}</h7>
          </div>
        </td>  
        </tr>
        `.replace(/\s\s+/g, ' '));
            $('#car_list').append($row);
        }
        var th = this;
        $('#car_list').off();
        $('#car_list').on('click', 'tr', async function () {
            $("table > tbody > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            th.onCarRowClicked(this.id);
        });
    }
    async onCarRowClicked(deviceName) {
        $('#route_list').children().remove();
        await Loading.waitForPromises(this.getRoutes({ ForDevice: deviceName }));
        this.routeMapHelperService.removeWaySegments(this.map);
        this.map.fit();
        $('#info_screen_splash').show();
        $('#info_screen_route').hide();
        var $CarRoute = this.CarRoutes[deviceName];
        if (!$CarRoute || !$CarRoute.Routes || !$CarRoute.Routes.length)
            return;
        for (const index in $CarRoute.Routes) {
            const $RouteName = $CarRoute.Routes[index].RouteName;
            const $RequestedByCar = $CarRoute.Routes[index].RequestedByCar;
            let $spinner = ``;
            let $color = "black";
            if (!$RequestedByCar) {
                $spinner = `<i class="col-1 fa-regular fa-spinner"></i>`;
                $color = "color: #A9A9A9;";
            }
            var $row = $(/*html*/ `
        <tr id="${index}" class='tableRow'>
        <td>
            <div class='columns' style="margin: 2px; ${$color}">
            <h7 class='col-11'>${$RouteName}</h7>
            ${$spinner}
          </div>
        </td>  
        </tr>
        `.replace(/\s\s+/g, ' '));
            $('#route_list').append($row);
        }
        var th = this;
        $('#route_list').off();
        $('#route_list').on('click', 'tr', async function () {
            $("#route_list > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            await Loading.waitForPromises(th.onRouteRowClicked(deviceName, th.CarRoutes[deviceName].Routes[this.id]));
        });
    }
    async onRouteRowClicked(deviceName, route) {
        $('#info_screen_splash').hide();
        $('#info_screen_route').show();
        this.routeMapHelperService.removeWaySegments(this.map);
        var routeDBarr = await this.fetchRoute(route.RouteId);
        var routeDB;
        for (const r of routeDBarr) {
            if (route.RouteName === r.RouteName) {
                routeDB = r;
                break;
            }
        }
        if (routeDB === undefined)
            return;
        let $btn_info = $('#info_route_info');
        let $btn_delete = $('#info_route_delete');
        $btn_info.off();
        $btn_delete.off();
        $btn_delete.on("click", () => this.alert_deleteRoute(deviceName, route));
        if (route.RequestedByCar)
            $("#info_screen_warning").hide();
        else
            $("#info_screen_warning").show();
        $('#info_route_name').text(route.RouteName);
        $('#info_route_added').text(AInputDate(new Date(route.DateAdded)));
        if (this.geoMap != undefined && this.geoMap.WaySegments != undefined && routeDB.RouteSegments != undefined && routeDB.RouteSegments.length) {
            let WaysegmentsParking = [];
            let WaysegmentsOther = [];
            for (const i in routeDB.RouteSegments) {
                const $s = routeDB.RouteSegments[i];
                let WaySegmentId = Math.abs($s.WaySegmentId);
                if (!this.geoMap.WaySegments[WaySegmentId])
                    continue;
                if ($s.ScanSide === "ScanLeft" || $s.ScanSide === "ScanRight" || $s.ScanSide === "ScanLeftAndRight" || $s.ScanSide === "ScanAny") {
                    if (WaysegmentsParking.indexOf(WaySegmentId) === -1) {
                        WaysegmentsParking.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.red);
                    }
                }
                else {
                    if (WaysegmentsOther.indexOf(WaySegmentId) === -1) {
                        WaysegmentsOther.push(WaySegmentId);
                        this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[WaySegmentId], this.waySegmentColors.orange);
                    }
                }
            }
            this.routeMapHelperService.fitBoundsWaySegments(this.map);
        }
    }
    async alert_deleteRoute($deviceName, $route) {
        let html_sch = ``;
        if ($route.Scheduled) {
            html_sch = /*html*/ `
      <label class="form-label">${this.translations['Route will also be removed from planning']}</label>     
    `;
        }
        const html = /*html*/ `
      <div class="form-group">
        <label class="form-label">${this.translations['Delete route']} ${this.translations['From']} ${$deviceName}: '${$route.RouteName}'?</label>
      </div>
      ${html_sch}`;
        const events = Alerts.show({
            translatedTitle: this.translations['Delete route'],
            content: html,
            type: ALERTS.Form,
            buttons: ALERT_BUTTONS.deleteCancel
        });
        var $page = this;
        events.on(ALERT_STATUS.ON_ACTION_PROCEED, async () => {
            var data = {
                "DeviceName": $deviceName,
                "RouteId": $route.RouteId,
                "RouteName": $route.RouteName
            };
            // if(route.RouteFromTime && route.RouteToTime){
            //   data.RouteFromTime = route.RouteFromTime;
            //   data.RouteToTime = route.RouteToTime;
            // }
            CCCClient.SendMessage("PlanRoute_DeleteRouteFromCar_Request", 0, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_DeleteRouteFromCar_Response`, response => {
                $page.refresh();
            });
        });
    }
    getGeoMap() {
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetGeoMap_Request", 1, null, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetGeoMap_Response`, response => {
                this.geoMap = response.GeoMap;
                resolve(true);
            });
        });
    }
    async getCars() {
        try {
            this.CarRoutes = [];
            let query = `SELECT 
        ForDevice
      FROM planroute_carroutes
      ORDER BY ForDevice`;
            let response = await requestService.query({
                Query: query
            });
            if (response.Rows.length === 0)
                return;
            for (let row of response.Rows) {
                //RouteId: string, RouteName: string, ForDevice: string, DateAdded: Date
                var DeviceName = row[0];
                this.CarRoutes[DeviceName] = {
                    DeviceName: DeviceName,
                    Routes: []
                };
            }
        }
        catch (err) {
            AError.handle(err);
        }
        return Promise.resolve();
    }
    async getRoutes(options) {
        try {
            let query = `SELECT 
        RouteId,
        RouteName, 
        DateAdded,
        Scheduled,
        RequestedByCar
      FROM planroute_carroutes
      WHERE ForDevice = :ForDevice 
      ORDER BY RouteName`;
            let response = await requestService.query({
                Params: options,
                Query: query
            });
            if (this.CarRoutes[options.ForDevice]) {
                this.CarRoutes[options.ForDevice].Routes = [];
            }
            else {
                let device = {
                    DeviceName: options.ForDevice,
                    Routes: []
                };
                this.CarRoutes[options.ForDevice] = device;
            }
            for (let row of response.Rows) {
                //RouteId: string, RouteName: string, ForDevice: string, DateAdded: Date
                var Route = {
                    RouteId: row[0],
                    RouteName: row[1],
                    DateAdded: row[2],
                    Scheduled: row[3],
                    RequestedByCar: (row[4] != undefined) ? row[4] : 1
                };
                this.CarRoutes[options.ForDevice].Routes.push(Route);
            }
        }
        catch (err) {
            AError.handle(err);
        }
        return Promise.resolve();
    }
    async fetchRoute(id) {
        let ares = await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT 
          r.RouteId, 
          r.RouteName, 
          r.CarNumber, 
          r.Cost, 
          r.Length,
          r.Requestcreated_datetime, 
          ST_AsGeoJSON(r.RouteGpsLine) AS RouteGpsLine,
          r.Active,
          r.RouteSegments,
          r.GeoDataTimeStamp
        FROM planroute_routes r 
        WHERE r.Finished = 1 AND r.RouteId = :RouteId
        ORDER BY Active DESC, RouteName ASC
      `),
            Params: { RouteId: id }
        }, {
            valueMapper: {
                CarNumber: (v) => parseInt(v),
                Cost: (v) => parseInt(v),
                Length: (v) => parseInt(v),
                Requestcreated_datetime: (v) => new Date(v),
                GeoDataTimeStamp: (v) => new Date(v),
            }
        });
        return ares.toArray();
    }
}
export function css() {
    return ( /*html*/`
    <style>   
      .tableRow:hover {
          background: #F9F9F9;
      }
      .tableRow{
          background: #ffffff;
      }
      .tableRow.selected{
          background: #ECECEC;
      }
      
      .legend-label.legend-label-sub {
        font-size: 11px;
        line-height: 1.5em;
      }

      .accordion-wrapper > a{
        padding: 15px 8px
      }      

      .accordion-wrapper .tableWrapper {
        max-height: 400px;
        overflow-y: auto;
      }

      #info_overview .display-once {
        background: transparent;
      }

      #info_overview #info_overview_splash {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      
      #info_overview {
        height: 250px;
      }

      .info-block {
        display: flex;
        text-align: center;
        height: calc(100% / 3.0);
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      
      .info-block h6,
      .info-block span {
        margin-bottom: 0;
      }

      .mapWrapper {
        position: relative;
        height: calc(100% - 250px);
        width: 100%;
      }

      .aci-map + .legend {
        display: none;
      }
    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 20%; min-width: 180px; border-right: 1px solid lightgrey;">
            <table class="table fixTableHead">
              <thead>
                <tr>
                  <th><b>Devices</b></th>
                </tr>
              </thead>
              <tbody id="car_list">
              </tbody>
            </table>
          </div>
          <div class="drag-section part-one" style="width: 20%; min-width: 180px;">
            <table class="table fixTableHead">
              <thead>
                <tr>
                  <th><b>Routes</b></th>
                </tr>
              </thead>
              <tbody id="route_list">
              </tbody>
            </table>
          </div>
          
          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 60%; min-width: 180px;">
            <div id='map_wrapper' class="mapWrapper">
              <div id="map" class="aci-map"></div>
              <div id="map_legend" class="legend legend-opaque">
                <div class="legend-item">
                    <div class="route-preview" style="background-color: rgba(255, 0, 0, 0.7); border-color: #a30000"></div>
                    <span>With Parking</span>
                  </div>
                  <div class="legend-item">
                    <div class="route-preview" style="background-color: rgba(255, 143, 15, 0.7); border-color: #e08722">
                    </div>
                    <span>Without Parking</span>
                  </div>
              </div>
            </div>

            <div id="info_screen" class="columns" style="height: 200px">

                <div id="info_screen_splash" class="columns col-12">
                  <div style="margin: auto; text-align: center;">
                    <div template="${ATemplates.RouteInformation}"></div>
                  </div>
                </div>

                <div id="info_screen_route" class="columns col-12" style="display: none;">

                  <div class="col-3" style="margin: auto; text-align: center;">
                    <h6>Route Name</h6>
                    <span id="info_route_name">ROUTENAME</span>
                  </div>

                  <div class="col-3" style="margin: auto; text-align: center;">
                    <h6>Added</h6>
                    <span id="info_route_added">DATE ADDED</span>
                  </div>

                  <div id="info_screen_warning" class="col-3" style="margin: auto; text-align: center;">
                    <h6>NOT YET ON CAR</h6>
                    <i class="fa fa-warning" style="color: orange ;" aria-hidden="true"></i>
                  </div>

                  <div class="col-3" style="margin: auto; text-align: center;">
                    <h6 for="info_route_delete">Delete</h6>
                    <button style="width: 36px; height: 36px;  margin-top: 5px" id="info_route_delete"
                      class="btn btn-error">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
          </div>
        </div>

      </div>
    </div>
  `);
}

export var MAP_POSITION;
(function (MAP_POSITION) {
    MAP_POSITION[MAP_POSITION["TOP_LEFT"] = 0] = "TOP_LEFT";
    MAP_POSITION[MAP_POSITION["TOP_RIGHT"] = 1] = "TOP_RIGHT";
    MAP_POSITION[MAP_POSITION["BOTTOM_LEFT"] = 2] = "BOTTOM_LEFT";
    MAP_POSITION[MAP_POSITION["BOTTOM_RIGHT"] = 3] = "BOTTOM_RIGHT";
})(MAP_POSITION || (MAP_POSITION = {}));
export var MAP_POSITION_VECTOR;
(function (MAP_POSITION_VECTOR) {
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["TOP"] = 1] = "TOP";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["RIGHT"] = 2] = "RIGHT";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["BOTTOM"] = 3] = "BOTTOM";
    MAP_POSITION_VECTOR[MAP_POSITION_VECTOR["LEFT"] = 4] = "LEFT";
})(MAP_POSITION_VECTOR || (MAP_POSITION_VECTOR = {}));
export class AMapOverlayService {
    constructor() {
        this.offsets = {};
        this.positionCollisionMatrix = {
            [MAP_POSITION.TOP_LEFT]: ($o) => ({
                x: $o.offset().left + $o.width(),
                y: $o.offset().top + $o.height(),
            }),
            [MAP_POSITION.TOP_RIGHT]: ($o) => ({
                x: $o.offset().left,
                y: $o.offset().top + $o.height(),
            }),
            [MAP_POSITION.BOTTOM_LEFT]: ($o) => ({
                x: $o.offset().left + $o.width(),
                y: $o.offset().top,
            }),
            [MAP_POSITION.BOTTOM_RIGHT]: ($o) => ({
                x: $o.offset().left,
                y: $o.offset().top,
            }),
        };
        this.positionToCls = {
            [MAP_POSITION.TOP_LEFT]: 'top-left',
            [MAP_POSITION.TOP_RIGHT]: 'top-right',
            [MAP_POSITION.BOTTOM_LEFT]: 'bottom-left',
            [MAP_POSITION.BOTTOM_RIGHT]: 'bottom-right',
        };
        this.uids = {};
    }
    autoInit() {
        // this.mapHelperService = AEngine.get(AMapHelperService)
    }
    isCollisionBetween(map, mapElement, position1, position2, opt) {
        const [pos1, pos2] = [position1, position2].sort();
        const $overlay1 = this.getOrCreateOverlay(map, mapElement, pos1);
        const $overlay2 = this.getOrCreateOverlay(map, mapElement, pos2);
        const point1 = this.positionCollisionMatrix[pos1]($overlay1);
        const point2 = this.positionCollisionMatrix[pos2]($overlay2);
        const delta = {
            x: point2.x - point1.x,
            y: point2.y - point1.y,
        };
        return {
            isCollision: opt?.checkX === true ? delta.x < 0 : delta.x < 0 || delta.y < 0,
            delta,
            point1,
            point2
        };
    }
    addGradientOverlay(map, mapElement) {
        const uid = idAllocatorService.getNextId({ prefix: 'gradient-' });
        const $gradientOverlay = $(/*html*/ `
      <div id="${uid}" class="map-controls-gradient"></div>
    `);
        const $overlay = this.getOverlay(map, mapElement);
        if ($overlay.find('.map-controls-gradient').length === 0) {
            $overlay.prepend($gradientOverlay);
        }
    }
    getOverlay(map, mapElement) {
        const $parent = map.get('overlay') ?? $();
        if ($parent.length > 0) {
            return $parent;
        }
        $(mapElement).css({ 'position': 'relative' });
        let $overlay = $('<div class="map-overlay"></div>');
        const POS_CENTER = google.maps.ControlPosition['CENTER'] ?? 13;
        map.controls[POS_CENTER].push($overlay.get(0));
        map.set('overlay', $overlay);
        return $overlay;
    }
    getOrCreateOverlay(map, mapElement, position) {
        const className = this.positionToCls[position];
        let $overlay = this.getOverlay(map, mapElement);
        let $overlaySection = $overlay.find(`.map-overlay-controls.${className}`);
        if ($overlaySection.length === 0) {
            $overlaySection = $(`<div class="map-overlay-controls ${className}"></div>`);
            $overlay.prepend($overlaySection);
        }
        $overlaySection.css('margin', this.offsets[position] ?? '');
        return $overlaySection;
    }
    clear() {
        this.uids = {};
    }
    setOffset(map, mapElement, position, offset) {
        this.offsets[position] = offset;
        this.getOrCreateOverlay(map, mapElement, position);
    }
    /**
     * @param mapElement map html element
     * @param $ele the element to show on top of the map
     * @param position position of the element on top of the map
     * @param options if options.uid is defined, it will delete the previous element with the same uid, if order is defined it'll sort the ui elements
     */
    add(map, mapElement, $ele, position, { uid, order }) {
        this.insert($ele, { $parent: this.getOrCreateOverlay(map, mapElement, position), order, uid });
    }
    /**
     * @param $container
     * @param options if uid is defined, it will delete the previous element with the same uid
     */
    insert($container, { uid, $parent, order }) {
        // Delete previous element if uid is set
        this.delete({ uid });
        this.uids[uid] = $container;
        $container.attr('order', order);
        const $mapOverlays = $parent.find('[order]').toArray().map((e) => $(e));
        for (let $ele of $mapOverlays) {
            if (order < Number($ele.attr('order'))) {
                $container.insertAfter($ele);
                this.sortElements($parent);
                return;
            }
        }
        this.sortElements($parent);
        $parent.append($container);
    }
    delete({ uid }) {
        if (this.uids.hasOwnProperty(uid)) {
            this.uids[uid].remove();
        }
    }
    sortElements($parent) {
        $parent.find('[order]')
            .sort((a, b) => Number($(a).attr('order')) - Number($(b).attr('order')))
            .appendTo($parent);
    }
}

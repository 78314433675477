import { AConfig } from "../classes/AConfig.js";
import { ATableFormatter } from "../core/form/table/ATableFormatter.js";
import { AEngine } from "../core/AEngine.js";
import { mergeDeep } from "./tools.js";
import { ALERT_TITLES } from "../services/AAlertService.js";
export const GeoAttributes = {
    type: "OBJECT",
    formatter: {
        "*": { type: "DYNAMIC" },
        'Capacity': { type: 'NUMBER' },
        'CalculatedCapacity': { type: 'NUMBER' },
        'Geo-Area': { type: 'NUMBER' },
        'Geo-Length': { type: 'NUMBER' },
        "BO.Color": { type: 'TEXT', style: 'color: {value};' },
        "KmlDescription": {
            type: 'BLOB',
            onClick: ($ele, data) => {
                const dataStr = ((typeof data === 'string') ? data : JSON.stringify(data, undefined, 4))
                    .replace(/&/g, "&amp;")
                    .replace(/</g, "&lt;")
                    .replace(/>/g, "&gt;")
                    .replace(/"/g, "&quot;")
                    .replace(/'/g, "&#039;")
                    .replace(/\//g, '&#x2F;');
                const style = `display: block; white-space: pre-wrap; background-color: #f3f3f3; padding: 8px; border-radius: 7px;`;
                const htmlWrapper = (`<div class="fw" style="${style}">${dataStr}</div>`);
                console.log(data);
                Alerts.show({
                    title: ALERT_TITLES.None,
                    content: htmlWrapper
                });
            }
        },
    }
};
export function default_order() {
    // TODO: Rethink logic
    return [...new Set(AConfig.get('tableformatter.default.order', []))];
}
export function default_tableformatter() {
    console.warn(`// TODO: Reimplement AConfig`);
    return new ATableFormatter(
    // AConfig.get('tableformatter.default.definitions', ATableFormatter.fallbackDefinitions)
    ATableFormatter.fallbackDefinitions);
}
export function team_sorting() {
    return [
        "User",
        "UserDisplayName",
        "Status",
        "StatusString",
        "ComState",
        "ValidLogin",
        "CheckPRDB",
        "NodeId",
        "DeviceName",
        "NodeName",
        "NodeType",
        "Unit",
        "UserSessionId",
        "LastUpdated",
        "Gps",
        "UserGroups",
        "id_mrs",
    ];
}
export function team_tableformatter() {
    return new ATableFormatter({
        "ValidLogin": { type: "BOOLEAN" },
        "User": { type: "TEXT" },
        "UserDisplayName": { type: "TEXT" },
        "Status": { type: "TEXT" },
        "StatusString": { type: "TEXT" },
        "ComState": { type: "TEXT" },
        "NodeId": { type: "TEXT" },
        "DeviceName": { type: "TEXT" },
        "NodeName": { type: "TEXT" },
        "NodeType": { type: "TEXT" },
        "Unit": { type: "TEXT" },
        "CheckPRDB": { type: "BOOLEAN" },
        "LastUpdated": { type: "TEXT" },
        "UserSessionId": { type: "HIDDEN" },
        "Gps": { type: "HIDDEN" },
        "UserGroups": { type: "HIDDEN" },
        "id_mrs": { type: "HIDDEN" },
    });
}
export function gpsstat_tableformatter(opt) {
    const fieldDefinition = mergeDeep({}, {
        'GpsTime': { type: "DATETIME" },
        'DeviceName': { type: "TEXT" },
        'Speed': { type: "NUMBER" },
        'Precision': { type: "NUMBER" },
    }, opt?.override ?? {});
    return new ATableFormatter(fieldDefinition);
}
export function locationimagerequest_tableformatter() {
    return new ATableFormatter({
        'id': { type: "HIDDEN" },
        'parentIndex': { type: "HIDDEN" },
        'LocationId': { type: "TEXT" },
        'DetectionDeviceId': { type: "NUMBER" },
        'ImageTime': { type: "DATETIME" },
        'CreationDeviceId': { type: "TEXT" },
        'LocationType': { type: "TEXT" },
        'Status': { type: "TEXT" },
        'Images': { type: "NUMBER" },
        'Latitude': { type: "TEXT" },
        'Longitude': { type: "TEXT" },
        'BeginTimeFrame': { type: "TEXT" },
        'EndTimeFrame': { type: "TEXT" },
        'Name': { type: "HIDDEN" },
        'Days': { type: "HIDDEN" },
        '_Days': { type: "ARRAY" },
        'ExpiryDate': { type: "DATETIME" },
        'CreationTime': { type: "DATETIME" },
        'CreationUser': { type: "TEXT" },
        'Description': { type: "TEXT" },
        'Valid': { type: "BOOLEAN" },
        'ActionFocus': { type: "HIDDEN" },
        'ActionCopy': { type: "HIDDEN" },
        'ActionEdit': { type: "HIDDEN" },
        'ActionDelete': { type: "HIDDEN" },
    });
}
export function locationimage_tableformatter() {
    return new ATableFormatter({
        'LocationId': { type: 'TEXT' },
        'CreationDeviceId': { type: 'TEXT' },
        'LocationType': { type: 'TEXT' },
        'Name': { type: 'TEXT' },
        'Description': { type: 'TEXT' },
        'BeginTimeFrame': { type: 'TEXT' },
        'EndTimeFrame': { type: 'TEXT' },
        'Days': { type: 'ARRAY' },
        'ExpiryDate': { type: 'DATETIME' },
        'CreationTime': { type: 'DATETIME' },
        'CreationUser': { type: 'TEXT' },
        'GpsPoint': { type: 'HIDDEN' },
        'parentIndex': { type: 'HIDDEN' },
        'id': { type: 'HIDDEN' },
        'ImageTime': { type: 'DATETIME' },
        'Images': { type: 'NUMBER' },
    });
}
export function geoLayers_tableformatter(opt) {
    const fieldDefinition = mergeDeep({}, {
        // '*': { type: 'UNKNOWN' },
        'Index': { type: 'NUMBER' },
        'GeoId': { type: 'NUMBER' },
        'Name': { type: 'TEXT' },
        'GeoType': { type: 'TEXT' },
        'Geo': {
            type: 'OBJECT',
            formatter: {
                '*': { type: 'DYNAMIC' },
                'type': { type: 'TEXT' },
                'srid': { type: 'NUMBER' },
                'coordinates': {
                    type: 'ARRAY',
                    formatter: {
                        '*': {
                            type: 'DYNAMIC',
                            // formatter: { '*': { type: 'DYNAMIC' }}
                        },
                    }
                },
            }
        },
        'Attributes': GeoAttributes,
        'Center': {
            type: 'OBJECT',
            formatter: {
                'lat': { type: 'REAL' },
                'lng': { type: 'REAL' },
            }
        },
        'RefList': { type: 'HIDDEN' },
        'Active': { type: 'BOOLEAN' },
        'Modified': { type: 'DATETIME' },
        'GeoCreated': { type: 'DATETIME' },
    }, opt?.override ?? {});
    AEngine.warn('polygons_tableformatter', fieldDefinition);
    return new ATableFormatter(fieldDefinition);
}
export function trafficsigns_tableformatter() {
    return new ATableFormatter({
        'data': { type: "HIDDEN" },
        'DetectionValid': { type: "BOOLEAN" },
        'TrafficSign': { type: "TEXT" },
        'TrafficSignText': { type: "TEXT" },
        'TrafficSignCenter': { type: "OBJECT" },
        'ScanDeviceCenter': { type: "OBJECT" },
        'ScanDeviceDirection': { type: "TEXT" },
        'Confidence': { type: "NUMBER" },
        'CameraId': { type: "TEXT" },
        'DetectionTime': { type: "DATETIME" },
        'GpsPrecision': { type: "NUMBER" },
        'ScanDeviceSpeed': { type: "NUMBER" },
        'SessionId': { type: "HIDDEN" },
        'SessionMode': { type: "TEXT" },
        'SessionName': { type: "TEXT" },
        'Side': { type: "TEXT" },
        'DetectionDevice': { type: "TEXT" },
        'DetectionId': { type: "TEXT" },
        'DetectionDeviceId': { type: "TEXT" },
    });
}
export function trafficsigns_sorting() {
    return [
        'DetectionValid',
        'TrafficSign',
        'TrafficSignText',
        'TrafficSignCenter',
        'ScanDeviceCenter',
        'ScanDeviceDirection',
        'Confidence',
        'CameraId',
        'DetectionTime',
        'GpsPrecision',
        'ScanDeviceSpeed',
        'SessionId',
        'SessionMode',
        'SessionName',
        'Side',
        'DetectionDevice',
        'DetectionId',
        'DetectionDeviceId',
    ];
}

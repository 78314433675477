import { AError } from "../classes/AError.js";
import { AResponse } from "../classes/AResponse.js";
import { TransformObjectsToResponse } from "../utils/tools.js";
export class AFollowUpOrm {
    async find({ OptionKey }) {
        return await requestService.query({
            Query: `SELECT OptionKey FROM config_followup_menus WHERE OptionKey=:OptionKey`,
            Params: { OptionKey }
        });
    }
    async fetchDefaultRootItems() {
        const rootItemTemplate = {
            OptionKey: '',
            OptionCode: undefined,
            ParentKey: null,
            ColorCls: null,
            Icon: null,
            IconCls: null,
            AllowCancel: undefined,
            EvidenceImagesMandatory: undefined,
            OptionType: undefined,
            Visible: undefined,
            OrderPosition: null,
            ExternalId: null
        };
        const channelCodes = (Channels.length > 0) ? Channels.map(c => c.ChannelCode) : ['CH|Default'];
        const res = await TransformObjectsToResponse(channelCodes.map((ChannelCode, i) => {
            const ChannelKey = ChannelCode.startsWith('CH|') ? 'FM|' + ChannelCode.substring(3) : ChannelCode;
            return Object.assign({}, rootItemTemplate, {
                OptionKey: ChannelKey,
                OptionCode: ChannelKey,
                OrderPosition: i,
            });
        }));
        return new AResponse(res);
    }
    async validateRootItems() {
        const rootItems = await this.fetchAll().then(ares => ares.toArray());
        const defaultRootItems = await this.fetchDefaultRootItems().then(res => res.toArray());
        // console.log('rootItems', rootItems)
        // console.log('defaultRootItems', defaultRootItems)
        const missingRootItems = defaultRootItems.filter(a => rootItems.findIndex((b) => b.OptionKey === a.OptionKey) === -1);
        // console.log('missingRootItems', missingRootItems)
        await Promise.all(missingRootItems.map((opt) => this.createFlat(opt)));
        await Promise.all(missingRootItems.map(({ OptionKey }) => {
            return this.applyRestrictions({
                OptionKey,
                Channels: Channels.length > 0 ? [(OptionKey.startsWith('FM|') ? 'CH|' + OptionKey.substring(3) : OptionKey)] : null,
                DeviceTypes: [],
                SessionModes: [],
                Usergroups: [],
            });
        }));
    }
    async fetchAll(options) {
        return await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          OptionKey,
          OptionCode,
          ParentKey,
          OptionType,
          ColorCls,
          IconCls,
          Icon,
          Visible,
          AllowCancel,
          EvidenceImagesMandatory,
          OrderPosition,
          GROUP_CONCAT(DISTINCT Usergroup ORDER BY Usergroup ASC SEPARATOR ', ') AS Usergroups,
          GROUP_CONCAT(DISTINCT SessionMode ORDER BY SessionMode ASC SEPARATOR ', ') AS SessionModes,
          GROUP_CONCAT(DISTINCT DeviceType ORDER BY DeviceType ASC SEPARATOR ', ') AS DeviceTypes,
          GROUP_CONCAT(DISTINCT ChannelCode ORDER BY ChannelCode ASC SEPARATOR ', ') AS Channels,
          ExternalId
        FROM config_followup_menus
        LEFT JOIN config_followup_menu_usergroup_filters USING (OptionKey)
        LEFT JOIN config_followup_menu_sessionmode_filters USING (OptionKey)
        LEFT JOIN config_followup_menu_devicetype_filters USING (OptionKey)
        LEFT JOIN config_followup_menu_channel_filters USING (OptionKey)
        WHERE IF(:OptionKey IS NULL, true, OptionKey=:OptionKey)
        GROUP BY OptionKey
        ORDER BY ParentKey, OrderPosition
      `),
            // GROUP_CONCAT(DISTINCT ChannelCode ORDER BY ChannelCode ASC SEPARATOR ', ') AS ChannelCode
            Params: options || {},
            Language: Language
        }, {
            valueMapper: {
                Usergroups: (i) => i != null ? i.split(', ') : i,
                SessionModes: (i) => i != null ? i.split(', ') : i,
                DeviceTypes: (i) => i != null ? i.split(', ') : i,
                Channels: (i) => i != null ? i.split(', ') : i,
            }
        });
    }
    async createFlat(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        INSERT IGNORE INTO config_followup_menus (
          OptionKey,
          OptionCode,
          ParentKey,
          ColorCls,
          IconCls,
          Icon,
          Visible,
          AllowCancel,
          OptionType,
          EvidenceImagesMandatory,
          OrderPosition,
          ExternalId,
          ModificationUser,
          ModificationDevice
        ) VALUES (
          :OptionKey,
          :OptionCode,
          :ParentKey,
          :ColorCls,
          :IconCls,
          :Icon,
          :Visible,
          :AllowCancel,
          :OptionType,
          :EvidenceImagesMandatory,
          :OrderPosition,
          NULLIF(:ExternalId, ''),
          :ModificationUser,
          :ModificationDevice
        )
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
    }
    async create(options) {
        const found = await this.find(options);
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! config_followup_menus.OptionCode=${options.OptionKey}`, 'OptionCode Already Exists');
            return false;
        }
        await this.createFlat(options);
        await this.applyRestrictions(options);
        return true;
    }
    async obfuscateItem({ OptionKey }, restrictionOptions) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: (`
        UPDATE config_followup_menus
        SET
          AllowCancel=NULL,
          OptionType=NULL,
          EvidenceImagesMandatory=NULL,
          ExternalId=NULL,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE OptionKey=:OptionKey
      `),
            Params: {
                OptionKey,
                ModificationUser,
                ModificationDevice
            }
        });
        if (restrictionOptions !== undefined) {
            await this.applyRestrictions(restrictionOptions);
        }
        return true;
    }
    async update(data, opt) {
        const found = await this.find(data);
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        if (found.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry config_followup_menus.OptionCode=${data.OptionKey}`, 'OptionCode Not Found');
            return false;
        }
        await requestService.query({
            Query: (`
        UPDATE config_followup_menus
        SET
          OptionKey=:OptionKey,
          ParentKey=:ParentKey,
          OptionCode=:OptionCode,
          ColorCls=:ColorCls,
          IconCls=:IconCls,
          Icon=:Icon,
          Visible=:Visible,
          AllowCancel=:AllowCancel,
          OptionType=:OptionType,
          OrderPosition=:OrderPosition,
          ExternalId=NULLIF(:ExternalId, ''),
          EvidenceImagesMandatory=:EvidenceImagesMandatory,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE OptionKey=:OptionKey
      `),
            Params: {
                ...data,
                ModificationUser,
                ModificationDevice
            }
        });
        if (opt.updateRestrictions === true) {
            await this.applyRestrictions(data);
        }
        return true;
    }
    async delete(options) {
        await this.deleteRestrictions(options);
        await requestService.query({
            Query: (`
        DELETE FROM config_followup_menus
        WHERE OptionKey=:OptionKey
      `),
            Params: options
        });
    }
    async deleteRestrictions(options) {
        await Promise.all([
            requestService.query({
                Query: `DELETE FROM config_followup_menu_usergroup_filters WHERE OptionKey=:OptionKey`, Params: options
            }),
            requestService.query({
                Query: `DELETE FROM config_followup_menu_sessionmode_filters WHERE OptionKey=:OptionKey`, Params: options
            }),
            requestService.query({
                Query: `DELETE FROM config_followup_menu_devicetype_filters WHERE OptionKey=:OptionKey`, Params: options
            }),
            requestService.query({
                Query: `DELETE FROM config_followup_menu_channel_filters WHERE OptionKey=:OptionKey`, Params: options
            }),
        ]);
    }
    async applyRestrictions(options) {
        const { OptionKey, Usergroups, SessionModes, DeviceTypes, Channels } = options;
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await this.deleteRestrictions({ OptionKey });
        const baseQueryParams = { OptionKey, ModificationUser, ModificationDevice };
        await Promise.all([
            ...(Usergroups || []).map(group => {
                return requestService.query({
                    Query: `INSERT INTO config_followup_menu_usergroup_filters (OptionKey, Usergroup, ModificationUser, ModificationDevice) VALUES (:OptionKey, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: group, ...baseQueryParams }
                });
            }),
            ...(SessionModes || []).map(sessionMode => {
                return requestService.query({
                    Query: `INSERT INTO config_followup_menu_sessionmode_filters (OptionKey, SessionMode, ModificationUser, ModificationDevice) VALUES (:OptionKey, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: sessionMode, ...baseQueryParams }
                });
            }),
            ...(DeviceTypes || []).map(deviceType => {
                return requestService.query({
                    Query: `INSERT INTO config_followup_menu_devicetype_filters (OptionKey, DeviceType, ModificationUser, ModificationDevice) VALUES (:OptionKey, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: deviceType, ...baseQueryParams }
                });
            }),
            ...(Channels || []).map((Channel) => {
                return requestService.query({
                    Query: `INSERT INTO config_followup_menu_channel_filters (OptionKey, ChannelCode, ModificationUser, ModificationDevice) VALUES (:OptionKey, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: Channel, ...baseQueryParams }
                });
            })
        ]);
    }
}

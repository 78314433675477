export class AArrayService {
    /**
     * Sort array using a template an array as template
     * @param {any[]} arrayToSort
     * @param {any[]} sortingTemplate
     */
    orderedSort(arrayToSort, sortingTemplate) {
        const result = [];
        sortingTemplate.forEach(function (key) {
            var found = false;
            arrayToSort = arrayToSort.filter(function (item) {
                if (!found && item == key) {
                    result.push(item);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });
        return result.concat(arrayToSort);
    }
}

import { AUnitTest } from "../core/AUnitTest.js";
export class AFilterUnit extends AUnitTest {
    constructor({ period }) {
        super({ period });
        super.addSetup(() => this.setup());
        super.addTest(() => this.test());
    }
    async setup() {
        this.$filters = $('#Filters');
    }
    async test() {
        this.selectTimeFrame();
        this.setInputs();
        this.setLimit(100);
    }
    filterIdsToIgnore() {
        return ['FromDate', 'FromTime', 'ToDate', 'ToTime', 'RefreshButton'];
    }
    selectTimeFrame() {
        switch (this.period) {
            case "month":
                $('#Filters').find('.quick-3').trigger('click');
                break;
            case "week":
                $('#Filters').find('.quick-2').trigger('click');
                break;
            default:
                break;
        }
    }
    setInputs() {
        this.$filters.find('#DeviceName').prop('selectedIndex', 1);
        this.$filters.find('.dropdown-tree').toArray().map(ele => {
            const $checkbox = $(ele).find('[unificationindex]').last();
            $checkbox.prop('checked', false);
            $checkbox.trigger('change');
        });
    }
    setLimit(limit) {
        this.$filters.find('#Limit').val(limit);
    }
}

import { ADetectionState } from "./AUnificationTypes.js";
export class ADetectionStateSnapshot {
    constructor(opt) {
        this.query = null;
        this.interval = opt?.interval || 5;
    }
    async makeTimeChart(Dest, opt) {
        let Options = await this.getOptions({ viewType: opt?.viewType });
        let CurrentTime = Date.now();
        let YAxes = [];
        let Series = [];
        for (let i = 0; i < Options.length; i++) {
            let Axe = {
                title: {
                    text: null,
                    style: { color: new Highcharts.Color(Options[i].color) }
                },
                visible: Options[i].showlable,
                labels: {
                    style: { color: new Highcharts.Color(Options[i].color) },
                }
            };
            if (Options[i].min !== undefined)
                Axe.floor = Options[i].min;
            if (Options[i].max !== undefined)
                Axe.ceiling = Options[i].max;
            if (Options[i].showlable !== undefined)
                Axe.visible = Options[i].showlable;
            if (Options[i].opposite !== undefined)
                Axe.opposite = Options[i].opposite;
            if (Options[i].format !== undefined)
                Axe.labels.format = Options[i].format;
            let hasType = (Options[i].type !== undefined);
            let Serie = {
                type: (opt && opt.chartType) ? opt.chartType : (hasType ? Options[i].type : 'area'),
                name: Options[i].title,
                labels: {
                    format: Options[i].format
                },
                marker: {
                    symbol: 'circle',
                    radius: 5
                },
                data: [],
                fillOpacity: Options[i].fill ? Options[i].opacity : 0.,
                lineWidth: Options[i].lineWidth,
                yAxis: Options[i].yAxe,
                color: new Highcharts.Color(Options[i].color).setOpacity(Options[i].opacity).get(),
                unit: Options[i].unit,
                visible: Options[i].visible === false ? false : true
            };
            if (Options[i].min !== undefined)
                Serie.floor = Options[i].min;
            if (Options[i].max !== undefined)
                Serie.ceiling = Options[i].max;
            YAxes.push(Axe);
            Series.push(Serie);
        }
        const destChart = document.getElementById(Dest);
        // let onLoadResolve = null
        // let onLoadPromise = new Promise((resolve) => { onLoadResolve = resolve })
        this.chart = new Highcharts.Chart({
            title: { text: "" },
            chart: {
                renderTo: destChart,
                // animation: Highcharts.svg, // don't animate in old IE
                // marginRight: 150,
                type: opt?.chartType || 'area',
                zooming: {
                    type: 'x',
                },
                events: {
                    load: function () {
                        this.reflow();
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                max: CurrentTime,
                min: CurrentTime - 60 * 60 * 1000,
                tickmarkPlacement: 'on',
                title: { text: '' }
            },
            yAxis: [{ labels: { format: `{value} ${await Translate.get('scans')}` }, title: { text: null } }],
            legend: {
                enabled: true,
                squareSymbol: false,
                navigation: {
                    enabled: true,
                }
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                //  series: {
                //     fillOpacity: 0 
                //    // fillOpacity: '100%' 
                //  },
                areaspline: {
                    marker: {
                        enabled: false
                    }
                },
                column: {
                    stacking: 'normal',
                    pointPadding: 5,
                    borderWidth: 0,
                    groupPadding: 5,
                    shadow: false
                },
                area: {
                    stacking: 'stream',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                }
            },
            tooltip: {
                shared: true,
                useHTML: true,
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                // borderRadius: 0,
                formatter: function () {
                    let parts = [];
                    let points = this.points ?? [];
                    for (let point of points) {
                        parts.push(( /*html*/`
              <tr>
                <td><span class="chart-dot" style="background-color: ${point.series.color}"></span> ${point.series.name}: </td>
                <td><b>${point.y}</b> ${point.series.userOptions.unit}</td>
              </tr>
            `).trim());
                    }
                    return ( /*html*/`
            <table class="tooltip-chart-table">
              <thead>
                <tr>
                  <th colspan="2">${new Date(points[0].x).toLocaleString()}</th>
                </tr>
              </thead>
              <tbody>
                ${parts.join('')}
              </tbody>
            </table>
          `);
                }
            },
            series: Series
        });
        // hc.onLoadPromise = onLoadPromise
        return this.chart;
    }
    async updateLastHour() {
        return await this.update(new Date(Date.now() - 60 * 60 * 1000), new Date());
    }
    async update(from, to) {
        if (this.query === null) {
            return;
        }
        let Interval = this.interval * (to.getTime() - from.getTime()) / (3600);
        let response = await requestService.query({ Query: this.query, Params: { FromTime: from, ToTime: to, Interval: Interval / 1000 } });
        response.Rows.map((row, i) => {
            response.Rows[i] = row.map(strNum => Number(strNum));
        });
        let ServerData = [];
        for (let c = 1; c < response.Columns.length; c++) {
            ServerData.push([]);
        }
        let LastTime = 0;
        for (let i = 0; i < response.Rows.length; i++) {
            let Time = response.Rows[i][0] * 1000;
            if (i) {
                for (let t = LastTime + Interval; t < Time; t += Interval) {
                    for (let c = 1; c < response.Columns.length; c++) {
                        ServerData[c - 1].push([t, response.Rows[i - 1][c]]);
                    }
                }
            }
            for (let c = 1; c < response.Columns.length; c++) {
                ServerData[c - 1].push([Time, response.Rows[i][c]]);
            }
            LastTime = Time;
        }
        if (response.Rows.length) {
            const now = Date.now();
            for (let t = LastTime + Interval; t <= now; t += Interval) {
                for (let c = 1; c < response.Columns.length; c++) {
                    ServerData[c - 1].push([t, response.Rows[response.Rows.length - 1][c]]);
                }
            }
        }
        for (let c = 1; c < response.Columns.length; c++) {
            this.chart.series[c - 1].setData(ServerData[c - 1], false);
        }
        this.chart.axes[0].setExtremes(from.getTime(), to.getTime(), true, false);
    }
    async getOptions(opt) {
        const fields = await this.loadFields(opt);
        this.query = this.buildQuery(fields);
        const shortFields = fields.map((field) => field + "_Short");
        const translations = await Translate.get(shortFields.concat(["Scans"]));
        const { calcColor } = mapHelperService.legend_detection_state;
        //const colors = ['#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395', '#994499', '#22aa99', '#aaaa11', '#6633cc', '#e67300', '#8b0707', '#651067', '#329262', '#5574a6', '#3b3eac', '#b77322', '#16d620', '#b91383', '#f4359e', '#9c5935', '#a9c413', '#2a778d', '#668d1c', '#bea413', '#0c5922', '#743411']
        let output = [];
        for (let field of fields) {
            // console.log(AColor.getStateColor(field));
            output.push({
                title: translations[field + "_Short"],
                min: 0.,
                format: `{value} ${translations['Scans']}`,
                unit: translations['Scans'],
                color: calcColor({ DetectionState: field })?.fill,
                fill: true,
                opacity: 1.,
                lineWidth: .8,
                yAxe: 0
            });
        }
        return output;
    }
    buildQuery(fields) {
        if (fields.length == 0) {
            return null;
        }
        // this.query = "SELECT floor(UNIX_TIMESTAMP(SnapshotTime)/:Interval) * :Interval as Time ";
        // for (let field of fields) {
        //   this.query += `, ${field}`
        // }
        // this.query += " FROM (SELECT SnapshotTime"
        // for (let field of fields) {
        //   this.query += `,Sum(${field}) as ${field}`
        // }
        // this.query += " FROM (SELECT SnapshotTime"
        // for (let field of fields) {
        //   this.query += `,IF(DetectionState='${field}', Count,0) as ${field}`
        // }
        // this.query += " FROM detection_states_snapshot where SnapshotTime Between :FromTime and :ToTime) t group by SnapshotTime ) t1 group by Time"
        return ( /*SQL*/`
      SELECT
        RoundedTime,
        ${fields.map(f => `IFNULL(MAX(${f}), 0) AS ${f}`).join(', ')}
      FROM (
        SELECT
          floor(UNIX_TIMESTAMP(SnapshotTime)/:Interval) * :Interval AS RoundedTime,
          ${fields.map(f => `IF(DetectionState='${f}',Count,NULL) AS ${f}`).join(', ')}
        FROM detection_states_snapshot
        WHERE SnapShotTime BETWEEN :FromTime AND :ToTime
      ) t
      GROUP BY RoundedTime
    `);
    }
    async loadFields(opt) {
        let whereClause = [`DetectionState != 'Done'`];
        const { PdaVerificationQueue, AssignedToPda, DetachedFromPda, CentralVerificationQueue, AssignedToCentralVerification, DetachedFromCentralVerification, } = new ADetectionState().Options.InProgress.Options;
        if (opt.viewType === 'operational') {
            whereClause.push(`DetectionState IN (${PdaVerificationQueue.FirstIndex}, ${AssignedToPda.FirstIndex}, ${DetachedFromPda.FirstIndex}, ${CentralVerificationQueue.FirstIndex}, ${AssignedToCentralVerification.FirstIndex}, ${DetachedFromCentralVerification.FirstIndex})`);
        }
        else if (opt.viewType === 'process') {
            whereClause.push(`DetectionState NOT IN (${PdaVerificationQueue.FirstIndex}, ${AssignedToPda.FirstIndex}, ${DetachedFromPda.FirstIndex}, ${CentralVerificationQueue.FirstIndex}, ${AssignedToCentralVerification.FirstIndex}, ${DetachedFromCentralVerification.FirstIndex})`);
        }
        let optionResult = await requestService.query(`
      SELECT DISTINCT DetectionState
      FROM detection_states_snapshot
      WHERE ${whereClause.join(' AND ')}
      ORDER BY DetectionState+0
    `);
        // console.log(optionResult)
        let result = [];
        for (let row of optionResult.Rows) {
            result.push(row[0]);
        }
        return result;
    }
}

import { AEngine } from "../core/AEngine.js";
export var EVENTS;
(function (EVENTS) {
    EVENTS["JSONS_LOADED"] = "JsonsLoaded";
    EVENTS["API_READY"] = "ApiReady";
    EVENTS["CONTENT_RESIZE"] = "ContentResize";
    EVENTS["CONTENT_DRAG"] = "ContentDragged";
    EVENTS["PREFETCH"] = "Prefetch";
    EVENTS["INFOWINDOW_CLOSE"] = "InfoClose";
    EVENTS["ROUTE_CHANGED"] = "RouteChanged";
    EVENTS["CONFIG_FETCHED"] = "Config";
    EVENTS["CONFIG_LOADED"] = "ConfigLoaded";
    EVENTS["CONSTRUCT"] = "construct";
    EVENTS["DESTRUCT"] = "destruct";
    EVENTS["DESTRUCT_DONE"] = "destruct->last";
    EVENTS["STATE_STREAM"] = "StateStream";
    EVENTS["STATE_CHANGED"] = "StateChanged";
    EVENTS["SERVER_STATS_STREAM"] = "ServerStatsStream";
    EVENTS["TRANSLATIONS_RESPONSE"] = "TranslationResponse";
    EVENTS["USER_INFO_STREAM"] = "UserInfoStream";
    EVENTS["SESSION_CHANGE_STREAM"] = "SessionChangeStream";
    EVENTS["SESSION_INFO"] = "SessionsInfo";
    EVENTS["SESSION_UPDATE_MAP"] = "SessionsOnMapChanged";
    EVENTS["QUERY_RESPONSE"] = "QueryResponse";
    EVENTS["TOGGLE_FULLSCREEN"] = "ToggleFullScreen";
    EVENTS["PAGE_INITIALIZED"] = "PAGE_INITIALIZED";
    EVENTS["ERROR"] = "ERROR";
    EVENTS["VERIFICATION_CHANNEL"] = "VerificationChannels";
    EVENTS["VERIFICATION_RESULT_OPTIONS"] = "VerificationResultOptions";
    EVENTS["CHANNEL_QUEUES_UPDATED"] = "CHANNEL_QUEUES_UPDATED";
    EVENTS["PARKING_RIGHT_STREAM"] = "ParkingRightStream";
    EVENTS["GEO_OBJECT_CLICKED"] = "GEO_OBJECT_CLICKED";
    EVENTS["REFRESH_INVOKED"] = "REFRESH_INVOKED";
    EVENTS["COMPONENT_VALIDATE"] = "COMPONENT_VALIDATE";
})(EVENTS || (EVENTS = {}));
export class AEventService {
    constructor() {
        this.logExclude = ['QueryResponse', 'ServerStatsStream', 'TranslationResponse'];
        this.routes = {};
        this.lastId = 0;
        this.logLevel = 0;
        if (window.Events === undefined) {
            Object.defineProperty(window, 'Events', {
                get: () => this
            });
        }
    }
    get nextId() {
        return (this.lastId++).toString(16);
    }
    /**
     * Whether a route with the given name has been defined already
     */
    hasRoute(name) {
        return this.routes[name] !== undefined && Object.keys(this.routes[name]).length > 0;
    }
    hardwire(name, cb) {
        return this.on(name, cb, true);
    }
    /**
     * Combination of AEventService.hardwire & AEventService.once
     */
    h_once(name, cb) {
        return this._once(name, cb, true);
    }
    once(name, cb) {
        return this._once(name, cb, false);
    }
    _once(name, cb, hardwire = false) {
        const id = this.nextId;
        return this.on(name, (p1, p2, p3, p4) => {
            cb(p1, p2, p3, p4);
            this.off(name, id);
        }, hardwire, id);
    }
    msgTypeToEvent(msgType) {
        const toFind = msgType;
        let found = null;
        for (let key in EVENTS) {
            if (key === toFind || EVENTS[key] === toFind) {
                found = { [key]: EVENTS[key] };
                break;
            }
        }
        return found;
    }
    eventIdToName(eventId) {
        return eventId;
    }
    on(eventId, cb, hardwire = false, id = this.nextId, data = {}) {
        const name = this.eventIdToName(eventId);
        if (this.logLevel >= 2)
            console.log(`Registering event <${name}>`);
        const entry = { id, cb, hardwire, data };
        if (this.routes[name] === undefined) {
            this.routes[name] = {};
        }
        this.routes[name][entry.id] = entry;
        return entry.id;
    }
    off(eventId, id) {
        const name = this.eventIdToName(eventId);
        if (this.logLevel >= 2)
            console.log(`Unregistering event <${name}> with id '${id}'`);
        if (this.routes.hasOwnProperty(name)) {
            if (this.routes[name].hasOwnProperty(id)) {
                delete this.routes[name][id];
            }
            else {
                console.error(`Couldn't remove Events route eventId=${eventId} id=${id}`);
            }
            if (Object.keys(this.routes[name]).length === 0) {
                delete this.routes[name];
            }
        }
    }
    tryInvoke(eventId, args) {
        const name = this.eventIdToName(eventId);
        if (this.logLevel) {
            // const argsText = args ? stringify(args, 2, null, 2) : ''
            if (!this.logExclude.includes(name)) {
                const eventNameHighlighted = name
                    // .replace(/[[]/g, '%p[%n').replace(/[\]]/g, '%p]%r')
                    .split('->').join('%p->%c');
                AEngine.log(`Invoking %c%s%p"%r${eventNameHighlighted}%s%p"%r%n`);
            }
            if (!globalThis.msgDict.hasOwnProperty(eventId) && typeof eventId === 'string') {
                const redirect = this.msgTypeToEvent(eventId);
                if (redirect !== null) {
                    globalThis.msgDict[eventId] = redirect;
                }
                else {
                    globalThis.msgDict[eventId] = '...';
                }
                if (this.logLevel >= 2) {
                    console.log('dict ', globalThis.msgDict);
                }
            }
        }
        if (this.hasRoute(name)) {
            return this.invoke(name, args);
        }
        return Promise.resolve(false);
    }
    async invoke(eventId, args) {
        const name = this.eventIdToName(eventId);
        // const excluded = ['QueryResponse', 'ServerStatsStream']
        // if (!excluded.includes(name.toString()) && this.logging)
        //   AEngine.log(`Invoking ${name}`)
        const callbacks = this.routes[name];
        await Promise.all(Object.keys(callbacks).map(key => {
            try {
                return callbacks[key].cb(args);
            }
            catch (err) {
                console.error(err);
            }
        }));
        return true;
    }
    clear() {
        for (let msgType of Object.keys(this.routes)) {
            const route = this.routes[msgType];
            for (let key of Object.keys(route)) {
                if (route[key].hardwire !== true) {
                    delete route[key];
                }
            }
            if (Object.keys(route).length === 0) {
                delete this.routes[msgType];
            }
        }
    }
}
/*

// EventHandler forever until unregistered
Events.hardwire('QueryResponse', data => {
    console.log('[HW] QueryResponse', data)
})

// EventHandler for a single page
Events.on('QueryResponse', data => {
    console.log('QueryResponse', data)
})

// Eventhandler for a single page, fired once, then unregistered
Events.once('QueryResponse', data => {
    console.log('once', data)
})

Events.invoke('QueryResponse', 1)
Events.invoke('QueryResponse', 2)
Events.invoke('QueryResponse', 3)

*/ 

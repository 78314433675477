import { AResponse } from "../classes/AResponse.js";
import { isUserACI } from "../utils/tools.js";
import { EVENTS } from "./AEventService.js";
export class APermissionService {
    constructor() {
        Object.defineProperty(window, 'Permissions', {
            get: () => this
        });
    }
    autoInit() {
        Events.h_once(EVENTS.PREFETCH, _ => {
            this.hideAciElements();
        });
    }
    async refetchUsergroups() {
        const flatArr = await this.fetchAllUsergroupsFlat();
        Usergroups = new AResponse(flatArr).toArray();
    }
    fetchAllUsergroupsFlat(name) {
        const where = name ? `WHERE UserGroup=:UserGroup` : '';
        return requestService.query({
            Query: `SELECT UserGroup, UserGroup as UserGroupText FROM usergroups ${where}`,
            Params: { UserGroup: name },
            Name: 'FetchUsergroups',
            Language: Language,
            Translate: ["UserGroupText"]
        });
    }
    fetchUsergroup(user) {
        return requestService.query({
            Query: `SELECT UserGroup FROM user_usergroups WHERE User=:User`,
            Name: 'FetchUsergroup',
            Params: {
                User: user
            }
        });
    }
    hasPermission(requiredIdMRs) {
        const user = _.getUser();
        if (!user) {
            return false;
        }
        for (const group of user.UserGroups) {
            if (group.toLowerCase() === ACI_ADMIN) {
                return true;
            }
        }
        for (const requiredIdMR of requiredIdMRs) {
            if (requiredIdMR != null && !user.id_mrs.includes(requiredIdMR)) {
                return false;
            }
        }
        return true;
    }
    hideAciElements() {
        const $eles = $(`[usergroup="aci_admin"]`);
        $eles.each((i, ele) => {
            if (!isUserACI()) {
                $(ele).remove();
            }
        });
    }
    getParent(id_mr) {
        for (const key in menuService.menu) {
            const item = menuService.menu[key];
            if (item.children && item.children.length) {
                for (const child of item.children) {
                    if (child.id_mr === id_mr) {
                        return item;
                    }
                }
            }
        }
        return null;
    }
    getMenuItem(id_mr) {
        let output = null;
        for (const menuItem of Object.values(menuService.menu)) {
            if (menuItem.id_mr == id_mr) {
                output = menuItem;
                break;
            }
            if (menuItem.children) {
                const child = this.findInChildren(menuItem.children, id_mr);
                if (child !== null) {
                    output = child;
                    break;
                }
            }
        }
        return output;
    }
    findInChildren(children, id_mr) {
        let output = null;
        for (const menuItem of children) {
            if (menuItem.id_mr == id_mr) {
                output = menuItem;
                break;
            }
        }
        return output;
    }
}

import { AError } from "../../classes/AError.js";
import { COLUMN_DATETIME, DATA_DATETIME } from "../../classes/AGridTypes.js";
import { AResponse } from "../../classes/AResponse.js";
import { sleep } from "../../core/AEngine.js";
import { ALERTS, ALERT_BUTTONS } from "../../services/AAlertService.js";
import { AInputDate, AInputTime, AShowTable, AConvertToGridColumns, AConvertToGridData, copyToClipboard, getApiBaseUrl, getApiDescriptionUrl, AFormatDate } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.baseUrl = getApiBaseUrl();
    }
    async init() {
        this.translations = await Loading.waitForPromises(Translate.get([
            'Access Token Generated',
            'Create Access Token',
            'Create',
            'Edit Access Token',
            'Edit',
            'Save Changes',
            'Cancel',
            'Close',
            'Expires',
            'AllowedIps',
            'AllowedReferrers',
            'No Restriction',
            'Copy To Clipboard',
            'CreationUser',
            'CreationTime',
            'Description'
        ]));
        this.refresh().catch(AError.handle);
        $('#create').on('click', _ => Loading.waitForPromises(this.showCreateModal()));
    }
    createTestLinkForAccessToken({ AccessToken }) {
        const href = this.baseUrl + `/test?Format=Echo&Bearer=${AccessToken}`;
        return href;
    }
    async requestApiJson() {
        CCCClient.SendMessage('OpenApiJsonRequest', 1, {});
        const msg = await Loading.waitForEvent('OpenApiJsonResponse');
        return msg;
    }
    async createAccessToken(opt) {
        const { Description, Expires, AllowedIps, AllowedReferrers } = opt;
        this.downloadRequestId = this.downloadRequestId ? ++(this.downloadRequestId) : 1;
        const generatedId = this.downloadRequestId.toString(16);
        CCCClient.SendMessage("ApiAccessTokenRequest", 1, {
            Name: generatedId,
            // Description,
            Expires: (Expires instanceof Date) ? Expires.toJSON() : Expires,
            AllowedIps: AllowedIps,
            AllowedReferrers: AllowedReferrers
        });
        const msg = await Loading.waitForEvent(`ApiAccessTokenResponse->${generatedId}`, false);
        return msg;
    }
    genHints() {
        const url = location.href;
        return [this.genHint(url, 'backoffice1'), this.genHint(url, 'backoffice2')].join(', ');
    }
    genHint(urlString, subdomain) {
        const url = new URL(urlString);
        const parts = url.host.split('.');
        parts[0] = subdomain;
        url.host = parts.join('.');
        return url.origin;
    }
    renderForm({ date, time, allowedIps, allowedReferrers }) {
        const hintAllowedReferrers = this.genHints();
        const hintAllowedIps = `127.0.0.1,192.168.1.26`;
        return ( /*html*/`
      <div>
        <label class="form-label" for="_date">${this.translations['Expires']}</label>
        <div class="columns col-gapless">
          <input class="form-input col-6" autocomplete="off" type="date" id="_date" value="${date}">
          <input class="form-input col-6" autocomplete="off" type="time" id="_time" value="${time}">
        </div>
      </div>
      <div class="hidden">
        <div class="form-group">
          <label class="form-label" for="Description">${this.translations['Description']}</label>
          <textarea id="Description" class="form-input" rows="3" autocomplete="off", autocorrect="off", autocapitalize="off", spellcheck="false"></textarea>
        </div>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label" for="AllowedIps">${this.translations['AllowedIps']}</label>
          <input class="form-input" type="text" id="AllowedIps" placeholder="${hintAllowedIps}" value="${allowedIps ? allowedIps.join(', ') : ''}">
        </div>
        <select id="AllowedIpsRender" size="3" class="form-select">
          <option></option>
        </select>
      </div>
      <div>
        <div class="form-group">
          <label class="form-label" for="AllowedReferrers">${this.translations['AllowedReferrers']}</label>
          <input class="form-input" type="text" id="AllowedReferrers" placeholder="${hintAllowedReferrers}" value="${allowedReferrers ? allowedReferrers.join(', ') : ''}">
        </div>
        <select id="AllowedReferrersRender" size="3" class="form-select">
          <option></option>
        </select>
      </div>
      <div class="hidden">
        <div class="form-group">
          <label class="form-label" for="CreationUser">${this.translations['CreationUser']}</label>
          <input class="form-input" disabled="disabled" type="text" id="CreationUser" value="${_.getUser().UserDisplayName}">
        </div>
      </div>
      <div class="hidden">
        <div class="form-group">
          <label class="form-label" for="CreationTime">${this.translations['CreationTime']}</label>
          <input class="form-input" disabled="disabled" type="text" id="CreationTime" value="${AFormatDate(new Date())}">
        </div>
      </div>
    `);
    }
    /**
     * @param {any} edit
     * @returns {{ title, proceedBtnText, cancelBtnText }}
     */
    getModalTranslations(edit) {
        let title, buttons;
        if (edit === undefined) {
            title = this.translations['Create Access Token'];
            buttons = ALERT_BUTTONS.createCancel;
        }
        else {
            title = this.translations['Edit Access Token'];
            buttons = ALERT_BUTTONS.saveCancel;
        }
        return { title, buttons };
    }
    getFormElements($form) {
        return {
            $allowedIps: $form.find('#AllowedIps'),
            $description: $form.find('#Description'),
            $allowedReferrers: $form.find('#AllowedReferrers'),
            $allowedIpsRender: $form.find('#AllowedIpsRender'),
            $allowedReferrersRender: $form.find('#AllowedReferrersRender'),
        };
    }
    async showCreateModal(edit) {
        const getDateTimeValues = () => {
            const inOneYear = moment(new Date()).add(12, 'months').toDate();
            return [AInputDate(inOneYear), AInputTime(inOneYear)];
        };
        const [date, time] = getDateTimeValues();
        const html = this.renderForm(edit ? edit : { date, time, allowedIps: null, allowedReferrers: null });
        const { title, buttons } = this.getModalTranslations(edit);
        const events = Alerts.show({
            translatedTitle: title,
            content: html,
            type: ALERTS.Info,
            buttons: buttons
        }); //title, html, ALERTS.Info, proceedBtnText, cancelBtnText)
        const $form = events.$ele;
        const { $description, $allowedIps, $allowedReferrers, $allowedIpsRender, $allowedReferrersRender } = this.getFormElements($form);
        $allowedIps.on('change keyup keydown', _ => {
            /** @type {any} */
            const str = $allowedIps.val();
            // @ts-ignore
            const parts = str.split(',');
            $allowedIpsRender.html(str.length === 0 ? `<option class="option-italic">(${this.translations['No Restriction']})</option>` :
                parts.map(str => str.trim()).map(option => ( /*html*/`<option>${option}</option>`)));
        });
        $allowedReferrers.on('change keyup keydown', _ => {
            /** @type {any} */
            const str = $allowedReferrers.val();
            // @ts-ignore
            const parts = str.split(',');
            $allowedReferrersRender.html(str.length === 0 ? `<option class="option-italic">(${this.translations['No Restriction']})</option>` :
                parts.map(str => str.trim()).map(option => ( /*html*/`<option>${option}</option>`)));
        });
        events.on('option1', () => {
            const valDate = $form.find('#_date').val();
            const valTime = $form.find('#_time').val();
            const expires = new Date(valDate + ' ' + valTime).toJSON().toString();
            /** @type {any} */
            const allowedIpsStr = $allowedIps.val();
            const allowedIps = allowedIpsStr.split(',').map(str => str.trim()).filter(str => str.length > 1);
            /** @type {any} */
            const allowedReferrersStr = $allowedReferrers.val();
            const allowedReferrers = allowedReferrersStr.split(',').map(str => str.trim()).filter(str => str.length > 1);
            if (edit) {
                return Alerts.notImplementedYet();
            }
            Loading.waitForPromises(this.createAccessToken({
                Description: $description.val(),
                Expires: expires,
                AllowedIps: allowedIps,
                AllowedReferrers: allowedReferrers
            }).then((msg) => {
                return this.displayAccessTokenModal(msg);
            })).catch(AError.handle);
        });
        $form.find(':input').trigger('change');
    }
    async displayAccessTokenModal(msg) {
        await this.refreshAfterInsert(msg);
        const disclaimer = await Translate.get(`It is important to save the access token below for you will not be able to view it again!`);
        // const testLink = this.createTestLinkForAccessToken(msg)
        const modalHtml = ( /*html*/`
      <div>
        <p>${disclaimer}</p>
        <div class="input-group mb-1">
          <input class="form-input" type="text" placeholder="..." value="${msg.AccessToken}">
          <button id="copy-token" class="btn btn-primary input-group-btn">${this.translations['Copy To Clipboard']}</button>
        </div>
      </div>
    `);
        const events = Alerts.show({
            translatedTitle: this.translations['Access Token Generated'],
            content: modalHtml,
            type: ALERTS.Info
        });
        events.$ele.find('#copy-token').on('click', e => {
            e.preventDefault();
            copyToClipboard(msg.AccessToken);
        });
    }
    async refreshAfterInsert(msg) {
        await sleep(1000);
        await this.refresh().catch(AError.handle);
        const insertedRecord = PageScript.grid.store.records.find(r => r.Id == msg.AccessTokenId);
        if (insertedRecord) {
            PageScript.grid.selectedRecord = insertedRecord;
        }
        else {
            console.warn(`Couldn't find inserted record!`);
        }
    }
    refresh() {
        return Loading.waitForPromises(requestService.query({
            Query: (`
          SELECT
            AccessTokenId,
            JSON_EXTRACT(AccessTokenContent, "$.Id") as Id,
            JSON_EXTRACT(AccessTokenContent, "$.AllowedIps") as AllowedIps,
            JSON_EXTRACT(AccessTokenContent, "$.AllowedReferrers") as AllowedReferrers,
            ${'' /*Description,*/}
            Expires,
            AccessTokenContent
            ${'' /*User as CreationUser,*/}
            ${'' /*CreationTime*/}
          FROM api_access_tokens
          ORDER BY AccessTokenId DESC
        `),
            Params: {},
            Language: Language
        })).then(async (response) => {
            let accessTokens = [];
            // Convert to array
            new AResponse(response).loop(({ Id, Name, Expires, Scopes, AllowedIps, AllowedReferrers }) => {
                accessTokens.push({
                    Id: Id,
                    Name: Name,
                    Expires: Expires,
                    Scopes: Scopes,
                    AllowedIps: AllowedIps,
                    AllowedReferrers: AllowedReferrers
                });
            });
            this.grid = AShowTable({
                aci: {
                    resizeToFit: false
                },
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(response, {
                    'Id': { flex: 0, hidden: true },
                    'AccessTokenId': { flex: 0, hidden: true },
                    'AccessTokenContent': {
                        flex: 0,
                        hidden: true,
                        field: 'AccessTokenContent',
                        type: 'template',
                        template: (value) => '...',
                    },
                    'Name': { flex: 2 },
                    'Scopes': { flex: 2 },
                    'AllowedIps': { flex: 2 },
                    'AllowedReferrers': { flex: 3 },
                    'Expires': Object.assign({ flex: 2 }, COLUMN_DATETIME),
                    // 'Description': COLUMN_TEXT,
                    // 'CreationUser': COLUMN_TEXT,
                    // 'CreationTime': COLUMN_DATETIME,
                }),
                data: AConvertToGridData(response, {
                    'Expires': DATA_DATETIME,
                    'Actions': () => this.translations['Copy To Clipboard'],
                    // 'CreationTime': DATA_DATETIME
                })
            });
        });
    }
}
export function css() {
    return ( /*html*/`
    <style>
      select option.option-italic {
        font-style: italic;
      }
    </style>
  `);
}
export function render() {
    const apiDescriptionUrl = getApiDescriptionUrl();
    return ( /*html*/`
    <div id="Rapport" class="bryntum-container has-footer-2">
      <div id="table-bryntum"></div>
      <div class="columns footer aci">
        <div class="column col-2">
          <div id="count" class="text">Viewing <span>0</span> Access Tokens</div>
        </div>
        <div class="column col-2 col-ml-auto">
          <a href="${apiDescriptionUrl}" target="_blank" class="btn btn-link col-12">
            <i class="fa-regular fa-circle-info"></i>
            View Api Specification
          </a>
        </div>
        <div class="column col-2">
          <button id="create" class="btn btn-success col-12">
            <i class="fa-solid fa-plus"></i>
            Create Access Token
          </button>
        </div>
      </div>
    </div>
  `);
}

import { AError } from "../classes/AError.js";
export class AChannelOrm {
    async find({ ChannelCode }) {
        return await requestService.query({
            Query: `SELECT ChannelCode FROM config_channels WHERE ChannelCode=:ChannelCode`,
            Params: { ChannelCode }
        });
    }
    async fetchAll(options) {
        return await requestService.fetch({
            AssertValues: true,
            Query: (`
        SELECT
          ChannelCode,
          ChannelCode AS ChannelName,
          IsFineCancelation,
          TIME_TO_SEC(DetectionLifespan) AS DetectionLifespan,
          GROUP_CONCAT(DISTINCT Usergroup ORDER BY Usergroup ASC SEPARATOR ', ') AS Usergroups,
          GROUP_CONCAT(DISTINCT SessionMode ORDER BY SessionMode ASC SEPARATOR ', ') AS SessionModes,
          GROUP_CONCAT(DISTINCT DeviceType ORDER BY DeviceType ASC SEPARATOR ', ') AS DeviceTypes,
          ExternalId
        FROM config_channels
        LEFT JOIN config_channel_usergroup_filters USING (ChannelCode)
        LEFT JOIN config_channel_sessionmode_filters USING (ChannelCode)
        LEFT JOIN config_channel_devicetype_filters USING (ChannelCode)
        WHERE IF(:ChannelCode IS NULL, true, ChannelCode=:ChannelCode)
        GROUP BY ChannelCode
      `),
            Params: options || {},
            Translate: ['ChannelName'],
            Language: Language
        }, {
            valueMapper: {
                Usergroups: (i) => i != null ? i.split(', ') : i,
                SessionModes: (i) => i != null ? i.split(', ') : i,
                DeviceTypes: (i) => i != null ? i.split(', ') : i,
            }
        });
    }
    async create(options) {
        const found = await this.find(options);
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        if (found.Rows.length > 0) {
            AError.handleSilent(`db entry already exists! config_channels.ChannelCode=${options.ChannelCode}`, 'ChannelCode Already Exists');
            return false;
        }
        await requestService.query({
            Query: (`
        INSERT INTO config_channels (ChannelCode, IsFineCancelation, DetectionLifespan, ExternalId, ModificationUser, ModificationDevice)
        VALUES (:ChannelCode, :IsFineCancelation, :DetectionLifespan, NULLIF(:ExternalId, ''), :ModificationUser, :ModificationDevice)
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        await this.applyRestrictions(options);
        return true;
    }
    async update(options) {
        const found = await this.find(options);
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        if (found.Rows.length === 0) {
            AError.handleSilent(`Couldn't find db entry config_channels.ChannelCode=${options.ChannelCode}`, 'ChannelCode Not Found');
            return false;
        }
        await requestService.query({
            Query: (`
        UPDATE config_channels
        SET
          IsFineCancelation=:IsFineCancelation,
          DetectionLifespan=:DetectionLifespan,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice,
          ExternalId=NULLIF(:ExternalId, '')
        WHERE ChannelCode=:ChannelCode
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        await this.applyRestrictions(options);
        return true;
    }
    async delete(options) {
        await this.deleteRestrictions(options);
        await requestService.query({
            Query: (`DELETE FROM config_channels WHERE ChannelCode=:ChannelCode`),
            Params: options
        });
    }
    async deleteRestrictions(options) {
        await Promise.all([
            requestService.query({
                Query: `DELETE FROM config_channel_usergroup_filters WHERE ChannelCode=:ChannelCode`, Params: options
            }),
            requestService.query({
                Query: `DELETE FROM config_channel_sessionmode_filters WHERE ChannelCode=:ChannelCode`, Params: options
            }),
            requestService.query({
                Query: `DELETE FROM config_channel_devicetype_filters WHERE ChannelCode=:ChannelCode`, Params: options
            }),
        ]);
    }
    async applyRestrictions(options) {
        const { ChannelCode, Usergroups, SessionModes, DeviceTypes } = options;
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await this.deleteRestrictions({ ChannelCode });
        const baseQueryParams = { ChannelCode, ModificationUser, ModificationDevice };
        await Promise.all([
            ...(Usergroups || []).map(group => {
                return requestService.query({
                    Query: `INSERT INTO config_channel_usergroup_filters (ChannelCode, Usergroup, ModificationUser, ModificationDevice) VALUES (:ChannelCode, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: group, ...baseQueryParams }
                });
            }),
            ...(SessionModes || []).map(sessionMode => {
                return requestService.query({
                    Query: `INSERT INTO config_channel_sessionmode_filters (ChannelCode, SessionMode, ModificationUser, ModificationDevice) VALUES (:ChannelCode, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: sessionMode, ...baseQueryParams }
                });
            }),
            ...(DeviceTypes || []).map(deviceType => {
                return requestService.query({
                    Query: `INSERT INTO config_channel_devicetype_filters (ChannelCode, DeviceType, ModificationUser, ModificationDevice) VALUES (:ChannelCode, :Value, :ModificationUser, :ModificationDevice)`,
                    Params: { Value: deviceType, ...baseQueryParams }
                });
            }),
        ]);
    }
}

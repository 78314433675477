import { ADynamicChartSnapshotDetections } from "../../charts/ADynamicChartSnapshotDetections.js";
import { AError } from "../../classes/AError.js";
import { sleep } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { AConvertToGridColumns, AConvertToGridData, AShowTable } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.snapshot = new ADynamicChartSnapshotDetections('Chart', {
            // @ts-ignore // TODO: FIX HIGHCHARTS
            calcDimensions: () => ({ height: $('.flex-content').height() - 50, width: $(`#${this.snapshot.chart?.id}`).width() })
        });
        Events.on('ACI_TABS_CHANGED->charts->tab-chart', () => this.resize().catch(AError.handle));
    }
    async init() {
        const $chartType = $('#ChartType');
        $chartType.on('change', (e) => this.updateFilterAvailability());
        FilterManager.load();
        const $print = $('#print');
        const $export = $('#export');
        const $flex_content = $('.flex-content');
        // @ts-ignore // TODO: FIX HIGHCHARTS
        $print.on('click', _ => this.getChart()?.print());
        Events.on(`ACI_TABS_CHANGED->charts`, ({ tabview, $tabview }) => {
            $flex_content.css({ 'background-color': (tabview === 'tab-table') ? 'inherit' : '#fff' });
            $print.toggleClass('hidden', (tabview === 'tab-table'));
            $export.toggleClass('hidden', !(tabview === 'tab-table'));
        });
        $('#FlipAxis').on('click', _ => {
            const h = $('#Horizontal').val();
            const v = $('#Vertical').val();
            $('#Horizontal').val(v);
            $('#Vertical').val(h);
            Loading.waitForPromises(this.refresh());
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => Loading.waitForPromises(this.refresh())));
    }
    async resize() {
        await sleep(1);
        this.snapshot?.resize();
    }
    updateFilterAvailability() {
        const $chartType = $('#ChartType');
        $('#Horizontal').prop('disabled', ($chartType.val() === 'pie'));
        if ($chartType.val() === 'pie') {
            $('#Horizontal').val($('#Horizontal option:first-child').attr('value'));
        }
    }
    getChart() {
        return this.snapshot.chart;
    }
    async refresh() {
        try {
            let { FromDate, ToDate, DeviceName, Horizontal, Vertical, ChartType, Inverted, Polar, IncludeTotals, Name } = FilterManager.save();
            FilterManager.setActive(false);
            if ($('#ChartType').val() === 'pie') {
                Horizontal = 'Chart_All';
            }
            this.chartOptions = { DataSource: 'detections', IncludeTotals, Horizontal, Vertical, ChartType, Inverted, Polar, Filters: [], IgnoreOutsideSegment: null, OnlyAllowFinalized: null, ShowLegend: true };
            this.chartFilters = { FromDate, ToDate, DeviceName: '%' };
            const { hasData, chartData } = await this.snapshot.update(this.chartOptions, this.chartFilters);
            this.snapshot.setTitle(Name);
            this.grid = AShowTable({
                appendTo: 'table-bryntum',
                columns: AConvertToGridColumns(chartData, {
                    'Bars': { text: '' }
                }),
                data: AConvertToGridData(chartData)
            });
            $('#print').prop('disabled', false);
            this.updateFilterAvailability();
        }
        catch (err) {
            AError.handle(err);
        }
        finally {
            FilterManager.setActive(true);
            $('[aci-show-on-input]').removeClass('hidden');
        }
    }
}
export function css() {
    return ( /*html*/`
    <style>
    .highcharts-container .aci-hover-item td {
      border: 4px solid transparent;
    }
    #Chart, #Table {
      width: 100%;
      height: 100%;
    }
    *[tabgroup="charts"][tabview] {
      height: 100%;
    }
    .flex-content {
      background-color: #fff;
    }
    #Filters.side-filter-bar > .column:first-child {
      height: calc(100% - 88px) !important;
    }
    </style>
  `);
}
export function render() {
    return ( /*html*/`
    <form id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ChartType">ChartType</label>
          <select class="form-select" id="ChartType">
            <option value="column">Column Chart</option>
            <option value="line">Line Chart</option>
            <option value="spline">Spline Chart</option>
            <option value="area">Area Chart</option>
            <option value="areaspline">Area Spline Chart</option>
            <option value="scatter">Scatter Chart</option>
            <option value="pie">Pie Chart</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Horizontal">Horizontal</label>
          <select class="form-select" id="Horizontal">
            ${ADynamicChartSnapshotDetections.genOptions()}
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Vertical">Vertical</label>
          <select class="form-select" id="Vertical">
            ${ADynamicChartSnapshotDetections.genOptions()}
          </select>
        </div>

        <!-- 
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
        -->

        <div class="form-group">
          <label class="form-switch">
            <input id="Inverted" type="checkbox">
            <i class="form-icon"></i> Inverted
          </label>
        </div>

        <div class="form-group">
          <label class="form-switch">
            <input id="Polar" type="checkbox">
            <i class="form-icon"></i> Polar
          </label>
        </div>

        <div class="form-group">
          <label class="form-switch">
            <input id="IncludeTotals" type="checkbox">
            <i class="form-icon"></i> Show Totals
          </label>
        </div>

        </div>
      <div class="column col-12">
        <div class="columns">
          <div class="column col-12">
            <button class="btn btn-grey btn-sm col-12 mb-1" id="FlipAxis">Flip Axis</button>
          </div>
          <div class="column col-12">
            <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
          </div>
        </div>
      </div>
    </form>
    <div id="Rapport" class="flex-child bryntum-container has-footer-2">
      <div class="flex-content">
        <div class="aci-tabs tabs-sticky tabs-fixed-md top-zero tabs-fw" tabgroup="charts">
          <button class="aci-tab active" tab="tab-chart">
            <span>Chart</span>
          </button>
          <button class="aci-tab" tab="tab-table">
            <span>Table</span>
          </button>
        </div>
        <div class="columns col-gapless" style="height: calc(100% - 60px);">
          <div class="column col-12">
            <div tabgroup="charts" tabview="tab-chart" class="h-padding">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="Chart" class="hidden"></div>
            </div>
            <div tabgroup="charts" tabview="tab-table" class="h-padding">
              <div template="${ATemplates.WaitingForInput}"></div>
              <div aci-show-on-input="true" id="table-bryntum" class="hidden fh"></div>
            </div> 
          </div>
        </div>
      </div>

      <div class="columns footer aci">
        <div class="column col-2 col-ml-auto">
          <button id="print" class="btn btn-primary col-12" disabled="disabled">Print</button>
          <button id="export" class="btn btn-primary col-12 hidden" disabled="disabled">Export</button>
        </div>
      </div>
    </div>
  `);
}

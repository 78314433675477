import { AColorHSV } from "../../classes/AColorHSV.js";
import { AError } from "../../classes/AError.js";
import { AEngine } from "../../core/AEngine.js";
import { ATemplates } from "../../core/ATemplateService.js";
import { ADragService } from "../../services/ADragService.js";
import { ARouteMapHelperService } from "../../services/ARouteMapHelperService.js";
import { createMap } from "../../utils/maps.js";
import { AFormatDate, AInputDate, metersToKilometerText } from "../../utils/tools.js";
export class APage {
    constructor() {
        this.map = createMap('map', {
            zoom: 24
        });
        FilterManager.load();
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
        // Move legend to map
        this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push($("#map_legend").get(0));
        this.devices = [];
        this.routeMapHelperService = AEngine.get(ARouteMapHelperService);
    }
    async init() {
        this.map.fit();
        this.translations = await Loading.waitForPromises(Translate.get([
            'Delete Planned Route',
            'Route Deleted',
            'Planning Deleted',
            'Delete planning',
            'Delete route from planning',
            'Route will be deleted from',
            'Planning'
        ]));
        this.waySegmentColors = {
            green: new AColorHSV(120, 100, 100),
            blue: new AColorHSV(192, 44, 87),
            red: new AColorHSV(0, 99, 99),
            lime: new AColorHSV(63, 100, 100),
            orange: new AColorHSV(32, 94, 100)
        };
        AEngine.get(ADragService).createDraggable();
        Loading.waitForPromises(this.getGeoMap()).then(response => {
            //this.setFilters()
            this.refresh();
        }).catch(AError.handle);
    }
    async refresh() {
        await Loading.waitForPromises(this.getDrivenRoutes()).then(response => {
            this.showInfoScreen("splash");
            this.createRoutesDoneRows();
        }).catch(AError.handle);
        this.routeMapHelperService.removeWaySegments(this.map);
        this.map.resetBounds();
    }
    showInfoScreen(screen) {
        $('#info_overview_splash').hide();
        $('#info_overview_driven').hide();
        $('#info_overview_planned').hide();
        if (screen === "splash") {
            $('#info_overview_splash').show();
        }
        else if (screen === "driven") {
            $('#info_overview_driven').show();
        }
        else if (screen === "planned") {
            $('#info_overview_planned').show();
        }
    }
    createRoutesDoneRows() {
        $('#routes_driven_list').children().remove();
        for (const index in this.drivenRoutes) {
            const $droute = this.drivenRoutes[index];
            const $droute_date = new Date($droute.From);
            const $routeName = $droute.RouteName;
            let $device = $droute.DeviceName;
            var $row = $(/*html*/ `
          <tr id="${index}" class='tableRow'>
          <td>
            <div class='columns' style="margin: 2px;">
              <h7 class='col-5'>${AInputDate($droute_date)}</h7>
              <h7 style="text-align: right;" class='col-7'>${$device}</h7>
            <h5 class='col-12'>${$routeName}</h5>
          </div>
        </td>  
        </tr>
        `.replace(/\s\s+/g, ' '));
            $('#routes_driven_list').append($row);
        }
        const th = this;
        $('#routes_driven_list').on('click', 'tr', async function () {
            $("table > tbody > tr").each(function () {
                $(this).removeClass('selected');
            });
            $(this).addClass('selected');
            await Loading.waitForPromises(th.onDrivenRowClicked(this.id));
        });
    }
    getGeoMap() {
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetGeoMap_Request", 1, null, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetGeoMap_Response`, response => {
                this.geoMap = response.GeoMap;
                resolve(true);
            });
        });
    }
    getDrivenRoutes() {
        const data = FilterManager.saveExplicit();
        return new Promise((resolve) => {
            CCCClient.SendMessage("PlanRoute_GetDrivenRoutes_Request", 1, data, 0, {
                Type: "ControlCenter",
                IndexNumber: 1,
                CustomerNumber: CCCClient.NodeCustomerNumber,
                ProjectNumber: CCCClient.NodeProjectNumber //CCCClient.NodeProjectNumber
            });
            Events.once(`PlanRoute_GetDrivenRoutes_Response`, response => {
                this.drivenRoutes = [];
                if (response && response.error == null) {
                    this.drivenRoutes = response;
                }
                resolve(true);
            });
        });
    }
    async onDrivenRowClicked(id) {
        let $routeDriven = this.drivenRoutes[id];
        this.routeMapHelperService.removeWaySegments(this.map);
        for (const i in $routeDriven.WaySegmentsDone) {
            if (this.geoMap.WaySegments[$routeDriven.WaySegmentsDone[i]]) {
                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsDone[i]], this.waySegmentColors.green);
            }
            //this.routeMapHelperService.drawWaySegmentGreen(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsDone[i]], 1.0)      
        }
        for (const i in $routeDriven.WaySegmentsToDo) {
            if (this.geoMap.WaySegments[$routeDriven.WaySegmentsToDo[i]]) {
                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsToDo[i]], this.waySegmentColors.red);
            }
            //this.routeMapHelperService.drawWaySegmentRed(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsToDo[i]], 1.0)
        }
        for (const i in $routeDriven.WaySegmentsOnRouteDone) {
            if (this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRouteDone[i]]) {
                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRouteDone[i]], this.waySegmentColors.lime);
            }
            //this.routeMapHelperService.drawWaySegmentLime(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRouteDone[i]], 1.0)
        }
        for (const i in $routeDriven.WaySegmentsOnRoute) {
            if (this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRoute[i]]) {
                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRoute[i]], this.waySegmentColors.orange);
            }
            //this.routeMapHelperService.drawWaySegmentOrange(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOnRoute[i]], 1.0)
        }
        for (const i in $routeDriven.WaySegmentsOther) {
            if (this.geoMap.WaySegments[$routeDriven.WaySegmentsOther[i]]) {
                this.routeMapHelperService.drawWaySegment(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOther[i]], this.waySegmentColors.blue);
            }
            //this.routeMapHelperService.drawWaySegmentBlue(this.map, this.geoMap.WaySegments[$routeDriven.WaySegmentsOther[i]], 1.0)
        }
        this.routeMapHelperService.fitBoundsWaySegments(this.map);
        this.fillInfoScreenDriven(id);
    }
    fillInfoScreenDriven(id) {
        this.showInfoScreen("driven");
        let $routeDriven = this.drivenRoutes[id];
        $('#info_driven_name').text($routeDriven.RouteName);
        $('#info_driven_device').text($routeDriven.DeviceName);
        let $users = "-";
        if ($routeDriven.Users && $routeDriven.Users.length > 0) {
            $users = $routeDriven.Users[0];
            for (let u = 1; u < $routeDriven.Users.length; u++) {
                $users += ", " + $routeDriven.Users[u];
            }
        }
        $('#info_driven_user').text($users);
        let totalDistance = $routeDriven.DistDone + $routeDriven.DistToDo;
        $('#info_driven_distanceroute').text(metersToKilometerText(totalDistance));
        $('#info_driven_distancedriven').text(metersToKilometerText($routeDriven.DistDone));
        let perc = Math.round(($routeDriven.DistDone / totalDistance) * 100);
        $('#info_driven_percentage').text(perc + "%");
        $('#info_driven_from').text(AFormatDate(new Date($routeDriven.From)));
        $('#info_driven_to').text(AFormatDate(new Date($routeDriven.To)));
    }
}
export function css() {
    return ( /*html*/`
    <style>
      .fixTableHead {
        overflow-y: auto;
      }
      .fixTableHead thead th {
        position: sticky;
        background: #F9F9F9;
        top: 0;
      }

      .tableRow {
        background: #ffffff;
      }
      .tableRow:hover {
        background: #F9F9F9;
      }
      .tableRow.selected {
        background: #ECECEC;
      }

      .tableRowInActive {
        background: #F8F8F8;
        color: #BBBBBB;
      }
      .tableRowInActive.selected {
        background: #EBEBEB;
        color: #BBBBBB;
      }
      .tableRowInActive:hover {
        background: #F1F1F1;
      }

      .legend-label.legend-label-sub {
        font-size: 11px;
        line-height: 1.5em;
      }

      .accordion-wrapper > a {
        padding: 15px 8px
      }      

      .accordion-wrapper .tableWrapper {
        max-height: 400px;
        overflow-y: auto;
      }

      #info_overview .display-once {
        background: transparent;
      }

      #info_overview #info_overview_splash {
        display: flex;
        text-align: center;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      
      #info_overview {
        height: 250px;
      }

      .info-block {
        display: flex;
        text-align: center;
        height: calc(100% / 3.0);
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }
      
      .info-block h6,
      .info-block span {
        margin-bottom: 0;
      }

      .mapWrapper {
        position: relative;
        height: calc(100% - 250px);
        width: 100%;
      }

      .aci-map + .legend {
        display: none;
      }

    </style>  
  `);
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>
        <div class="form-group">
          <label class="form-label" for="DeviceName">Device</label>
          <select class="form-select" id="DeviceName">
            <option value="%">All</option>
          </select>
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>

    <div class="flex-child fh">
      <div class="flex-content">
        <div class="splitter two-split fh" style="overflow-y: auto; overflow-x: hidden;">

          <div class="drag-section part-one" style="width: 30%; min-width: 180px;">
            <table class="table fixTableHead">
              <thead>
                <tr>
                  <th><b>Routes Driven</b></th>
                </tr>
              </thead>
              <tbody id="routes_driven_list">
              </tbody>
            </table>
          </div>
          
          <div id="separator1" class="drag-seperator separator"></div>

          <div class="drag-section part-two" style="width: 70%; min-width: 180px;">
            <div id='map_wrapper' class="mapWrapper">
              <div id="map" class="aci-map"></div>
              <div id="map_legend" class="legend legend-opaque">
                <div class="legend-label legend-label-sub">Route Driven</div>

                <div class="legend-item">
                  <div class="route-preview" style="background-color: #00FF00; border-color: #00cf00"></div>
                  <span>With Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #F4FF00; border-color: #d7e000"></div>
                  <span>Without Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #7DCCDF; border-color: #67a6b5"></div>
                  <span>Off Route</span>
                </div>

                <div class="legend-label legend-label-sub mt-1">Route ToDo</div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #fc0303; border-color: #a30000"></div>
                  <span>With Parking</span>
                </div>
                <div class="legend-item">
                  <div class="route-preview" style="background-color: #FF8F0F; border-color: #e08722"></div>
                  <span>Without Parking</span>
                </div>
              </div>
            </div>

            <div id="info_overview">
              <div id="info_overview_splash">
                <div template="${ATemplates.RouteInformation}"></div>
                <!--<h1 class='col-12' style="text-align: center; color: lightgray; margin-top: 70px;">Route Information</h1>-->
              </div>
              <div id="info_overview_driven" class="columns fh" style="display: none;">

                <div class="col-4 info-block">
                  <h6>Route Name</h6>
                  <span id="info_driven_name">ROUTENAME</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Device</h6>
                  <span id="info_driven_device">DEVICE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>User</h6>
                  <span id="info_driven_user">USER</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Route distance</h6>
                  <span id="info_driven_distanceroute">DISTANCE ROUTE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Route driven</h6>
                  <span id="info_driven_distancedriven">DISTANCE DRIVEN</span>
                </div>

                <div class="col-4 info-block">
                  <h6>Percentage done</h6>
                  <span id="info_driven_percentage">PERCENTAGE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>From</h6>
                  <span id="info_driven_from">FROM DATE</span>
                </div>

                <div class="col-4 info-block">
                  <h6>To</h6>
                  <span id="info_driven_to">TO DATE</span>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  `);
}

export class AOffenceOrm {
    async fetchAll(options) {
        return await requestService.fetch({
            AssertValues: true,
            Query: ( /*SQL*/`
        SELECT
          OffenceCode,
          OffenceText,
          OffenceDescription,
          OffencePrice,
          ModificationUser,
          ModificationDevice,
          ModificationTime
        FROM config_offences
        WHERE IF(:OffenceCode IS NULL, true, OffenceCode=:OffenceCode)
        GROUP BY OffenceCode
      `),
            Params: options || {},
            Translate: [],
            Language: Language
        });
    }
    async create(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        // if (found.Rows.length > 0) {
        //   AError.handleSilent(`db entry already exists! config_offences.prdbCode=${options.prdbCode}`, 'prdbCode Already Exists')
        //   return false
        // }
        await requestService.query({
            Query: ( /*SQL*/`
        INSERT INTO config_offences (
          OffenceCode,
          OffenceText,
          OffenceDescription,
          OffencePrice,
          ModificationUser,
          ModificationDevice
        ) VALUES (
          :OffenceCode,
          :OffenceText,
          :OffenceDescription,
          :OffencePrice,
          :ModificationUser,
          :ModificationDevice
        )
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        // await this.applyRestrictions(options)
        return true;
    }
    async update(options) {
        const ModificationUser = _.getUser().User;
        const ModificationDevice = _.getSession().DeviceName;
        await requestService.query({
            Query: ( /*SQL*/`
        UPDATE config_offences
        SET
          OffenceCode=:OffenceCode,
          OffenceText=:OffenceText,
          OffenceDescription=:OffenceDescription,
          OffencePrice=:OffencePrice,
          ModificationUser=:ModificationUser,
          ModificationDevice=:ModificationDevice
        WHERE OffenceCode=:OffenceCode
      `),
            Params: {
                ...options,
                ModificationUser,
                ModificationDevice
            }
        });
        // await this.applyRestrictions(options)
        return true;
    }
    async delete(options) {
        await requestService.query({
            Query: (`DELETE FROM config_offences WHERE OffenceCode=:OffenceCode`),
            Params: options
        });
    }
}

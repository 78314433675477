import { AError } from "../../classes/AError.js";
import { AEngine } from "../../core/AEngine.js";
import { ADetectionService } from "../../services/ADetectionService.js";
import { MAP_OPTIONS } from "../../services/AMapHelperService.js";
import { createMap } from "../../utils/maps.js";
import { DetectionsFullRanged } from "../../utils/query.js";
export class APage {
    constructor() {
        this.SessionMarkers = {};
        this.map = createMap('map', {
            streetViewControl: true,
        });
        this.heatmap = this.prepareHeatmap(this.map);
        this.weightMultiplier = 10;
        this.markers = [];
    }
    init() {
        FilterManager.load();
        mapHelperService.prepareMapItems(MAP_OPTIONS.Default, {
            allowExport: false
        }).catch(AError.handle);
        this.map.fit();
        mapHelperService.displaySessionsOnMap({
            interpolate: false,
            sessions: this.SessionMarkers,
            map: this.map
        });
        $('#RefreshButton').on('click', _ => FilterManager.showFilterWarning().then(_ => this.refresh()));
    }
    prepareHeatmap(map) {
        return new google.maps.visualization.HeatmapLayer({ map, data: [] });
    }
    fetchData(filters) {
        const conditions = [
            'DetectionTime BETWEEN :FromDate AND :ToDate',
            'ParkingSpaceId IS NULL',
            'VehicleCenterLatitude IS NOT NULL',
            'VehicleCenterLongitude IS NOT NULL',
            'GpsValid=1'
        ];
        const { MaxGpsPrecisionEnum } = filters;
        if (MaxGpsPrecisionEnum !== '%')
            conditions.push('GpsPrecision <= :MaxGpsPrecisionEnum');
        return requestService.query({
            Query: (`
                SELECT
                    DetectionId,
                    DetectionDeviceId,
                    VehicleCenterLatitude,
                    VehicleCenterLongitude,
                    VehicleBounds
                FROM (${DetectionsFullRanged}) detections
                WHERE ${conditions.join(' AND ')}
                LIMIT :Limit
            `),
            Params: filters
        });
    }
    transformData(response) {
        const { Columns, Rows } = response;
        const iVehicleCenterLatitude = Columns.indexOf('VehicleCenterLatitude');
        const iVehicleCenterLongitude = Columns.indexOf('VehicleCenterLongitude');
        return Rows.map((row) => {
            const VehicleCenterLatitude = row[iVehicleCenterLatitude];
            const VehicleCenterLongitude = row[iVehicleCenterLongitude];
            return {
                location: new google.maps.LatLng(VehicleCenterLatitude, VehicleCenterLongitude),
                weight: 1 * this.weightMultiplier
            };
        });
    }
    createPolygon(values) {
        const { DetectionId, DetectionDeviceId, VehicleCenterLatitude, VehicleCenterLongitude, VehicleBounds } = values;
        const { coordinates } = mapHelperService.geoJsonToPolygonCoords(VehicleBounds);
        const polygon = new google.maps.Polygon({
            paths: coordinates,
            strokeColor: '#ff0000',
            strokeOpacity: 0.1,
            fillColor: '#ff0000',
            fillOpacity: 0.1,
            strokeWeight: 3,
            zIndex: 10.0
        });
        AEngine.get(ADetectionService).setMarkerIdentifier(polygon, DetectionDeviceId, DetectionId);
        polygon.setMap(this.map);
        return polygon;
    }
    addClickListener(polygon) {
        google.maps.event.addListener(polygon, 'click', purgatoryService.onMarkerClickRealtime);
    }
    refresh() {
        const filters = FilterManager.save();
        FilterManager.setActive(false);
        mapHelperService.destroy(this.markers);
        return Loading.waitForPromises(this.fetchData(filters)).then(response => {
            mapHelperService.updateStaticPolygons();
            FilterManager.setActive(true);
            if (response.Rows.length === 0) {
                return Alerts.noResults();
            }
            this.markers = [];
            for (const row of response.Rows) {
                const [DetectionId, DetectionDeviceId, VehicleCenterLatitude, VehicleCenterLongitude, VehicleBounds] = row;
                const polygon = this.createPolygon({
                    DetectionId,
                    DetectionDeviceId,
                    VehicleCenterLatitude,
                    VehicleCenterLongitude,
                    VehicleBounds
                });
                this.addClickListener(polygon);
                this.markers.push(polygon);
            }
            this.heatmap.setData(this.transformData(response));
            // TODO: Implement heatmap export
            // const exportMap = new AExportMap('scans', { scales: true })
            // exportMap.addMarkers(this.markers)
            // exportMap.allowExport()
            this.map.focusOnMarkers();
        }).catch(AError.handle);
    }
}
export function render() {
    return ( /*html*/`
    <div id="Filters" class="filter-bar side-filter-bar columns">
      <div class="column c-scroll col-12">
        <div class="form-group">
          <label class="form-label" for="FromDate">From</label>
          <input class="form-input" type="date" id="FromDate" required="required">
          <input class="form-input" type="time" id="FromTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="ToDate">To</label>
          <input class="form-input" type="date" id="ToDate" required="required">
          <input class="form-input" type="time" id="ToTime" required="required">
        </div>

        <div class="form-group">
          <label class="form-label" for="MaxGpsPrecisionEnum">MaxGpsPrecision</label>
          <select class="form-select" id="MaxGpsPrecisionEnum">
            <option value="%">All</option>
            <option value="0.1">0.1 Meters</option>
            <option value="0.2">0.2 Meters</option>
            <option value="0.5">0.5 Meters</option>
            <option value="1.0">1.0 Meters</option>
            <option value="2.0">2.0 Meters</option>
            <option value="5.0">5.0 Meters</option>
            <option value="10.0">10.0 Meters</option>
          </select>
        </div>

        <div class="form-group">
          <label class="form-label" for="Limit">Max results</label>
          <input class="form-input" type="number" id="Limit" value="2000">
        </div>
      </div>
      <div class="column col-12">
        <button class="btn btn-primary col-12" id="RefreshButton">Show</button>
      </div>
    </div>
    <div class="flex-child">
      <div id="map" class="aci-map"></div>
    </div>
  `);
}
